import { SystemSettings } from '@/constants/global-config';
import { SessionStorageKeys } from '@/constants/session-storage-keys';
import { GetFromSessionStorage, SetInSessionStorage } from '@/libraries/browser-storage';
import { getCurrentTimestamp } from '@/libraries/date-time';
import { GetSystemSettingById_api } from '@/services/website-settings/services';
import { WebWorker, workerThread } from '@/functions/web-worker';
import jsCookie from 'js-cookie';

export const CheckForMaintenance = () => {
  const worker = new WebWorker(workerThread);
  const reqInterval = 60000;

  // we send a request to the worker using worker.postMessage({ parameter })
  const interval = setTimeout(() => {
    const currentTimestamp = getCurrentTimestamp();
    const prevTimestamp = GetFromSessionStorage(SessionStorageKeys.MAINTAIN_PREV_TIMESTAMP, 1) || 1;
    const milisec = currentTimestamp - prevTimestamp;

    if (milisec > 60000 && jsCookie.get('maintenanceFree') != '1') {
      // console.log('----------work thread start------->>>>')
      SetInSessionStorage(SessionStorageKeys.MAINTAIN_PREV_TIMESTAMP, getCurrentTimestamp());
      worker.postMessage({ api: GetSystemSettingById_api(SystemSettings.MAINTENANCE_ENABLED || '') });
      // console.log('----------work thread end------->>>>')
    } else {
      worker.terminate();
      clearTimeout(interval);
      CheckForMaintenance();
    }
  }, reqInterval);

  // listenenr get responce from worker thread
  worker.addEventListener('message', (event: any) => {
    if (event.data != undefined) {
      if (event.data.setting_value == 1 && jsCookie.get('maintenanceFree') != '1') {
        worker.terminate();
        clearTimeout(interval);
        document.location.href = '/temporary-maintenance';
      } else {
        worker.terminate();
        clearTimeout(interval);
        CheckForMaintenance();
      }
    }
  });
};

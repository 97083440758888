import React from 'react';
import { DrawerSubMenus } from '@/containers/header/top-bar/drawer-menu/drawer-menu-index/drawer-menu-index';

interface IProps {
  activeSubMenu: DrawerSubMenus | null;
  id: DrawerSubMenus;
  title: string;
  handleToggleSubMenu: (target: DrawerSubMenus) => void;
}

const DrawerBodyDropdownListItem: React.FC<IProps> = ({ handleToggleSubMenu, id, title, activeSubMenu }) => (
  <li onClick={() => handleToggleSubMenu(id)} className={`dropdown_drawerBodyListItem dropdown_drawerBodyDropdownListItem`}>
    <p className={`dropdown_drawerBodyListItemText dropdown_drawerBodyDropdownListItemText`}> {title} </p>
    {activeSubMenu !== id && <p className={'dropdown_drawerBodyDropdownListItemTextBtn'}>&#43;</p>}
    {activeSubMenu === id && <p className={'dropdown_drawerBodyDropdownListItemTextBtn'}>&#8722;</p>}
  </li>
);

export default DrawerBodyDropdownListItem;

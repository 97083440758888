import { Pages } from '@/constants/pages';
import { useAppSelector, useAppDispatch } from '@/hooks/redux-hooks';
import { DeepClone } from '@/libraries/utility-methods';
import { checkProductOnlyForSale, discountAndPriceCalculation, getUniqueItems, cartTimerInfo } from 'components/shared/globalFunctions';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import CartPopperProductsListItem from '@/containers/header/top-bar/cart-btn/cart-popper/cart-popper-cart-items/cart-popper-products-list-item/cart-popper-products-list-item';
import { getCustomerCart } from "components/shared/cartFunctions";
import styles from './cart-popper-cart-items-index.module.scss';
import Gconfig from 'globalconfig';
import moment from 'moment';

interface IProps {
  _saveForLaterCount:any,
  isPoperShown: any
}

let timerIntervalId:any = null
let cartRemaingTime:any = 0
let timerInfo:any = null
let _lastChildType = false 

const CartPopperCartItemsIndex: React.FC<IProps> = ({_saveForLaterCount, isPoperShown}) => {
  const customerData = useAppSelector((state) => state.customer);
  const cartData = useAppSelector((state) => state.cart);
  const systemSettingsData = useAppSelector((state) => state.systemSettings);

  const [salePrice, setSalePrice] = useState(0.0);
  const [cartCount, setCartCount] = useState(0);
  const [isTimerUpdate, setIsTimerUpdate] = useState(false)

  const dispatch = useAppDispatch();

  const handleCountCartItems = (cartType: number, productCategory: number, isHoldItem) => {
    const saleItemOnly = cartData.cart?.items.filter((item) => (cartType != 0 && item.cartItemType === 2 && item.isSelectedSizeAvailable == true) || (item.isSelectedSizeAvailable == false && cartType == 0 && item.cartItemType === 2)) || [];
    const rentalItemOnly = cartData.cart?.items.filter((item) => (cartType != 0 && item.cartItemType === 1 && item.isSelectedSizeAvailable == true) || (item.isSelectedSizeAvailable == false && cartType == 0 && item.cartItemType === 1)) || [];
    let unique = getUniqueItems(saleItemOnly, false);
    unique = unique.concat(rentalItemOnly);

    let count = 0;
    if (cartType != 0) {
    unique.map((item) => {
      if (item.cartItemType === cartType && productCategory === 0) count += 1;
      else if (productCategory !== 0) {
        if (cartType === 1 && item.cartItemType === cartType && item.sku.product.product_catagory === productCategory && ((item.sku_hold_notes_id > 0 && isHoldItem) || (!isHoldItem && !item.sku_hold_notes_id)  )) count += 1; // count for accessory or clothing rentals
        else if (cartType === 1 && item.cartItemType === cartType && item.sku.product.product_catagory === productCategory + 1 && productCategory !== 1) count += 1; // count for accessory or clothing purchase
      }
    });
    return count;
    }
    else {
      return unique.length
    }
  };



  useEffect(() => {
    if (customerData.customer === null || customerData.customer === 'not_login') {
      if (cartData.cart !== null && cartData.cart.items) {
        const uniqueItems = getUniqueItems(cartData.cart.items, false);
        setCartCount(uniqueItems.length);
      }
    } else if (cartData.cart !== null && cartData.cart.items) {
      const saleItemOnly = cartData.cart.items.filter((item) => item.cartItemType === 2);
      const rentalItemOnly = cartData.cart.items.filter((item) => item.cartItemType === 1);
      const uniqueSaleItems = getUniqueItems(saleItemOnly, false);
      setCartCount(uniqueSaleItems.length + rentalItemOnly.length);
      let rentalCount = 0
      cartData.cart.items.map((item) => { if(item.cartItemType === 1) { rentalCount ++ } });
      
      if(rentalCount <= 1) {
        clearInterval(timerIntervalId)
        timerIntervalId = null
        if(rentalCount == 1) { _timer() }
        setTimeout(() => {
          setIsTimerUpdate(prev => !prev)
        },1000) 
      }
      else if(rentalCount > 1){
        if(timerIntervalId == null){
          _timer()
          setTimeout(() => {
            setIsTimerUpdate(prev => !prev)
          },2000)
        }
      }
    } else setCartCount(0);
  }, [customerData.customer, cartData.cart]);

  useEffect(() => {
    const cartDataClone = cartData.cart !== null ? DeepClone(cartData.cart) : null;
    let tempSalePrice = 0;
    // let has_newitem = false;
    // let discountVal = 0;


    cartDataClone?.items.forEach((item) => {
      const onlyForSale = checkProductOnlyForSale(item.sku.product);
      const priceObj = discountAndPriceCalculation(customerData.customer, item, onlyForSale, systemSettingsData.systemSettings);
      if (item.cartItemType === 2) {
        item.qty = 0;
        tempSalePrice += priceObj.price;
      }
    });

    setSalePrice(tempSalePrice);
  }, [cartData.cart]);


    function _timer() {
      let oneSecDiff:any = localStorage.getItem('cartPoperHover')
      if(oneSecDiff) {
        oneSecDiff = parseInt(oneSecDiff) + 1
        if(oneSecDiff < moment().unix()) {
          localStorage.setItem('cartPoperHover', ''+moment().unix())
          
          if(timerIntervalId == null) {
              timerInfo = cartTimerInfo(cartData.cart)     
              if(timerInfo.isTimerValid) {
                  clearInterval(timerIntervalId)
                  timerIntervalId = null
                  cartRemaingTime = timerInfo?.differenceInSeconds
                  updateCartTimer()
              }
              else {
                  clearInterval(timerIntervalId)
                  timerIntervalId = null
                  cartRemaingTime = 0
              }   
          }
        }
      }
      else {
        clearInterval(timerIntervalId)
        timerIntervalId = null
        localStorage.setItem('cartPoperHover', ''+moment().unix())
      }
    }

    _timer()
   
    function updateCartTimer() {
      timerIntervalId = setInterval(() => {
          if(cartRemaingTime > 0) {
              cartRemaingTime = cartRemaingTime - 1
              if(document.getElementById('cartPoperTimerSpan')) {
                document.getElementById('cartPoperTimerSpan').innerHTML = `${Math.floor(cartRemaingTime / 60)} min ${cartRemaingTime % 60} sec`
              }
          }
          else {
            clearInterval(timerIntervalId)
              timerIntervalId = null
              cartRemaingTime = 0
              let rentalCount = 0
              cartData.cart.items.map((item) => { if(item.cartItemType === 1) { rentalCount ++ } });
              if(rentalCount > 0) {
                setTimeout(() => {
                  setIsTimerUpdate(prev => !prev)
                  dispatch(getCustomerCart())
                },2000)
              }
          }
      }, 1000)
  }

  function isAnyHoldItem() {
    let isAnyHoldItem = false
    if (cartData.cart !== null && cartData.cart?.items ) {
      cartData.cart.items.map((item:any) => {
          if(item.cartItemType === 1 && item.sku_hold_notes_id > 0) {
            isAnyHoldItem = true
          }
      });
    }
    return isAnyHoldItem
  }
  

  return (
    <div className={styles.container}>
      {customerData.customer != 'not_login'  ? <div className={styles.checkoutHeader} >
        <p className={styles.checkoutHeaderMsg} > CART ({cartData.cart?.items?.length}) </p>
        <p className={styles.checkoutHeaderMsg}> SAVED FOR LATER ({_saveForLaterCount}) </p>
      </div> : "" }
      <div className={styles.productsListcontainer}>

        <CartPopperProductsListItem popp_id={1} title="ON HOLD FOR" cartType={1} productCategory={1} cartCount={handleCountCartItems(1, 1, true)} saveForLaterCount={_saveForLaterCount}  isHoldItemComp={true}/>
        <CartPopperProductsListItem popp_id={1} title="ON HOLD FOR" cartType={1} productCategory={2} cartCount={handleCountCartItems(1, 2, true)} saveForLaterCount={_saveForLaterCount}  isHoldItemComp={true}/>
        
         {customerData.customer != 'not_login' && customerData.customer.has_subscription && timerIntervalId != null ? <div className={styles.popperItemsTimer}>
          <img src={Gconfig.fashionpassImageStorage+'assets/img/pinkInfoIcon.svg'}/>
          <p> { isAnyHoldItem() ? 'We will reserve the rest of the items  for the next ' : 'Items in your cart will be reserved for the next ' }<span id="cartPoperTimerSpan"> {Math.floor(cartRemaingTime / 60)} min {cartRemaingTime % 60} sec</span></p>
        </div> : "" }
        
        {/* sold out items */}
        <CartPopperProductsListItem title="" cartType={0} productCategory={0} cartCount={handleCountCartItems(0, 0)} /> 
        {/* end sold out item */}
        <CartPopperProductsListItem popp_id={2} title="Clothing Rental" cartType={1} productCategory={1} cartCount={handleCountCartItems(1, 1)} saveForLaterCount={_saveForLaterCount} />
        <CartPopperProductsListItem popp_id={3} title="Accessory Rental" cartType={1} productCategory={2} cartCount={handleCountCartItems(1, 2)} saveForLaterCount={_saveForLaterCount} />
        <CartPopperProductsListItem popp_id={4} title="Purchase Item" cartType={2} productCategory={0} cartCount={handleCountCartItems(2, 0)} saveForLaterCount={0} />
        <div className={styles.checkoutContainer}>
          {handleCountCartItems(2, 0) > 0 && (
            <div className={styles.checkoutTotals}>
              <span> TOTAL: </span>
              <span> ${salePrice} </span>
            </div>
          )}
          <Link className={styles.checkoutBtn} href={`${Pages.CART}${customerData.customer !== 'not_login' && customerData.customer !== null ? '?checkout=true' : ''}`}>
            Checkout
          </Link>
        </div>
      </div>
    </div>  
  );
};

export default CartPopperCartItemsIndex;

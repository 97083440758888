import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './holdItemPopup.module.scss'
import { releaseHoldItem, updateCustomerPrefrenceForHoldItem } from 'components/shared/globalFunctions';
import jsCookie from 'js-cookie';
import Router from 'next/router';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';

type Props = {
    isOpen: any,
    toggle: any,
    holdItemData: any,
    removeHoldItemFromCart: any
    _this:any,
    from: any,
    holdItemId: any,
    isReleasedHoldFromSaveLater:any,
    holdItemProd:any,
    addHoldItemInSaveForLater:any
}

const HoldItemPopup = (props: Props) => {
    const [openModal, setOpenModal] = useState(true)

    const toggle = () => {
        setOpenModal(!openModal)
    }

    const dispatch = useAppDispatch();

    const releasedHoldItem = async () => {
  
        if (props.holdItemId) {

                if(props.isReleasedHoldFromSaveLater) {
                    jsCookie.remove('sku_hold_item_obj')
                    props.addHoldItemInSaveForLater(props.holdItemData, props.holdItemProd, true)
                    props.toggle()
                }
                else { 
                    props.removeHoldItemFromCart(props.holdItemData, props.from == 'cart_section' ? true : props._this)
                    releaseHoldItem(props.holdItemId)
                    props.toggle()
                    jsCookie.remove('sku_hold_item_obj')
                    Router.push('/releasedItem');
                }

              
        }
        else {
            props.toggle()
        }
    }

    return (
        <div>
            <Modal className={styles.hold_container} isOpen={props.isOpen} centered contentClassName={styles.content_modal}>
                <ModalBody>
                    <p className={styles.heading_text}>Are you sure you want to <br />release your hold item?</p>
                    <div className={styles.btn_wrap}>
                        <p className={styles.release_btn} onClick={() => releasedHoldItem()}>RELEASE HOLD ITEM</p>
                        <p className={styles.nevermind_btn} onClick={() => props.toggle()}>NEVERMIND</p>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default HoldItemPopup
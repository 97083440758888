import { StoreThunk } from '@/redux/store';
import { fetchSpotsSuccess } from '@/redux/actions/spotsActions';
import { SpotsDataType } from '@/redux/reducers/spotsReducer';

export const setSpotsLeft = (data: SpotsDataType): StoreThunk => {
  return async (dispatch, getState) => {
    // data.total = 8;
    // data.socialite = 5
    // data.trensetter = 1
    // data.wonderlust = 2
    dispatch(fetchSpotsSuccess(data));
  };
};

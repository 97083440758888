import React from 'react';
import styles from './cart-popper-empty-cart.module.scss';
import { useAppSelector } from '@/hooks/redux-hooks';

interface IProps {}

const CartPopperEmptyCart: React.FC<IProps> = () => {
  
  const cartData = useAppSelector((state) => state.cart);
  const customerData = useAppSelector((state) => state.customer);
  var _savedLaterCount = -1
  if(customerData.customer != 'not_login' &&  cartData.savedLaterCount.totalCount != undefined){
    _savedLaterCount = cartData.savedLaterCount.totalCount
  }
  
  return ( 
    customerData.customer != 'not_login'  ?
    <div className={styles.container}>{_savedLaterCount <= 0 ? (customerData.customer == 'not_login' ? 'Your shopping bag is currently empty' : <p className='mb-0 text-center' style={{ fontSize:'12px' }}> Your shopping bag is currently empty. <br/> Save Items for later to plan your next order! </p>  ) : <p className={styles.save_count_msg}> You have {_savedLaterCount} saved for later item{_savedLaterCount > 1 ? 's':''}. <br/> Organize them to prepare for your next order! </p>} </div> : 
    <div className={styles.container}>Your shopping bag is currently empty </div>
  );
};

export default CartPopperEmptyCart;

import { CookieKeys } from '@/constants/cookie-keys';
import { API } from '@/constants/global-config';
import { CheckForMaintenance } from '@/functions/check-for-maintenance';
import { handleSetupLiveChatWidget } from '@/functions/live-chat-widget';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { GetCookie, RemoveCookie } from '@/libraries/browser-storage';
import { FullStoryMethods } from '@/libraries/fullstory';
import { GoogleAnalytics } from '@/libraries/google-analytics';
import { TabletView } from '@/libraries/responsive';
import { setBlackFridayBarRenderingConfig } from '@/redux/actions/renderingConfigActions';
import { DoesWindowExists } from '@/utils/window-methods';
import NotificationBarIndex from '@/containers/header/notification-bar/notification-bar-index/notification-bar-index';
import TanBar from '@/containers/header/tan-bar/tan-bar';
import TopBarIndex from '@/containers/header/top-bar/top-bar-index/top-bar-index';
import NavBarIndex from '@/containers/header/nav-bar/nav-bar-index/nav-bar-index';
import React, { useEffect, useRef, useState } from 'react';
import { getSystemSettings } from '@/redux/thunks/systemSettingsThunks';
import ProductListHeadersIndex from '../product-list-headers/product-list-headers-index/product-list-headers-index';
import { LeftBarDataType_currentCat } from '@/redux/reducers/collectionPageReducer';
import { CategoryDataType } from '@/services/collections';
import dynamic from 'next/dynamic';
import jsCookie from 'js-cookie';
import { getCustomerDefaultPaymentMethod } from 'components/shared/globalFunctions';
import { GetDefaultPaymentMethod_ResponseType, PaymentMethodDataType } from '@/services/customer';
import styles from './header-index.module.scss';

const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import Router from 'next/router';
// import TransferRentedItemsModal from 'components/shared/transferRentedItemsModal';
// import CustomerSurveyPopupIndex from '@/containers/customer-surveypopup/customer-surveypopup-index';
import Gconfig from 'globalconfig';
import SmsEmailVerificationIndex from '@/containers/sms-email-verification/sms-email-verification-index';
import { addProductToCartFromFunctionComp } from "components/shared/cartFunctions"
import { triggerCartPopper } from "redux/actions/cartActions";
import MobileBreadCrumbToggle from "components/shared/mobileBreadCrumps.js"
import moment from 'moment';
import { TextExistsInString } from '@/utils/string-methods';
import { addingHoldItemIntoCart, removeProductFromCart, getCustomerCart } from "components/shared/cartFunctions";
import { getCustomerTags, updateCustomerPrefrenceForHoldItem, getProductDataByProductID, releaseHoldItem, updateCustomerTags, addCustomerViewProductActivity, pauseDelayRequest } from 'components/shared/globalFunctions'
import Login from 'components/shared/login';
import NewPlanAggrementPopup from '@/containers/new-plan-aggrement-popup/agreement-popup';
import NewPlanBanner from "@/containers/new-plans/new-plan-banner/new-plan-banner"
import { checkUserAthentication } from '@/redux/thunks/customerThunks';
import Cookiepopup from "@/containers/cookie-popup/cookie-popup-index";
import { fetchCustomerSuccess } from '@/redux/actions/customerActions';
import PauseDelayPopupp from 'components/shared/pausedDelayPopup'
interface IProps {
  // faqPageSectionsData?: faqPageSectionsDataType;
  canShowBlackBox?: boolean;
  collectionPageData?: { isForSale: boolean; handleSizeToggle: (data: string) => Promise<void> };
  isProductListingPage?: boolean;
  isSignup?: boolean;
}

let scrolledPossition = 50;
let prevTime = 0
let filterHideVar = false

const HeaderIndex: React.FC<IProps> = ({ canShowBlackBox, isProductListingPage }) => {
  const dispatch = useAppDispatch();
  let previousScrolVal = 0;
  let scrolledPossition = 50;
  let prevTime = 0
  let filterHideVar = false

  const customerData = useAppSelector((state) => state.customer);
  const notificationData = useAppSelector((state) => state.notification);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);
  const cart = useAppSelector((state) => state.cart);

  const [calledObjectSetter, setCalledObjectSetter] = useState(false);
  const [headerLoading, setHeaderLoading] = useState(true);
  const [blackFridayPopupClose, setBlackFridayPopupClose] = useState(false);
  const [waitlistURL, setWaitlistURL] = useState(API.BASE_URL_WAITLIST_URL || '');
  const desktopMenu = useAppSelector((state) => state.catsDesktopmenu);
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethodDataType | null>(null);
  const [nextItemTransferPopup, setNextItemTransferPopup] = useState(false)
  const [isTransferPopupViewed, setIsTransferPopupViewed] = useState(false)
  const [isVerificationSuccesClose, setIsVerificationSuccesClose] = useState(false)
  const [isFavoritePage, setIsFavoritePage] = useState(false)
  const [msg, setMsg] = useState({ type: "", msg: "" })
  const [newPlanAggrement, setNewPlanAggrement] = useState(false)

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [hideGlowUpBanner, setHideGlowUpBanner] = useState(false);
  const [pauseDelayPopup , setPauseDelayPopup] = useState(false);
  const [isGettingNPSFromUser, setIsGettingNPSFromUser] = useState(false)


  useEffect(() => {
    CheckForMaintenance();
    GoogleAnalytics.initialize();

    if (location && (location?.href?.indexOf('boardShared') > -1 || location?.href?.indexOf('cart') > -1)) {
      setIsFavoritePage(true)
    }
    if (location && location?.href?.indexOf('glow-up') > -1) {
      setHideGlowUpBanner(true)
    }
    

    if (typeof window != undefined && window.location && window.location.href.indexOf('utm_campaign=') > -1) {
      let code = window.location.href.split('utm_campaign=')[1];
      jsCookie.set('utm_campaign_code', code, { expires: 1 });
    }

    if (typeof window != undefined && window.location && window.location.href.indexOf('paused/festive') > -1) {
      let code = 'FESTIVE'
      if (Gconfig.environment != 'production' && location.host != 'www.fashionpass.com') {
        code = 'FRIDAYTEST'
      }
      jsCookie.set('black_friday_campaign_code', code, { expires: 1 });
    }

    let processedWaitlistURL = waitlistURL || '';
    if (customerData.customer !== 'not_login') {
      processedWaitlistURL = `${processedWaitlistURL}?email=${btoa(customerData.customer.email) || ''}&loggedin=true`;

      if (customerData.customer.has_subscription) dispatch(getSystemSettings(false, false, true));
      else dispatch(getSystemSettings(false, false));
      if (jsCookie.get('SMSVerificationPending') != undefined) {
        dispatch(getCustomerTags())
      }
      FullStoryMethods.identify(customerData.customer.customer_id.toString(), {
        name: customerData.customer.first_name + ' ' + customerData.customer.last_name,
        displayName: customerData.customer.first_name + ' ' + customerData.customer.last_name,
        email: customerData.customer.email,
        lastorderID: customerData.customer.last_order_id.toString(),
        Subscription_End_date: customerData.customer.subscription_end_date || '',
        Subscription: customerData.customer.subscription_name || '',
        Tags: customerData.customer.tag_list.join(),
      });

      if (!customerData?.customer?.tag_list?.toString()?.includes('SKIP_NEW_PLAN')) {
        let userTagString = customerData?.customer?.tag_list?.toString()?.toLowerCase()
        if(userTagString?.indexOf('popupacceptedat') > -1 && userTagString?.indexOf('plan_name:fashionpass') == -1 && userTagString?.indexOf('paused') == -1){
          updateCustomerTags('plan_name:FashionPass', 'plan_name:Socialite,plan_name:Trendsetter,plan_name:Wanderlust,DowngradeToSocialite,DowngradeToTrendsetter').then(response => response.json()).then(data => {
            if (data.success == true) {
                let customer = customerData.customer
                customer.tag_list = data.tags;
                dispatch(fetchCustomerSuccess(customer))
              }
          })
        }
      }
    } else {
      dispatch(getSystemSettings(false, false));
      processedWaitlistURL = waitlistURL || '';
    }

    setHeaderLoading(false);
    setWaitlistURL(processedWaitlistURL);
    setBlackFridayPopupClose(GetCookie(CookieKeys.BLACK_FRIDAY_POPUP_CLOSE) || false);

    if (location.href?.indexOf('verification=true') > -1) {
      // jsCookie.set('fromVerificationLink', true)
      let params = new URLSearchParams(location.href)
      if (location.href?.indexOf('phone_number') > -1) {
        if (params.get('verfcode') != null && params.get('verfcode') != undefined) {
          let code = params.get('verfcode')
          let phone = params.get('phone_number')
          jsCookie.set("verficationPhone", phone)
          jsCookie.set("verficationPhoneCode", code)
        }
      }
      if (location.href?.indexOf('email') > -1) {
        if (params.get('verfcode') != null && params.get('verfcode') != undefined) {
          let code = params.get('verfcode')
          let email = params.get('email')
          jsCookie.set("verficationEmail", email)
          jsCookie.set("verficationEmailCode", code)
        }
      }
      if (customerData.customer == 'not_login') {
        location.href = '/?openlogin'
      }
    }

    if (location.href?.indexOf('tranferitem=true') > -1) {
      let params = new URLSearchParams(location.href)
      let orderid = params.get('orderid')
      let skuid = params.get('skuid')
      jsCookie.set("cancelTransferCookie",orderid+'-'+skuid)
      if(typeof window != undefined && window.location) {
        window.history.pushState('', '', '/');
      }
    }

    if(customerData.customer != 'not_login' && jsCookie.get('freethirdshipment') != undefined && customerData?.customer?.tag_list?.toString()?.toLowerCase()?.indexOf('freethirdshipment') > -1){
      jsCookie.remove('freethirdshipment')
    }
    
  }, []);

  useEffect(() => {
    if (DoesWindowExists() && customerData.customer !== 'not_login' && !calledObjectSetter) {
      setCalledObjectSetter(true);
      FullStoryMethods.identify(customerData.customer.customer_id.toString(), {
        name: customerData.customer.first_name + ' ' + customerData.customer.last_name,
        displayName: customerData.customer.first_name + ' ' + customerData.customer.last_name,
        email: customerData.customer.email,
        lastorderID: customerData.customer.last_order_id.toString(),
        Subscription_End_date: customerData.customer.subscription_end_date || '',
        Subscription: customerData.customer.subscription_name || '',
        Tags: customerData.customer.tag_list.join(),
      });

      handleSetupLiveChatWidget(customerData.customer);

      if (customerData.customer.has_subscription && renderingConfigData.show.blackfridayBar) {
        RemoveCookie(CookieKeys.BLACK_FRIDAY_POPUP_CLOSE);
        dispatch(setBlackFridayBarRenderingConfig('hide'));
      }
    }
    if (window.location.href.indexOf('#plans') > -1 && customerData.customer != 'not_login' && customerData.customer.has_subscription == true) {
      Router.push('/my-account?tab=6');
    }
    let assignedHome = null;
    // if (customerData.customer != 'not_login') {
    //   console.log('home ab condition1');
    //   let fetchedPrefs = customerPrefrence.customerPreferences;
    //   if (fetchedPrefs.preferences !== null) {
    //     let homePageAB: any = fetchedPrefs.preferences && fetchedPrefs.preferences.homePageABversion && JSON.parse(fetchedPrefs.preferences.homePageABversion);
    //     if (fetchedPrefs.preferences && fetchedPrefs.preferences.homePageABversion) {
    //       assignedHome = homePageAB.id;
    //       jsCookie.set('customerHomeAB', homePageAB.id);
    //     } else {
    //       let obj: any = { id: jsCookie.get('customerHomeAB') };
    //       assignedHome = jsCookie.get('customerHomeAB');
    //       if (jsCookie.get('customerHomeAB') == undefined || jsCookie.get('customerHomeAB') == null) return;
    //       obj = JSON.stringify(obj);
    //       dispatch(updateCustomerPreferences(obj, 'homePageABversion', {}));
    //     }
    //   } else {
    //     let obj: any = { id: jsCookie.get('customerHomeAB') };
    //     assignedHome = jsCookie.get('customerHomeAB');
    //     if (jsCookie.get('customerHomeAB') == undefined || jsCookie.get('customerHomeAB') == null) return;
    //     obj = JSON.stringify(obj);
    //     dispatch(updateCustomerPreferences(obj, 'homePageABversion', {}));
    //   }
    // } else {
      // if((customerData.customer != 'not_login' && !customerData.customer.has_subscription) || customerData.customer == 'not_login'){
      //   assignedHome = jsCookie.get('customerHomeAB');
      // }
    // }
    // if (typeof window != undefined && window !== undefined && window.location.pathname == '/' && assignedHome != undefined && window.location.href.indexOf('token=') == -1 && window.location.href.indexOf('#plans') == -1) {
    //   // Router.push(`/?${assignedHome}`)
    //   if (assignedHome.indexOf('B') > -1) {
    //     Router.push(`/?B`)
    //   }
    //   else {
    //     Router.push(`/?A`)
    //   }
    // }

    // if (customerData.customer != 'not_login' && customerData.customer.has_subscription == true) {
    //   let userTagstring = customerData.customer.tag_list.join().toLowerCase();
    //   if (userTagstring.indexOf('transfer_popup') == -1 && sessionStorage.getItem('hide_transfer_popup') == undefined && customerData.customer.last_order_id != 0 && (userTagstring.indexOf('verification') == -1 || (userTagstring.indexOf('verification') > -1 && userTagstring.indexOf('email_verified') > -1 && userTagstring.indexOf('phone_verified') > -1))) {
    //     setNextItemTransferPopup(true)
    //   }
    //   else {
    //     setNextItemTransferPopup(false)
    //   }

    //   if (userTagstring.indexOf('transfer_popup') == -1 && sessionStorage.getItem('hide_transfer_popup') == undefined) {
    //     setIsTransferPopupViewed(false)
    //   }
    //   else {
    //     setIsTransferPopupViewed(true)
    //   }

    // }

    if (customerData.customer != 'not_login' && customerData.customer.has_subscription == true) {
      let kalviyoPopup: any = document.getElementsByClassName('needsclick')[0];
      if (kalviyoPopup && kalviyoPopup != undefined) {
        kalviyoPopup.style.display = 'none';
      }
      let params = new URLSearchParams(location.href)
      if (location.href?.indexOf('verification=true') > -1 && location.href?.indexOf('phone_number') > -1 && customerData.customer.tag_list.join().indexOf('Phone_Verified') == -1) {
        if (params.get('verfcode') != null && params.get('verfcode') != undefined) {
          let code = params.get('verfcode')
          let phone = params.get('phone_number')
          jsCookie.set("verficationPhone", phone)
          jsCookie.set("verficationPhoneCode", code)
        }
      }
      if (location.href?.indexOf('verification=true') > -1 && location.href?.indexOf('email') > -1 && customerData.customer.tag_list.join().indexOf('Email_Verified') == -1) {
        if (params.get('verfcode') != null && params.get('verfcode') != undefined) {
          let code = params.get('verfcode')
          let email = params.get('email')
          jsCookie.set("verficationEmail", email)
          jsCookie.set("verficationEmailCode", code)
        }
      }
    }

    if (customerData.customer === 'not_login') return;
        dispatch(getCustomerDefaultPaymentMethod(false)).then((data: GetDefaultPaymentMethod_ResponseType) => {
          if (data && data.success === true) setDefaultPaymentMethod(data.pay_method);
        });

        if (customerData.customer != 'not_login' && customerData.customer.has_subscription == true) {
          let userTagstring = customerData.customer.tag_list.join().toLowerCase();
          if (userTagstring.indexOf('survey_submitted') > -1 && userTagstring.indexOf('transfer_popup') == -1 && sessionStorage.getItem('hide_transfer_popup') == undefined) {
            setNextItemTransferPopup(true)
          }
          else {
            setNextItemTransferPopup(false)
          }

          if (userTagstring.indexOf('transfer_popup') == -1 && sessionStorage.getItem('hide_transfer_popup') == undefined) {
            setIsTransferPopupViewed(false)
          }
          else {
            setIsTransferPopupViewed(true)
          }
    }

    if (customerData.customer != 'not_login' && customerData.customer.has_subscription == true) {
      let fetchedPrefs = customerPrefrence.customerPreferences;
      if (fetchedPrefs.preferences && fetchedPrefs.preferences !== null && fetchedPrefs.preferences?.addProductToCart != 'null' && fetchedPrefs.preferences.addProductToCart != undefined) {
        let addProductToCartPrefObj = JSON.parse(fetchedPrefs.preferences.addProductToCart)
        dispatch(addProductToCartFromFunctionComp(() => dispatch(triggerCartPopper(true)), addProductToCartPrefObj, setMsg, true, 1))
        dispatch(updateCustomerPreferences(null, 'addProductToCart', {}));
      } else if (jsCookie.get('addProductToCartCookie') != undefined) {
        let addCartToProductCookie = jsCookie.get('addProductToCartCookie')
        let addProductToCartPrefObj = JSON.parse(addCartToProductCookie)
        addProductToCartPrefObj.customer_email = customerData.customer.email;
        addProductToCartPrefObj.customer_id = customerData.customer.customer_id;
        dispatch(addProductToCartFromFunctionComp(() => dispatch(triggerCartPopper(true)), addProductToCartPrefObj, setMsg, true, 1))
        jsCookie.remove('addProductToCartCookie')
      }
    }

  }, [customerData.customer]);

  useEffect(() => {
    if (canShowBlackBox && !blackFridayPopupClose) setBlackFridayPopupClose(true);
  }, [canShowBlackBox, blackFridayPopupClose]);

  useEffect(() => {
    if (customerData) {
      if (customerData?.customer !== "not_login" && !customerData?.customer?.tag_list?.toString()?.includes('SKIP_NEW_PLAN')) {
          let userTagString = customerData?.customer?.tag_list?.toString()?.toLowerCase()
          if(userTagString?.indexOf('plan_name') > -1 && userTagString?.indexOf('plan_name:fashionpass') == -1 && userTagString?.indexOf('popupacceptedat') == -1){
            setNewPlanAggrement(true)
          }
      }
    }

    if(customerData?.customer !== "not_login" && typeof window != undefined && window != undefined && window.location.pathname.indexOf('my-account') == -1) {
      if (jsCookie.get('getNpsScore') != undefined) {
        if(customerData?.customer.customer_id != jsCookie.get('getNpsScore')){
          jsCookie.remove('getNpsScore')
        }
        else {
          setIsGettingNPSFromUser(true)
          setPauseDelayPopup(true)
        }
      }
    }

  }, [customerData])
  
  useEffect(() => {
    // check hold item for user
    if(Router && Router.query && Router.query['sku_hold_notes_id'] != undefined) {
      let obj:any = {
        id: "",
        time: "",
        isReleasing: false,
        itemAddedToCart: false,
        customer_id: "",
        isEligibleToCheckout: false,
        productId: ''
      }

      if(Router.query['sku_hold_notes_id'] != undefined) obj.id = Router.query['sku_hold_notes_id'];
      if(Router.query['timestamp'] != undefined) obj.time = Router.query['timestamp'];
      if(Router.query['release'] != undefined) obj.isReleasing = Router.query['release'];
      if(Router.query['customer_id'] != undefined) obj.customer_id = Router.query['customer_id'];
      if(Router.query['product_id'] != undefined) obj.productId = Router.query['product_id'];
      
      jsCookie.set('sku_hold_item_obj', JSON.stringify(obj))
      
      
      if(customerData.customer == 'not_login' && (obj.isReleasing == false || obj.isReleasing == "false")) {
          setOpenLoginModal(true);
          setTimeout(() => {
            // setOpenLoginModal(false);
          }, 500);
      }

      updateUserCartForHoldItem(obj)
    }
    else if (jsCookie.get('sku_hold_item_obj') != undefined) {
      // if id is saved in cookie becuase user was not login 
      let obj = jsCookie.get('sku_hold_item_obj');
      let parseObj = JSON.parse(obj)
      updateUserCartForHoldItem(parseObj)
    }

    // else {
    //   let fetchedPrefs = customerPrefrence.customerPreferences;
    //   if (fetchedPrefs.preferences !== null) {
    //     let prefSkuHoldObj: any = fetchedPrefs.preferences && fetchedPrefs.preferences.sku_hold_item_info && JSON.parse(fetchedPrefs.preferences.sku_hold_item_info);
    //     if (fetchedPrefs.preferences && fetchedPrefs.preferences.sku_hold_item_info && fetchedPrefs.preferences.sku_hold_item_info != null  && fetchedPrefs.preferences.sku_hold_item_info != "null") {
    //       jsCookie.set('sku_hold_item_info', JSON.stringify(prefSkuHoldObj));
    //     }
    //   }
    // }


    if (customerData.customer != 'not_login' && customerData.customer?.has_subscription == true) {
      let currentTimestamp =  moment()?.valueOf();
      currentTimestamp = currentTimestamp ? parseInt(currentTimestamp / 60000) : 0
      if (jsCookie.get('lastCustomerSummaryFetchedTime')) {
        let prevTimestamp =  jsCookie.get('lastCustomerSummaryFetchedTime') 
        if(prevTimestamp) {
          let minutes = currentTimestamp - parseInt(prevTimestamp);
          if(minutes > 60) {
            dispatch(checkUserAthentication(undefined, true));
            jsCookie.set('lastCustomerSummaryFetchedTime', currentTimestamp)
          }
        }
        else {
          let currentTimestamp = moment()?.valueOf();
          currentTimestamp = parseInt(currentTimestamp / 60000)
          jsCookie.set('lastCustomerSummaryFetchedTime', currentTimestamp)
        }
      }
      else {
        let currentTimestamp = moment()?.valueOf();
        currentTimestamp = parseInt(currentTimestamp / 60000)
        jsCookie.set('lastCustomerSummaryFetchedTime', currentTimestamp)
      }
    }


    if (customerData.customer != 'not_login') {
        let currentTimestamp_prod : any =  moment()?.valueOf();
        currentTimestamp_prod = currentTimestamp_prod ? parseInt(currentTimestamp_prod / 60000) : 0
        
        if (jsCookie.get('lastProductViewActivityUpdateTime')) {
          let prevTimestamp_prod =  jsCookie.get('lastProductViewActivityUpdateTime') 
          if(prevTimestamp_prod) {
            let minutes = currentTimestamp_prod - parseInt(prevTimestamp_prod);
            if(minutes > 1) {
              if(localStorage.getItem('customerViewedProductsArr')) {
                let customerViewedProductArr = JSON.parse(''+localStorage.getItem('customerViewedProductsArr'))
                if(customerViewedProductArr && customerViewedProductArr.length > 0) {
                  addCustomerViewProductActivity(customerViewedProductArr)
                  localStorage.removeItem('customerViewedProductsArr')
                  let currentTimestamp : any = moment()?.valueOf();
                  currentTimestamp = parseInt(currentTimestamp / 60000)
                  jsCookie.set('lastProductViewActivityUpdateTime', currentTimestamp)
                }
              }
            }
          }
          else {
            let currentTimestamp = moment()?.valueOf();
            currentTimestamp = parseInt(currentTimestamp / 60000)
            jsCookie.set('lastProductViewActivityUpdateTime', currentTimestamp_prod)
          }
        }
        else {
          let currentTimestamp = moment()?.valueOf();
          currentTimestamp = parseInt(currentTimestamp / 60000)
          jsCookie.set('lastProductViewActivityUpdateTime', currentTimestamp_prod)
        }
    }


    if(Router && Router.query && Router.query['pause_delay_request'] != undefined) {

      let obj:any = {
        customer_id: "",
      }

      if(Router.query['customer_id'] != undefined) obj.customer_id = Router.query['customer_id'];
      if(customerData.customer == 'not_login') {
        setOpenLoginModal(true);
        setTimeout(() => { }, 500);
        jsCookie.set('pause_delay_request_obj', JSON.stringify(obj))
        window.history.pushState('', '', '/');
      }
      else {
        if (customerData.customer != 'not_login') {
          if(customerData.customer.customer_id != obj.customer_id) {
            jsCookie.remove('pause_delay_request_obj')
            window.history.pushState('', '', '/');
            return
          }
          updatePauseDelayForCustomer(obj.customer_id)
        }
      }
    }
    else if(jsCookie.get('pause_delay_request_obj') != undefined) {
      let cokie_obj:any = jsCookie.get('pause_delay_request_obj');
      let parseObj = JSON.parse(cokie_obj)
      if (customerData.customer != 'not_login') {
        if(customerData.customer.customer_id != parseObj.customer_id){
          jsCookie.remove('pause_delay_request_obj')
          return
        }
        updatePauseDelayForCustomer(parseObj.customer_id)
      }
    }

  
}, [customerData.customer != 'not_login' && customerData.customer.tag_list]);


async function updatePauseDelayForCustomer(customer_id){
  let res = await pauseDelayRequest(customer_id)
  if(res.success) {
    window.history.pushState('', '', '/');
    setPauseDelayPopup(true)
    jsCookie.remove('pause_delay_request_obj')
  }
}




  function is24HoursPassed(timestamp:any) {
    const timestampInMillis = timestamp * 1000;
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestampInMillis;
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
    return timeDifference >= twentyFourHoursInMilliseconds;
    // return timeDifference >= 600000
  }

  async function updateUserCartForHoldItem(HoldItemObj: any) {
    console.log('HoldItemObj', HoldItemObj)
    
    if(typeof window != undefined && window.location && window.location.href.indexOf('sku_hold_notes_id') > -1) {
      // window.history.pushState('', '', '/');
    }

    // if customer want to relase item (click relase btn in email)
    if(HoldItemObj && (HoldItemObj.isReleasing == true || HoldItemObj.isReleasing == 'true')) {
      if(customerData.customer == 'not_login') {
        releaseHoldItem(HoldItemObj.id)
        jsCookie.remove('sku_hold_item_obj')
        Router.push('/releasedItem');
      }
      else { 
        await dispatch(getCustomerCart())
          .then((res) => {
            res && res.items.map(async(item:any, ind:any)  =>  {
              if(item.sku_hold_notes_id == HoldItemObj.id) {
                let cartObj = {sku_id: parseInt(item.sku.sku_id), sku_code: item.sku.sku, cartItemType: item.cartItemType,newItem:item.newItem, Customer_guid: "false", IsLogin: true, customer_id: customerData.customer.customer_id}
                dispatch(removeProductFromCart(cartObj))
              }
            })
            releaseHoldItem(HoldItemObj.id)
            jsCookie.remove('sku_hold_item_obj')
            Router.push('/releasedItem');
            return
          }).catch(() => {
              releaseHoldItem(HoldItemObj.id)
              jsCookie.remove('sku_hold_item_obj')
              Router.push('/releasedItem');
              return
          })
      }
      return
    }
    
    if (!HoldItemObj || customerData.customer == 'not_login'){
      // Router.push('/')
      return
    } 

    if(HoldItemObj && HoldItemObj.customer_id != "" && HoldItemObj.customer_id != customerData.customer.customer_id) {
      jsCookie.remove('sku_hold_item_obj')
      Router.push('/')
      return
    }

    let customerTagList = customerData.customer.tag_list;

    // condition for check 24 hour time
    if(HoldItemObj && is24HoursPassed(HoldItemObj.time) == true) {
      if(HoldItemObj.productId && HoldItemObj.itemAddedToCart == false) {
        let isPlan = false
        if(customerData.customer !== 'not_login' && customerData.customer.has_subscription == true){
            isPlan = true
        }
        let pres = await getProductDataByProductID(HoldItemObj.productId,0,isPlan)
        if(pres && pres[0]) {
          let product =  pres[0]
          let vlink = (product.vendor_handle != null && product.vendor_handle != "" && product.vendor_handle != undefined) ? product.vendor_handle : 'vendor';
          vlink = "/product/" + vlink + "/" + product.product_handle;
          sessionStorage.setItem('holdItemTimeExpire', '' + product.product_id + '::' + customerData.customer.customer_id)
          Router.push(vlink)
        }
      }
      jsCookie.remove('sku_hold_item_obj')
      return
    }

    // if item already added in cart before
    if(HoldItemObj.itemAddedToCart == true) {
      return
    }

    if(customerData.customer.has_subscription && customerTagList.indexOf('CurrentlyBorrowing') > -1 && (customerTagList?.join()?.toLowerCase()?.indexOf('items') > -1 || customerTagList.indexOf('2upgradeItems') > -1 || customerTagList.indexOf('4upgradeItems') > -1 || customerTagList?.join()?.toLowerCase()?.indexOf('additionalitem') > -1)) {
      HoldItemObj.isEligibleToCheckout = false
      jsCookie.set('sku_hold_item_obj', JSON.stringify(HoldItemObj))
    }


    if(customerData.customer.has_subscription && customerTagList.indexOf('CurrentlyBorrowing') > -1 && customerTagList.indexOf('ADDED ITEMS') > -1 && customerTagList?.join()?.toLowerCase()?.indexOf('additionalitem') == -1) {
      jsCookie.remove('sku_hold_item_obj')
      Router.push('/releasedItem?added=true')
      return
    }

    
    let lastRentalOrderId = (customerData.customer.lastRentalOrder && customerData.customer.lastRentalOrder.orderid) ? customerData.customer.lastRentalOrder.orderid : -1 ;
    // hold item condition for currently borrowing user and subscribed user
    if(customerData.customer.has_subscription) {
        // if item is not added in cart before
        if(HoldItemObj.itemAddedToCart == false) {
          let obj = {
            "customer_id": customerData.customer.customer_id,
            "cartItemType": 1,
            "customer_email": customerData.customer.email,
            "IsLogin": true,
            "sku_hold_notes_id": parseInt(HoldItemObj.id)
          }
          
          dispatch(addingHoldItemIntoCart(obj)).then((res) => {
            if(res.success) {
              HoldItemObj.itemAddedToCart = true 
              //subscribed user condition
              if(customerTagList.indexOf('CurrentlyBorrowing') == -1) {
                HoldItemObj.isEligibleToCheckout = true
              } 
              jsCookie.set('sku_hold_item_obj', JSON.stringify(HoldItemObj)) 
              // let prefObj = {
              //   id: HoldItemObj.id,
              //   time: HoldItemObj.time
              // }
              // dispatch(updateCustomerPrefrenceForHoldItem(prefObj, 'sku_hold_item_info'))
              Router.push('/cart')
            }
            else if(res.message && res.message.indexOf("status is not 2") > -1) {
              jsCookie.remove('sku_hold_item_obj')
              Router.push('/releasedItem?alreadyReleased=true')
            }
            else if(res.message && res.message.indexOf('already exists in cart') > -1) {
              HoldItemObj.itemAddedToCart = true 
               //subscribed user condition
              if(customerTagList.indexOf('CurrentlyBorrowing') == -1) {
                HoldItemObj.isEligibleToCheckout = true
              } 
              jsCookie.set('sku_hold_item_obj', JSON.stringify(HoldItemObj)) 
              // let prefObj = {
              //   id: HoldItemObj.id,
              //   time: HoldItemObj.time
              // }
              // dispatch(updateCustomerPrefrenceForHoldItem(prefObj, 'sku_hold_item_info'))
              Router.push('/cart')
            }
            else{
              jsCookie.remove('sku_hold_item_obj')
              Router.push('/')
            }
          });
        }
    }
    // paused user condiiton 
    else if(!customerData.customer.has_subscription ) {
      if(HoldItemObj.showSignup == true) return;
      HoldItemObj.showSignup = true
      jsCookie.set('sku_hold_item_obj', JSON.stringify(HoldItemObj)) 
      Router.push(Gconfig.waitlistURL+`?email=${btoa(customerData.customer.email)}&loggedin=true`)
    }
  }




  return (
    <>
      <MediaQuery maxWidth={767}>
        <div id="transitonScroll1">
          {customerData.customer !== 'not_login' && customerData.customer.has_subscription && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />}
          {renderingConfigData.show.tanBar && <TanBar />}
        </div>
      </MediaQuery>
      <header id="header_container_id" className={styles.container}>
        <MediaQuery minWidth={768}>
          {customerData.customer !== 'not_login' && customerData.customer.has_subscription && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />}
          {/* {customerData.customer !== 'not_login' && customerData.customer.has_subscription && !notificationData.showNotificationBar && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />} */}
          {renderingConfigData.show.tanBar && <TanBar />}
          {renderingConfigData.show.topBar && <TopBarIndex navbarRef={navbarRef} isVerificationSuccess={isVerificationSuccesClose} />}
          {(customerData.customer === 'not_login' || !customerData.customer.has_subscription) && notificationData.showNotificationBar && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />}
          {renderingConfigData.show.navBar && <NavBarIndex navbarRef={navbarRef} mediaScreen={'desktop'} />}
          {/* {isTransferPopupViewed == false && customerData.customer != 'not_login' && customerData.customer.has_subscription == true ?
          <TransferRentedItemsModal
            customer_id={customerData.customer.customer_id}
            toogleTransferPopup={() => setNextItemTransferPopup(false)}
            toogleTransferPopupViewed={() => setIsTransferPopupViewed(true)}
            isOpen={nextItemTransferPopup}
          /> : ""} */}
          {/* {customerData.customer !== 'not_login' && customerData.customer.has_subscription && <CustomerSurveyPopupIndex />} */}
          {customerData.customer !== 'not_login' && <SmsEmailVerificationIndex verificationSuccess={() => { setIsVerificationSuccesClose(true) }} />}
          {/* <NewPlanBanner /> */}
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {/* {customerData.customer !== 'not_login' && isFavoritePage == true && customerData.customer.has_subscription && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />} */}
          {/* {customerData.customer !== 'not_login' && customerData.customer.has_subscription && !notificationData.showNotificationBar && <NotificationBarIndex showNotification={notificationData.showNotificationBar} />} */}
          {/* {renderingConfigData.show.tanBar && isFavoritePage == true && <TanBar />} */}
          {renderingConfigData.show.topBar && <TopBarIndex navbarRef={navbarRef} isVerificationSuccess={isVerificationSuccesClose} />}
          {(customerData.customer === 'not_login' || !customerData.customer.has_subscription) && notificationData.showNotificationBar && <div id='nonsub-notification'><NotificationBarIndex showNotification={notificationData.showNotificationBar} /></div>}
          {renderingConfigData.show.navBar && <NavBarIndex navbarRef={navbarRef} mediaScreen={'mobile'} />}

          {isProductListingPage && <ProductListHeadersIndex />}

          {/* {isProductListingPage && <div id='transitonScroll2'>
          <ProductListHeadersIndex />
            <div className='product-grid-header'>
              <MobileBreadCrumbToggle/>
            </div>
          </div> } */}

          {customerData.customer !== 'not_login' && <SmsEmailVerificationIndex verificationSuccess={() => { setIsVerificationSuccesClose(true) }} />}
          {/* { hideGlowUpBanner == false && <NewPlanBanner />} */}
        </MediaQuery>
        <Login isSignup={true} isOpen={openLoginModal} fromHold={true} />
      </header>
      {customerData?.customer !== "not_login" && newPlanAggrement == true && <NewPlanAggrementPopup isOpen={newPlanAggrement} toggle={() => setNewPlanAggrement(!newPlanAggrement)} customerData={customerData?.customer} />}
      {customerData?.customer !== "not_login" && pauseDelayPopup == true && <PauseDelayPopupp isOpen={pauseDelayPopup} toggle={() => { setPauseDelayPopup(!pauseDelayPopup); setIsGettingNPSFromUser(false) }}  isGettingNPSFromUser={isGettingNPSFromUser}/>}
      <Cookiepopup/>
    </>
  );
};

export default HeaderIndex;

  import { CookieKeys } from '@/constants/cookie-keys';
import { API, PlansDiscountedAmount } from '@/constants/global-config';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { GetCookie } from '@/libraries/browser-storage';
import { getTimeDifference } from '@/libraries/date-time';
import { useResponsive } from '@/libraries/responsive';
import { setSpotsLeft } from '@/redux/thunks/spotsThunks';
import { isLocalhost } from '@/utils/misc-methods';
import { handleGetRandomNumber } from '@/utils/number-methods';
import { TextExistsInString } from '@/utils/string-methods';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import 'styles/_fontsFamily.module.scss';
import jsCookie from 'js-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './tan-bar.module.scss';

interface IProps { }

const cookielocalUrl = 'fashionpass.com';

const TanBar: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  const customerData = useAppSelector((state) => state.customer);
  const spotsLeftData = useAppSelector((state) => state.spots_left);

  const [spotBar, setSpotBar] = useState(true);
  const [waitlistUrl, setWaitlistUrl] = useState('');
  const [isPause, setIsPause] = useState(false);

  // const [currentIndex, setCurrentIndex] = useState(0);

  const handleGetRemainingPlanSpots = (totalPlanSpots: number) => {
    let spotText = 'spot left';
    let CookieTime: any = cookie.get('planSpotsRamdomNumberTIme');
    let socialite = 0;
    if (CookieTime && CookieTime != '') {
      CookieTime = new Date(CookieTime);
      let diffObj = getTimeDifference(CookieTime, new Date());
      // console.log(diffObj)
      if (diffObj.hours == 0 && diffObj.minutes <= 1) {
        // console.log('before 2 mins condition')
        socialite = totalPlanSpots - 3;
        let secondsLeft = 120 - diffObj.seconds;
        secondsLeft = secondsLeft * 1000;
        if (socialite == 1) spotText = 'spot left';
        // $('.spotAvailArea.socialite').html('<b>'+socialite+'</b> '+spotText)
        let spot = {
          total: totalPlanSpots,
          socialite: socialite,
          trensetter: 1,
          wonderlust: 2,
        };
        dispatch(setSpotsLeft(spot));
        setTimeout(function () {
          handleGetRemainingPlanSpots(parseInt(cookie.get('planSpotsRamdomNumber') || '0'));
        }, secondsLeft);
      } else if (diffObj.hours == 0 && diffObj.minutes <= 6) {
        // console.log('after 2 mins condition')
        socialite = totalPlanSpots - 3;
        let secondsLeft = 420 - diffObj.seconds;
        secondsLeft = secondsLeft * 1000;
        socialite = socialite - 1;
        let spot = {
          total: totalPlanSpots - 1,
          socialite: socialite,
          trensetter: 1,
          wonderlust: 2,
        };
        dispatch(setSpotsLeft(spot));
        setTimeout(function () {
          handleGetRemainingPlanSpots(parseInt(cookie.get('planSpotsRamdomNumber') || '0'));
        }, secondsLeft);
      } else if (diffObj.hours == 0 && diffObj.minutes > 4 && diffObj.minutes < 26) {
        // console.log('after 5 min condition')
        socialite = totalPlanSpots - 4;
        let secondsLeft = 1560 - diffObj.seconds;
        secondsLeft = secondsLeft * 1000;
        let spot = {
          total: totalPlanSpots - 2,
          socialite: socialite,
          trensetter: 1,
          wonderlust: 1,
        };
        dispatch(setSpotsLeft(spot));
        setTimeout(function () {
          handleGetRemainingPlanSpots(parseInt(cookie.get('planSpotsRamdomNumber') || '0'));
        }, secondsLeft);
      } else if (diffObj.hours > 7) {
        // console.log('8 hours condition')
        if (location.host.indexOf('localhost') > -1 || location.host.indexOf('192.168.') > -1) {
          document.cookie = 'planSpotsRamdomNumberTIme=';
          document.cookie = 'planSpotsRamdomNumber=';
        } else {
          document.cookie = 'planSpotsRamdomNumberTIme=;domain=.' + cookielocalUrl;
          document.cookie = 'planSpotsRamdomNumber=;domain=.' + cookielocalUrl;
        }

        totalPlanSpots = handleGetRandomNumber(6, 9);
        if (location.host.indexOf('localhost') > -1 || location.host.indexOf('192.168.') > -1) {
          document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots;
        } else {
          document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots + ';domain=.' + cookielocalUrl;
        }

        handleGetRemainingPlanSpots(totalPlanSpots);
      } else {
        // console.log('after 20 min condition')
        if (totalPlanSpots > 5) {
          totalPlanSpots = handleGetRandomNumber(2, 4);
          if (location.host.indexOf('localhost') > -1 || location.host.indexOf('192.168.') > -1) {
            document.cookie = 'planSpotsRamdomNumber=';
            document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots;
          } else {
            document.cookie = 'planSpotsRamdomNumber=;domain=.' + cookielocalUrl;
            document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots + ';domain=.' + cookielocalUrl;
          }
        }
        socialite = totalPlanSpots - 1;
        // $('.numberBox').text(number)
        // let spotText = 'spots left'
        // if(socialite == 1)
        //     spotText = 'spot left'
        //     $('.spotAvailArea.socialite').html('<b>'+socialite+'</b> '+spotText)
        //     $('.spotAvailArea.wanderlust').html('<b>0</b> spots left')
        //     $('.spotAvailArea.trendsetter').html('<b>1</b> spot left')
        let spot = {
          total: totalPlanSpots,
          socialite: socialite,
          trensetter: 1,
          wonderlust: 0,
        };
        dispatch(setSpotsLeft(spot));
      }
    } else {
      if (location.host.indexOf('localhost') > -1 || location.host.indexOf('192.168.') > -1) {
        document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots;
        document.cookie = 'planSpotsRamdomNumberTIme=' + new Date();
      } else {
        document.cookie = 'planSpotsRamdomNumber=' + totalPlanSpots + ';domain=.' + cookielocalUrl;
        document.cookie = 'planSpotsRamdomNumberTIme=' + new Date() + ';domain=.' + cookielocalUrl;
      }
      socialite = totalPlanSpots - 3;
      let spotText = 'spots left';
      if (socialite == 1) spotText = 'spot left';
      // $('.spotAvailArea.socialite').html('<b>'+socialite+'</b> '+spotText)
      // $('.numberBox').text(number)
      let spot = {
        total: totalPlanSpots,
        socialite: socialite,
        trensetter: 1,
        wonderlust: 2,
      };
      dispatch(setSpotsLeft(spot));
      setTimeout(function () {
        handleGetRemainingPlanSpots(parseInt(cookie.get('planSpotsRamdomNumber') || '0'));
      }, 121000);
      // console.log('2 min condition')
    }
  };

  useEffect(() => {

    const totalPlanSpots = GetCookie(CookieKeys.PLAN_SPOTS_RANDOM_NUMBER) || handleGetRandomNumber(6, 9);
    if (isLocalhost()) document.cookie = `${CookieKeys.PLAN_SPOTS_RANDOM_NUMBER}=${totalPlanSpots}`;
    else document.cookie = `${CookieKeys.PLAN_SPOTS_RANDOM_NUMBER}=${totalPlanSpots};domain=.${cookielocalUrl}`;
    handleGetRemainingPlanSpots(totalPlanSpots);
    setWaitlistUrl(API.BASE_URL_WAITLIST_URL || '');
  }, []);

  useEffect(() => {
    const userTagsString = customerData.customer !== 'not_login' ? customerData.customer.tag_list.join().toLowerCase() : '';
    if (TextExistsInString(userTagsString, 'plan_name') || TextExistsInString(userTagsString, 'pause')) setSpotBar(false);
    if(customerData.customer != 'not_login'){
      setWaitlistUrl(`${API.BASE_URL_WAITLIST_URL}?email=${btoa(customerData.customer !== 'not_login' ? customerData.customer.email : '')}&loggedin=true`);
    }else{
      setWaitlistUrl(API.BASE_URL_WAITLIST_URL);
    }

    if (userTagsString?.indexOf('paused') > -1) {
      setIsPause(true)
    }
  }, [customerData.customer]);

  let setPrevUrlForSignup = () => {
    let url = location.pathname
    jsCookie.set('prevWebsiteUrl', url)
  }

  return (
    <>
      <div className={styles.banner}>
      <Link className={`tanbar_container`} href={waitlistUrl} onClick={() => { setPrevUrlForSignup() }}>
        {/* <div className={`tanbar_container`}> */}
        {/* <Swiper slidesPerView={1} loop={true} direction={'vertical'} effect={'slide'} speed={500} autoplay={{ delay: 2000, reverseDirection: true, disableOnInteraction: false }} modules={[Autoplay]} className={styles.swiperContainer}> */}
          <Swiper slidesPerView={1} direction={'vertical'} effect={'slide'} modules={[Autoplay]} className={styles.swiperContainer}>
            <SwiperSlide className={styles.swiperSlide}>
              <div>
                Limited time summer sale:<br/> use code <span className={styles.discount_number_code}>VACATION </span> to <span className={styles.discount_number_code}>try for $59</span>
              </div>
            </SwiperSlide>
          </Swiper>
        {/* </div> */}
        </Link>
      </div>
    </>
  );
};

export default TanBar;

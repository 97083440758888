const HOME = '/';
const HOME_SECTION = Object.freeze({
  FAQ: `${HOME}#faqsection`,
});

const HOW_IT_WORKS = '/how-it-works';
const HOW_IT_WORKS_SECTION = Object.freeze({
  BENEFITS: `${HOW_IT_WORKS}#benefits`,
});

const FAQ = '/faq';
const FAQ_SECTION = Object.freeze({
  SHIPPING: `${HOME}#shiping`,
});

export const Pages = Object.freeze({
  FOR_SALE: '/for-sale',
  PRODUCT: '/product',
  ABOUT: '/about',
  BLOG_TEST: '/blog_test',
  BRANDS: '/brands',
  CAREERS: '/careers',
  CART: '/cart',
  CHECKOUT: '/checkout',
  COMPLETE_PROFILE: '/complete-profile',
  CONFIRMATION: '/confirmation',
  CONTACT: '/contact',
  FAQ: FAQ,
  FAQ_SECTION: FAQ_SECTION,
  FEDEX: '/fedex',
  GIFT: '/gift',
  GIFTCHECKOUT: '/giftcheckout',
  HOW_IT_WORKS: HOW_IT_WORKS,
  HOW_IT_WORKS_SECTION: HOW_IT_WORKS_SECTION,
  HOME: HOME,
  HOME_SECTION: HOME_SECTION,
  LOADING: '/loading',
  MEMBER_REWARDS: '/member-rewards',
  MOBILEPAGE: '/mobilepage',
  MY_ACCOUNT: '/my-account',
  MY_FAVORITES: '/my-favorites',
  MY_ORDERS: '/my-orders',
  NEW_CHECKOUT: '/new-checkout',
  ORDERCONFIRMATION: '/orderconfirmation',
  PLANS: '/plans',
  PRESS: '/press',
  PRIVACY_POLICY: '/privacy-policy',
  PRODUCTLISTING: '/productlisting',
  PURCHASE_PLAN: '/purchase-plan',
  RESET_PASSWORD: '/reset-password',
  RETURNS: '/returns',
  SERVER_STATS: '/serverstats',
  SIGNUP_PLANS: '/signup-plans',
  SIGNUP: '/signup',
  SNAPSHOT: '/snapshot',
  TEMPORARY_MAINTENANCE: '/temporary-maintenance',
  TERMS_OF_SERVICE: '/terms-of-service',
  UPDATE_PROFILE: '/update-profile',
  FAQ_SHIPPING: '/faq#returns',
  NOTIFICATION: '/notification',
  SNAPSHOTUSER: '/snapshot?footer=true',
  ABOUT_US: '/about-us'
});

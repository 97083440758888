import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { useResponsive } from '@/libraries/responsive';
import styles from '@/containers/sms-email-verification/sms-email-verification-index.module.scss'
import { useSelector } from 'react-redux';
import jsCookie from 'js-cookie';
import { authenticateAgainstEmail, authenticateAgainstPhone, updateCustomerEmailAndPhone, checkCustomerVerificationPopupShow, fireSmsVerificationSub } from "components/shared/customerDataFunctions";
import { searchTag, validateEmail, checkCustomerSubscribeToKlavio } from 'components/shared/globalFunctions';
import { fetchCustomerSuccess } from '@/redux/actions/customerActions';
import { useAppDispatch } from '@/hooks/redux-hooks';
import InputMask from 'react-input-mask';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import Gconfig from 'globalconfig';
import CompleteProfilePopupMember from '@/containers/complete-profile-popup/complete-profile-popup-index'
interface IProps {
  verificationSuccess: () => void;
}

const SmsEmailVerificationIndex: React.FC<IProps> = ({ verificationSuccess }) => {
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [inputPhone, setInputPhone] = useState('');
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false);
  const [resendVerificationModalOpen, setResendVerificationModalOpen] = useState(false);
  const [hidePhoneVerification, setHidePhoneVerification] = useState(false);
  const [inputError, setInputError] = useState({ email: '', phone: '', expireEmail: '', expirePhone: '' });
  const [disableSmsVerification, setDisableSmsVerification] = useState(false);
  const [completeProfileModal, setCompleteProfileModal] = useState(false);
  const { isTablet } = useResponsive();
  const dispatch = useAppDispatch();
  const customer = useSelector((state) => state.customer);
  const customerPreferences = useSelector((state) => state.customerPreferences.customerPreferences);
  const systemSettings = useSelector((state) => state.systemSettings);
  let removeAllVerificationCookies = () => {
    jsCookie.remove('verficationPhoneCode')
    jsCookie.remove('verficationPhone')
    jsCookie.remove('verficationEmailCode')
    jsCookie.remove('verficationEmail')
    jsCookie.remove('verificationEmailSent')
    jsCookie.remove('verificationPhoneSent')
  }

  // const handleAsyncComponentDidMount = async () => {
  //   let disableSms = false;
  //   if (systemSettings.systemSettings.length > 0) {
  //       systemSettings.systemSettings.filter((val) => {
  //         if (val.setting_id == Gconfig.system_settings.disableSMSVerificationId) {
  //           if(val.setting_value == 'true'){
  //             disableSms = true
  //           }
  //         }
  //       });
  //     }
  //     setDisableSmsVerification(disableSms)
  //     setHidePhoneVerification(disableSms)
  //     if(disableSms == false){
  //     if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true && searchTag(customer?.customer?.tag_list, 'fire_sms') == false) {
  //         setHidePhoneVerification(true)
  //         jsCookie.set('SMSVerificationPending', true)
  //       } else {
  //         setHidePhoneVerification(false)
  //         jsCookie.remove('SMSVerificationPending')
  //       }
  //       if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true && searchTag(customer?.customer?.tag_list, 'fire_sms') == false) {
  //         setIsOpenVerificationModal(false)
  //         return;
  //       }
  //       if (searchTag(customer?.customer?.tag_list, 'phone_verified') == true && searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
  //         return;
  //       }
  //   }else{
  //     if(searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
  //       setIsOpenVerificationModal(false);
  //       return;
  //     }
  //   }
  //   // if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
  //   //   setHidePhoneVerification(true)
  //   //   jsCookie.set('SMSVerificationPending', true)
  //   // } else {
  //   //   setHidePhoneVerification(false)
  //   //   jsCookie.remove('SMSVerificationPending')
  //   // }
  //   // if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
  //   //   setIsOpenVerificationModal(false)
  //   //   return;
  //   // }
  //   // if (searchTag(customer?.customer?.tag_list, 'phone_verified') == true && searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
  //   //   return;
  //   // }
  //   //  if(searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
  //   //   setIsOpenVerificationModal(false);
  //   //   return;
  //   // }
  
  //   if (searchTag(customer?.customer?.tag_list, 'verification') == false) {
  //     return;
  //   }
  //   let phoneCode = jsCookie.get('verficationPhoneCode')
  //   let emailCode = jsCookie.get('verficationEmailCode')
  //   let phoneNum = jsCookie.get('verficationPhone')
  //   let email = jsCookie.get('verficationEmail')
  //   let hasSubscription = customer?.customer?.has_subscription;

  //   let isVerificationPopupShowRes = await checkCustomerSubscribeToKlavio(customer?.customer?.email);

  //   if (isVerificationPopupShowRes && isVerificationPopupShowRes.success && hasSubscription == true && (isVerificationPopupShowRes?.data?.email_sub == true || isVerificationPopupShowRes?.data?.phone_sub == true || searchTag(customer?.customer?.tag_list, 'email_verified') == true || searchTag(customer?.customer?.tag_list, 'phone_verified') == true )) {
    
  //   if(searchTag(customer?.customer?.tag_list, 'fire_sms') == true) {
  //       let fireSmsRes = await fireSmsVerificationSub(customer?.customer.email , isVerificationPopupShowRes?.data?.phone_sub)
  //         if(fireSmsRes.success) {
  //           let isCustomerSubSms = fireSmsRes?.response?.customer_sub
  //           if (((searchTag(customer?.customer?.tag_list, 'email_verified') == true && disableSms == true) || (disableSms == false && searchTag(customer?.customer?.tag_list, 'email_verified') == true && isCustomerSubSms)) && customer?.customer?.has_subscription == true) {
  //             setIsOpenVerificationModal(false)
  //           } else {
  //             setIsOpenVerificationModal(true)
  //           }
  //         }
  //         else {
  //           if (((searchTag(customer?.customer?.tag_list, 'email_verified') == true && disableSms == true) || (disableSms == false && searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true)) && customer?.customer?.has_subscription == true) {
  //             setIsOpenVerificationModal(false)
  //           } else {
  //             setIsOpenVerificationModal(true)
  //           }
  //         }
          
  //   } 
  //   else {
  //     if (((searchTag(customer?.customer?.tag_list, 'email_verified') == true && disableSms == true) || (disableSms == false && searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true)) && customer?.customer?.has_subscription == true) {
  //       setIsOpenVerificationModal(false)
  //     } else {
  //       setIsOpenVerificationModal(true)
  //     }
  //   }
  // }

  //   // let verificationPopupShowCookie = jsCookie.get('verificationPopupShow')
  //   // if(verificationPopupShowCookie != undefined && customerPreferences?.preferences?.verificationPopupShow != "true"){
  //   //   dispatch(updateCustomerPreferences('true', 'verificationPopupShow',{}));
  //   // }else{
  //   //   if(verificationPopupShowCookie == undefined && customerPreferences?.preferences?.verificationPopupShow == "true"){
  //   //     verificationPopupShowCookie = 'true'
  //   //     jsCookie.set('verificationPopupShow',true)
  //   //   }
  //   // }

  //   if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && phoneCode != undefined) {
  //     let verifyPhoneLinkRes = await authenticateAgainstPhone(phoneNum, phoneCode, disableSms)
  //     if (verifyPhoneLinkRes && verifyPhoneLinkRes?.success == true) {
  //       let customerData = customer?.customer
  //       customerData.tag_list = verifyPhoneLinkRes?.tags;
  //       dispatch(fetchCustomerSuccess(customerData))
  //       if (searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) {
  //         if(customerData?.has_subscription == true && searchTag(customerData.tag_list, 'height') !== true && searchTag(customerData.tag_list, 'jeans size') !== true){
  //           jsCookie.remove('loginSuccess')
  //           jsCookie.set('completeProfilePopupFromVerification',true)
  //         }
  //         setIsOpenVerificationModal(false)
  //         setIsOpenSuccessModal(true)
  //         removeAllVerificationCookies()
  //       } else {
  //         if (hasSubscription == true) {
  //           setIsOpenVerificationModal(true)
  //         }
  //         jsCookie.remove('verficationPhoneCode')
  //         jsCookie.remove('verficationPhone')
  //         jsCookie.remove('verificationPhoneSent')
  //       }
  //     } else {
  //       if (verifyPhoneLinkRes?.message?.indexOf('Your Link has Expired') > -1) {
  //         if (hasSubscription == true) {
  //           setInputError({ email: '', phone: '', expireEmail: '', expirePhone: 'Sorry your link expired. Please send a new link.' })
  //           setIsOpenVerificationModal(true)
  //           setTimeout(() => {
  //             setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
  //           }, 4000)
  //         }
  //         jsCookie.remove('verificationPhoneSent')
  //         setPhoneVerificationSent(false)
  //       }
  //       if (hasSubscription == true) {
  //         setIsOpenVerificationModal(true)
  //       }
  //       jsCookie.remove('verficationPhoneCode')
  //       jsCookie.remove('verficationPhone')
  //     }
  //   }
  //   if (searchTag(customer?.customer?.tag_list, 'email_verified') == false && emailCode != undefined) {
  //     let verifyEmailRes = await authenticateAgainstEmail(email, emailCode)
  //     if (verifyEmailRes && verifyEmailRes?.success == true) {
  //       let customerData = customer?.customer
  //       customerData.tag_list = verifyEmailRes?.tags;
  //       dispatch(fetchCustomerSuccess(customerData))
  //       if(disableSms == false){
  //         if (hasSubscription == true) {
  //           setIsOpenVerificationModal(true)
  //         }
  //       }
  //       // if (searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) {
  //         if (( disableSms == true && searchTag(customerData.tag_list, 'email_verified') == true) || ( disableSms == false && searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) ) {
  //           if(customerData?.has_subscription == true && searchTag(customerData.tag_list, 'height') !== true && searchTag(customerData.tag_list, 'jeans size') !== true && searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true){
  //             jsCookie.remove('loginSuccess')
  //             jsCookie.set('completeProfilePopupFromVerification',true)
  //           }
  //           setIsOpenVerificationModal(false)
  //         setIsOpenSuccessModal(true)
  //         removeAllVerificationCookies()
  //       } else {
  //         if (customer?.customer != 'not_login' && customer?.customer?.phone_no != null && hasSubscription == true)
  //           setIsOpenVerificationModal(true)
  //         else {
  //           setIsOpenVerificationModal(false)
  //           setIsOpenSuccessModal(true)
  //         }
  //         // if(verificationPopupShowCookie != undefined && customerPreferences?.preferences?.verificationPopupShow != "false"){
  //         //   dispatch(updateCustomerPreferences('false', 'verificationPopupShow',{}));
  //         // }
  //         // jsCookie.remove('verificationPopupShow')
  //         jsCookie.remove('verficationEmailCode')
  //         jsCookie.remove('verficationEmail')
  //         jsCookie.remove('verificationEmailSent')
  //       }

  //       if (searchTag(verifyEmailRes?.tags, 'email_verified') == true && searchTag(verifyEmailRes?.tags, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
  //         setIsOpenVerificationModal(false)
  //       }
  //     } else {
  //       if (verifyEmailRes?.message?.indexOf('Your Link has Expired') > -1) {
  //         if (hasSubscription == true) {
  //           setInputError({ email: '', phone: '', expirePhone: '', expireEmail: 'Sorry your link expired. Please send a new link.' })
  //           setIsOpenVerificationModal(true)
  //           setTimeout(() => {
  //             setInputError({ email: '', phone: '', expirePhone: '', expireEmail: '' })
  //           }, 4000)
  //         } else {
  //           setIsOpenVerificationModal(false)
  //           setResendVerificationModalOpen(true)
  //         }
  //         jsCookie.remove('verificationEmailSent')
  //         setEmailVerificationSent(false)
  //       }
  //       if (hasSubscription == true) {
  //         setIsOpenVerificationModal(true)
  //       }
  //       jsCookie.remove('verficationEmailCode')
  //       jsCookie.remove('verficationEmail')
  //     }
  //   }
  //   if(disableSms == false){
  //     if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
  //       setIsOpenVerificationModal(false)
  //     }
  //   }
  // }

  const handleAsyncComponentDidMount = async () => {

    let disableSms = false;
    if (systemSettings.systemSettings.length > 0) {
        systemSettings.systemSettings.filter((val) => {
          if (val.setting_id == Gconfig.system_settings.disableSMSVerificationId) {
            if(val.setting_value == 'true'){
              disableSms = true
            }
          }
        });
      }
      setDisableSmsVerification(disableSms)
      setHidePhoneVerification(disableSms)

      if(searchTag(customer?.customer?.tag_list, 'fire_sms') == true) {
        setIsOpenVerificationModal(false)
        return;
      }
    
      if(disableSms == false){
      if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
          setHidePhoneVerification(true)
          jsCookie.set('SMSVerificationPending', true)
        } else {
          setHidePhoneVerification(false)
          jsCookie.remove('SMSVerificationPending')
        }
        if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
          setIsOpenVerificationModal(false)
          return;
        }
        if (searchTag(customer?.customer?.tag_list, 'phone_verified') == true && searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
          return;
        }
    }else{
      if(searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
        setIsOpenVerificationModal(false);
        return;
      }
    }
    // if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
    //   setHidePhoneVerification(true)
    //   jsCookie.set('SMSVerificationPending', true)
    // } else {
    //   setHidePhoneVerification(false)
    //   jsCookie.remove('SMSVerificationPending')
    // }
    // if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
    //   setIsOpenVerificationModal(false)
    //   return;
    // }
    // if (searchTag(customer?.customer?.tag_list, 'phone_verified') == true && searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
    //   return;
    // }
    //  if(searchTag(customer?.customer?.tag_list, 'email_verified') == true) {
    //   setIsOpenVerificationModal(false);
    //   return;
    // }
    if (searchTag(customer?.customer?.tag_list, 'verification') == false) {
      return;
    }
    let phoneCode = jsCookie.get('verficationPhoneCode')
    let emailCode = jsCookie.get('verficationEmailCode')
    let phoneNum = jsCookie.get('verficationPhone')
    let email = jsCookie.get('verficationEmail')
    let hasSubscription = customer?.customer?.has_subscription;

    let isVerificationPopupShowRes = await checkCustomerVerificationPopupShow();
    if (isVerificationPopupShowRes && isVerificationPopupShowRes.success && hasSubscription == true && (isVerificationPopupShowRes.email == true || isVerificationPopupShowRes.phone == true)) {
      // if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
        if (((searchTag(customer?.customer?.tag_list, 'email_verified') == true && disableSms == true) || (disableSms == false && searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true)) && customer?.customer?.has_subscription == true) {
        setIsOpenVerificationModal(false)
      } else {
        setIsOpenVerificationModal(true)
      }
    }

    // let verificationPopupShowCookie = jsCookie.get('verificationPopupShow')
    // if(verificationPopupShowCookie != undefined && customerPreferences?.preferences?.verificationPopupShow != "true"){
    //   dispatch(updateCustomerPreferences('true', 'verificationPopupShow',{}));
    // }else{
    //   if(verificationPopupShowCookie == undefined && customerPreferences?.preferences?.verificationPopupShow == "true"){
    //     verificationPopupShowCookie = 'true'
    //     jsCookie.set('verificationPopupShow',true)
    //   }
    // }

    if (searchTag(customer?.customer?.tag_list, 'phone_verified') == false && phoneCode != undefined) {
      let verifyPhoneLinkRes = await authenticateAgainstPhone(phoneNum, phoneCode, disableSms)
      if (verifyPhoneLinkRes && verifyPhoneLinkRes?.success == true) {
        let customerData = customer?.customer
        customerData.tag_list = verifyPhoneLinkRes?.tags;
        dispatch(fetchCustomerSuccess(customerData))
        if (searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) {
          if(customerData?.has_subscription == true && searchTag(customerData.tag_list, 'height') !== true && searchTag(customerData.tag_list, 'jeans size') !== true){
            jsCookie.remove('loginSuccess')
            jsCookie.set('completeProfilePopupFromVerification',true)
          }
          setIsOpenVerificationModal(false)
          setIsOpenSuccessModal(true)
          removeAllVerificationCookies()
        } else {
          if (hasSubscription == true) {
            setIsOpenVerificationModal(true)
          }
          jsCookie.remove('verficationPhoneCode')
          jsCookie.remove('verficationPhone')
          jsCookie.remove('verificationPhoneSent')
        }
      } else {
        if (verifyPhoneLinkRes?.message?.indexOf('Your Link has Expired') > -1) {
          if (hasSubscription == true) {
            setInputError({ email: '', phone: '', expireEmail: '', expirePhone: 'Sorry your link expired. Please send a new link.' })
            setIsOpenVerificationModal(true)
            setTimeout(() => {
              setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
            }, 4000)
          }
          jsCookie.remove('verificationPhoneSent')
          setPhoneVerificationSent(false)
        }
        if (hasSubscription == true) {
          setIsOpenVerificationModal(true)
        }
        jsCookie.remove('verficationPhoneCode')
        jsCookie.remove('verficationPhone')
      }
    }
    if (searchTag(customer?.customer?.tag_list, 'email_verified') == false && emailCode != undefined) {
      let verifyEmailRes = await authenticateAgainstEmail(email, emailCode)
      if (verifyEmailRes && verifyEmailRes?.success == true) {
        let customerData = customer?.customer
        customerData.tag_list = verifyEmailRes?.tags;
        dispatch(fetchCustomerSuccess(customerData))
        if(disableSms == false){
          if (hasSubscription == true) {
            setIsOpenVerificationModal(true)
          }
        }
        // if (searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) {
          if (( disableSms == true && searchTag(customerData.tag_list, 'email_verified') == true) || ( disableSms == false && searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true) ) {
            if(customerData?.has_subscription == true && searchTag(customerData.tag_list, 'height') !== true && searchTag(customerData.tag_list, 'jeans size') !== true && searchTag(customerData.tag_list, 'phone_verified') == true && searchTag(customerData.tag_list, 'email_verified') == true){
              jsCookie.remove('loginSuccess')
              jsCookie.set('completeProfilePopupFromVerification',true)
            }
            setIsOpenVerificationModal(false)
          setIsOpenSuccessModal(true)
          removeAllVerificationCookies()
        } else {
          if (customer?.customer != 'not_login' && customer?.customer?.phone_no != null && hasSubscription == true)
            setIsOpenVerificationModal(true)
          else {
            setIsOpenVerificationModal(false)
            setIsOpenSuccessModal(true)
          }
          // if(verificationPopupShowCookie != undefined && customerPreferences?.preferences?.verificationPopupShow != "false"){
          //   dispatch(updateCustomerPreferences('false', 'verificationPopupShow',{}));
          // }
          // jsCookie.remove('verificationPopupShow')
          jsCookie.remove('verficationEmailCode')
          jsCookie.remove('verficationEmail')
          jsCookie.remove('verificationEmailSent')
        }

        if (searchTag(verifyEmailRes?.tags, 'email_verified') == true && searchTag(verifyEmailRes?.tags, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
          setIsOpenVerificationModal(false)
        }
      } else {
        if (verifyEmailRes?.message?.indexOf('Your Link has Expired') > -1) {
          if (hasSubscription == true) {
            setInputError({ email: '', phone: '', expirePhone: '', expireEmail: 'Sorry your link expired. Please send a new link.' })
            setIsOpenVerificationModal(true)
            setTimeout(() => {
              setInputError({ email: '', phone: '', expirePhone: '', expireEmail: '' })
            }, 4000)
          } else {
            setIsOpenVerificationModal(false)
            setResendVerificationModalOpen(true)
          }
          jsCookie.remove('verificationEmailSent')
          setEmailVerificationSent(false)
        }
        if (hasSubscription == true) {
          setIsOpenVerificationModal(true)
        }
        jsCookie.remove('verficationEmailCode')
        jsCookie.remove('verficationEmail')
      }
    }
    if(disableSms == false){
      if (searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'pending_sms_sub') == true && customer?.customer?.has_subscription == true) {
        setIsOpenVerificationModal(false)
      }
    }
  }


  useEffect(() => {
    handleAsyncComponentDidMount()
    openCompleteProfilePopup();
    // if(customer.customer != 'not_login' && customer.customer.has_subscription == true && searchTag(customer?.customer?.tag_list, 'phone_verified') == true && searchTag(customer?.customer?.tag_list, 'email_verified') == true && searchTag(customer?.customer?.tag_list, 'height') !== true && searchTag(customer?.customer?.tag_list, 'jeans size') !== true && localStorage.getItem('timerValue') == null){
    //   console.log('Run Time == 2')
    //   let count = 0
    //   const intervalId = setInterval(() => {
    //     if(localStorage.getItem('timerValue') != null){
    //       count = localStorage.getItem('timerValue')
    //     }
    //     localStorage.setItem("timerValue",(parseInt(count)+1))
    //   }, 1000);
  
    //   const timeoutId = setTimeout(() => {
    //     clearInterval(intervalId);
    //   }, 60000);
    //   return () => {
    //     clearInterval(intervalId);
    //     clearTimeout(timeoutId);
    //   };
    // }
  }, [customer.customer?.tag_list]);

  let ResendEmailLink = async () => {
    let email = customer?.customer?.email
    let verifyEmailRes = await authenticateAgainstEmail(email)
    if (verifyEmailRes?.success) {
      setEmailVerificationSent(true)
      jsCookie.set('verificationEmailSent', true, { expires: 1 })
    } else {
      setInputError({ email: verifyEmailRes?.message, phone: '', expireEmail: '', expirePhone: '' })
      setTimeout(() => {
        setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
      }, 4000)
    }
  }

  let ResendPhoneLink = async () => {
    let phone = customer?.customer?.phone_no
    let verifyPhoneLink = await authenticateAgainstPhone(phone,undefined,disableSmsVerification)
    if (verifyPhoneLink?.success) {
      setPhoneVerificationSent(true)
      jsCookie.set('verificationPhoneSent', true, { expires: 1 })
    } else {
      setInputError({ email: '', phone: verifyPhoneLink?.message, expireEmail: '', expirePhone: '' })
      setTimeout(() => {
        setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
      }, 4000)
    }
  }

  let updateEmailAndPhoneNum = async (type: string) => {
    setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
    if (type == 'email') {
      if (inputEmail == '') {
        setInputError({ email: 'Please enter a email', phone: '', expireEmail: '', expirePhone: '' })
      } else if (validateEmail(inputEmail) != true) {
        setInputError({ email: 'Email must be a valid email address', phone: '', expireEmail: '', expirePhone: '' })
      } else if (inputEmail == customer?.customer?.email) {
        setEditEmail(false)
        setInputEmail('')
      } else {
        let obj = {
          customer_id: customer?.customer?.customer_id,
          email: inputEmail,
          phone_no: ''
        }
        let updateEmailRes = await updateCustomerEmailAndPhone(obj)
        if (updateEmailRes?.success) {
          let customerData = customer?.customer
          customerData.email = inputEmail;
          dispatch(fetchCustomerSuccess(customerData))
          setEditEmail(false)
          setInputEmail('')
          jsCookie.remove('verificationEmailSent')
          jsCookie.remove('verficationEmailCode')
          jsCookie.remove('verficationEmail')
          setEmailVerificationSent(false)
        } else {
          if (updateEmailRes?.message?.indexOf('Email already exist') > -1)
            setInputError({ email: 'That email is already in use for another account. Please try another email.', phone: '', expireEmail: '', expirePhone: '' })
          else
            setInputError({ email: updateEmailRes?.message, phone: '', expireEmail: '', expirePhone: '' })
        }
      }
    }
    if (type == 'phone') {
      let phone = inputPhone
      phone = phone.replace(/[^0-9]/g, "")
      if (inputPhone == '') {
        setInputError({ email: '', phone: 'Please enter a Phone', expireEmail: '', expirePhone: '' })
      } else if (phone.length < 10) {
        setInputError({ email: '', phone: 'Please enter a 10 digit phone number.', expireEmail: '', expirePhone: '' })
      } else if (phone == customer?.customer?.phone_no) {
        setEditPhone(false)
        setInputPhone('')
      } else {
        let obj = {
          customer_id: customer?.customer?.customer_id,
          email: '',
          phone_no: phone
        }
        let updatePhoneRes = await updateCustomerEmailAndPhone(obj)
        if (updatePhoneRes?.success) {
          let customerData = customer?.customer
          customerData.phone_no = phone;
          dispatch(fetchCustomerSuccess(customerData))
          setEditPhone(false)
          setInputPhone('')
          jsCookie.remove('verificationPhoneSent')
          jsCookie.remove('verficationPhoneCode')
          jsCookie.remove('verficationPhone')
          setPhoneVerificationSent(false)
        } else {
          if (updatePhoneRes?.message?.indexOf('PhoneNumber already exist') > -1)
            setInputError({ email: '', phone: 'That phone number is already in use for another account. Please try another number.', expireEmail: '', expirePhone: '' })
          else
            setInputError({ email: '', phone: updatePhoneRes?.message, expireEmail: '', expirePhone: '' })
        }
      }
    }
  }

  let toggleSuccessfullPopup = () => {
    setIsOpenSuccessModal(false)
    // dispatch(updateCustomerPreferences('false', 'verificationPopupShow',{}));
    // jsCookie.remove('verificationPopupShow')
    verificationSuccess()
  }
  let handleEnterEvent = (e: any, type: string) => {
    if (e.key === 'Enter') {
      updateEmailAndPhoneNum(type);
    }
  }

  let resendExpireEmailLink = async () => {
    let email = customer?.customer?.email
    let verifyEmailRes = await authenticateAgainstEmail(email)
    if (verifyEmailRes?.success) {
      setResendVerificationModalOpen(false)
    } else {
      setInputError({ email: verifyEmailRes?.message, phone: '', expireEmail: '', expirePhone: '' })
      setTimeout(() => {
        setInputError({ email: '', phone: '', expireEmail: '', expirePhone: '' })
      }, 4000)
    }
  }

  let openCompleteProfilePopup = () => {
    if(customer?.customer?.has_subscription == true && searchTag(customer?.customer?.tag_list, 'height') !== true && searchTag(customer?.customer?.tag_list, 'jeans size') !== true && searchTag(customer?.customer?.tag_list, 'email_verified') === true && searchTag(customer?.customer?.tag_list, 'phone_verified') === true && jsCookie.get('completeProfilePopupFromVerification') != undefined){
      jsCookie.remove('loginSuccess')
      setTimeout(()=>{
        setCompleteProfileModal(true)
        jsCookie.remove('completeProfilePopupFromVerification')
      },60000)
    }
  }

  let toggleCompleteProfilePopup = () => {
    setCompleteProfileModal(!completeProfileModal);
    jsCookie.remove('completeProfilePopupFromVerification');
  }

  return (
    <>
      <div>
        {/* <Modal className={ isOpenVerificationModal ? styles.sms_verification_modal : styles.sms_success_modal} size="lg" centered isOpen={true}> */}
        <Modal className={`${styles.sms_verification_modal} ${customer?.customer != 'not_login' && customer?.customer?.phone_no == null || hidePhoneVerification ? ` ${styles.email_verification}` : ''}`} size="lg" centered isOpen={isOpenVerificationModal}>
          <ModalBody className={styles.sms_verification_body}>
            <svg className={styles.image_main} xmlns="http://www.w3.org/2000/svg" width="61" height="52" viewBox="0 0 61 52" fill="none">
              <g clipPath="url(#clip0_2945_1151)">
                <path d="M29.6568 0.755676L2.0625 48.804L2.88066 50.5147L57.7628 50.8122L59.4824 48.6552L31.2187 0.755676H29.6568Z" fill="#FEF3F8" />
                <path d="M30.491 51.9904C21.7494 51.9904 13.0085 51.9808 4.2668 52.0001C2.91684 52.0031 1.92091 51.4824 1.28572 50.311C0.791108 49.3998 0.800778 48.4537 1.31473 47.5508C2.70709 45.1038 4.11953 42.6679 5.52082 40.226C6.84995 37.9099 8.16868 35.5871 9.50451 33.2747C11.2472 30.2586 13.007 27.2522 14.7511 24.237C16.7571 20.7702 18.7542 17.299 20.7557 13.83C22.755 10.3654 24.7535 6.90016 26.7573 3.43785C27.1797 2.70895 27.6022 1.97781 28.0641 1.27345C29.1753 -0.418653 31.8432 -0.428323 32.944 1.26899C34.3416 3.42447 35.5443 5.69672 36.8355 7.91542C38.2859 10.4078 39.7348 12.901 41.1755 15.3986C41.9118 16.6757 42.6199 17.9684 43.3562 19.2454C45.087 22.2473 46.8297 25.2418 48.5627 28.2422C50.0986 30.9012 51.6249 33.5662 53.1593 36.226C54.3962 38.3696 55.6405 40.5079 56.8782 42.6508C57.8198 44.2811 58.7391 45.9256 59.7008 47.5441C60.8507 49.4802 59.1742 51.9451 57.3706 51.9503C56.9518 51.9518 56.5331 51.9845 56.1136 51.9845C47.5727 51.9867 39.0326 51.986 30.4918 51.986V51.9904H30.491ZM30.4576 49.6683C39.1264 49.6683 47.7944 49.6683 56.4632 49.6669C56.6997 49.6669 56.9399 49.6691 57.1727 49.6349C57.651 49.5635 57.9031 49.0763 57.6807 48.6546C57.555 48.4158 57.3877 48.1986 57.2523 47.9643C55.2411 44.4805 53.2322 40.9951 51.2217 37.5105C49.212 34.0288 47.1986 30.5494 45.1912 27.067C43.2454 23.6918 41.3056 20.3135 39.3614 16.9375C37.9765 14.5321 36.5901 12.1267 35.2014 9.72355C33.8938 7.46022 32.5826 5.19913 31.2735 2.93729C30.8168 2.14739 30.2039 2.13326 29.7569 2.90382C28.3363 5.3516 26.9001 7.78972 25.498 10.2479C24.1228 12.6593 22.749 15.0706 21.3344 17.4589C19.9866 19.7349 18.6924 22.0421 17.3745 24.3359C15.8036 27.0693 14.2379 29.8049 12.6611 32.5346C10.914 35.5588 9.15642 38.5771 7.40481 41.5983C6.10468 43.8408 4.82017 46.0922 3.49922 48.3214C2.99047 49.1797 3.42484 49.7509 4.23333 49.6683C4.37762 49.6535 4.52489 49.6669 4.67142 49.6669C13.2666 49.6669 21.8624 49.6669 30.4576 49.6683Z" fill="black" />
                <path d="M26.9851 17.2996C26.9598 15.5703 27.592 14.3342 28.8297 13.5331C30.2659 12.6034 31.9305 12.9173 33.1183 14.3959C33.9491 15.4313 34.1514 16.6206 33.9752 17.9229C33.7751 19.4016 33.6724 20.8914 33.4835 22.373C33.2559 24.1573 33.1079 25.952 32.916 27.7416C32.7449 29.334 32.5583 30.9242 32.3835 32.5159C32.266 33.5825 32.1358 34.6483 32.0495 35.7179C31.9863 36.4966 31.3496 37.1177 30.5307 37.1244C29.7051 37.1311 29.0513 36.5264 28.974 35.7551C28.788 33.909 28.5656 32.0667 28.3723 30.2214C28.1997 28.5746 28.0606 26.9242 27.8814 25.2782C27.7415 23.9944 27.5504 22.7159 27.4061 21.4321C27.2439 19.9854 27.1101 18.5365 26.9851 17.2989V17.2996Z" fill="black" />
                <path d="M33.3716 41.6451C33.4728 43.2323 32.2492 44.5139 30.5527 44.5674C29.0577 44.615 27.7419 43.3469 27.6534 41.9166C27.5322 39.9686 29.0227 38.8455 30.3913 38.7763C31.9636 38.6967 33.4839 40.0095 33.3724 41.6443L33.3716 41.6451Z" fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_2945_1151">
                  <rect width="59.1559" height="52" fill="white" transform="translate(0.921875)" />
                </clipPath>
              </defs>
            </svg>
            <span className={styles.verification_head}>Verify your account</span><br />
            <p className={styles.discription_paragraph}>{ hidePhoneVerification ? `We sent you an email.` : `We sent you an email and a text.`} <br />
              { hidePhoneVerification ? `Please click the link to verify your account!` : `Please click the link in both to verify your account!`} </p>
            <p className={styles.verification_email}>Email Address</p>
            <div className={styles.verification_container_one}>
              {
                editEmail == false ?
                  <div className={styles.non_verification_section}>
                    <span className={`${searchTag(customer?.customer?.tag_list, 'email_verified') == true ? `${styles.verifed_text}` : ''}`}>{'' + customer.customer?.email}</span>
                    {searchTag(customer?.customer?.tag_list, 'email_verified') == false ? <i className="fas fa-pencil-alt" onClick={() => { setEditEmail(true) }}></i> : <span className={styles.verified_tag}>Verified <img src="https://fashionpass.s3.us-west-1.amazonaws.com/assets/img/checkpinkbox.svg" /> </span>}
                  </div>
                  :
                  <>
                    <span> <input className={styles.box_one} type="email" autoFocus={true} onKeyDown={(e) => handleEnterEvent(e, 'email')} value={inputEmail} onBlur={() => { updateEmailAndPhoneNum('email') }} onChange={(e) => { setInputEmail(e.target.value) }} /></span>
                  </>
              }
            </div>
            {inputError?.expireEmail != '' ? <p className={styles.error_msg_link}>{inputError.expireEmail}</p> : ''}
            {!editEmail && searchTag(customer?.customer?.tag_list, 'email_verified') == false ? (emailVerificationSent == false && jsCookie.get('verificationEmailSent') == undefined) ? <p className={styles.verification_resend_email} onClick={() => { ResendEmailLink() }}>Resend Email</p> : <p className={styles.verification_text_sent}>Email Sent</p> : ''}
            {inputError?.email != '' ? <p className={styles.error_msg}>{inputError?.email}</p> : ''}
            {
              customer?.customer != 'not_login' && customer?.customer?.phone_no != null && hidePhoneVerification == false &&
              <>
                <p className={styles.verification_phone_number}>Phone Number</p>
                <div className={styles.verification_container_two}>
                  {
                    editPhone == false ?
                      <div className={styles.non_verification_section}>
                        <span>{`(${customer.customer?.phone_no.slice(0, 3)}) ${customer.customer?.phone_no.slice(3, 6)}-${customer.customer?.phone_no.slice(6, 10)}`}</span>
                        {searchTag(customer?.customer?.tag_list, 'phone_verified') == false ? <i className="fas fa-pencil-alt" onClick={() => { setEditPhone(true) }}></i> : <span className={styles.verified_tag}>Verified <img src="https://fashionpass.s3.us-west-1.amazonaws.com/assets/img/checkpinkbox.svg" /> </span>}
                      </div>
                      :
                      <>
                        <span><InputMask className={styles.box_two} mask="(999) 999-9999" onKeyDown={(e) => handleEnterEvent(e, 'phone')} autoFocus={true} value={inputPhone} onBlur={() => { updateEmailAndPhoneNum('phone') }} onChange={(e) => { setInputPhone(e.target.value) }} /></span>
                      </>
                  }
                </div>
                {inputError?.expirePhone != '' ? <p className={styles.error_msg_link}>{inputError.expirePhone}</p> : ''}
                {!editPhone && searchTag(customer?.customer?.tag_list, 'phone_verified') == false ? (phoneVerificationSent == false && jsCookie.get('verificationPhoneSent') == undefined) ? <p className={styles.verification_resend_email} onClick={() => { ResendPhoneLink() }}>Resend Text</p> : <p className={styles.verification_text_sent}>Text Sent</p> : ''}
                {inputError?.phone != '' ? <p className={styles.error_msg}>{inputError?.phone}</p> : ''}
              </>
            }
            <p className={styles.verification_paragraph_one}> Please allow up to 10 minutes to receive verification links. </p>
            <p className={styles.verification_paragraph_two}>Issues? Text member concierge at <span className={styles.ph_number}>(323) 375-0733</span></p>

          </ModalBody>
        </Modal>

        <Modal className={styles.sms_success_modal} size="lg" centered isOpen={isOpenSuccessModal} toggle={() => { toggleSuccessfullPopup() }}>
          <ModalHeader className={styles.verification_success_popup_header}>
            <p className={styles.success_image_two} onClick={() => { toggleSuccessfullPopup() }}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <rect x="2.13477" y="12.8603" width="2.01907" height="15.1681" transform="rotate(-135 2.13477 12.8603)" fill="black" stroke="black" />
              <rect x="0.707107" y="2.13483" width="2.01907" height="15.1681" transform="rotate(-45 0.707107 2.13483)" fill="black" stroke="black" />
            </svg></p>
          </ModalHeader>
          <ModalBody className={styles.verification_success_popup_body}>
            <p className={styles.success_image_one}><svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
              <rect x="0.5" width="36.2613" height="36.2613" rx="10" fill="#FFF3F7" />
              <path d="M6.83902 18.3724C6.48984 17.9158 6.54356 17.2711 6.93303 16.9085C7.38966 16.5056 8.0343 16.6131 8.39691 17.0025L14.5882 24.2548L29.6837 10.4621C30.0866 10.1129 30.7446 10.1129 31.1475 10.5561C31.4967 10.959 31.4967 11.6171 31.0535 12.02L15.1926 26.5111C14.7359 26.8603 14.0913 26.8065 13.7287 26.4036L6.83902 18.3456V18.3724ZM8.10145 0H29.8985C32.0071 0 33.8739 0.859528 35.284 2.21597C36.6405 3.62613 37.5 5.49292 37.5 7.60145V29.3985C37.5 31.5071 36.6405 33.3739 35.284 34.784C33.8739 36.1405 32.0071 37 29.8985 37H8.10145C6.03321 37 4.12613 36.1405 2.76969 34.784C1.35953 33.3739 0.5 31.5071 0.5 29.3985V7.60145C0.5 5.49292 1.35953 3.62613 2.76969 2.21597C4.12613 0.859528 6.04664 0 8.10145 0ZM29.8985 2.06824H8.10145C6.59728 2.06824 5.2274 2.72632 4.22015 3.73358C3.21289 4.68711 2.60853 6.09728 2.60853 7.61488V29.412C2.60853 30.9162 3.21289 32.3263 4.22015 33.2933C5.2274 34.3005 6.58385 34.9586 8.10145 34.9586H29.8985C31.4027 34.9586 32.8129 34.3005 33.8201 33.2933C34.7737 32.3397 35.4318 30.9296 35.4318 29.412V7.60145C35.4318 6.09728 34.7737 4.68711 33.8201 3.72015C32.8129 2.71289 31.4027 2.05481 29.8985 2.05481V2.06824Z" fill="black" />
            </svg></p>
            {/* {<p className={styles.success_paragraph_one}>Your email is <br /> successfully verified!</p>} */}
            {customer?.customer?.has_subscription == true && disableSmsVerification == false ? <p className={styles.success_paragraph_one}>Your account is <br /> successfully verified!</p> : <p className={styles.success_paragraph_one}>Your email is <br /> successfully verified!</p>}
            {customer?.customer?.has_subscription == true ? <p className={styles.success_paragraph_two}>Happy shopping :) </p> : ''}
          </ModalBody>
        </Modal>


        <Modal className={`${styles.sms_success_modal} ${styles.resend_modal}`} size="lg" centered isOpen={resendVerificationModalOpen} toggle={() => { setResendVerificationModalOpen(false);openCompleteProfilePopup() }}>
          <ModalHeader className={styles.verification_success_popup_header}>
            <p className={styles.success_image_two} onClick={() => { setResendVerificationModalOpen(false);openCompleteProfilePopup() }}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <rect x="2.13477" y="12.8603" width="2.01907" height="15.1681" transform="rotate(-135 2.13477 12.8603)" fill="black" stroke="black" />
              <rect x="0.707107" y="2.13483" width="2.01907" height="15.1681" transform="rotate(-45 0.707107 2.13483)" fill="black" stroke="black" />
            </svg></p>
          </ModalHeader>
          <ModalBody className={`${styles.verification_success_popup_body} mt-0`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
              <g clip-path="url(#clip0_5026_1493)">
                <path d="M16.9455 33.5C7.54179 33.4765 -0.0226923 26.0874 5.11513e-05 16.9456C0.0227946 7.8185 7.6358 0.47944 17.0562 0.500043C26.4613 0.520646 34.0228 7.90974 34.0001 17.053C33.9773 26.1816 26.3658 33.5221 16.9455 33.4986V33.5ZM18.8301 14.4041C18.8301 14.4041 18.8256 14.4041 18.8241 14.4041C18.9105 12.9854 19.0091 11.5682 19.0818 10.1496C19.1531 8.77506 18.2464 7.79495 16.9591 7.82733C15.6885 7.8597 14.8955 8.8148 14.9774 10.1761C15.1548 13.1105 15.3216 16.045 15.4838 18.9794C15.5414 20.0257 16.0767 20.6512 16.9728 20.6806C17.9143 20.7115 18.5042 20.1346 18.5784 19.0265C18.6831 17.4872 18.7468 15.9449 18.8286 14.4041H18.8301ZM19.1137 24.186C19.1228 23.1014 18.1842 22.1655 17.0622 22.1419C15.9008 22.1169 14.988 22.9749 14.9698 24.1066C14.9516 25.2236 15.8507 26.1257 17.0001 26.1433C18.1569 26.161 19.1061 25.2824 19.1152 24.186H19.1137Z" fill="#D5193C" />
              </g>
              <defs>
                <clipPath id="clip0_5026_1493">
                  <rect width="34" height="33" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <p className={styles.success_paragraph_one}>Your link expired!</p>
            <button className={styles.resendLink} onClick={() => { resendExpireEmailLink() }}>Resend Email</button>
            {inputError?.email != '' ? <p className={`${styles.error_msg} mt-2`}>{inputError?.email}</p> : ''}
          </ModalBody>
        </Modal>


      </div>
      {completeProfileModal && <CompleteProfilePopupMember isOpen={completeProfileModal} toggle={()=>{toggleCompleteProfilePopup()}} fromReview={false}/>}

    </>
  );

};

export default SmsEmailVerificationIndex;

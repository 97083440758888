import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { triggerCartPopper } from '@/redux/actions/cartActions';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { bindPopper, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useRef, useState } from 'react';
import CartPopperCartItemsIndex from '@/containers/header/top-bar/cart-btn/cart-popper/cart-popper-cart-items/cart-popper-cart-items-index/cart-popper-cart-items-index';
import CartPopperEmptyCart from '@/containers/header/top-bar/cart-btn/cart-popper/cart-popper-empty-cart/cart-popper-empty-cart';
import styles from './cart-popper-index.module.scss';
import { useResponsive } from '@/libraries/responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getSaveForLaterItemCount } from "components/shared/cartFunctions";

interface IProps {
  totalItemsInCart: number;
  popupState: PopupState;
  anchorEl: HTMLElement | null;
  showCartOnMobile: boolean;
}
let timer: any;
const CartPopperIndex: React.FC<IProps> = ({ popupState, totalItemsInCart, anchorEl, showCartOnMobile }) => {
  const dispatch = useAppDispatch();
  const cartData = useAppSelector((state) => state.cart);
  const customerData = useAppSelector((state) => state.customer);

  const [isPopperOpenedManually, setIsPopperOpenedManually] = useState(false);
  const [saveForLaterCount, setSaveForLaterCount] = useState(0)
  const { isTablet } = useResponsive();

  useEffect(() => {
    // if (cartData.triggerHeaderCartPopper && !isPopperOpenedManually) {
    //   // timer = null
    //   setIsPopperOpenedManually(true);
    //   dispatch(triggerCartPopper(false));
    // }
    if(location && location.pathname == "/cart" && customerData.customer != 'not_login') { 
      dispatch(triggerCartPopper(false));
      return
    };
    if(cartData.triggerHeaderCartPopper) {
        if(timer != null) {
            clearTimeout(timer)
        }
        setIsPopperOpenedManually(true);
        dispatch(triggerCartPopper(false));
        timer =  setTimeout(() => {
            setIsPopperOpenedManually(false)
        }, 2000); 
    }

  }, [cartData.triggerHeaderCartPopper]);

  useEffect(() => {
    // debugger
    if(customerData.customer != 'not_login' &&  cartData.savedLaterCount.totalCount == undefined) {
      
      dispatch(getSaveForLaterItemCount())
    }
    if(customerData.customer != 'not_login' &&  cartData.savedLaterCount.totalCount != undefined) {
      setSaveForLaterCount(cartData.savedLaterCount.totalCount ? cartData.savedLaterCount.totalCount : 0 )
    }
  },[cartData.savedLaterCount, customerData.customer])


  return (
    <>
    { isTablet && totalItemsInCart <= 0 ? 
      <Popper className={styles.popperContainer}  keepMounted placement={'bottom-end'} open={showCartOnMobile} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <div className={styles.container}>
            {totalItemsInCart <= 0 && <CartPopperEmptyCart /> }
          </div>
        </Fade>
        )}
      </Popper> :
      <Popper className={styles.popperContainer}  {...bindPopper(popupState)} anchorEl={(popupState.anchorEl || anchorEl) } keepMounted placement={'bottom-end'} open={(isPopperOpenedManually || (popupState.isOpen && !isTablet))} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <div className={styles.container}>
            {totalItemsInCart <= 0 && <CartPopperEmptyCart /> } 
            {totalItemsInCart > 0 && <CartPopperCartItemsIndex _saveForLaterCount={saveForLaterCount} isPoperShown={isPopperOpenedManually || (popupState.isOpen && !isTablet)} />}
          </div>
        </Fade>
        )}
      </Popper>}
      {isTablet && totalItemsInCart > 0 && (isPopperOpenedManually) ? <div onClick={()=>{setIsPopperOpenedManually(false);}} className={styles.overlay_container} > </div> : "" }
     </>
  );
};

export default CartPopperIndex;

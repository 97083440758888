import { CookieKeys } from '@/constants/cookie-keys';
import { useAppSelector } from '@/hooks/redux-hooks';
import { GetCookie } from '@/libraries/browser-storage';
import { searchTag } from 'components/shared/globalFunctions';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './select-next-items-message.module.scss';
import Gconfig from 'globalconfig';
import { checkForShipment  } from 'components/shared/globalFunctions'
import Icons from '@/components/icon/icons';

interface IProps {}

const SelectNextItemsMessage: React.FC<IProps> = () => {
  const customerData = useAppSelector((state) => state.customer);

  const [hideNextItemBox, setHideNextItemBox] = useState(true);
  const [checkSsr, setCheckSsr] = useState(false);

  const hoverBoxCookie = useMemo(() => GetCookie(CookieKeys.HIDE_NEXT_ITEM_BOX), []);

  const handleHideNextItemHoverBox = () => {
    const now = new Date();
    const minutes = 120;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const expires = now.toGMTString();
    document.cookie = `hideNextItemBox=${true}; expires=${expires}; path=/;`;
    setHideNextItemBox(false);
  };

  useEffect(()=>{
    setCheckSsr(true)
  })

  return (
    <>
      {hoverBoxCookie === undefined && hideNextItemBox && customerData.customer !== 'not_login' && customerData.customer.has_subscription && searchTag(customerData.customer.tag_list, 'currentlyborrowing') === false && (
        <div className={'nxt_item_message_container'}>
          <img className={'img'} src={Gconfig.fashionpassImageStorage+"assets/img/unlock.png"} alt={` ${checkForShipment(customerData.customer)?.shipmentNumber == 1 ? 'First' : 'Second'} order is unlocked! Happy renting!`} />
          {/* <span className={'text'}>it's time to select your next items!</span> */}
          <span className={'text'}>
            {checkForShipment(customerData.customer)?.shipmentNumber == 1 ? <span>First order unlocked!<br/> Happy renting 💖</span>   : checkForShipment(customerData.customer)?.shipmentNumber == 2 ? <span>Second order unlocked!<br/> Happy renting 💖</span> : <span>Your next order will unlock<br/> in {checkForShipment(customerData.customer)?.remainingPlanDays} {checkForShipment(customerData.customer)?.remainingPlanDays>1?'days':'day'}</span>}
          </span>
          <button className={'close_icon'} onClick={handleHideNextItemHoverBox}>
            {/* X */}
            <Icons.Close />
          </button>
        </div>
      )}
    </>
  );
};

export default SelectNextItemsMessage;

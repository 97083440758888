import React from 'react';
import WaveLoader from 'components/waveLoader'
import Gconfig from 'globalconfig';
// import ReactDOM from 'react-dom';
const MainLoader = (props) => {
    if(props.small  != undefined && props.small == true ){
        return(
            <div className={`mainLoader ${props.from == 'planPopup' ? 'posistion' : ''}`}>
                <span>
                    {/* <img src={Gconfig.fashionpassImageStorage+"assets/img/loading-filter-sorting-light-new.gif?profile=a"}/> */}
                    <WaveLoader size="large"/>
                </span>
            </div>
        )
    }
    else if(props.blackLoader  != undefined && props.blackLoader == true ){
        return(<div className={`mainLoader ${props.from == 'planPopup' ? 'posistion' : ''}`}>
            <span>
                <img src={Gconfig.fashionpassImageStorage+"assets/img/new_black_loader.svg"}/>
            </span>
        </div>
        )
    }
    return(
        <div className={`mainLoader ${props.from == 'planPopup' ? 'posistion' : ''}`}>
            <span>
                {/* <img src={Gconfig.fashionpassImageStorage+"assets/img/loading-filter-sorting-light-new.gif?profile=a"}/> */}
                <WaveLoader size="large"/>
            </span>
        </div>
    )
}
export default MainLoader;
import React from 'react';
import ProductFilterMobile from 'components/layouts/products/productFiltersMobile';
import styles from './product-list-headers-index.module.scss';

interface IProps {}

const ProductListHeadersIndex: React.FC<IProps> = () => (
  <>
    {/* <ProductFilterMobile /> */}
  </>
);

export default ProductListHeadersIndex;

import { Icons } from '@/libraries/icons';
import { Popup } from '@/libraries/material-ui';
import React from 'react';
import styles from './notification-popup.module.scss';

interface IProps {
  isVisible: boolean;
  handleClosePopup: () => void;
  borderColor: string;
  bodyHtml: string;
}

const NotificationPopup: React.FC<IProps> = ({ isVisible, handleClosePopup, borderColor, bodyHtml }) => {
  return (
    <Popup isVisible={isVisible} handleClosePopup={handleClosePopup} size="xl" className={styles.popup} style={{ borderColor: borderColor }}>
      <Icons.CrossBold className={styles.icon} onClick={handleClosePopup} />
      <section dangerouslySetInnerHTML={{ __html: bodyHtml }} />
    </Popup>
  );
};

export default NotificationPopup;

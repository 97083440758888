const numericOnly = (value: string) => {
  if (value == 'e') return false;
  return /^[0-9]*$/gm.test(value);
};

const alphabetOnly = (value: string) => {
  if (value.length == 1 && /\s/.test(value) == true) return false;
  return /^[a-zA-Z\s]*$/gm.test(value);
};

export const Validate = {
  isNumericValue: (value: string) => numericOnly(value),
  isAlphabetValue: (value: string) => alphabetOnly(value),
};

import { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { updateTags } from './myAccountFunctions';
import { connect } from 'react-redux';

const SubscribedHomePagePopup = (props) => {

    const [modal, setModal] = useState(true);
    let newSitePopupBtn = ()=>{
        setModal(false)
        props._updateTags('', 'NEWSITEPOPUP')

    }
    return (
        <div className=""> 
            <Modal isOpen={modal} centered className="subscribe_home_modal">
                <ModalBody className="modalBody">
                    <div className="pt-3">
                        <p className="text-left"> Welcome to the new FashionPass website! Below are just a few updates you will notice as you begin to use the new website, with many more on the way:  </p>
                        <ul>
                            <li> Search + filtering improvements </li>
                            <li> Significant speed enhancements </li>
                            <li> Cleaner design </li>
                            <li> App like functionality (with an official app on the way!) </li>
                        </ul>
                        <p> We have made a few changes to our FashionPass credit program to support our continued growth while continuing to reward you, our amazing members: </p>
                        <p> 1) Product Review Credits: Earn $0.50 in FP Cash per review + earn an additional $0.50 for every photo upload </p>
                        <p> 2) You will now receive $10 in FP Cash for every friend referral </p>
                        <p> 3) For every $50 spent on purchases, you will receive $5 in FP Cash </p>
                        <div className="text-center">
                            <button onClick={(e)=>newSitePopupBtn()}>START RENTING! </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        _updateTags: (add, remove) => dispatch(updateTags(add, remove))
    }
};

export default connect(null, mapDispatchToProps)(SubscribedHomePagePopup)
// export default (SubscribedHomePagePopup);
import React, { useState, useEffect, createElement, Fragment } from 'react';
import styles from './new-search.module.scss';
import algoliasearch from 'algoliasearch';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import Router from 'next/router';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, FreeMode } from 'swiper';
import { render } from 'react-dom';
import dynamic from 'next/dynamic';
import { set } from 'lodash';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });
import { useAppSelector, useAppDispatch } from '@/hooks/redux-hooks';
import { filterQuerySubmit, globalRedirectionNavigation } from '@/redux/thunks/collectionPageThunks';
import Link from 'next/link';
import SnapShotPopup from 'components/shared/snapshotPopup';
import Gconfig from 'globalconfig';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { useSelector } from 'react-redux';
let trendingSearchArr:any = []
let recentSearchesPlugin:any = null
let isUserLogin = false
let timer: any = null;
let queryState: any = '';
let prevQuery = ""
let _cswiperSlide = 0
let datafilter = {}
const NewSearch: React.FC<IProps> = () => {
  const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0); //for swiper if needed
  const [domRender, setDomRender] = useState(0);
  const [renderState, setRenderState] = useState([]) // for swiper if needed
  const [openSnapModal, setOpenSnapModal] = useState(false); // for take snap workisQueryText

  let collectionPageData = useSelector((state) => state.collectionPage);
  const customer = useAppSelector(state => state.customer.customer); //for snapshot modal
  const systemSettings = useAppSelector((state) => state.systemSettings);

  const dispatch = useAppDispatch();
  let _arr: any = [];
  let brandsArr: any = [];
  let collectionsArr: any = [];


  const handleLinkClick = (event: any) => {
    event.preventDefault();
    Router.push('/'+event.currentTarget.getAttribute('href'));
  };

  const handleRedirection = (handle: any, type: string): void => {

    updateRecentSearchManually()

    console.log(handle, type , 'handle type console',type )
    if (handle != "" && handle != undefined) {
     
      if (type == "product_handle") {
        Router.push("/" + handle);
        window.scrollTo(0, 0);
      }
      else {
        dispatch(globalRedirectionNavigation(handle, true, datafilter,false,false,false,'new_search'));
      }
      setTimeout(()=>{
        document.body.classList.remove('aa-Detached');
        document.body.removeAttribute('style');
      },300)
    }
  };

  const updateRecentSearchManually = () => {
    if(isUserLogin == false) return
    if(document.querySelector('.aa-Input')) {
      if(recentSearchesPlugin) {
        let lastSearchVal = document.querySelector('.aa-Input')?.value
        if(lastSearchVal) {
          let obj = {
              id: lastSearchVal,  
              label: lastSearchVal,  
              url: ''  
            }
          recentSearchesPlugin?.data?.addItem(obj)
        }
      }
    } 
  }


  function onSwiperSlideChange(e: any) { //for swiper use if needed
    setCurrentSwiperSlide(e.activeIndex)
    _cswiperSlide = e.activeIndex
  }

  const handleSwiperSlideChange = (swiper:any) => { //for swiper use if needed
    setCurrentSwiperSlide(swiper.activeIndex);
    _cswiperSlide = swiper.activeIndex
  };

  const toggleSnapModal = () => {  //for take snao modal
    setOpenSnapModal(!openSnapModal);
  }

  useEffect(() => {

    if(systemSettings.systemSettings && systemSettings.systemSettings.length > 0) {
      let _systemSettings = systemSettings.systemSettings
      _systemSettings.find(settings => {
          if (settings.setting_id == Gconfig?.system_settings?.trendingSearchId) {
              trendingSearchArr = JSON.parse(settings.setting_value)
          }
      })
    }

    const searchClient = algoliasearch(
      'ADKUUYLBX3',
      '0977792072d942deb16719a55f0eb3f6'
    );

    recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'RECENT_SEARCH',
      limit:3
    });

    const brandSuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "Vendor_Live",
      getSearchParams() {
        return {
          hitsPerPage: 3,
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'brandSuggestionsPlugin',
          getItems(params) {
            if (!params.state.query) {
              return [];
            }
            if (params.state.query) {
              brandsArr = [];
            }
            return source.getItems(params);
          },
          templates: {
            ...source.templates,
            header({ items, html }) {
              if (items.length > 0) {
                return (
                  html`<span className=${styles.aCategoryTitle} > Brands </span>`
                );
              }
            },

            item({ item, components, html }) {
              let vendorHandle = item.linked_category_handle;
              // onContextMenu={(e: any) => { handleRightClick(e, vendorHandle) }}
              return (
                <Link legacyBehavior href={'/' + vendorHandle}>
                  <a onClick={handleLinkClick}>
                    <div className='cursor-pointer no-hover algolia-highlight' data-href={"/" + vendorHandle} onClick={() => { handleRedirection(vendorHandle, '') }} >
                      {components.Highlight({ hit: item, attribute: ['name'], tagName: 'span' })}
                    </div>
                   </a>
                 </Link>
              )
            }
          },
        };
      },
    });

    const collectionSuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: "Collection_Live",
      getSearchParams() {
        return {
          hitsPerPage: 3,
        };
      },
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'collectionSuggestionsPlugin',
          getItems(params) {
            if (!params.state.query) {
              return [];
            }
            if (params.state.query) {
              collectionsArr = [];
            }

            return source.getItems(params);
          },
          templates: {
            ...source.templates,
            header({ items, html }) {
              if (items.length > 0) {
                return (
                  html`<span className=${styles.aCategoryTitle}> Collections </span>`
                );
              }
            },
            item({ item, components, html }) {

              let _Handle = item.handle;
              let newHandle = item.handle
              // onContextMenu={(e: any) => { handleRightClick(e, _Handle) }} 
              return (
                <Link legacyBehavior href={newHandle}>
                  <a onClick={handleLinkClick}>
                    <div className='cursor-pointer algolia-highlight' data-href={"/" + _Handle} onClick={() => { handleRedirection(_Handle, '') }}>
                      {components.Highlight({ hit: item, attribute: ['collection_name'], tagName: 'span' })}
                    </div>
                  </a>
                </Link>
              )
            }
          },
        };
      },
    });

    let mobileView = false;
    let hideProduct = false;
    let inputVal = document.getElementsByClassName('aa-Input')[0]?.value;

    if (screen.width < 767) {
      mobileView = true
    } else {
      mobileView = false
    }

    const debounce = (fn:any, delay:any) => {
      let timeoutID:any;
      return (...args:any) => {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...args), delay);
      };
    }

    const autoCompleteInstance = autocomplete({
      container: '#autocomplete',
      placeholder: mobileView ? '' : 'Search',
      insights: true,
      plugins: isUserLogin == false ? [brandSuggestionsPlugin, collectionSuggestionsPlugin] : [brandSuggestionsPlugin, collectionSuggestionsPlugin, recentSearchesPlugin],
      openOnFocus: true,
      renderer: { createElement, Fragment, render },
      
      onStateChange: debounce(({ state, setIsOpen }) => {
        queryState = state.query
        if (state.query !== prevQuery) {
          prevQuery = state.query;
          // console.log('Search completed with query:', state.query);
          if (!state.query) return; 
          autoCompleteInstance.setQuery(queryState + '.')
          autoCompleteInstance.refresh();
          setTimeout(() => {
            if (queryState.indexOf('.') > -1) queryState = queryState.split('.')[0]
            autoCompleteInstance.setQuery(queryState)
            autoCompleteInstance.refresh();
            // _cswiperSlide = 0

            if(document.querySelector('#algolia-search-swiper_mobile')) {
              const swiper = document.querySelector('#algolia-search-swiper_mobile').swiper;
              if(swiper){
                swiper.slideTo(0);
              }
            }
            
            if(document.querySelector('#algolia-search-swiper_desktop')) {
              const swiper = document.querySelector('#algolia-search-swiper_desktop').swiper;
              if(swiper){
                swiper.slideTo(0);
              }
            }

          }, 5);
        }
      }, 600),

      getSources({ query }) {
        if (query) {
          _arr = [];
        }

        let isQueryText = ""
         if(document.querySelector('.aa-Input')) {
          isQueryText = document.querySelector('.aa-Input')?.value;
         }

        setTimeout(() => {
          setDomRender(domRender + 1);
        }, 2000);
       
        return [
          {
            sourceId: !isQueryText ? 'recentItem' : 'products',
            getItems() {
              const searchResultsPromise = getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: 'Product_Live',
                    query,
                    params: {
                      hitsPerPage: !isQueryText ? 1 : 20,
                      attributesToSnippet: ['name:10', 'description:35'],
                      snippetEllipsisText: '…',
                    },
                  },
                ],
              });
              return searchResultsPromise;
            },
            templates: {
              header({ }) {

                  if (isQueryText) {
                    hideProduct = false;
                  } else {
                    hideProduct = true;
                  }

                  let topbar = document.querySelector('.topbar_container')
                  if(topbar && !mobileView) {
                    let topbarProperties = topbar.getBoundingClientRect()
                    let _aaPanel = document.querySelector('.aa-Panel');
                    if(_aaPanel){
                      _aaPanel.style.top = 46 + topbarProperties.top + "px"
                    }
                  }
                
                return (
                  hideProduct ? <></> :
                  <>
                    <p hidden={hideProduct} className={`${styles.aCategoryTitle} mb-3`}>Products</p>
                  </>
                )
              },

              item({ item, components, }) {
              
                if(!isQueryText) { 

                  let recentSearches = []
                  let isShowRecentSearch = true

                  if(recentSearchesPlugin.data){
                    let algoliaRecentSearch = recentSearchesPlugin?.data.getAll()
                    if(algoliaRecentSearch && algoliaRecentSearch.length > 0) {
                      recentSearches = algoliaRecentSearch
                    }
                  }

                  if(isUserLogin == false) {
                    isShowRecentSearch = false
                  }

                  return (
                    <div className={styles.recent_search_div} >

                      { isShowRecentSearch && recentSearches.length > 0 ? 
                      <div className={styles.recent_search_box}>
                        <h5 className={styles.heading} > Recent Searches </h5>
                        {recentSearches.map((val:any, ind:number) => (
                          ind < 3 ?
                            <div className={styles.search_item} onClick={(e)=>{ searchFromRecent(val.label, 'recent') }} > 
                                {/* <img className={styles.icon} src={`${Gconfig.fashionpassImageStorage+"assets/img/recent_search_icon.svg"}`} />  */}
                                <i className={`fas fa-history  ${styles.icon}`}></i>
                                <p className={styles.text}> {''+val.label.toLowerCase()} </p>
                            </div> : ""
                          )
                        )}
                      </div> : ""}

                      <div className={styles.trending_search_box}>
                        <h5 className={styles.heading} > Trending Searches </h5>
                        <div className={styles.search_box}>
                          {
                            trendingSearchArr.length > 0 && trendingSearchArr.map((val:string, index:number) => {
                              return (
                                <span className={styles.search_text} onClick={(e) => {searchFromRecent(val, 'trending') }} > { val } </span>
                              )
                            })
                          }
                        </div>
                      </div>

                    </div>
                  )
                }
                else {

                  // let productHandle = "product/" + item.product_handle;
                  // if (item.vendor_handle != "") {
                  //   productHandle = "product/" + item.vendor_handle + "/" + item.product_handle;
                  // }
  
                  // setRenderState(_arr)
                  // let ind = new Date(Date.now()).getTime();
                
                  // return (
                  //   <Link legacyBehavior href={'/' + productHandle}>
                  //     <a onClick={handleLinkClick}>
                  //       <div key={'range_key' + ind}>
                  //         <div className={styles.image_info_div}>
                  //           <a className={styles.cursorPointer} data-href={"/" + productHandle} onClick={() => { handleRedirection(productHandle, "product_handle") }} >
                  //             <img style={{ width: '150px' }} src={item?.image + "?profile=b"} width="100%" />
                  //           </a>
                  //         </div>
                  //         <span className="d-flex flex-column text-center algolia-highlight mt-1">
                  //           <a data-href={"/" + productHandle} onClick={() => { handleRedirection(productHandle, "product_handle") }}>
                  //             <label className={styles.brandName}> {components.Highlight({ hit: item, attribute: ['vendor'], tagName: 'span' })} </label>
                  //             <label className={styles.vendorName}> {components.Highlight({ hit: item, attribute: ['product_title'], tagName: 'span' })} </label>
                  //           </a>
                  //         </span>
                  //       </div>
                  //     </a>
                  //   </Link>
                  // )

                  // ------------------------------------------------------------------------------------------
                  // slider work 
                  const addItemInArray = (itm: any) => {
                    if (!_arr.some((elem: any) => elem.objectID === item.objectID)) {
                      _arr.push(itm);
                    }
                  }
                  addItemInArray(item);

                }
              },

              footer({ components }) {

                const handleRedirectionProd = (handle: any, type: string): void => {
                  updateRecentSearchManually()
                  if (handle != "" && handle != undefined) {
                    if (type == "product_handle") {
                      autoCompleteInstance.setIsOpen(false)
                      Router.push("/" + handle);
                      window.scrollTo(0, 0);
                    }
                    setTimeout(()=>{
                      document.body.classList.remove('aa-Detached');
                      document.body.removeAttribute('style');
                    },300)
                  }
                };
                
                const clearButton = document.querySelector('.aa-ClearButton');
             
                const sourceHeader = document.querySelector('.aa-SourceHeader');
                if (clearButton && mobileView) {
                  clearButton.addEventListener('click', (event) => {
                    if (sourceHeader) {
                      hideProduct = true;
                      sourceHeader.childNodes[0].classList.add('hidden');
                    }
                    document.getElementsByClassName('aa-Input')[0].value = '';
                    document.getElementsByClassName('aa-Input')[0].focus();
                    autoCompleteInstance.setQuery('')
                    autoCompleteInstance.refresh();
                    event.preventDefault();
                  });
                }
                if(isQueryText) {
                  return (
                    <>
                      <MediaQuery minWidth={768}>
                        <Swiper
                          initialSlide={_cswiperSlide}
                          slidesPerView={3}
                          modules={[Pagination, Navigation]}
                          navigation={true}
                          className="plan-carosel-mobile plan-carosel-algolia-comp"
                          onSlideChange={handleSwiperSlideChange}
                          id="algolia-search-swiper_desktop"
                          spaceBetween={2}
    
                          >
                          {
                            _arr.length > 0 && _arr.map((val: any, ind: number) => {
                              let productHandle = "product/" + val.product_handle;
                              if (val.vendor_handle != "") {
                                productHandle = "product/" + val.vendor_handle + "/" + val.product_handle;
                              }

                            return (
                              <SwiperSlide key={'algolia_swiper' + ind}>
                                <Link legacyBehavior href={'/' + productHandle}>
                                  <a onClick={handleLinkClick}>
                                    <div onClick={() => { handleRedirectionProd(productHandle, "product_handle") }}>
                                      <div className={styles.image_info_div}>
                                        <img className={styles.cursorPointer} src={val?.image + "?profile=b"} />
                                      </div>
                                      <span className="d-flex flex-column text-center algolia-highlight mt-1">
                                        <a data-href={"/" + productHandle} onClick={() => { handleRedirectionProd(productHandle, "product_handle") }}>
                                          <label className={styles.brandName}> {components.Highlight({ hit: val, attribute: ['vendor'], tagName: 'span' })} </label>
                                          <label className={styles.vendorName}> {components.Highlight({ hit: val, attribute: ['product_title'], tagName: 'span' })} </label>
                                        </a>
                                      </span>
                                    </div>
                                  </a>
                                </Link>  
                              </SwiperSlide>
                            )
                          })}
                        </Swiper>
                      </MediaQuery>

                      <MediaQuery maxWidth={768}>
                        <Swiper
                          modules={[Pagination, Navigation, FreeMode]}
                          slidesPerView={2.38}
                          // centeredSlides={_arr.length > 3 ? true : false}
                          initialSlide={_cswiperSlide}
                          navigation={true}
                          spaceBetween={10}
                          className="plan-carosel-mobile plan-carosel-algolia-comp"
                          onSlideChange={(e: any) => { onSwiperSlideChange(e) }}
                          id="algolia-search-swiper_mobile"
                          simulateTouch={true}
                          // loop={_arr.length > 3 ? true : false}
                        >
                          {_arr.length > 0 && _arr.map((val: any, ind: number) => {

                            let productHandle = "product/" + val.product_handle;
                            if (val.vendor_handle != "") {
                              productHandle = "product/" + val.vendor_handle + "/" + val.product_handle;
                            }

                            return (
                              <SwiperSlide key={'main_c' + ind} hidden={hideProduct}>
                                <Link legacyBehavior href={'/' + productHandle}>
                                  <a onClick={handleLinkClick}>
                                    <div>
                                      <a data-href={"/" + val.product_handle} onClick={() => { handleRedirectionProd(productHandle, "product_handle") }} >
                                        <div className={styles.image_info_div}>
                                          <img src={val.image + "?profile=b"} />
                                        </div>
                                      </a>
                                      <span className="d-flex flex-column text-center algolia-highlight mt-1">
                                        <a data-href={"/" + productHandle} onClick={() => { handleRedirectionProd(productHandle, "product_handle") }}>
                                          <label className={styles.brandName}> {components.Highlight({ hit: val, attribute: ['vendor'], tagName: 'span' })} </label>
                                          <label className={styles.vendorName}> {components.Highlight({ hit: val, attribute: ['product_title'], tagName: 'span' })} </label>
                                        </a>
                                      </span>
                                    </div>
                                  </a>
                                 </Link>  
                              </SwiperSlide>
                            )
                          })
                          }
                        </Swiper>
                      </MediaQuery>
                    </>
                  )
                }
              },

              noResults() {
                if (_arr.length == 0 && inputVal?.length > 0) return 'No products for this query.';
              },
            }
          },
        ];
      },

    });

    const searchFromRecent = (data:string, from:any) => {
  
      let search_inp =  document.querySelector('.aa-Input')

      if(search_inp) {
        search_inp.value = '';    
        search_inp.blur();
      }
      autoCompleteInstance.setQuery('')
      autoCompleteInstance.refresh();

      setTimeout(() => {  
        if(search_inp) {
          search_inp.value = data;
          search_inp.focus();
        }
      
        if(document.querySelector('.aa-DetachedSearchButtonIcon')) {
          document.querySelector('.aa-DetachedSearchButtonIcon').click()
        }
       
        autoCompleteInstance.setQuery(data)
        autoCompleteInstance.refresh();
      },5)
    }

    return () => {
      if (autoCompleteInstance) {
        autoCompleteInstance.destroy();
      }
    };
  }, []);

  useEffect(() => {
    datafilter = collectionPageData?.filterData?.data
  }, [collectionPageData]);
  useEffect(() => {
    const img = document.createElement('img');
    img.src = Gconfig.fashionpassImageStorage+'assets/Search.svg';
    img.alt = 'Search icon';
    img.ariaLabel = 'Search for brands, collections or products.'
    if(document.getElementsByClassName('aa-SubmitButton')?.length > 0){
      if(document.getElementsByClassName('searchIcon')?.length == 0) {
        img.className = "searchIcon"
        document.getElementsByClassName('aa-SubmitButton')?.[0]?.appendChild(img)
      }
    }
    if(document.getElementsByClassName('aa-DetachedSearchButtonIcon')?.length > 0){
      if(document.getElementsByClassName('searchIconMobile')?.length == 0) {
        img.className = "searchIconMobile"
        document.getElementsByClassName('aa-DetachedSearchButtonIcon')?.[0]?.appendChild(img)
      }
    }

    function handleKeyDown(evt: any) {
      if (evt.key === 'Enter' && evt?.target?.className == "aa-Input") {
        const sourceElement = document.querySelector('.aa-Source');
        const aaPanel = document.querySelector('.aa-Panel');
        const sourceElementFooter =  document.querySelector('.aa-SourceFooter'); // for product swiper

        let inputElement = document.querySelector('.aa-Input');
        let inputValue = inputElement?.value; // for snapshot work

        //snapshot modal work
        // debugger
        if (inputValue == "take snap") {
          toggleSnapModal();
          aaPanel.style.display = 'none';
          return;
        }
        if (sourceElement) {
          let linkNode = '';
          if (sourceElement.childNodes[1]?.childNodes[0]?.childNodes[0]) {
            linkNode = sourceElement.childNodes[1]?.childNodes[0]?.childNodes[0];
          }

          if (linkNode) {
            let linkVal = linkNode.getAttribute('href');
            if (linkVal && linkVal.trim() !== "") {
              handleRedirection(linkVal, "enter_key");
              document.body.classList.remove('aa-Detached');
              document.body.removeAttribute('style');
              return;
            }
          }
        }

        if (sourceElement) {
          let linkNode = '';
          if (sourceElement?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]) {
            linkNode = sourceElement?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0];
          }

          if (linkNode) {
            let linkVal = linkNode.getAttribute('href');

            if (linkVal && linkVal.trim() !== "") {
              handleRedirection(linkVal, "enter_key");
              document.body.classList.remove('.aa-Detached');
              document.body.removeAttribute('style');
              return;
            }
          }
        }

        if (aaPanel) {
          let linkNode = '';
          if (aaPanel?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]) {
            linkNode = aaPanel?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0];
          }

          if (linkNode) {
            let linkVal = linkNode.getAttribute('href');
            if (linkVal && linkVal.trim() !== "") {
              handleRedirection(linkVal, "enter_key");
              document.body.classList.remove('.aa-Detached');
              document.body.removeAttribute('style');
              return;
            }
          }
        }

      // for swiper
       if(sourceElement) {
        let linkNode = ""
        if(sourceElement?.childNodes[2]?.childNodes[0]?.childNodes[2]?.childNodes[0]?.childNodes[0]) {
          var viewElemIndex:number = 0
          var elemArr:any = sourceElement?.childNodes[2]?.childNodes[0]?.childNodes[2]?.childNodes
          if(elemArr && elemArr.length > 0) {
            elemArr.forEach((node:string, index:number) => {
              if (document.querySelector('#algolia-search-swiper_mobile')) {
                if(node?.className?.indexOf('swiper-slide-prev') > -1) {
                  viewElemIndex = index
                }
              }
              else if(document.querySelector('#algolia-search-swiper_desktop')) {
                if(node?.className?.indexOf('swiper-slide-active') > -1) {
                  viewElemIndex = index
                }
              }
            })
          }
          
          
          linkNode = sourceElement?.childNodes[2]?.childNodes[0]?.childNodes[2]?.childNodes[viewElemIndex]?.childNodes[0];
          if(linkNode) {
            let linkVal = linkNode.getAttribute('href');
            if (linkVal && linkVal.trim() !== "") {
              handleRedirection(linkVal, "enter_key");
              document.body.classList.remove('.aa-Detached');
              document.body.removeAttribute('style');
              return;
            }
          }
         }
       }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    const detachedCancelButton = document.querySelector('.aa-DetachedSearchButtonIcon');
      detachedCancelButton?.addEventListener('click', (event) => {
        setTimeout(()=>{
          document.body.classList.remove('aa-Detached');
          document.body.removeAttribute('style');
        },1000)
        event.preventDefault();
    });
   
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if(customer == "not_login"){
      isUserLogin = false
    }
    else {
      isUserLogin = true
    }
  },[customer])



  return (
    <>
      <div id=''>
        <div id="autocomplete" className={styles.spaceTop + " newsearch "}></div>
      </div>
      {openSnapModal && <SnapShotPopup isOpen={openSnapModal} toggle={() => toggleSnapModal()} isForm={true} customer={customer} />}
    </>
  );
};

export default NewSearch;
import { useAppSelector } from '@/hooks/redux-hooks';
import { TextExistsInString } from '@/utils/string-methods';
import React, { useMemo, useState, useEffect } from 'react';
import styles from './monthly-discount-message.module.scss';
import { searchTag } from 'components/shared/globalFunctions';
import Link from 'next/link';
interface IProps {}

const UpdateProfileNotification: React.FC<IProps> = () => {
  const [showUpdateProfileIcon, setShowUpdateProfileIcon] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showSflNotification, setShowSflNotification] = useState<any>('')
  const customerData = useAppSelector((state) => state.customer);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);

  const checkCustomerProfileFill =() => {
    if(customerData.customer != 'not_login'){
      let count = 0
        const tag_list = customerData.customer.tag_list;
        const fetchedPrefs = customerPrefrence.customerPreferences;
        if(searchTag(tag_list, 'height') !== true && searchTag(tag_list, 'jeans size') !== true){
            setShowUpdateProfileIcon(true)
            count += 1
          }else{
            setShowUpdateProfileIcon(false)
        }

        if (fetchedPrefs.preferences === null || (fetchedPrefs.preferences && (!fetchedPrefs.preferences.showNotificationPage || !fetchedPrefs.preferences.homePageNotification))) {
          if (fetchedPrefs.preferences == null || (!fetchedPrefs.preferences.showNotificationPage && !fetchedPrefs.preferences.homePageNotification)){
              setShowSflNotification('multiple')
              count += 2
          } else if (!fetchedPrefs.preferences.showNotificationPage || !fetchedPrefs.preferences.homePageNotification) {
            setShowSflNotification('single')
            count += 1
          }
          // setShowSflNotification(true)
        }else{
          setShowSflNotification('')
          // setShowSflNotification(false)
        }
        
        setNotificationCount(count)
    }
  };

  useEffect(()=>{
    checkCustomerProfileFill()
  }, [customerData,customerPrefrence.customerPreferences])

  if (customerData.customer === 'not_login' || (showUpdateProfileIcon === false && showSflNotification == '')) return <></>;
  return (
    <div className='updprof_notification_box'>
        <div className='update_prof_Icon'><label><Link href={showSflNotification != '' ? '/notification' : '/my-account'}>{notificationCount}</Link></label></div>
        {
          showSflNotification == 'single' ?
          <div className='update_prof_tooltip_box'>
              <p className='heading'>New Feature Alert:</p>
              Save items for later to plan your next order! <Link href={'/notification'}>Learn More</Link>
          </div> :
          showSflNotification == 'multiple' ?
          <div className='update_prof_tooltip_box'>
              <p className='heading'>New Feature Alert:</p>
              Find inspiration for your next order on our new and improved homepage! <Link href={'/notification'}>Learn More</Link>
          </div>:
          <div className='update_prof_tooltip_box'>
              Update your profile in order to leave product reviews & filter by your size!
          </div>
        }
    </div>
  );
};

export default UpdateProfileNotification;

import { useAppSelector } from '@/hooks/redux-hooks';
import React, { useEffect, useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import styles from "styles/_pause_delay_popup.module.scss"
import { customerRating } from 'components/shared/globalFunctions';
import jsCookie from 'js-cookie';
import moment from 'moment'
import { addEditSurveyResponse, getSurveyQuestionsBySurveyId } from './myAccountFunctions';

interface IProps {
    isOpen: boolean,
    toggle: any,
    isGettingNPSFromUser : boolean,
}

interface SurveyQuestionDetail {
  id: number;
  survey_question_id: number;
  survey_field: string;
  customfield: string;
  options: number;
  placeholder: string;
  survey_type_id: number;
  surveyquestion: string;
  type: string;
  created_at: number;
  created_by: number;
  updated_at: number;
  updated_by: number;
}

interface ChildQuestion {
  created_at: number;
  created_by: number;
  groupname: string;
  id: number;
  isChecked: boolean;
  parrent_id: number;
  priority: number;
  status: number;
  surveyQuestionDetailVMs: SurveyQuestionDetail[];
  survey_type_id: number;
  surveyquestion: string;
  type: string;
  updated_at: number;
  updated_by: number;
}

// Interface for a parent question, which includes child questions
interface ParentQuestion extends ChildQuestion {
  childQuestions: ChildQuestion[];
}

// const npsScore = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const PauseDelayPopup: React.FC<IProps> = ({ isOpen, toggle, isGettingNPSFromUser }) => {

  const [isSubmittedNpsScore, setIsSubmittedNpsScore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [snoozeDate, setSnoozeDate] = useState('')
  const [_npsScore, set_npsScore] = useState<ParentQuestion[]>([])
  
  const customer = useAppSelector(state => state.customer.customer)
  
  useEffect(() => { 
    let snozedDate = ''
    if(customer !== 'not_login' && customer?.attributes?.attributes?.length > 0) {
      const attributes = customer.attributes.attributes
      attributes.map((val:any, ind:number) => {
        if(val.name == 'Paused Scheduled') {
            if(val?.attributeValues?.length > 0) {
              val?.attributeValues.map((v) => {
                if(v.valueDefination == 'Paused Scheduled Date') {
                  snozedDate = v.value
                }
              })
            }
        }    
      })
    }
    setSnoozeDate(snozedDate)
    getQuickSurveyQuestion()
  }, [])

  // getting surveytype for NPS questions / quick questions popup
  const getQuickSurveyQuestion = async() => {
    let res = await getSurveyQuestionsBySurveyId(30);
    if(res?.success && res.response && res.response.length > 0) { 

      let _parrentQuestions: ParentQuestion[] = []
      let childQuestions: ChildQuestion[] = []

      res.response?.map((item:any) => {
          if(item.parrent_id === 0 && item.status == 1){
              item.childQuestions = []
              item.isChecked = item.type === "label" ? true : false
              _parrentQuestions.push(item)
          }
          else if(item.parrent_id != 0 && item.status == 1) {
              childQuestions.push(item)
          }
      })
      
      childQuestions.forEach((ite:ChildQuestion) => {
          _parrentQuestions.forEach(it => {
              if (it.id === ite.parrent_id && ite.status == 1) {
                  it.childQuestions.push({...ite, isChecked:false});
              }
          });
      });
      set_npsScore(_parrentQuestions)

    } else {
      set_npsScore([])
    }
  }
  
  // const updateCustomerNpsScore = async(score:number) => {

  //   let snozedDate = null
  //   if(customer !== 'not_login' && customer?.attributes?.attributes?.length > 0) {
  //     const attributes = customer.attributes.attributes
  //     attributes.map((val:any, ind:number) => {
  //       if(val.name == 'Paused Scheduled') {
  //           if(val?.attributeValues.length > 0) {
  //             // snozedDate = val?.attributeValues[0].value
  //             val?.attributeValues.map((v) => {
  //               if(v.valueDefination == 'Paused Scheduled Date') {
  //                 snozedDate = v.value
  //               }
  //             })
  //           }
  //       }    
  //     })
  //   }
    
  //   let obj = {
  //     customer_id:customer !== 'not_login' ? customer.customer_id: null,
  //     nps_value: score,
  //     snooze_date: snozedDate
  //   }

  //   if(snozedDate != null) {
  //     setSnoozeDate(snozedDate)
  //   }

  //   if(snozedDate == null) {
  //     delete obj["snooze_date"];
  //   }
  //   setIsLoading(true)
  //   try {
  //      customerRating(obj)
   
  //     setTimeout(() => { 
  //       setIsLoading(false)
  //       setIsSubmittedNpsScore(true)
  //       jsCookie.remove('getNpsScore')
  //     },2000)
      
  //   } catch (error) {
  //     console.log('error',error)
  //   }
  
  // }

  // function to submit nps score in banjo
  const submitCustomerNpsScore = async(question:any) => {

    let snozedDate = null
    if(customer !== 'not_login' && customer?.attributes?.attributes?.length > 0) {
      const attributes = customer.attributes.attributes
      attributes.map((val:any, ind:number) => {
        if(val.name == 'Paused Scheduled') {
            if(val?.attributeValues.length > 0) {
              // snozedDate = val?.attributeValues[0].value
              val?.attributeValues.map((v) => {
                if(v.valueDefination == 'Paused Scheduled Date') {
                  snozedDate = v.value
                }
              })
            }
        }    
      })
    }

    let data = {
      customer_id:customer !== 'not_login' ? customer.customer_id: null,
      // customer_id: customer.customer_id, 

      survey_type_id: 30,

      // surveyResponseDetailVMs: reformatQuestionObjects(),
      // nps_value: score,
      // email: this.props.email,
      // survey_ids: survey_id,
      // snooze: this.state.snoozeDate ? true : false,

      // isChecked: true,

      surveyResponseDetailVMs: [
        {
          survey_question_detail_id: question.id,
          survey_question_id: question.parrent_id,
          // survey_response_answer: "yes",
          survey_response_answer: question.surveyquestion,
        }
      ],

      nps_value: question.surveyquestion,

    }

    if(snozedDate != null) {
      setSnoozeDate(snozedDate)
    }

    if(snozedDate == null) {
      delete data["snooze_date"];
    }
    setIsLoading(true)
    try {
      addEditSurveyResponse(data).then(async response => { 
        if (response.success == true){
          console.log("succ")
        }
      })
   
      setTimeout(() => { 
        setIsLoading(false)
        setIsSubmittedNpsScore(true)
        jsCookie.remove('getNpsScore')
      },2000)
      
    } catch (error) {
      console.log('error',error)
    }

  }
  

  
  return (    

      <Modal className={`${styles.pause_delay_popup} pause_delay_popup_g`} contentClassName={styles.pause_content} size="md" centered isOpen={isOpen} toggle={() => { !isSubmittedNpsScore && isGettingNPSFromUser ? {} : toggle() }}>
        <ModalBody>
          <div>
            {!isSubmittedNpsScore && isGettingNPSFromUser ? "" : 
              <p className={styles.Quick_icon}> <span onClick={()=>{toggle()}} > <i className="fa fa-times" aria-hidden="true"></i> </span> </p>
            }
           
           {!isGettingNPSFromUser ?  
            <>
              <h5 className={styles.heading}> Snooze Confirmed </h5>
              <div className={styles.pause_snooze_div}>
                  <div className={styles.pause_snooze_inn_div}>
                      <h5 className={`${styles.question} text-center`}> Your FashionPass will reactivate on { moment(snoozeDate).add(1, 'months').format("MMMM Do") } </h5>
                      <p className={styles.answer}> Happy snoozing! You can reactivate at any time if you change your mind and want to start renting sooner. </p>
                  </div>
                  <div className={styles.button_container}> 
                      <button className={styles.snooze_btn} onClick={()=>{toggle()}}> Done </button>
                  </div> 
              </div>
            </> 
            :
            <>
              {!isSubmittedNpsScore ? 
              <>
                {/* prev work with static array 'npsScore' */}
                {/* <div className={styles.nps_score_div}>    
                  <h5 className={styles.nps_score_heading}>Hey {customer.first_name}! Quick question...</h5>
                  <p className={styles.nps_score_text}>How likely would you be to recommend FashionPass to a friend?</p>
                  
                  <div className={styles.nps_value_container}>
                    {npsScore.map((item)=>{
                      return (
                      <div onClick={()=> {isLoading ? {} : updateCustomerNpsScore(item) }} className={styles.nps_value}>{item}</div>
                      )
                    })}
                  </div>
                
                  <div className={styles.like_text}>
                    <span>Not Likely</span>
                    <span>Very Likely</span>
                  </div>
                </div> */}

                {/* new work with dynamic data from banjo surveyTypes '_npsScore' */}
                {_npsScore[0] &&
                  <div className={styles.nps_score_div}>    
                    <h5 className={styles.nps_score_heading}>Hey {customer.first_name}! Quick question...</h5>
                    <div className={styles.nps_question_and_opions}>
                      <p className={styles.nps_score_text}>{_npsScore[0]?.surveyquestion}</p>
                      <div className={styles.nps_value_container}>
                      {/* submitCustomerNpsScore */}
                        {_npsScore[0]?.childQuestions.map((item:any)=>{ 
                          return (<div onClick={()=> {isLoading ? {} : submitCustomerNpsScore(item)}} className={styles.nps_value}>{item.surveyquestion}</div>)
                        })}
                      </div>
                    </div> 
                    <div className={styles.like_text}>
                      <span>Not Likely</span>
                      <span>Very Likely</span>
                    </div>
                  </div>
                }
                
                {!_npsScore[0] && 
                  <div className={styles.nps_question_and_opions_loader_wrapper}>
                    <Spinner />
                  </div>
                }

              </>
               :
              
              <>
                <div className={styles.response_container}>
                  <h5 className={styles.response_heading}>Thank you for your response!</h5>
                  <p className={styles.response_text}>If you ever need anything do not hesitate to reach out!<br /><span className={styles.pink_text}> hello@fashionpass.com</span></p>
                </div>
              </>

            }
            </> 
          }

         </div>
        </ModalBody>
      </Modal>
  )
}
export default PauseDelayPopup;
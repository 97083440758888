import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, Button, FormFeedback } from 'reactstrap';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { sendGenericEmail, CreateTicketAndSendEmail } from './globalFunctions';


const SnapShotPopup = (props) => {

    const {footer} = props
    const [msg, setMsg] = useState(false)
    const [msgText, setMsgText] = useState('')
    const [name, setName] = useState('')
    const [user, setUser] = useState((props.customer != 'false' && props.customer != 'not_login') ? props.customer.first_name + ' ' + props.customer.last_name : '')
    const [cusEmail, setCusEmail] = useState((props.customer != 'false' && props.customer != 'not_login') ? props.customer.email : '')
    const [description, setDescription] = useState('')
    const [sendTo, setSendTo] = useState(['mohsin.hassan.7ctech@gmail.com', 'aakifusmani7ctech@gmail.com'])
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const copyCodeToClipboard = () => {
        const el = fsLink
        el.select()
        document.execCommand("copy")
        setMsg(true)
        setMsgText('Link Copied!')
        setTimeout(() => {
            setMsg(false)
            props.toggle()
        }, 2000)
    }

    const getFsLink = () => {
        try{
            return window["FullS"]?.getCurrentSessionURL(true)
        }catch(e){
            return undefined
        }
    }

    const sendEmail = async () => {
        if ((name == '' && props.isForm ) || description == '' || sendTo.length == 0) {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
            return
        }
        let fsLink = getFsLink()
        let body_obj = {
            user: user,
            application: "Website",
            email: cusEmail,
            description: description,
            fsLink: fsLink != undefined ? fsLink : '',
            submitted_on: moment(Date.now()).format('ddd, MMM DD [at] hh:mm A'),
            submitted_by: name == '' ? 'Customer' : name
        } 
        let body = `<p>Application: Website<br /><br />User: ${user}<br /><br />Email: ${cusEmail}<br /><br />Description: ${description}<br /><br />${fsLink != undefined ? fsLink : ''}<br /><br />Submitted on ${moment(Date.now()).format('ddd, MMM DD [at] hh:mm A')}<br />Submitted by ${name}</p>`
        let emailContent = {
            "Subject": `${!footer ? name != '' ? name : 'Customer'  : 'Customer'} submitted a Website FullStory snapshot`,
            "Body": body,
            "body_obj": body_obj,
            "ToAddresses": sendTo,
        }
        let email = await sendGenericEmail(emailContent)
        if (email.success == true) {
            setMsg(true)
            setMsgText('Message was sent!')
            setTimeout(() => {
                setMsg(false)
                props.toggle()
            }, 2000)
        } else {
            setMsg(true)
            setMsgText(email.message)
            setTimeout(() => {
                setMsg(false)
                props.toggle()
            }, 2000)
        }
    }

    const submitEmailFromCustomer = async () => {
        if (cusEmail == '' || description == '' || user == '') {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
            return
        }
        setLoading(true);
        let fsLink = getFsLink()
        let body = `<p>Application: Website<br /><br />User: ${user}<br /><br />Email: <a href=${`mailto:${cusEmail}`}>${cusEmail}</a><br /><br />Description: ${description}<br /><br /> <a href=${fsLink != undefined ? fsLink : ''}>${fsLink != undefined ? fsLink : ''}</a> <br /><br />Submitted on ${moment(Date.now()).format('ddd, MMM DD [at] hh:mm A')}<br />Submitted by ${name}</p>`
        let emailContent = {
            "Subject": 'Customer submitted a Website FullStory snapshot',
            "Body": body,
            "ToAddresses": [],
            "CreateZDTicket": true,
            "RequesterEmail": cusEmail,
            "RequesterName": user
        }
        let email = await CreateTicketAndSendEmail(emailContent)
        if (email.success) {
            setLoading(false);
            setMsg(true)
            setMsgText('Message was sent!')
            setTimeout(() => {
                setMsg(false)
                props.toggle()
            }, 2000)
        } else {
            setLoading(false);
            setMsg(true)
            setMsgText(email.message)
            setTimeout(() => {
                setMsg(false)
                props.toggle()
            }, 2000)
        }
    }

    const handleSendTo = async (e) => {
        let recipient = []
        if (e.target.value == 'both') {
            recipient.push('mohsin.hassan.7ctech@gmail.com')
            recipient.push('aakifusmani7ctech@gmail.com')
        } else {
            recipient.push(e.target.value)
        }
        await setSendTo(recipient)
    }

    let fsLink = '';
    const fsStyle = {
        width: '100%',
        border: 0,
        resize: 'none'
    }

    useEffect(()=>{
        setTimeout(function () {
            if(props.customer != 'false' && props.customer != 'not_login'){
                if(props.isForm){
                    if (document.getElementById("name")) document.getElementById("name").focus();
                }else{
                    if (document.getElementById("description")) document.getElementById("description").focus();
                }
            }else{
                if (document.getElementById("name")) document.getElementById("name").focus();
            }
          }, 200)
    },[])
    return (
        <div className="mt-1">
            <Modal isOpen={props.isOpen} toggle={props.toggle} size='xl' centered animation="false" className="rewardModals newUserModal  snapshotModal">
                {!msg ?
                    <>
                        <ModalHeader toggle={props.toggle}></ModalHeader>
                        <ModalBody>
                            {
                                <div>
                                    <Form>
                                        {
                                            props.customer != 'false' && props.customer != 'not_login' ?
                                            <FormGroup>
                                                <Label for="name">User:</Label>
                                                <Input type="text" defaultValue={user} readOnly />
                                            </FormGroup>
                                            : 
                                            <FormGroup>
                                                <Label for="name">Your Name:</Label>
                                                <Input type="text" name="name" id="name" value={user} onChange={(e) => setUser(e.target.value)} />
                                            </FormGroup> 
                                        }
                                        {
                                            props.isForm ?
                                            <FormGroup>
                                                <Label for="name">Your Name:</Label>
                                                <Input type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                            </FormGroup> :
                                            <FormGroup>
                                                <Label for="name">Email:</Label>
                                                {
                                                    props.customer != 'false' && props.customer != 'not_login' ?
                                                    <Input type="email" readOnly defaultValue={cusEmail} /> :
                                                    <Input type="email" id="email" value={cusEmail} onChange={(e)=>{setCusEmail(e.target.value)}} />
                                                }
                                            </FormGroup>
                                        }
                                        <FormGroup>
                                            <Label for="description">Description of Problem:</Label>
                                            <Input type="textarea" required name="description" id="description" onChange={(e) => setDescription(e.target.value)} />
                                        </FormGroup>
                                        {!footer && <FormGroup>
                                            <Label for="sendTo">Send to:</Label>
                                            <Input type="select" required name="sendTo" id="sendTo" onChange={(e) => handleSendTo(e)}>
                                                <option hidden>Select</option>
                                                <option value="mohsin.hassan.7ctech@gmail.com">Mohsin</option>
                                                <option value="aakifusmani7ctech@gmail.com">Aakif</option>
                                                <option selected value="both">Both Mohsin & Aakif</option>
                                            </Input>
                                        </FormGroup>}
                                        {error && <Label className="ml-2" style={{ color: '#ff0000' }}>All fields are required.</Label>}
                                    </Form>
                                </div>
                            }
                            {!footer && <div className="d-flex align-items-center mb-3">
                                <textarea ref={(textarea) => fsLink = textarea} value={getFsLink()} style={fsStyle} readOnly />
                                <Button className="ml-1" style={{ whiteSpace: 'nowrap', fontSize:'12px' }} onClick={() => copyCodeToClipboard()}> Copy Link </Button>
                            </div>}
                        </ModalBody>
                      
                        <ModalFooter>
                            <Button className="mb-2 pl-5 pr-5" type='button' disabled={loading} onClick={()=>{ footer ? submitEmailFromCustomer() : sendEmail()}}> Submit </Button>
                        </ModalFooter>
                    </>
                    :
                    <div className="p-2" style={{ textAlign: 'center' }}>{msgText}</div>
                }
            </Modal>
        </div>
    )
}

export default SnapShotPopup

import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import styles from './style.module.scss'
import Gconfig from 'globalconfig';
import { CustomerSummaryDataType } from '@/services/customer';
import { SystemSettingsType } from '@/services/website-settings';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { getPlanNameAgianstSubscriptionName, getSubscriptionId, searchTag, upgradePlan } from 'components/shared/globalFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import WaveLoader from 'components/waveLoader';

interface IProps {
    isOpen: boolean;
    toggle: () => void;
    customerData: CustomerSummaryDataType;
}

const NewPlanAggrementPopup: React.FC<IProps> = (props) => {

    const { isOpen, toggle, customerData } = props
    const [agree, setAgree] = useState(false);
    const [currentPlanName, setCurrentPlanName] = useState('')
    const [allPlans, setAllPlans] = useState({})
    const [objDetails, setObjDetails] = useState({})
    const [subscriptionId, setSubscriptionId] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitButton, setSubmitButton] = useState(false)
    const [subscriptionDate, setSubscriptionDate] = useState(0)
    const [upgradePopupView, setUpgradePopupView] = useState(1)

    const systemSettings = useSelector(state => state.systemSettings)

    const dispatch = useDispatch()

    let image = `${Gconfig.fashionpassImageStorage}assets/img/img_update_popup.jpg?profile=a`


    useEffect(() => {
        const fetchData = async () => {
            console.log('<<<<<<<< S_ID >>>>>>>>>>',customerData?.stripe_id)
            let response = await getSubscriptionId(customerData?.stripe_id)
            if (response){
                console.log('<<<<<<<< SUB_ID 1 >>>>>>>>>>',response)
                setSubscriptionId(response)
            } 
        }
        if (customerData) {
            fetchData()
            let currentPlanName = customerData?.tag_list?.filter(x => x.includes('plan_name'))?.map(x => x?.split(':')[1])
            setCurrentPlanName(currentPlanName[0])
            let currentPlan = allPlans[currentPlanName[0]?.toLowerCase()]
            let currentDate = moment().utc()
            let billingDate = moment(customerData?.subscription_end_date).utc()
            let remainingDays = billingDate.startOf('day').diff(currentDate.startOf('day'), 'days')
            let date = moment.utc(customerData?.subscription_end_date).format('MMMM D')
            if(remainingDays <= 15) {
                date = moment.utc(customerData?.subscription_end_date).add('1', 'month').format('MMMM D')
            }
            setSubscriptionDate(date)
            
        }
        if (systemSettings) {
            let obj = systemSettings?.systemSettings?.find(x => x.setting_id == Gconfig.fp_plans)
            setAllPlans(JSON.parse(obj.setting_value))
        }
    }, [customerData, systemSettings])

    useEffect(() => {
        if (Object.keys(allPlans).length > 0 && currentPlanName) {
            let currentPlanPrice = parseInt(allPlans[currentPlanName?.toLowerCase()]?.price)
            let newPlanPrice = parseInt(allPlans['fashionpass']?.price)
            setObjDetails({ ...objDetails, ['priceDiff']: newPlanPrice - currentPlanPrice })
        }
    }, [allPlans, currentPlanName])

    const handleSubmit = async () => {
        if(upgradePopupView <= 2){
            setUpgradePopupView(upgradePopupView+1)
            return;
        }
        setLoading(true)
        let newPlan = allPlans['fashionpass']
        let currentPlan = allPlans[currentPlanName?.toLowerCase()]
        let currentDate = moment().utc()
        let billingDate = moment(customerData?.subscription_end_date).utc()
        let remainingDays = billingDate.startOf('day').diff(currentDate.startOf('day'), 'days')
        let cbStatus = searchTag(customerData?.tag_list, "currentlyborrowing")
        let subscriptionIDget = subscriptionId
        if(subscriptionIDget == '' || !subscriptionIDget){
            subscriptionIDget = await getSubscriptionId(customerData?.stripe_id)
            console.log('<<<<<<<< SUB_ID 2 >>>>>>>>>>',subscriptionIDget)
        }
        let payload = {
            "customerEmail": customerData?.email,
            "newPlanId": newPlan?.planStripeId,
            "customerStripeId": customerData?.stripe_id,
            "currentPlanName": currentPlanName,
            "newPlanName": newPlan?.planName,
            "customerId": customerData?.customer_id,
            "payfromcredit": false,
            "addCreditAmount": 0,
            "chargeAmount": 0,
            "addPromoCreditAmount": 0,
            "tagToAdd": "",
            "subscription_name": newPlan?.planName,
            "customerName": `${customerData.first_name + ' ' + customerData.last_name}`,
            "currentPlanId": customerData?.plan_id,
            "fromPromotion": false,
            "leftDays": remainingDays,
            "billingCycleDate": moment.utc(customerData?.subscription_end_date).format('X'),
            "subscriptionId": subscriptionIDget,
            "tax": 0,
            "cbStatus": cbStatus,
            "subscriptionEndDate": moment.utc(customerData?.subscription_end_date).format('X'),
            "clothing": parseInt(newPlan?.clothing),
            "accessories": parseInt(newPlan?.accessory),
            "newPlan": `plan_name:${newPlan?.planName}`,
            "currentPlan": `plan_name:${getPlanNameAgianstSubscriptionName(customerData?.subscription_name)}`,
            "newPlanPrice": newPlan?.price,
            "charge_type": "plan_upgrade",
            'isFromUpdatePopup': true
        }
        let emailPayload = {
            oldPlan: getPlanNameAgianstSubscriptionName(customerData?.subscription_name),
            newPlan: newPlan?.planName,
            firstName: customerData?.first_name,
            planAmount: newPlan?.price,
            email: customerData?.email,
            daysLeft: remainingDays,
            nextBillingDate: customerData?.subscription_end_date,
            action: 'upgrade',
            cb_status: cbStatus
        }
        let response = await dispatch(upgradePlan(payload, emailPayload, 0, 0, 0, 0, false))
        if (response) {
            setUpgradePopupView(4)
            setSubmitButton(true)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };


    const handleSubmit2 = () => {
        toggle();
        if(typeof window != undefined && window != undefined) {
            window.location.reload();
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} centered={true} contentClassName={styles.contentclassPopup} size={'xl'} className={`${styles.newPlanPopup}`} >
                <ModalBody className={`${styles.modalbody}`}>
                    <div className={`${styles.mainContainer}`}>
                        {!agree ?
                            <div className={`${styles.left}`}>
                                <div className={`${styles.details}`}>
                                    <div>
                                        {
                                            submitButton
                                                ?
                                                <>
                                                    <div className={`${styles.heading} ${styles.div1heading}`}>
                                                        <p className={`${styles.headingtop} mb-0`}>Welcome to the new</p>
                                                        <p className='mb-0'>FashionPass membership!<span>✨</span></p>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={`${styles.heading} ${styles.div2heading} `}>
                                                        <p className='mb-0'>FashionPass just got a glow up!<span>✨</span></p>
                                                    </div>
                                                    <div className={`${styles.content}`}>
                                                        <p className='mb-0'>One simplified plan. Even more value.</p>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    <div className={`${styles.boxes}`}>
                                        <div className={`${styles.box}`}>
                                            <p>2</p>
                                            <span>orders/month</span>
                                        </div>
                                        <div className={`${styles.box}`} style={{ background: '#FCECEF' }} >
                                            <p>5</p>
                                            <span>items/order</span>
                                        </div>
                                        <div className={`${styles.box} ${styles.box3}`} style={{ background: '#FCECEF' }} >
                                            <p><sup>$</sup>{allPlans['fashionpass']?.price}</p>
                                            <span>/month</span>
                                        </div>
                                    </div>

                                    {
                                        upgradePopupView == 1 ?
                                        <div className={`${styles.checkedcontent} ${styles.div1}`}>
                                            <div className={`${styles.checkedcontentdiv2}`}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                <p>You will be able to utilize the FashionPass plan (5 clothing items + 1 complimentary accessory) starting with your next order. Any prior orders in your current billing cycle <span>will not</span> count towards your 2 FashionPass orders for the month.</p>  
                                            </div>
                                            <div className={` ${styles.checkedcontentdiv3}`}>
                                                <p><span>You will not be charged the price of the new plan(${allPlans['fashionpass']?.price}/mo)until your next billing date, <br />{subscriptionDate}.</span></p>
                                            </div> 
                                        </div> : ''
                                    }

                                    {
                                        upgradePopupView == 2 ?
                                        <div className={`${styles.checkedcontent} ${styles.div1}`}>
                                            <div className={`${styles.checkedcontentdiv2}`}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                <p>All members are being migrated to the new FashionPass plan.</p>
                                            </div>

                                            <div className={`${styles.checkedcontentdiv4}`}>
                                                <p>By clicking “I understand" below, <span>your<br className='d-block d-md-none'/> membership will be immediately updated to the FashionPass plan.</span></p>
                                            </div>
                                        </div> : ''
                                    }

                                    {
                                        upgradePopupView == 3 ?
                                        <div className={`${styles.checkedcontent} ${styles.div1}`}>
                                            <div className={`${styles.checkedcontentdiv2}`}>
                                                <FontAwesomeIcon icon={faCheckCircle} className={'mt-md-0' }  />
                                                <p>If you decide not to continue your membership on the FashionPass plan, you must cancel your account and return any outstanding items before your next billing date.</p>
                                            </div>
                                        </div> : ''
                                    }
                                    {
                                        upgradePopupView == 4 ?
                                        <div className={`${styles.checkedcontent} ${styles.div1}`}>
                                             <div className={`${styles.checkedcontentdiv}`}>
                                                 <FontAwesomeIcon icon={faCheckCircle} />
                                                 <p><span>5 clothing items in every order</span> so we can be there for even more of life's moments, big and small</p>
                                             </div>

                                             <div className={`${styles.checkedcontentdiv}`}>
                                                 <FontAwesomeIcon icon={faCheckCircle} />
                                                 <p><span>Free item transfers </span>so you can keep renting the items you love + swap out the rest</p>
                                             </div>
                                             <div className={`${styles.checkedcontentdiv}`}>
                                                 <FontAwesomeIcon icon={faCheckCircle} />
                                                 <p><span>1 complimentary accessory </span>in every order so you can always complete the look</p>
                                             </div>
                                         </div> : ''
                                    }

                                    <div className={`${styles.buttondiv}`}>
                                        {
                                            submitButton
                                                ?
                                                <button className={`${styles.submitbutton} ${styles.submitbutton2}`} onClick={handleSubmit2}>START SHOPPING</button>
                                                :
                                                <button className={`${styles.submitbutton}`} onClick={handleSubmit} disabled={loading}>{loading ? <Spinner className={styles.spinner} /> : 'I UNDERSTAND'}</button>
                                        }

                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className={`${styles.right}`} >
                            {!imageLoaded && <div className={`${styles.loaderDiv}`}><WaveLoader size="large" /></div>}
                            <img src={image} onLoad={handleImageLoad} onError={handleImageLoad} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default NewPlanAggrementPopup
import { Pages } from '@/constants/pages';
import { useAppSelector, useAppDispatch } from '@/hooks/redux-hooks';
import Link from 'next/link';
import React from 'react';
import Router from 'next/router';
import styles from './favorites-btn.module.scss';
import Gconfig from 'globalconfig';
import { clearPaginationDataQuery } from 'components/shared/collectionAPIFunctions';

interface IProps {}

const FavoritesBtn: React.FC<IProps> = () => {
  const customerData = useAppSelector((state) => state.customer);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);

  const dispatch = useAppDispatch();

  let handleFavourite = () => {
    localStorage.removeItem('myFavouriteSeleted');
    localStorage.removeItem('myFavouriteScrollPosition');
    localStorage.removeItem('currentPage')
    localStorage.removeItem('currentPageBoard')
    dispatch(clearPaginationDataQuery())
    if(location.pathname && location.pathname.indexOf('my-favorites') > -1){
      if(document.getElementById('myBoards') && (document.getElementById('myBoards')?.classList?.value?.indexOf('active') == -1 || screen.width <= 767 )) {
        document.getElementById('myBoards').click();
      } 
    }
    Router.push('/my-favorites')
  }

  if (customerData.customer === 'not_login' || renderingConfigData.show.favBtn === false) return <></>;
  return (
    <Link href='/my-favorites' className={`header_fav_icon`}>
      <img onClick={()=>{handleFavourite()}} className={'icon'} src={Gconfig.fashionpassImageStorage+"assets/Favorites.svg"}  alt='Heart icon' aria-label='Find all of the products you have favorited and create boards.' />
    </Link>
  );
};

export default FavoritesBtn;

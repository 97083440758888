import React from 'react';
// import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { earlyCheckinProcess, getCustomerTags,getTaxForOrderWithEmail } from '/components/shared/globalFunctions';
import {connect} from 'react-redux';
import Gconfig from "globalconfig"
import Router from 'next/router';
import moment from 'moment'
import {getTaxOnCheckout} from "components/shared/checkoutFunctions";
let isTax = 0
const EarlyCheckinModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [checkinError, setCheckinError] = useState('');
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [earlyCheckinSetting, setEarlyCheckinSetting] = useState(null);
  const [isTaxShow, setIsTaxShow] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [defaultAddress, setDefaultAddress] = useState(null);

  const toggle = () => {
    setModal(!modal)
    setCheckinError('')
    setButtonDisable(false)
    setAgree(false)
    props.setTooltip(false)
    if(modal == false){
      isTax = 0
      setIsTaxShow(false)
      setTaxAmount(0)
    }
  };
  let buyEarlyCheckin = () => {
    if (!agree) {
      setCheckinError('Please tick the box')
      return
    }
    setCheckinError('')
    setButtonDisable(true)
    setLoading(true)
    props._getCustomerTags(props.customer.customer.customer_id).then(() => {
      let customerTag = props.customer.customer.tag_list.join().toLowerCase();
      let tagRemove = 'CurrentlyBorrowing,EarlyCheckin,ADDED ITEMS';
      if (customerTag.indexOf('currentlyborrowing') > -1 && customerTag.indexOf('earlycheckin') > -1) {
        props._earlyCheckinProcess(props.customer.customer.email, '', tagRemove, setCheckinError,setLoading,setButtonDisable,earlyCheckinSetting.amount,taxAmount, props.customer.customer.customer_id)
      }
      // setLoading(false)
      // setButtonDisable(false)
    })
  }
  useEffect(() => {
    let earlyCheckin = null
    if(props.systemSettings.length > 0 && earlyCheckinSetting == null){
      earlyCheckin = props.systemSettings.filter(setting => setting.setting_id == Gconfig.system_settings.earlyCheckin)[0];
      if(earlyCheckin){setEarlyCheckinSetting(JSON.parse(earlyCheckin.setting_value))}
    }
  });

  const handleAsyncComponentDidUpdate = async () => { 
    if(modal == true && isTax == 0){
      if(earlyCheckinSetting != null){
        isTax = 1
        if (props.customerAddresses.addresses.length > 0) {
          let defaultAddress = props.customerAddresses.addresses.find(function (address) {
            if (address.default_address == true && address.is_shipping == true)
              return address
          })
          if (defaultAddress == undefined) {
            defaultAddress = props.customerAddresses.addresses.find(function (address) {
              if (address.default_address == true && address.is_shipping == false)
                return address
            })
          }
          await setDefaultAddress(defaultAddress)
          let timeStamp = moment.unix(Date.now()).format('x')
          // console.log('timeStamp', timeStamp)
          let line_items = [{
            "id": timeStamp,
            "name":"Early Checkin",
            "quantity": 1,
            "product_tax_code": 20010,
            "unit_price": 0
          }]
          let taxObj = await getTaxOnCheckout(defaultAddress,0,line_items,earlyCheckinSetting.amount)
          
          if(taxObj.tax == true && taxObj.freight_taxable == true){
            await setTaxAmount(taxObj.taxPrice)
            await setIsTaxShow(true)
          }
        } else {
          let getTax = await getTaxForOrderWithEmail(earlyCheckinSetting.amount, props.customer.customer.email)
          if (getTax != null || getTax != undefined) {
            await setTaxAmount(getTax.amount_to_collect)
            if (getTax.amount_to_collect > 0) {
              await setIsTaxShow(true)
            }
          }
        }
      }
    } 
  }

  useEffect(() => {
    handleAsyncComponentDidUpdate();
  });
  return (
   
    <div>
      <div onMouseEnter={()=>{props.setTooltip(true)}} onMouseLeave={()=>{props.setTooltip(false)}} className="button-small addItem-btn-mobile button-black-small" onClick={toggle}>{props.textButton}</div>
      <Modal isOpen={modal} centered toggle={toggle} className="basicModalLayout earlyCheckinModal">
        <ModalHeader toggle={toggle}>Early Check-In</ModalHeader>
        <ModalBody>
        {checkinError ? <div className="errorMessage mb-2 md-md-3">{checkinError}</div>:''}
        {checkinError.indexOf('declined')>-1 ? <div className="errorMessage mb-2 md-md-3">Please update your <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => { Router.push({ pathname: '/my-account', query: { tab: 4 } })}}>billing info here</span> and try again!</div> : ''}
        {props.paymentMethod && earlyCheckinSetting != null ?<p><input type="checkbox" onChange={() => {setAgree(!agree);}}  /> <label>I understand that my default credit card <br className="d-block d-md-none" /> ({props.paymentMethod.brand} ending in {props.paymentMethod.last4} - {props.paymentMethod.expiry.match(/.{1,2}/g)[0]}/20{props.paymentMethod.expiry.match(/.{1,2}/g)[1]})<br className="d-block d-md-none"/> will be charged ${earlyCheckinSetting.amount} {isTaxShow ? ' + tax' : ''} and then I can<br className="d-block d-md-none"/> immediately place a new order.</label></p>:''}
        </ModalBody>
        <ModalFooter>
          <button color="primary" onClick={buyEarlyCheckin} disabled={buttonDisable ? true : false} className="chooseitem">{loading ? <Spinner /> : "Let me pick new items!"}</button>{' '}
         
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
      customer:state.customer,
      paymentMethod:state.customer.customer.default_payment_method ,
      systemSettings: state.systemSettings.systemSettings,
      customerAddresses:state.customerAddresses
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    _earlyCheckinProcess: (email,tagAdd,tagRemove,callback,setLoading,setButtonDisable,amount,taxAmount,customerId) => dispatch(earlyCheckinProcess(email,tagAdd,tagRemove,callback,setLoading,setButtonDisable,amount,taxAmount,customerId)),
    _getCustomerTags:(customer_id)=>dispatch(getCustomerTags(customer_id))

  }
};
export default connect(mapStateToProps,mapDispatchToProps) (EarlyCheckinModal);
import { FaBolt, FaCheck, FaSync, FaCheckCircle, FaFacebookF, FaInfoCircle, FaInstagram, FaPinterest, FaLock, FaSyncAlt, FaTiktok } from 'react-icons/fa';
import { GrFormClose, GrSearch } from 'react-icons/gr';
import { IoMdClose } from 'react-icons/io';
import { BsClockFill } from 'react-icons/bs';
import { RxCaretDown } from 'react-icons/rx';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

export const Icons = {
  Facebook: FaFacebookF,
  Instagram: FaInstagram,
  Pinterest: FaPinterest,
  Tiktok: FaTiktok,

  Checkmark: FaCheck,
  Info: FaInfoCircle,
  CheckCircle: FaCheckCircle,
  FaBolt: FaBolt,
  Sync: FaSyncAlt,

  Cross: IoMdClose,
  CrossBold: GrFormClose,
  Lock: FaLock,
  Check: FaCheck,

  Clock: BsClockFill,

  CaretDown: RxCaretDown,

  Search: GrSearch,

  arrowUp: faAngleUp,
};

import { CC_Parent, ContentSettings } from '@/constants/global-config';
import { LocalStorageKeys } from '@/constants/local-storage-keys';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { RemoveFromLocalStorage } from '@/libraries/browser-storage';
import { Icons } from '@/libraries/icons';
import { Spinner } from '@/libraries/material-ui';
import { useResponsive } from '@/libraries/responsive';
import { CategoriesNamespace } from '@/redux/reducers/categoryReducer';
import { getMainCategory } from '@/redux/thunks/categoryThunks';
import { filterQuerySubmit, globalRedirectionNavigation } from '@/redux/thunks/collectionPageThunks';
import { Category_SubCategoryDataType } from '@/services/collections';
import { preventAllEvents } from '@/utils/event-methods';
import { isValueEmpty, TextExistsInString } from '@/utils/string-methods';
import { ScrollToTop } from '@/utils/window-methods';
import NavBarDropdownMenuIndex from '@/containers/header/nav-bar/nav-bar-dropdown-menu/nav-bar-dropdown-menu-index/nav-bar-dropdown-menu-index';
import Link from 'next/link';
import Router from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import 'styles/_fontsFamily.module.scss';
import jsCookie from 'js-cookie';

interface IProps {
  navbarRef?: React.MutableRefObject<HTMLDivElement | null>;
  mediaScreen?: string;
}

const NavBarIndex: React.FC<IProps> = ({ navbarRef, mediaScreen }) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  const customerData = useAppSelector((state) => state.customer);
  const catsMenuData = useAppSelector((state) => (mediaScreen === 'mobile' ? state.catsMobilemenu : state.catsDesktopmenu));
  const collectionPageData = useAppSelector((state) => state.collectionPage);
  // const [settingColor, setSettingColor] = useState<string>('');
  const [visibleDropdownIndex, setVisibleDropdownIndex] = useState<number | 'none'>('none');

  useEffect(() => {
    if (typeof window != undefined && window != undefined) {
      window.onscroll = async () => {
        if (visibleDropdownIndex != 'none') {
          hideCategoryZIndex();
          setVisibleDropdownIndex('none');
        }
      };
    }
    return () => {
      if (typeof window != undefined && window != undefined) {
        window.onresize = null;
      }
    };
  }, [visibleDropdownIndex]);

  useEffect(() => {
    const namespace: CategoriesNamespace = mediaScreen === 'mobile' ? 'Mobilemenu' : 'Desktopmenu';
    let categoryID = mediaScreen === 'mobile' ? ContentSettings.MOBILE_MENU : ContentSettings.DESKTOP_MENU;
    if (customerData.customer !== 'not_login' && customerData.customer.has_subscription) categoryID = mediaScreen === 'mobile' ? ContentSettings.SUBSCRIBED_MOBILE_MENU : ContentSettings.SUBSCRIBED_DESKTOP_MENU;
    dispatch(getMainCategory(categoryID || '', namespace));
  }, [customerData.customer]);

  const handleRedirection = (e: React.MouseEvent<HTMLElement>, subCategory: Category_SubCategoryDataType, categName: any) => {
    if ('' + categName == 'Occasions') return;
    hideCategoryZIndex();
    const linkedCategory = Boolean(subCategory.linked_category !== undefined && subCategory.linked_category !== null) ? subCategory.linked_category : subCategory;
    const path = linkedCategory?.handle || '';
    if (mediaScreen === 'desktop') RemoveFromLocalStorage(LocalStorageKeys.IS_PRODUCT_PAGE);
    preventAllEvents(e);
    // ScrollToTop();
    setVisibleDropdownIndex('none');
    if (linkedCategory?.parent_id.toString() === CC_Parent) {
      Router.push('/' + linkedCategory?.handle, '/' + linkedCategory?.handle);
      return;
    }

    if (mediaScreen === 'desktop' || TextExistsInString(location.pathname, '/' + path)) dispatch(globalRedirectionNavigation(path, true, collectionPageData.filterData.data));
    else dispatch(globalRedirectionNavigation(path, false));
  };

  const handleToggleMegaMenu = (index: number, e: any) => {
    if (mediaScreen === 'desktop') return;
    else {
      if (visibleDropdownIndex !== 'none') {
        hideCategoryZIndex();
        if (e.target.className != 'dropdownHeading' && e.target.className?.indexOf('chevron') == -1) {
          setVisibleDropdownIndex('none');
        }
      } else {
        hideCategoryZIndex();
        setVisibleDropdownIndex(index);
      }
    }
  };

  const hideCategoryZIndex = () => {
    if (isTablet && document.getElementById('prod-listing-header-mobile')) {
      document.getElementById('prod-listing-header-mobile').style.zIndex = 999;
      document.getElementById('prod-listing-header-mobile').style.transitionDuration = 'unset';
    }
  };

  const onMouseHover = (val: any) => {
    let onHover = localStorage.getItem('navMenuHoverStart');
    if (mediaScreen === 'desktop') {
      if (val != 'none' && (onHover == null || onHover != 'true')) {
        setVisibleDropdownIndex(val);
        localStorage.setItem('navMenuHoverStart', true);
      } else {
        if (val == 'none') {
          localStorage.removeItem('navMenuHoverStart');
          setVisibleDropdownIndex(val);
        }
      }
    } else if (mediaScreen === 'mobile' && val === 'none') {
      setVisibleDropdownIndex(val);
    }
  };

  return (
    <div ref={navbarRef} className={`navbar_container`}>
      {/* {console.log('catsMenuData.currentSubCats', catsMenuData.currentSubCats)} */}
      {(catsMenuData.subcatLoading || catsMenuData.currentSubCats.length <= 0) && <Spinner size={25} className={`circularProgress`} />}
      {!catsMenuData.subcatLoading && catsMenuData.currentSubCats.length > 0 && (
        <nav className={`navbar_head`}>
          <ul className={`menuItemsList`}>
            {catsMenuData.currentSubCats.map((item, index) => {
              let isSale = false;
              if (item.name.toLowerCase() == 'sale') isSale = true;
              const isValidSubCatHandle = !isValueEmpty(item.handle);
              const isValidLinkedCatAndSubCat = !Boolean((isValueEmpty(item.linked_category) || isValueEmpty(item.linked_category?.handle)) && isValueEmpty(item.handle));
              const href = isValidSubCatHandle ? item.handle : `/` + item.linked_category?.handle;
              const categoryName = item.name.replace('-main', '').replace('-internal', '');
              const hasSubCategories = Boolean(item.subcategory_count > 0);
              const title = <label className={`text`}>{categoryName}</label>;
              const className = mediaScreen === 'mobile' ? (hasSubCategories && visibleDropdownIndex !== index ? `link_nohover` : `link`) : `link ${item.name === 'FAQs' ? 'text-transfrom-none' : ''}`;
              const isActiveClassName = visibleDropdownIndex === index ? `activeLink` : '';
              return (
                <li
                  className={`menuItem`}
                  cat-name={categoryName}
                  onClick={(e) => handleToggleMegaMenu(index, e)}
                  key={`catindex-${index}`}
                  onMouseEnter={() => {
                    onMouseHover(index);
                  }}
                  onMouseLeave={() => {
                    onMouseHover('none');
                  }}
                  style={{ cursor: isValidLinkedCatAndSubCat ? 'pointer' : 'default', position: item?.category_link == "how-it-works" ? "relative" : "unset" }}
                >
                  {mediaScreen === 'mobile' ? (
                    <>
                      {hasSubCategories || !isValidLinkedCatAndSubCat ? (
                        <a className={`${className} ${isActiveClassName}`} style={{ cursor: 'default' }}>
                          {title}
                        </a>
                      ) : !(hasSubCategories || !isValidLinkedCatAndSubCat) && !isValidSubCatHandle ? (
                        <Link
                        href="/[...category_handle]"
                        as={href}
                        className={`${className} ${isActiveClassName}`}
                        onClick={(e) => {
                          if (href !== '/brands') {
                            handleRedirection(e, item, categoryName);
                          }
                        }}
                      >
                        {title}
                      </Link>
                      ) : !(hasSubCategories || !isValidLinkedCatAndSubCat) && isValidSubCatHandle ? (
                        <Link href="/[...category_handle]" as={href} className={`${className} ${isActiveClassName}`}>
                          {title}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {!isValidLinkedCatAndSubCat && !isValidSubCatHandle ? (
                        <a
                          className={`${className} ${isActiveClassName}`}
                          style={{ cursor: 'inherit' }}
                          onClick={(e) => {
                            handleRedirection(e, item, categoryName);
                          }}
                        >
                          {title}
                        </a>
                      ) : !isValidLinkedCatAndSubCat && isValidSubCatHandle ? (
                        <a className={`${className} ${isActiveClassName}`} style={{ cursor: 'pointer' }}>
                          {title}
                        </a>
                      ) : isValidLinkedCatAndSubCat && !isValidSubCatHandle ? (
                        <Link href={href} as={href} className={`${className} ${isActiveClassName}`} style={{ cursor: 'pointer' }} onClick={(e) => {
                          if (href !== '/brands') {
                            handleRedirection(e, item, categoryName);
                          }
                        }}
                      >
                        {title}
                      </Link>
                      ) : isValidLinkedCatAndSubCat && isValidSubCatHandle ? (
                        <Link href={href} className={`${className} ${isActiveClassName}`} style={{ cursor: 'pointer' }}>
                          {title}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {hasSubCategories && visibleDropdownIndex === index && <NavBarDropdownMenuIndex subCategory={item} setVisibleDropdownIndex={setVisibleDropdownIndex} />}
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default NavBarIndex;

import { SocialLinks } from '@/constants/global-config';
import { Pages } from '@/constants/pages';
import BrandsPopup from '@/containers/brands/brands-popup/brands-popup';
import CareersPopup from '@/containers/careers/careers-popup/careers-popup';
import ContactPopup from '@/containers/contact/contact-popup/contact-popup';
import PressPopup from '@/containers/press/press-popup/press-popup';
import PrivacyPolicyPopup from '@/containers/privacy-policy/privacy-policy-popup/privacy-policy-popup';
import TermsOfServicePopup from '@/containers/terms-of-service/terms-of-service-popup/terms-of-service-popup';
import { useAppSelector } from '@/hooks/redux-hooks';
import { Icons } from '@/libraries/icons';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import styles from './footer-index.module.scss';
import Gconfig from 'globalconfig';

interface IProps { }

const FooterIndex: React.FC<IProps> = () => {
  const customerData = useAppSelector((state) => state.customer);

  const isSubscribedCustomer = useMemo<boolean>(() => Boolean(customerData.customer !== 'not_login' && customerData.customer.has_subscription), [customerData.customer]);

  return (
    <footer className={`footer_container`}>
      <div className={`footerNavContainer`}>
        <div className={`footerNavSection`}>
          <div className={`footerNavGroup`}>
            <h3 className={`footerNavGroupTitle`}>Information</h3>
            <ul className={`footerNavGroupList`}>
              <FooterNavGroupItem href={Pages.ABOUT_US} title="About Us" isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.BRANDS} title="Our Brands" isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.GIFT} title="Gift Cards" isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.FAQ} title={'FAQs'} isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.FAQ_SHIPPING} title={'Shipping & Returns'} isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.PRIVACY_POLICY} title="Privacy Policy" isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.TERMS_OF_SERVICE} title="Terms of Service" isSubscribedCustomer={isSubscribedCustomer} />
            </ul>
          </div>

          <div className={`footerNavGroup`}>
            <h3 className={`footerNavGroupTitle`}>Contact</h3>
            <ul className={`footerNavGroupList`}>
              {/* <FooterNavGroupItem href={Pages.CAREERS} title="Careers" isSubscribedCustomer={isSubscribedCustomer} popup={(isVisible, handleClosePopup) => <CareersPopup isVisible={isVisible} handleClosePopup={handleClosePopup} />} /> */}
              <FooterNavGroupItem href='/careers' title="Careers" isSubscribedCustomer={isSubscribedCustomer} />
              <FooterNavGroupItem href={Pages.PRESS} title="Press" isSubscribedCustomer={isSubscribedCustomer} popup={(isVisible, handleClosePopup) => <PressPopup isVisible={isVisible} handleClosePopup={handleClosePopup} />} />
              <FooterNavGroupItem href={Pages.CONTACT} title="Contact Us" isSubscribedCustomer={isSubscribedCustomer} popup={(isVisible, handleClosePopup) => <ContactPopup isVisible={isVisible} handleClosePopup={handleClosePopup} />} />
              <FooterNavGroupItem href={Pages.SNAPSHOTUSER} title="Report a Bug" isSubscribedCustomer={isSubscribedCustomer} />
            </ul>
          </div>

          <div className={`footerNavGroup`}>
            <h3 className={`footerNavGroupTitle`}>Social</h3>
            <ul className={`footerNavIconsGroupList`}>
              <FooterNavGroupIconItem href={SocialLinks.INSTAGRAM} icon={() => <img className={`icon`} src={Gconfig.fashionpassImageStorage + 'assets/img/footer_instagram_logo.svg?profile=c'} alt='Instagram icon' aria-label='Check out all of our content on Instagram.'/>} />
              <FooterNavGroupIconItem href={SocialLinks.Tiktok} icon={() => <img className={`icon`} src={Gconfig.fashionpassImageStorage + 'assets/img/footer_tiktok_logo.svg?profile=c'} alt='TikTok icon' aria-label='Check out all of our content on TikTok.'/>} />
              <FooterNavGroupIconItem href={SocialLinks.PINTEREST} icon={() => <img className={`icon`} src={Gconfig.fashionpassImageStorage + 'assets/img/footer_pinterest_logo.svg?profile=c'} alt='Pinterest icon' aria-label='Check out all of our content on Pinterest.'/>} />
              <FooterNavGroupIconItem href={SocialLinks.FACEBOOK} icon={() => <img className={`icon`} src={Gconfig.fashionpassImageStorage + 'assets/img/footer_facebook_logo.svg?profile=c'} alt='Facebook icon' aria-label='Check out all of our content on Facebook.' />} />
            </ul>
            <div className={`footerImgSection-mobile`}>
              <a href='https://apps.apple.com/us/app/fashionpass/id1669217540' target="_blank"><img src={Gconfig.fashionpassImageStorage + 'assets/img/download-app-banner.png?profile=a'} alt='Download on the apple store' aria-label='Download our app from the apple store.' /></a>
            </div>
          </div>
        </div>
        <div className={`footerImgSection`}>
          <a href='https://apps.apple.com/us/app/fashionpass/id1669217540' target="_blank"><img src={Gconfig.fashionpassImageStorage + 'assets/img/download-app-banner.png?profile=c'} alt='Download on the apple store' aria-label='Download our app from the apple store.' /></a>
        </div>
      </div>

      <div className={`footerCopyrightSection`}>
        <p>
        COPYRIGHT <i className="far fa-copyright"></i> 2024 FASHIONPASS, INC. ALL RIGHTS RESERVED.{' '}
        </p>
      </div>
    </footer>
  );
};

export default FooterIndex;

const FooterNavGroupItem: React.FC<{ href: string; title: string; isSubscribedCustomer: boolean; popup?: (isVisible: boolean, handleOnClose: () => void) => JSX.Element }> = ({ href, title, isSubscribedCustomer, popup }) => {
  const [isVisible, setIsVisible] = useState(false);

  if ((isSubscribedCustomer || popup === undefined)) {
    return (
      <li>
        <Link className={`footerNavGroupItem`} href={href}>
          {title}
        </Link>
      </li>
    );
  }

  return (
    <>
      {popup && popup(isVisible, () => setIsVisible(false))}
      <li onClick={() => setIsVisible(true)}>
        <span className={`footerNavGroupItem`}>{title}</span>
      </li>
    </>
  );
};

const FooterNavGroupIconItem: React.FC<{ href: string; icon: () => JSX.Element }> = ({ href, icon }) => (
  <li>
    <Link href={href} target="_blank">
      {icon()}
    </Link>
  </li>
);

import { CookieKeys } from '@/constants/cookie-keys';
import { API } from '@/constants/global-config';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { GetCookie } from '@/libraries/browser-storage';
import { DeepClone } from '@/libraries/utility-methods';
import { CartItemDataType } from '@/services/cart';
import { isValueEmpty, MakeTextSingularOrPlural } from '@/utils/string-methods';
import { removeProductFromCart } from 'components/shared/cartFunctions';
import { capitializeString, checkProductOnlyForSale, discountAndPriceCalculation, getNewOldProductTags, getUniqueItems } from 'components/shared/globalFunctions';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styles from './cart-popper-products-list-item.module.scss';
import { replaceItemInCart, addSaveLaterProduct, getSaveForLaterItemCount } from "components/shared/cartFunctions";
import ReleaseItemPopup from '@/containers/header/header-index/holdItemPopup/holdItemPopup';
import jsCookie from 'js-cookie';
import moment from 'moment'

interface IProps {
  popp_id: number;
  title: string;
  cartType: number;
  productCategory: number;
  cartCount: number;
  saveForLaterCount: number;
  isHoldItemComp: boolean;
}

const CartPopperProductsListItem: React.FC<IProps> = ({ popp_id, title, cartType, productCategory, cartCount, saveForLaterCount, isHoldItemComp }) => {
  const dispatch = useAppDispatch();

  const systemSettingsData = useAppSelector((state) => state.systemSettings);
  const customerData = useAppSelector((state) => state.customer);
  const cartData = useAppSelector((state) => state.cart);

  const [uniqueItems, setUniqueItems] = useState<CartItemDataType[]>([]);
  const [isReleasingonHoldItem, setIsReleasingonHoldItem] = useState<any>(false);
  const [holdItemInfo, setHoldItemInfo] = useState<any>(null);
  const [holdItemId, setHoldItemId] = useState<any>(null);

  
  const handleRemoveProductFromCartWrapper = (event: React.MouseEvent<HTMLElement>) => {

    const productData = {
      customer_id: customerData.customer !== 'not_login' ? `${customerData.customer.customer_id || 0}` : '0',
      sku_id: Number(event.currentTarget.getAttribute('data-sku-id')),
      sku_code: event.currentTarget.getAttribute('data-sku') || '',
      cartItemType: Number(event.currentTarget.getAttribute('data-item-type')),
      newItem: JSON.parse(event.currentTarget.getAttribute('data-new-item') || ''),
      IsLogin: Boolean(customerData.customer !== 'not_login'),
      Customer_guid: `${customerData.customer === 'not_login' && GetCookie(CookieKeys.CUSTOMER_GUID)}`,
    };

    let skuHoldId = event.currentTarget.getAttribute('data-sku-hold-notes-id') 
    // let HoldItemObj = jsCookie.get('sku_hold_item_obj');
    if(skuHoldId != undefined && skuHoldId != '0') {
        setIsReleasingonHoldItem(true)
        setHoldItemInfo(productData)
        setHoldItemId(skuHoldId)
        return
    } 
    dispatch(removeProductFromCart(productData));
  };
  
  function RemoveSkuHoldProduct ()  {
    dispatch(removeProductFromCart(holdItemInfo));
  };


  function RemoveSkuHoldProduct ()  {
    dispatch(removeProductFromCart(holdItemInfo));
  };

  useEffect(() => {
    const cartDataClone = cartData.cart !== null ? DeepClone(cartData.cart) : null;

    const saleItemOnly = cartDataClone?.items.filter((item) => item.cartItemType === 2) || [];
    const rentalItemOnly = cartDataClone?.items.filter((item) => item.cartItemType === 1) || [];

    let unique = getUniqueItems(saleItemOnly, false);
    unique = unique.concat(rentalItemOnly);
    unique.find((xItem) =>
      cartDataClone?.items.find((yItem) => {
        if (xItem.sku.sku === yItem.sku.sku && xItem.newItem === yItem.newItem && yItem.cartItemType === 2) {
          if (isNaN(xItem.qty)) xItem.qty = 1;
          else xItem.qty++;
        }
      })
    );

    setUniqueItems(unique);
  }, [cartData.cart]);


  function onHoldText() {
    
    const cartDataClone = cartData.cart !== null ? DeepClone(cartData.cart) : null;
    const holdItemOnly = cartDataClone?.items.filter((item) => item.cartItemType === 1 && item.sku_hold_notes_id > 0 ) || [];
    let skuHoldEmailSendedDate = 0 
    
    if(holdItemOnly.length > 0){
      skuHoldEmailSendedDate = holdItemOnly[0]?.skuHoldEmailSendedDate
    }

    if(!skuHoldEmailSendedDate) return;
    let holdTime = skuHoldEmailSendedDate + 25200
    let twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000
    let totalTime = (holdTime * 1000) + twentyFourHoursInMilliseconds
    
    let currentTime = moment.unix(new Date()).format('X');
    var time_diff = Math.abs(currentTime - totalTime);

    const hours = Math.floor(time_diff / (1000 * 3600));
    time_diff =  time_diff - hours * 1000 * 3600;
    const minutes = Math.floor(time_diff / (1000 * 60)); 
    return (
        <span>
           ON HOLD FOR  <span className={styles.hold_time_text} > {hours} hours {minutes} minutes </span>
        </span>
    )
  }

  async function addSaveLaterData(event:any, prod:any) {
    if(prod.sku && prod.sku.product && prod.sku.product.product_id) {
        let data = {
            "product_id": prod.sku.product.product_id,
            "status": 1,
            "skuid": prod.sku.sku_id,
            "FromCart": true 
        }
        let responce = await addSaveLaterProduct(data)
        if(responce.success) {
          
          const productData = {
            customer_id: customerData.customer !== 'not_login' ? `${customerData.customer.customer_id || 0}` : '0',
            sku_id: Number(event.target.getAttribute('data-sku-id')),
            sku_code: event.target.getAttribute('data-sku') || '',
            cartItemType: Number(event.target.getAttribute('data-item-type')),
            newItem: JSON.parse(event.target.getAttribute('data-new-item') || ''),
            IsLogin: Boolean(customerData.customer !== 'not_login'),
            Customer_guid: `${customerData.customer === 'not_login' && GetCookie(CookieKeys.CUSTOMER_GUID)}`,
          };
          dispatch(removeProductFromCart(productData));
          dispatch(getSaveForLaterItemCount())
        }
    }
}

  async function swapSizeOfItemInCart(newSkuId: any, prod: any, sizeArr: any) {
    let selectedSize = sizeArr.filter((size: any) => { return size.sku_id == newSkuId })[0]
    let obj = {
      "addToCart": {
        "customer_id": customerData.customer.customer_id,
        "product_id": prod.sku.product.product_id,
        "size": selectedSize.size,
        "cartItemType": prod.cartItemType,
        "newItem": prod.newItem,
        "extraParameters": "",
        "sku_id": selectedSize.sku_id,
        "isShoes": false,
        "customer_email": customerData.customer.email,
        "sku_code": selectedSize.sku,
        "IsLogin": true
      },
      "removeCartItem": {
        "customer_id": customerData.customer.customer_id,
        "sku_id": prod.sku.sku_id,
        "sku_code": prod.sku.sku,
        "cartItemType": prod.cartItemType,
        "newItem": prod.newItem
      }
    }
    dispatch(replaceItemInCart(obj, cartData)).then((res) => {
    })
  }

  function checkLastItemType(id:any) {
    let lastItemType = -1
    let _marginBot = 0
    if (cartData.cart !== null && cartData.cart?.items ) {
      cartData.cart.items.map((item:any) => {
          if(item.cartItemType === 1 && item.sku_hold_notes_id > 0 && lastItemType <= 1) {
            lastItemType = 1
            _marginBot = 65
          }
          else if(item.cartItemType === 1 && !item.sku_hold_notes_id && lastItemType <= 2) {
            lastItemType = 2
            _marginBot = 55
          }
          else if(item.cartItemType === 2  && lastItemType <= 3) {
            lastItemType = 4
            _marginBot = 86
          }
      });
      if(id < lastItemType) return { lastItemType , _marginBot: 0 }
      else return { lastItemType , _marginBot }
    }
    else return { lastItemType , _marginBot }
  }


  if (cartCount <= 0) return <></>;
  return (
    <div className={`${styles.container} ${isHoldItemComp ? styles.hold_container : ""}`} style={ (popp_id == 1 || popp_id == 4) ? {marginBottom: checkLastItemType(popp_id)._marginBot + 'px' } : {}}>
      {title != "" ? 
        (isHoldItemComp ? 
          <p className={`${styles.title} text-center`}>
            {onHoldText()}
          </p>
           :
          <p className={styles.title}>
            {MakeTextSingularOrPlural(title, cartCount)} ({cartCount})
          </p>) : ""}
      <div className={cartType == 0 ? styles.soldout_container : ""}>
        {cartType == 0 && cartCount > 0 ? <p className={styles.popper_soldout_text} > The following item(s) are no longer available. Please edit or remove these items in your cart. </p> : ""}
      {uniqueItems.map((item, index) => {
       
       if((isHoldItemComp && !item.sku_hold_notes_id) || (!isHoldItemComp && item.sku_hold_notes_id > 0) ) return
       
       
       // const has_newitem = item.sku.has_newitem;
        const vlink = `/product/${!isValueEmpty(item.sku.product.vendor_handle) ? item.sku.product.vendor_handle : 'vendor'}/${item.sku.product.product_handle}`;
        const onlyForSale = checkProductOnlyForSale(item.sku.product);
        const priceObj = discountAndPriceCalculation(customerData.customer, item, onlyForSale, systemSettingsData.systemSettings);
        const tagName = getNewOldProductTags(customerData, item, onlyForSale);
        let isFinalSaleTag = false;
        if (item.sku && item.sku.product && item.sku.product.tags && item.sku.product.tags.filter((item) => { if (item == 'final-sale') return true; else return false }).length > 0) {
          isFinalSaleTag = true;
        }
        if((customerData.customer?.has_subscription && item?.sku?.product?.status_for_sale == 2) || (!customerData.customer?.has_subscription && item?.sku?.product?.status_for_sale_nonsubscriber == 2)){
          isFinalSaleTag = false
        }
        (item.cartItemType == cartType && productCategory == 0) || (productCategory != 0 && cartType == 1 && item.cartItemType == cartType && item.sku.product.product_catagory == productCategory) || (cartType == 1 && item.cartItemType == cartType && item.sku.product.product_catagory == productCategory + 1 && productCategory != 1);

        if (cartType != 0 && (Boolean((item.cartItemType === cartType && productCategory === 0) || (productCategory !== 0 && cartType === 1 && item.cartItemType === cartType && item.sku.product.product_catagory === productCategory) || (cartType === 1 && item.cartItemType === cartType && item.sku.product.product_catagory === productCategory + 1 && productCategory !== 1)) === false || item.isSelectedSizeAvailable == false)) return <React.Fragment key={index}></React.Fragment>;
        if (cartType == 0 && item.isSelectedSizeAvailable != false) return <React.Fragment key={index}></React.Fragment>
        
        let allItemRentedOut = false
        let availableSizes = item.sku.product && item.sku.product.sizes ? item.sku.product.sizes.filter(size => {
          return (size.inventory_quantity > 0 && size.size != item.size)
        }) : []
        
        if (availableSizes.length == 0) {
          allItemRentedOut = true
        }
        
        let oneSize = false
        availableSizes.length == 1 && availableSizes.map((size) => {
          if (size.size == "NONE" || size.size == "ONE SIZE") oneSize = true;
        })

        return (
          <div key={index} className={`${styles.itemContainer} ${cartType == 0 ? styles.itemContainer_sold : ""}`}>
            <div className={styles.itemImageContainer}>
              <Link href="/product/[...handle]" as={vlink}>
                {item.sku.product.images?.length > 0 && <img className={styles.itemImage} src={`${API.FASHION_PASS_PRODUCT_IMAGE_URL}${item.sku.product.images[0]}?profile=c`} />}
              </Link>
            </div>

            <div className={styles.itemContent}>
                <div className={styles.itemContentGroup}>
                  {item.size && item.sku.retail && (
                    <Link href={`/${item.sku.product.vendor_handle}`} className={styles.brandTitle}>
                      {item.sku.product.vendor && item.sku.product.vendor.toLowerCase()}
                    </Link>
                  )}
                  <Link href="/product/[...handle]" as={vlink} className={styles.productTitle}>
                    {capitializeString(item.sku.product.product_title)}
                  </Link>
                  {item.cartItemType === 2 && tagName !== undefined && <small className={styles.newItemTag}>{tagName}</small>}
                </div>

              <div className={styles.itemContentGroup}>

                {cartType == 0 ? <>
                      {availableSizes.length > 0 && oneSize == false ?
                        <select name="size" className={item.isSelectedSizeAvailable == false ? 'select_input_2_cart mb-2' : 'select_input_cart mb-2'} onChange={(e) => { swapSizeOfItemInCart(e.target.value, item, availableSizes) }} >
                          {availableSizes.map((size, j) => {
                            return (
                              <React.Fragment key={'size' + j}>
                                {j == 0 ? <option value="" selected disabled style={{ color: "#000 !important" }} > Select a size </option> : ""}
                                <option key={"size" + j} name={size.sku_id} value={size.sku_id}> {size.size} </option>
                              </React.Fragment>
                            )
                          })}
                        </select> : <span className={`${styles.sold_all_rent0ut_btn} mb-2`}> ALL RENTED OUT </span>}
                  </> : ""}

                {item.size !== '' && cartType != 0 && <span className={styles.sizeText}> Size: {item.size} </span>}
                {item.size === '' && item.sku.product.product_catagory === 2 && <br />}

                {item.cartItemType === 2 && <span className={styles.qtyText}> QTY: {item.qty} </span>}

                {item.cartItemType !== 2 && <span style={{ color: "#000" }} className={styles.freeText}>FREE</span>}
                {item.cartItemType === 2 && (
                  <div className={styles.itemPriceContainer}>
                    {priceObj.discount <= 0 && <> ${Math.floor(item.sku.retail)} </>}
                    {priceObj.discount > 0 && (
                      <>
                        <del> ${Math.floor(item.sku.retail)}</del>
                        <span className={styles.itemNewPrice}> ${priceObj.price} </span>
                      </>
                    )}
                  </div>
                )}
                {isFinalSaleTag ? <span className='final_sale_text' style={{ fontSize: "11px" }} > Final Sale </span> : ""}
              </div>
              <div className={styles.mts_remove_btnContainer}>
                {customerData.customer != 'not_login' && cartType <= 1 ? saveForLaterCount <= 49 ? <span className={`pt-3 remove-item_sold ${styles.cartPopperMTS}`} onClick={(e)=>{ addSaveLaterData(e, item) }} data-new-item={item.newItem} data-item-type={item.cartItemType} data-sku-id={item.sku.sku_id} data-sku={item.sku.sku} data-sku-hold-notes-id={item.sku_hold_notes_id}  >move to saved  </span> : <span className={`pt-3 ${styles.saveForLaterFull}`}>save for later full</span> : "" } 
                <span onClick={handleRemoveProductFromCartWrapper} style={{ color: "#000" }}  className={styles.removeBtn} data-new-item={item.newItem} data-item-type={item.cartItemType} data-sku-id={item.sku.sku_id} data-sku={item.sku.sku} data-sku-hold-notes-id={item.sku_hold_notes_id}>
                  remove
                </span>

              </div>
            </div>
          </div>
        );
      })}
    </div>
    {isReleasingonHoldItem ?  <ReleaseItemPopup isOpen={isReleasingonHoldItem} toggle={()=> { setIsReleasingonHoldItem(false), setHoldItemInfo(null), setHoldItemId(null) }} holdItemData={holdItemInfo} removeHoldItemFromCart={RemoveSkuHoldProduct} from={'cart_section'} _this={''} holdItemId={holdItemId}/> : "" }
    </div>
  );
};

export default CartPopperProductsListItem;

// <DropdownMenu right={true} className="fp-cart-menu">
//   <div className="cart-main">
//     {cartCount == '0' ? (
//       <div className="cart-empty" style={{ textTransform: 'none' }}>
//         Your shopping bag is currently empty.
//       </div>
//     ) : (
//       <div className="cartItemDiv">
//         {itemCount(customer_cart, 1, 1) > 0 && (
//           <>
//             <p>
//               Clothing {itemCount(customer_cart, 1, 1) > 1 ? 'Rentals' : 'Rental'} ({itemCount(customer_cart, 1, 1)})
//             </p>
//             <hr />
//             {cartItems(1, 1)}
//             {/* <hr /> */}
//           </>
//         )}

//         {itemCount(customer_cart, 1, 2) > 0 && (
//           <>
//             <p>
//               Accessory {itemCount(customer_cart, 1, 2) > 1 ? 'Rentals' : 'Rental'} ({itemCount(customer_cart, 1, 2)})
//             </p>
//             <hr />
//             {cartItems(1, 2)}
//             {/* <hr /> */}
//           </>
//         )}

//         {itemCount(customer_cart, 2, 0) > 0 && (
//           <>
//             <p>
//               Purchase {itemCount(customer_cart, 2, 0) > 1 ? 'Items' : 'Item'} ({itemCount(customer_cart, 2, 0)})
//             </p>
//             <hr />
//             {cartItems(2, 0)}
//             {/* <hr /> */}
//           </>
//         )}
//       </div>
//     )}
//     {cartCount != '0' ? <hr className={cartCount != '0' && itemCount(customer_cart, 2, 0) > 0 ? `hr_total` : 'hr_total mb-2'} /> : ''}
//   </div>
// </DropdownMenu>;

import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import style from 'styles/newpriceinfo.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { crossEmailbytemplate } from 'components/shared/customerDataFunctions';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import moment from 'moment'
import {getPlanNameAgianstSubscriptionName,calculateAmountForPlanUpdate,getSubscriptionId,updateCustomerTags,searchTag} from "components/shared/globalFunctions";
import Gconfig from 'globalconfig';
import { fetchCustomerSuccess } from 'redux/actions/customerActions'
import jsCookie from 'js-cookie';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(()=> import('react-responsive'), { ssr: false });
import Router from 'next/router';

const LeftPricingPopup = (props) => {
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(false);
    const [firstModal, setFirstModal] = useState(true);
    const [multiPlanModal, setMultiPlanModal] = useState(false);
    const customer = useSelector(state => state.customer)
    const [fpPlans, setFpPlans] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showBar, setShowBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectPlan, setSelectPlan] = useState(0);
    const [comparePlanData, setComparePlanData] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(0);
    const [customerCurrentPlan, setCustomerCurrentPlan] = useState('')
    const [nextToNextDate, setNextToNextDate] = useState('');
    const systemSettings = useSelector(state => state.systemSettings)
    const customerPreferences = useSelector(state => state.customerPreferences.customerPreferences)
    const [timer, setTimer] = useState()
    

    const Pagination = () => {
        return (
            <div className={!multiPlanModal ? `${style.pagination_div}` : `${style.pagination_div} border-0 shadow-none p-0 ml-0`}>
                <span className={style.pagination_inner}>
                    <i className={`fa fa-caret-left ${style.fa1}`} onClick={()=>{setFirstModal(true);setMultiPlanModal(false)}}></i>
                    <span className='d-flex' > <i className={`${style.icon} ${firstModal && `${style.selected}`}`} onClick={()=>{setFirstModal(true);setMultiPlanModal(false)}}></i></span>
                    {customer && customer.customer && customer.customer.subscription_name == 'Gold' ? <span className='d-flex'> <i className={`${style.icon} ${multiPlanModal && `${style.selected}`}`} onClick={()=>{setFirstModal(false);setMultiPlanModal(true)}}></i></span> : ''}
                    <i className={`fa fa-caret-right ${style.fa1}`} onClick={()=>{setFirstModal(false);setMultiPlanModal(true)}}></i>
                </span>
            </div>
        )
    }

    useEffect(() => {
        let url= window.location.pathname
        let pricingCookie = jsCookie.get('pricing48hourMinimize');
        let reactivatePricingOffer = jsCookie.get('reactivatePricingOffer')
        let setting =  systemSettings.systemSettings.find(settings => {
            if (settings.setting_id == Gconfig.fp_plans){
                return settings.setting_value;
            }
        })
        if(reactivatePricingOffer != undefined){
            // Router.push('/clothing')
            if(searchTag(customer.customer.tag_list, 'promoofferreject') == true && url == '/clothing'){
                setShowBar(false)
                setOpenModal(true)
                jsCookie.remove('reactivatePricingOffer')
                if(searchTag(customer.customer.tag_list, 'promoofferreject48') == true){
                    updateCustomerTags('','promoOfferReject48').then(response => response.json()).then(data => {
                        if (data.success == true) {
                            let custumer = customer.customer
                            custumer.tag_list = data.tags;
                            dispatch(fetchCustomerSuccess(custumer))
                        }
                    })
                }
            }
        }else{
            if((props.isOpen == true && pricingCookie == undefined) || ((props.isOpen == true && pricingCookie != undefined && url.indexOf('my-account') > -1))){
                setOpenModal(true)
                setShowBar(false)
            }else{
                if(pricingCookie != undefined){
                    setShowBar(true)
                    setOpenModal(false)
                }
            }
        }
        setFpPlans(JSON.parse(setting.setting_value))
        if (window != undefined && window.screen.width < 767) {
            setIsMobile(true)
        }
        if(customerPreferences.preferences && customerPreferences.preferences.pricingFirstOffer && customerPreferences.preferences.pricingFirstOffer != null){
            let currentDateTime = new Date().getTime() / 1000
            currentDateTime = Math.floor(currentDateTime)
            let availableDateTime = customerPreferences.preferences.pricingFirstOffer.offerRejectTime + (24*60*60*2)
            if(availableDateTime < currentDateTime){
                updateCustomerTags('promoOfferReject48','').then(response => response.json()).then(data => {
                    if (data.success == true) {
                        setShowBar(false)
                        jsCookie.remove('pricing48hourMinimize');
                        let custumer = customer.customer
                        custumer.tag_list = data.tags;
                        dispatch(updateCustomerPreferences(null , 'pricingFirstOffer'))
                        dispatch(fetchCustomerSuccess(custumer))
                    }
                })
            }
        }
        let planName = getPlanNameAgianstSubscriptionName(customer.customer.subscription_name)
        setCustomerCurrentPlan(planName)
        let nextToNextMonth= '';
        nextToNextMonth = moment(customer && customer.customer && customer.customer.subscription_end_date)
        nextToNextMonth = moment(nextToNextMonth).add(1, 'M');
        nextToNextMonth = moment(nextToNextMonth._d).format('M/D/YYYY')
        setNextToNextDate(nextToNextMonth)
    },[])

    let seprateItems = (item) => {
        let data = []
        if(item){
            if(item.indexOf('+') > -1)
              data = item.split('+')
            else
              data = item.split(':')
        }
        return data
    }

    let legacyOption = () => {
        updateCustomerTags('promoOfferReject48','').then(response => response.json()).then(data => {
            if (data.success == true) {
                props.toggle()
                let custumer = customer.customer
                custumer.tag_list = data.tags;
                jsCookie.remove('pricing48hourMinimize')
                dispatch(updateCustomerPreferences(null , 'pricingFirstOffer'))
                dispatch(fetchCustomerSuccess(custumer))
            }
        })
    }

    let submitUpgradePlan = async (plan) => {
        if(plan == 'Trendsetter')
            setSelectPlan(0)
        else
            setSelectPlan(1)
        setIsLoading(true)
        let id = await getSubscriptionId(customer.customer.stripe_id)
        if(id){
            props.setSelectedPlan(plan,id,true)
            props.toggle()
        }
    }

    let minimizePopup = () => {
        jsCookie.set('pricing48hourMinimize', true);
        if(!jsCookie.get('crossEmailSent')){
            let planId = ''
            if(Gconfig.environment == 'production'){
                if(customer.customer.subscription_name == 'Gold')
                planId = 'd-d2d231cc4d304a27856ead734b116b2b'
                if(customer.customer.subscription_name == 'Platinum')
                    planId = 'd-a0bee1f0cf6849ada4a12a9d140a3c87'
                if(customer.customer.subscription_name == 'Wanderlust')
                    planId = 'd-353beaa1172b482ead42991b91129681'
            }else{
                if(customer.customer.subscription_name == 'Gold')
                    planId = 'd-a3f5278ede82421185fba46d18be2c91'
                if(customer.customer.subscription_name == 'Platinum')
                    planId = 'd-12ac6dd8f9fe4c1d8b433dd777ecd543'
                if(customer.customer.subscription_name == 'Wanderlust')
                    planId = 'd-530f02922a4d438eb12652ce9c35d824'
            }
            crossEmailbytemplate(customer.customer.email,planId)
            jsCookie.set('crossEmailSent', true);
        }
        
        setOpenModal(false)
        setShowBar(true)
    }

    let openModalFromTab = (e) => {
        setOpenModal(true)
        setShowBar(false)
        e.preventDefault()
    }

    let crosssubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        updateCustomerTags('promoOfferReject48','').then(response => response.json()).then(data => {
            if (data.success == true) {
                setShowBar(false)
                jsCookie.remove('pricing48hourMinimize');
                let custumer = customer.customer
                custumer.tag_list = data.tags;
                dispatch(updateCustomerPreferences(null , 'pricingFirstOffer'))
                dispatch(fetchCustomerSuccess(custumer))
            }
        })
    }
    useEffect(()=>{
        let comparePlanData = {}
        if(fpPlans != null){
            comparePlanData = Object.values(fpPlans).filter((val, ind) => {
                if(val.planWebName.toLowerCase() == customerCurrentPlan.toLowerCase()){
                    return val
                }
            })
            setComparePlanData(comparePlanData)
        }
    },[fpPlans])

    const intervalTimer = (availableHours, availableMinutes) => {
        let hours = availableHours
        let minutes = availableMinutes
        let seconds = 0
        const timerInterval = setInterval(() => {
            if(hours > 0 && minutes == 0 && seconds == 0) {
                hours = hours - 1
                minutes = 59
            } else if(minutes > 0 && seconds == 0){
                minutes = minutes - 1
            }
            if (hours > -1 && minutes > -1 && seconds == 0) {
                seconds = 59
            } else {
                seconds = seconds - 1
            }
            
            setTimer(hours + ':' + minutes + ':' + seconds)
            if (hours < 1 && minutes < 1 && seconds < 1) clearInterval(timerInterval)
        }, 1000)
    }
    
    useEffect(() => {
        let timestamp = new Date().getTime() / 1000
        timestamp = Math.floor(timestamp)
        if(customerPreferences && customerPreferences.preferences != null){
            if(customerPreferences.preferences && customerPreferences.preferences.pricingFirstOffer){
                // console.log('Noadd')
            }else{
                let obj = {offerRejectTime: timestamp}
                dispatch(updateCustomerPreferences(obj , 'pricingFirstOffer'))
            }
        }
        if(customerPreferences && customerPreferences.preferences == null){
            let obj = {offerRejectTime: timestamp}
            dispatch(updateCustomerPreferences(obj , 'pricingFirstOffer'))
        }
        let currentDateTime = moment.unix(moment().unix()).format('X');
        let endTime = 0
        if (customerPreferences.preferences && customerPreferences.preferences.pricingFirstOffer && customerPreferences.preferences.pricingFirstOffer != null) {
            endTime = customerPreferences.preferences.pricingFirstOffer.offerRejectTime + (24 * 60 * 60 * 2)
            let a = moment.unix(currentDateTime)
            let b = moment.unix(endTime)
            let diff = b - a
            let availableHours = b.diff(a, 'hours')
            let availableMinutes = Math.round(((diff % 86400000) % 3600000) / 60000)
            intervalTimer(availableHours, availableMinutes)
        }
        if(customerPreferences.preferences == null || (customerPreferences.preferences && customerPreferences.preferences.pricingFirstOffer == null)){
            endTime = parseInt(currentDateTime) + (24 * 60 * 60 * 2)
            let a = moment.unix(currentDateTime)
            let b = moment.unix(endTime)
            let diff = b - a
            let availableHours = b.diff(a, 'hours')
            let availableMinutes = Math.round(((diff % 86400000) % 3600000) / 60000)
            intervalTimer(availableHours, availableMinutes)
        }
    }, [])

    return (
        <>
            {
                firstModal == true || multiPlanModal == true ?
                    <>
                        <Modal isOpen={openModal} centered className={style.newpricepopup} >
                            {
                                multiPlanModal == true ? <>
                                    <ModalBody className={`border-0 p-0 shadow-none ${style.modal_body}`} >
                                        <div className={style.select_new_plan}>
                                            <div className={style.plan_heading}><p>$20 off promo <br/> Applied <i className='fas fa-check-circle'></i></p></div>
                                            <div className={style.plan_box_main}>
                                                <div className={style.planbox}>
                                                    <label className={style.plan_name}>{fpPlans && fpPlans.trendsetter.planWebName}</label>
                                                    <div className={style.new_text}><hr/><img src={Gconfig.fashionpassImageStorage+'assets/img/new.svg'} /></div>
                                                    <p className={style.items}>{seprateItems(fpPlans && fpPlans.trendsetter.items)[0]}</p>
                                                    <p className={style.items}>+{seprateItems(fpPlans && fpPlans.trendsetter.items)[1]}</p>
                                                    <p className={style.order_text}>Per order</p>
                                                    <p className={style.plan_price}><span className={style.cross_price}>${calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.trendsetter.planWebName )} </span>${(calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.trendsetter.planWebName ) - 20 )> 0 ? (calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.trendsetter.planWebName ) - 20 ) : 0} to Upgrade Today</p>
                                                    <div className={style.perks_text}><img src={Gconfig.fashionpassImageStorage+'assets/img/new_perks.svg'}/> <i className="fas fa-info-circle">
                                                        <div className={style.perks_tooltip}>
                                                            <span>This plan includes the following perks:</span>
                                                            <span className={style.heading_inner}>Select Rentals Same Day as Scan</span>
                                                            The ability to ‘select new rentals same day as scan’ means you will be checked-in to select new rentals the same day Fedex scans your return package. 
                                                            <span className={style.heading_inner}>Swap 2 Accessories for 1 Clothing Item</span>
                                                            With qualifying plans (Trendsetter/Wanderlust) you can swap 2 accessories for 1 item at the time that you select your rentals. Simply add the clothing item of your choice instead of 2 accessories and checkout as normal.
                                                            <span className={style.heading_inner}>Monthly Purchase Discount</span>
                                                            Qualifying plans (Trendsetter/Wanderlust) receive a monthly purchase discount that will automatically be applied to the first purchase of your billing cycle. Discounts can be redeemed either by purchasing directly from the website or purchasing by keeping an item(s) from a rental order.
                                                        </div></i>
                                                    </div>
                                                    <div className={style.plan_desc}>
                                                        <p><i className={`fas fa-check-circle ${style.check}`}></i>Select New Rentals <br /> Same Day as Scan</p>
                                                        <hr />
                                                        <p><i className={`fas fa-check-circle ${style.check}`}></i>Swap {seprateItems(fpPlans && fpPlans.trendsetter.itemSwapRatio)[0]} Accessories <br />for {seprateItems(fpPlans && fpPlans.trendsetter.itemSwapRatio)[1]} Clothing Item</p>
                                                        <hr />
                                                        <p><img src={Gconfig.fashionpassImageStorage+'assets/img/dollarIcon.svg'} alt='dollar' />Monthly <span>${fpPlans && fpPlans.trendsetter.monthlyPurchaseCredit}</span> Purchase Discount</p>
                                                    </div>
                                                    <p className={style.upgrade_btn}><button onClick={()=>submitUpgradePlan('Trendsetter')}>{isLoading && selectPlan == 0 ? <Spinner /> : isMobile ? 'upgrade today' : 'upgrade to new trendsetter'}</button></p>
                                                </div>
                                                <div className={style.planbox}>
                                                    <label className={style.plan_name}>{fpPlans && fpPlans.wanderlust.planWebName}</label>
                                                    <div className={style.new_text}><hr/><img src={Gconfig.fashionpassImageStorage+'assets/img/new.svg'} /></div>
                                                    <p className={style.items}>{seprateItems(fpPlans && fpPlans.wanderlust.items)[0]}</p>
                                                    <p className={style.items}>+{seprateItems(fpPlans && fpPlans.wanderlust.items)[1]}</p>
                                                    <p className={style.order_text}>Per order</p>
                                                    <p className={style.plan_price}><span className={style.cross_price}>${calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.wanderlust.planWebName )}</span> {(calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.wanderlust.planWebName ) - 20) > 0 ? (calculateAmountForPlanUpdate(customer.customer, systemSettings.systemSettings, fpPlans.wanderlust.planWebName ) - 20): 0} to Upgrade Today</p>
                                                    <div className={style.perks_text}><img src={Gconfig.fashionpassImageStorage+'assets/img/new_perks.svg'}/> <i className="fas fa-info-circle">
                                                        <div className={style.perks_tooltip}>
                                                            <span>This plan includes the following perks:</span>
                                                            <span className={style.heading_inner}>Select Rentals Same Day as Scan</span>
                                                            The ability to ‘select new rentals same day as scan’ means you will be checked-in to select new rentals the same day Fedex scans your return package.
                                                            <span className={style.heading_inner}>Swap 2 Accessories for 1 Clothing Item</span>
                                                            With qualifying plans (Trendsetter/Wanderlust) you can swap 2 accessories for 1 item at the time that you select your rentals. Simply add the clothing item of your choice instead of 2 accessories and checkout as normal.
                                                            <span className={style.heading_inner}>Monthly Purchase Discount</span>
                                                            Qualifying plans (Trendsetter/Wanderlust) receive a monthly purchase discount that will automatically be applied to the first purchase of your billing cycle. Discounts can be redeemed either by purchasing directly from the website or purchasing by keeping an item(s) from a rental order.
                                                        </div></i>
                                                    </div>
                                                    <div className={style.plan_desc}>
                                                        <p><i className={`fas fa-check-circle ${style.check}`}></i>Select New Rentals <br /> Same Day as Scan</p>
                                                        <hr />
                                                        <p><i className={`fas fa-check-circle ${style.check}`}></i>Swap {seprateItems(fpPlans && fpPlans.wanderlust.itemSwapRatio)[0]} Accessories <br />for {seprateItems(fpPlans && fpPlans.wanderlust.itemSwapRatio)[1]} Clothing Item</p>
                                                        <hr />
                                                        <p><img src={Gconfig.fashionpassImageStorage+'assets/img/dollarIcon.svg'} alt='dollar' />Monthly <span>${fpPlans && fpPlans.wanderlust.monthlyPurchaseCredit}</span> Purchase Discount</p>
                                                    </div>
                                                    <p className={style.upgrade_btn}><button onClick={()=>submitUpgradePlan('Wanderlust')}>{isLoading && selectPlan == 1 ? <Spinner /> :isMobile ? 'upgrade today' : 'upgrade to new wanderlust'}</button></p>
                                                </div>
                                            </div>
                                            <div className='text-center'><button className={style.socialite_btn} onClick={()=>{legacyOption()}}>stay on legacy socialite</button></div>
                                            <p className={style.cross} name="cross">
                                                <svg className='cross' style={{ fill: '#000' }} onClick={()=>{minimizePopup()}} xmlns="http://www.w3.org/2000/svg" data-name="close" version="1" tabIndex="-1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
                                            </p>
                                        </div>
                                        <Pagination />

                                    </ModalBody>
                                </> : <>
                                    <ModalBody className={`${style.div} ${style.modal_body}`}>
                                        {
                                            (customer.customer.subscription_name == "Wanderlust" ||  customer.customer.subscription_name == "Platinum") ?
                                                <>
                                                <div className={style.user_upgrade_div}>
                                                        <div >
                                                            <div className={style.planbox}>
                                                                <label className={style.plan_name}>{fpPlans && customer.customer.subscription_name == "Platinum" ? fpPlans.trendsetter.planWebName : fpPlans && fpPlans.wanderlust.planWebName}</label>
                                                                <div className={style.new_text}><hr/><img src={Gconfig.fashionpassImageStorage+'assets/img/new.svg'} /></div>
                                                                <p className={style.items}>{seprateItems(fpPlans && customer.customer.subscription_name == "Platinum" ? fpPlans.trendsetter.items : fpPlans && fpPlans.wanderlust.items)[0]}</p>
                                                                <p className={style.items}>+{seprateItems(fpPlans && customer.customer.subscription_name == "Platinum" ? fpPlans.trendsetter.items : fpPlans && fpPlans.wanderlust.items)[1]}</p>
                                                                <p className={style.order_text}>Per order</p>
                                                                <p className={style.plan_price}>$0 to Upgrade Today</p>
                                                                <div className={style.perks_text}><img src={Gconfig.fashionpassImageStorage+'assets/img/new_perks.svg'}/></div>
                                                                <div className={style.plan_desc}>
                                                                    <p><i className={`fas fa-check-circle ${style.check}`}></i>Select New Rentals <br /> Same Day as Scan</p>
                                                                    <p><i className={`fas fa-check-circle ${style.check}`}></i>Swap 2 Accessories <br />for 1 Clothing Item</p>
                                                                    <p><img src={Gconfig.fashionpassImageStorage+'assets/img/dollarIcon.svg'} alt='dollar' />Monthly <span>${fpPlans && customer.customer.subscription_name == "Platinum" ? fpPlans.trendsetter.monthlyPurchaseCredit : fpPlans && fpPlans.wanderlust.monthlyPurchaseCredit}</span> Purchase Discount</p>
                                                                </div>
                                                        </div>
                                                        <div className={style.pink_box_main}>
                                                            <div className={style.pink_box}>
                                                                    <p className={`${style.hour_48_text_mob} mb-0`}>
                                                                        <span>ONLY <i className={style.number}>4</i>8 HOURS LEFT</span> <span>TO CLAIM YOUR</span> <span>EXCLUSIVE OFFER!</span></p>
                                                                    <hr className={`${style.line_48_mob} mb-1`}/>
                                                                    <p className={style.upgrade_48_today}>UPGRADE TODAY FOR $0</p>
                                                                    <hr className={`${style.line_48_mob} mt-1`}/>
                                                                    <div className={style.upgrade_box_desc}>
                                                                        <p className={`${style.upgrade_desc_bold} mt-3 mb-4 pb-1`}>we’ll also waive the additional cost for your next month <span>and the new ${comparePlanData && comparePlanData[0].price} rate will go into effect on {nextToNextDate}</span></p>
                                                                        <p className={`${style.upgrade_desc_bold} ${style.next_month_price} mb-4 pb-3`}>$0 <span className={style.light}>TODAY </span>• { customerCurrentPlan && customerCurrentPlan.toLowerCase() == 'trendsetter' ? '$109' : '$139'} <span className={style.light}>NEXT MONTH ($10 OFF)</span> </p>
                                                                    </div>
                                                                <p className={style.claim_btn}><button onClick={()=>{submitUpgradePlan(customer.customer.subscription_name == "Platinum" ?'Trendsetter':'Wanderlust')}}>{isLoading ?  <Spinner /> : `CLAIM OFFER &  ${ customerCurrentPlan.toLowerCase() == 'socialite' ?  'EXPLORE PLANS' : 'UPGRADE Today'}`}</button></p>
                                                            </div>
                                                            <div className='text-center'>
                                                                <button className={`${style.current_plan_btn}`} onClick={()=>{legacyOption()}}>STAY ON LEGACY {customerCurrentPlan}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className={style.offer_time}>OFFER EXPIRES IN <span>{timer && ((parseInt(timer.split(':')[0]) < 10 ? 0 : '') + timer.split(':')[0]) + ':' + ((parseInt(timer.split(':')[1]) < 10 ? 0 : '') + timer.split(':')[1]) + ':' + ((parseInt(timer.split(':')[2]) < 10 ? 0 : '') + timer.split(':')[2])}</span></p>
                                                    <p className={style.cross} name="cross">
                                                        <svg className='cross' style={{ fill: '#000' }} onClick={()=>{minimizePopup()}} xmlns="http://www.w3.org/2000/svg" data-name="close" version="1" tabIndex="-1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
                                                    </p>
                                                </div> 
                                                </>
                                                :
                                                <>
                                                <div className={style.user_upgrade_div_socialite}>
                                                    <div className={`${style.plan_box_main} d-block`}>
                                                        <div className={style.pink_box}>
                                                                <p className={`${style.hour_48_text} mb-0`}>ONLY <span>4</span>8 HOURS LEFT TO CLAIM</p>
                                                                <p className={`${style.hour_48_text_mob} mb-0`}>
                                                                    <span>ONLY <i className={style.number}>4</i>8 HOURS LEFT</span> <span>TO CLAIM YOUR</span> <span>EXCLUSIVE OFFER!</span></p>
                                                                <p className={style.hour_48_text}>YOUR EXCLUSIVE OFFER!</p>
                                                                <hr className={`${style.socialite_line_48_mob} mb-1 mt-1`}/>
                                                                <p className={`${style.socialite_upgrade_48_today} border-0 p-0`}>UPGRADE TODAY & <br/> GET $20 OFF</p>
                                                                <hr className={`${style.socialite_line_48_mob} mt-1`}/>
                                                                <div className={`${style.upgrade_box_desc} pb-3`}>
                                                                    <MediaQuery minWidth={768}>
                                                                        <p className={`${style.upgrade_desc_bold} mt-3`}>Upgrade today and we’ll apply $20 to your prorated</p>
                                                                        <p className={`${style.upgrade_desc_bold} mb-3`}>amount<span> of New Trendsetter or New Wanderlust.</span> </p>
                                                                    </MediaQuery>
                                                                    <MediaQuery maxWidth={767}>
                                                                        <p className={`${style.upgrade_desc_bold} mt-3 mb-4 pb-1`}>Upgrade today and we’ll apply $20 to your prorated amount<span> of New Trendsetter or New Wanderlust.</span></p>
                                                                    </MediaQuery>
                                                                </div>
                                                            <p className={style.claim_btn}><button onClick={() => { setMultiPlanModal(true); setFirstModal(false)}}>{`CLAIM OFFER &  ${ customerCurrentPlan.toLowerCase() == 'socialite' ?  'EXPLORE PLANS' : 'UPGRADE Today'}`}</button></p>
                                                        </div>
                                                        <div className='text-center'>
                                                            <button className={`${style.current_plan_btn}`} onClick={()=>{legacyOption()}}>STAY ON LEGACY {customerCurrentPlan}</button>
                                                            <p className={style.offer_time}>OFFER EXPIRES IN <span>{timer && ((parseInt(timer.split(':')[0]) < 10 ? 0 : '') + timer.split(':')[0]) + ':' + ((parseInt(timer.split(':')[1]) < 10 ? 0 : '') + timer.split(':')[1]) + ':' + ((parseInt(timer.split(':')[2]) < 10 ? 0 : '') + timer.split(':')[2])}</span></p>
                                                        </div>
                                                    </div>
                                                    <p className={style.cross} name="cross">
                                                        <svg className='cross' style={{ fill: '#000' }} onClick={()=>{minimizePopup()}} xmlns="http://www.w3.org/2000/svg" data-name="close" version="1" tabIndex="-1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
                                                    </p>
                                                </div> 
                                                <Pagination />
                                                </>
                                        }
                                    </ModalBody>
                                </>
                            }
                        </Modal>
                    </>
                    :
                    <></>
            }
            { showBar ? 
                <div className={style.pricing48hour} onClick={(e)=>{openModalFromTab(e)}} >
                    <p className={style.claim_text}>CLAIM EXCLUSIVE <br/> UPGRADE OFFER</p>
                    <p className={style.cross} name="cross">
                        <i className="fas fa-times" onClick={(e)=>{crosssubmit(e)}}></i>
                    </p>
                </div>  : ''
            }
        </>
    )
}

export default LeftPricingPopup;
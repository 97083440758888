import React from 'react';
// import ReactDOM from 'react-dom';
import {flushSync} from 'react-dom';
import { Component } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Label, Spinner } from 'reactstrap'
import moment from 'moment'
import momentTimeZone from 'moment-timezone'
import Gconfig from "globalconfig"
import { getPlanNameAgianstSubscriptionName, updateCustomerTags, getPlanAmount, emailChangePlan, upgradePlan, searchTag, getTaxForOrderWithEmail,getCustomerTags, capitializeString, getAllWinbackDiscounts } from './globalFunctions';
import { connect } from 'react-redux';
import Router from 'next/router';
import { fetchCustomerSuccess } from 'redux/actions/customerActions';
import { updateTags } from './myAccountFunctions'
import MainLoader from 'components/shared/mainLoader';
import {getTaxOnCheckout} from "components/shared/checkoutFunctions";

let remainingDays;
let offsetCalculation = 0

class ChangePlanPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: '',
            cardExpire: '',
            check0:false,
            check1: false,
            check2: false,
            check3: false,
            check4: false,
            check5:false,
            loading: false,
            buttonText: 'UPGRADE',
            upgradeItems: '',
            oldUpgradeItems: '',
            paymentError: '',
            noOrders: false,
            earlyCheckIn: false,
            dataLoading: false,
            applyTax: false,
            taxAmount: 0,
            plans: null
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    componentDidUpdate(prvProps) {
        if (prvProps.customer != this.props.customer) {
            this.componentDidMount()
        }
        if (prvProps.customer.tag_list != this.props.customer.tag_list) {
            if (searchTag(this.props.customer.tag_list, 'currentlyborrowing') == false && searchTag(this.props.customer.tag_list, 'earlycheckin') == false) {
                this.setState({ noOrders: true })
            }
        }
    }

    async componentDidMount() {
        // console.log('planname', this.props.selectedPlan)
        let plan_data = this.props.systemSettings.find(function (sett) { return (typeof sett != undefined && sett != undefined && sett.setting_id == Gconfig.fp_plans)});
        if(plan_data && plan_data.setting_value) {
            // console.log('plandata-------->', JSON.parse(plan_data.setting_value));
            let plan = JSON.parse(plan_data.setting_value)
            await this.setState({ plans : plan })
        }
        this.setState({dataLoading: true})
        if (this.props.customer.tag_list != undefined) {
            let amount = Math.abs(this.getPlanChargeAmount())
            if(this.props.upgrade == true){
            if (this.props.customerAddresses.addresses.length > 0) {
                let defaultAddress = this.props.customerAddresses.addresses.find(function (address) {
                  if (address.default_address == true && address.is_shipping == true)
                    return address
                })
                if (defaultAddress == undefined) {
                  defaultAddress = this.props.customerAddresses.addresses.find(function (address) {
                    if (address.default_address == true && address.is_shipping == false)
                      return address
                  })
                  let timeStamp = moment.unix(Date.now()).format('x')
                  // console.log('timeStamp', timeStamp)
                  let line_items = [{
                    "id": timeStamp,
                    "name":"Upgrade Plan",
                    "quantity": 1,
                    "product_tax_code": 20010,
                    "unit_price": amount
                  }]
                  let taxObj = await getTaxOnCheckout(defaultAddress,amount,line_items,0)
                  if(taxObj.tax == true){
                    this.setState({applyTax: true , taxAmount: taxObj.taxPrice })
                  }
                } else {
                    let getTax = await getTaxForOrderWithEmail(amount,this.props.customer.email)
                    if(getTax != null || getTax != undefined){
                        if(getTax.amount_to_collect > 0){
                            this.setState({applyTax: true , taxAmount: getTax.amount_to_collect })
                        }
                    }
                }
              } else {
                let getTax = await getTaxForOrderWithEmail(amount,this.props.customer.email)
                if(getTax != null || getTax != undefined){
                    if(getTax.amount_to_collect > 0){
                        this.setState({applyTax: true , taxAmount: getTax.amount_to_collect })
                    }
                }
              }
            }else {
                let planAmount = getPlanAmount(this.props.selectedPlan, this.props.systemSettings)
                let getTax = await getTaxForOrderWithEmail(planAmount,this.props.customer.email)
                if(getTax != null || getTax != undefined){
                    if(getTax.amount_to_collect > 0){
                        this.setState({applyTax: true , taxAmount: getTax.amount_to_collect })
                    }
                }
            }

            if (searchTag(this.props.customer.tag_list, 'currentlyborrowing') == true && searchTag(this.props.customer.tag_list, 'earlycheckin') == false && searchTag(this.props.customer.tag_list, 'added items') == false && (this.state.Gold == true && this.state.plans.socialite.planStripeIdNew != this.props.customer.plan_id) || (this.state.Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id)) {
                this.setState({
                    buttonText: 'Upgrade and immediately choose more items!',
                    dataLoading: false
                })
            } else {
                this.setState({
                    buttonText: 'Upgrade',
                    dataLoading: false
                })
            }
            if (searchTag(this.props.customer.tag_list, 'currentlyborrowing') == false && searchTag(this.props.customer.tag_list, 'earlycheckin') == false) {
                this.setState({ noOrders: true, dataLoading: false })
            }
            if (searchTag(this.props.customer.tag_list, 'earlycheckin') == true) {
                this.setState({
                    earlyCheckIn: true,
                    dataLoading: false
                })
            }
        }
    }

    downgrade = () => {

        let { Platinum, Wanderlust } = this.props;

        if (this.state.check1 == false ) {
            this.setState({ error: 'Please agree to the terms before proceeding.' })
            return
        }
        if(((Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id) || (Wanderlust == true && this.state.plans.wanderlust.planStripeIdNew != this.props.customer.plan_id))  && this.state.check0 == false ){
            this.setState({ error: 'Please agree to the terms before proceeding.' })
            return
        }

        // let newPlanId = '';
        // if(this.props.selectedPlan == this.state.plans.socialite.planName) {
        //     newPlanId = this.state.plans.socialite.planStripeIdNew
        // }
        // else if(this.props.selectedPlan == this.state.plans.trendsetter.planName) {
        //     newPlanId = this.state.plans.trendsetter.planStripeIdNew
        // }

        // let newDowngradePlanData = {
        //     currentPlanId: this.props.customer.plan_id,
        //     newPlanId: newPlanId,
        //     chargeAmount : "",
        //     fromPromotion : "",
        //     creditToAdd : "",
        //     billingCycleDate : this.props.nextBillingDate,
        //     SubscriptionId : this.props.customer.plan_id
        //     // firstName: this.props.customer.first_name,
        //     // planAmount: getPlanAmount(this.props.selectedPlan, this.props.systemSettings),
        //     // email: this.props.customer.email,
        //     // daysLeft: remainingDays,
        //     // nextBillingDate: this.props.nextBillingDate,
        //     // action: 'downgrade',
        //     // cb_status: '',
        //     // tax: this.state.taxAmount
        // }
        
        // console.log('this.props.selectedPlan', this.props.selectedPlan)
        // return;

        this.setState({
            error: '',
            loading: !this.state.loading
        })

        let downgradePlanName = getPlanNameAgianstSubscriptionName(this.props.selectedPlan)
        let tagList = this.props.customer.tag_list
        let removeTag = ''
        let clothing = 0;
        let accessory = 0;
        let new_amount = 0;

        if (this.props.selectedPlan == this.state.plans.trendsetter.planName) {
            clothing = this.state.plans.trendsetter.clothing;
            accessory = this.state.plans.trendsetter.accessory;
            new_amount = this.state.plans.trendsetter.price;
        }
        if (this.props.selectedPlan == this.state.plans.socialite.planName) {
            clothing = this.state.plans.socialite.clothing
            accessory = this.state.plans.socialite.accessory
            new_amount = this.state.plans.socialite.price;
        }

        if(this.state.applyTax) {
            new_amount = new_amount + " +  tax"
        }

        // let emailData = {
        //     oldPlan: getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name),
        //     newPlan: getPlanNameAgianstSubscriptionName(this.props.selectedPlan),
        //     firstName: this.props.customer.first_name,
        //     planAmount: getPlanAmount(this.props.selectedPlan, this.props.systemSettings),
        //     email: this.props.customer.email,
        //     daysLeft: remainingDays,
        //     nextBillingDate: this.props.nextBillingDate,
        //     action: 'downgrade',
        //     cb_status: '',
        //     tax: this.state.taxAmount
        // }
        
        let newEmailData = {
            first_name :  capitializeString(this.props.customer.first_name),
            old_plan_name : getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name),
            new_plan_name : getPlanNameAgianstSubscriptionName(this.props.selectedPlan),//"Trendsetter",
            next_days : remainingDays,
            start_date : this.props.nextBillingDate,
            clothing : parseInt(clothing),
            accessories : parseInt(accessory),
            amount : new_amount,
            email : this.props.customer.email
        }
        // let newDowngradePlanData = {
        //     oldPlan: getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name),
        //     newPlan: getPlanNameAgianstSubscriptionName(this.props.selectedPlan),
        //     firstName: this.props.customer.first_name,
        //     planAmount: getPlanAmount(this.props.selectedPlan, this.props.systemSettings),
        //     email: this.props.customer.email,
        //     daysLeft: remainingDays,
        //     nextBillingDate: this.props.nextBillingDate,
        //     action: 'downgrade',
        //     cb_status: '',
        //     tax: this.state.taxAmount
        // }

        // if (searchTag(tagList, 'downgradetosocialite') == true && this.props.selectedPlan == Gconfig.plan[1].planName) {
        //     removeTag = 'DowngradeToSocialite'
        // }
        // if (searchTag(tagList, 'downgradetotrendsetter') == true && this.props.selectedPlan == Gconfig.plan[0].planName) {
        //     removeTag = 'DowngradeToTrendsetter'
        // }

        if (searchTag(tagList, 'downgradetosocialite') == true && this.props.selectedPlan == this.state.plans.trendsetter.planName) {
            removeTag = 'DowngradeToSocialite'
        }
        if (searchTag(tagList, 'downgradetotrendsetter') == true && this.props.selectedPlan == this.state.plans.socialite.planName) {
            removeTag = 'DowngradeToTrendsetter'
        }
      
        removeTag += ',freesubscriptionWanderlust,freesubscriptionTrendsetter'
        updateCustomerTags('DowngradeTo' + downgradePlanName, removeTag).then(response => response.json()).then(data => {
            if (data.success == true) {
                emailChangePlan(newEmailData)
                this.setState({
                    loading: !this.state.loading
                })
                this.props.changeStatus(data.tags)
                this.props.toggle(true)
                let customer = this.props.customer
                customer.tag_list = data.tags;
                this.props._fetchCustomerSuccess(customer)
            }
        })
    }

    upgrade = async (chargeAmount,taxDeductionAmount) => {

        // console.log('chargeAmount', chargeAmount)

        let { Gold, Platinum } = this.props;
        if (this.state.check2 == false || this.state.check3 == false || this.state.check4 == false) {
            this.setState({
                error: 'Please agree to the terms before proceeding.'
            })
            return
        }
        if(((Gold == true && this.state.plans.socialite.planStripeIdNew != this.props.customer.plan_id) || (Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id)) && !this.state.check5){
            this.setState({
                error: 'Please agree to the terms before proceeding.'
            })
            return
        }


        this.setState({
            error: '',
            loading: !this.state.loading
        })
        this.props._getCustomerTags(this.props.customer.customer_id).then(async () => {
            let currentPlanName = getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name)
            let selectedPlan = getPlanNameAgianstSubscriptionName(this.props.selectedPlan)
            let tagList = this.props.customer.tag_list.join().toLowerCase()
            let clothing, accessories, upgradeItems
            let upgradeIndex = tagList.indexOf('upgradeitems')
            let oldUpgradeItems = tagList.substring(upgradeIndex - 1, upgradeIndex)
            if (tagList.indexOf('currentlyborrowing') > -1 && tagList.indexOf('earlycheckin') == -1 && tagList.indexOf('added items') == -1) {
                if (currentPlanName ==  capitializeString(this.state.plans.socialite.planWebName) && selectedPlan == capitializeString(this.state.plans.trendsetter.planWebName)) {
                    clothing = this.state.plans.socialite.clothing - this.state.plans.trendsetter.clothing
                    accessories = this.state.plans.socialite.accessory - this.state.plans.trendsetter.accessory
                    upgradeItems = Math.abs(clothing + accessories)
                    flushSync( async() => {
                        await this.setState({upgradeItems: upgradeItems + 'upgradeItems'})
                    })
                    if (tagList.indexOf('upgradeitems') > -1 && upgradeItems != oldUpgradeItems) {
                        flushSync( async() => {
                            await this.setState({oldUpgradeItems: oldUpgradeItems + 'upgradeItems'})
                        })
                        
                    }
                }
                if (currentPlanName == capitializeString(this.state.plans.trendsetter.planWebName) && selectedPlan == capitializeString(this.state.plans.wanderlust.planWebName)) {
                    clothing = this.state.plans.trendsetter.clothing - this.state.plans.wanderlust.clothing
                    accessories = this.state.plans.trendsetter.accessory - this.state.plans.wanderlust.accessory
                    upgradeItems = Math.abs(clothing + accessories)
                    if (tagList.indexOf('upgradeitems') > -1)
                        upgradeItems = upgradeItems + 2
                    flushSync( async() => {
                        await this.setState({upgradeItems: upgradeItems + 'upgradeItems'})
                    })
                    if (tagList.indexOf('upgradeitems') > -1 && upgradeItems != oldUpgradeItems) {
                        flushSync( async() => {
                            await this.setState({oldUpgradeItems: oldUpgradeItems + 'upgradeItems'})
                        })
                    }

                }
                if (currentPlanName == capitializeString(this.state.plans.socialite.planWebName) && selectedPlan == capitializeString(this.state.plans.wanderlust.planWebName)) {
                    clothing = this.state.plans.socialite.clothing - this.state.plans.wanderlust.clothing
                    accessories = this.state.plans.socialite.accessory - this.state.plans.wanderlust.accessory
                    upgradeItems = Math.abs(clothing + accessories);
                    flushSync( async() => {
                        await this.setState({upgradeItems: upgradeItems + 'upgradeItems'})
                    })
                    if (tagList.indexOf('upgradeitems') > -1 && upgradeItems != oldUpgradeItems) {
                        flushSync( async() => {
                            await this.setState({oldUpgradeItems: oldUpgradeItems + 'upgradeItems'})
                        })
                    }
                }
            }
            let cb_status = '0'
            if (tagList.indexOf('currentlyborrowing') != -1) {
                cb_status = '1'
            }
            let plan_id 
            if (this.props.selectedPlan == this.state.plans.trendsetter.planName) {
                plan_id = this.state.plans.trendsetter.stripeName
            }
            if (this.props.selectedPlan == this.state.plans.wanderlust.planName) {
                plan_id = this.state.plans.wanderlust.stripeName
            }
            let addTags = "", removeTags = "";
            if (getPlanNameAgianstSubscriptionName(this.props.selectedPlan) == 'Trendsetter') {
                this.setState({ addTags: 'freesubscriptionWanderlust', removeTags: 'freesubscriptionTrendsetter' })
                this.props.customer.tag_list.filter(async tag => {
                    if (tag.indexOf('freesubscription') > -1) {
                        if (tag.indexOf('Trendsetter') > -1) {
                            addTags = 'freesubscriptionWanderlust'
                            removeTags = 'freesubscriptionTrendsetter'
                        }
                    }
                })
            } else {
                removeTags = 'freesubscriptionTrendsetter,freesubscriptionWanderlust'
            }

            // let upgradeData = {
            //     plan_id: plan_id,
            //     cb_status: cb_status,
            //     subscriptionId: this.props.subscriptionId,
            //     leftDays: remainingDays,
            //     email: this.props.customer.email,
            //     name: this.props.customer.first_name + ' ' + this.props.customer.last_name,
            //     newPlan: 'plan_name:' + getPlanNameAgianstSubscriptionName(this.props.selectedPlan) + ',' + this.state.upgradeItems + ',' + addTags,
            //     currentPlan: 'plan_name:' + getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name) + ',' + this.state.oldUpgradeItems + ',' + removeTags,
            //     subscription_name: this.props.selectedPlan,
            //     customer_id: this.props.customer.customer_id
            // }
            let emailData = {
                oldPlan: getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name),
                newPlan: getPlanNameAgianstSubscriptionName(this.props.selectedPlan),
                firstName: this.props.customer.first_name,
                planAmount: getPlanAmount(this.props.selectedPlan, this.props.systemSettings),
                email: this.props.customer.email,
                daysLeft: remainingDays,
                nextBillingDate: this.props.nextBillingDate,
                action: 'upgrade',
                cb_status: cb_status
            }
            let payFromCredit = false
            let addCreditAmount = 0
            if (taxDeductionAmount > 0 && taxDeductionAmount < 0.5){
                payFromCredit = true
                addCreditAmount = taxDeductionAmount.toFixed(2)
            }

                // new api work
                let newPlanId = '';
                let clothing1 = 0, accessory1 = 0, planPrice = 0
                if(this.props.selectedPlan == this.state.plans.socialite.planName) {
                    newPlanId = this.state.plans.socialite.planStripeIdNew
                    clothing1 = this.state.plans.socialite.clothing
                    accessory1 = this.state.plans.socialite.accessory
                    planPrice = this.state.plans.socialite.price
                }
                else if(this.props.selectedPlan == this.state.plans.trendsetter.planName) {
                    newPlanId = this.state.plans.trendsetter.planStripeIdNew
                    clothing1 = this.state.plans.trendsetter.clothing
                    accessory1 = this.state.plans.trendsetter.accessory
                    planPrice = this.state.plans.trendsetter.price
                }
                else if(this.props.selectedPlan == this.state.plans.wanderlust.planName) {
                    newPlanId = this.state.plans.wanderlust.planStripeIdNew
                    clothing1 = this.state.plans.wanderlust.clothing
                    accessory1 = this.state.plans.wanderlust.accessory
                    planPrice = this.state.plans.wanderlust.price
                }
                

                let newUpgradePlanData = {
                    customerEmail: this.props.customer.email,
                    newPlanId: newPlanId,
                    customerStripeId: this.props.customer.stripe_id,
                    currentPlanName: getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name),
                    newPlanName: getPlanNameAgianstSubscriptionName(this.props.selectedPlan),
                    customerId: this.props.customer.customer_id,
                    payfromcredit : payFromCredit,
                    addCreditAmount: addCreditAmount,
                    chargeAmount: this.props.fromPromotion == true ? this.props.Gold ? chargeAmount : 0 : chargeAmount,
                    addPromoCreditAmount: this.props.fromPromotion == true ? this.props.Gold ? 20 : 0 : 0,
                    tagToAdd: this.props.fromPromotion == true && !this.props.Gold == true ? this.props.selectedPlan == 'Wanderlust' ? 'promoUpgradeWanderlust' : 'promoUpgradeTrendsetter' : '',
                    subscription_name: this.props.selectedPlan,
                    customerName: this.props.customer.first_name + ' ' + this.props.customer.last_name,
                    currentPlanId: this.props.customer.plan_id,
                    fromPromotion : this.props.fromPromotion == true ? true : false,
                    leftDays: remainingDays,
                    billingCycleDate : moment(this.props.nextBillingDate).format('X'),
                    subscriptionId: this.props.subscriptionId,
                    tax: this.props.fromPromotion == true ? this.props.Gold ? this.state.taxAmount : 0 : this.state.taxAmount,
                    cbStatus: parseInt(cb_status) == 1 ? true : false,
                    subscriptionEndDate: moment(this.props.nextBillingDate).format('X'),
                    clothing: parseInt(clothing1) ,
                    accessories: parseInt(accessory1),
                    newPlan: 'plan_name:' + getPlanNameAgianstSubscriptionName(this.props.selectedPlan) + ',' + this.state.upgradeItems + ',' + addTags,
                    currentPlan: 'plan_name:' + getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name) + ',' + this.state.oldUpgradeItems + ',' + removeTags,
                    newPlanPrice: planPrice,
                    charge_type: "plan_upgrade"
                }
                // console.log('newUpgradePlanObject', newUpgradePlanData, chargeAmount)
            let promotion = false
            if(this.props.fromPromotion){
                promotion= true
            }
            this.props._upgradePlan(newUpgradePlanData, emailData, chargeAmount, this.state.taxAmount,payFromCredit,addCreditAmount,promotion).then(res => {
            // this.props._upgradePlan(upgradeData, emailData, chargeAmount, this.state.taxAmount,payFromCredit,addCreditAmount).then(res => {
                if (res == true) {
                    let updateTag= ''
                    
                    this.setState({
                        loading: !this.state.loading,
                    })
                    if(promotion){
                        this.setState({
                            paymentError: '',
                            error: '',
                            check0:false,
                            check1: false,
                            check2: false,
                            check3: false,
                            check4: false,
                            check5:false,
                            taxAmount: 0
                        })
                        this.props.updatePlanToggle()
                        this.props.refresh()
                    }else{
                        this.props.refresh()
                        this.closeModal()
                    }
                } else {
                    this.setState({
                        loading: !this.state.loading,
                        paymentError: 'Your card was declined.'
                    })
                }
            })
        })
    }

    offsetCalculation = () => {
        let today = moment(Date.now()).format('YYYY-MM')
        if (moment(Date.now()).format('M') != moment(this.props.customer.subscription_end_date).format('M')) {
            if (moment(today, 'YYYY-MM').daysInMonth() == 31) {
                return offsetCalculation = -1
            }
            if (moment(today, 'YYYY-MM').daysInMonth() == 29) {
                return offsetCalculation = 1
            }
            if (moment(today, 'YYYY-MM').daysInMonth() == 28) {
                return offsetCalculation = 2
            }
            if (moment(Date.now()).format('D') == 31) {
                return offsetCalculation = 0
            }
        }
        return offsetCalculation
    }

    closeModal() {
        this.props.toggle(true)
        this.setState({
            paymentError: '',
            error: '',
            check0:false,
            check1: false,
            check2: false,
            check3: false,
            check4: false,
            check5:false,
            taxAmount: 0
        })
    }
    gotoMyaccount() {
        if(location.pathname.indexOf('my-account') > -1){
            window.location.href = "/my-account?tab=4";
        }else{
            Router.push({ pathname: '/my-account', query: { tab: 4 } })
        }
    }

    getPlanChargeAmount(){
        let { Gold, Platinum, selectedPlan, Wanderlust, memberPoints } = this.props
        // console.log('oooooo------>', Gold, Platinum, Wanderlust, selectedPlan)
        let subscriptionEndDate = moment(this.props.customer.subscription_end_date).format('x')
        // remainingDays = billingDate.diff(today, 'days') + this.offsetCalculation()
        let pacificTime = momentTimeZone().tz("America/Los_Angeles").format()
        let timeDiff = Math.abs(parseInt(moment(pacificTime).format('x')) - parseInt(subscriptionEndDate));
        remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        let oldPlan, newPlan, costPerDay, amountCharged;

        if (Gold == true) {
            if(this.props.customer.plan_id == this.state.plans.socialite.planStripeIdNew)
                oldPlan = this.state.plans.socialite.price
            else
                oldPlan = this.state.plans.socialite.planStripePrice
        }
        if (Platinum == true) {
            if(this.props.customer.plan_id == this.state.plans.trendsetter.planStripeIdNew)
                oldPlan = this.state.plans.trendsetter.price
            else
                oldPlan = this.state.plans.trendsetter.planStripePrice
        }
        if (Wanderlust == true) {
            oldPlan = this.state.plans.wanderlust.planStripePrice
        }
        newPlan = getPlanAmount(selectedPlan, this.props.systemSettings)
        costPerDay = (newPlan - oldPlan) / 30
        amountCharged = costPerDay * (remainingDays)
        amountCharged = Math.floor(amountCharged)
        // console.log('amount charged------------>', amountCharged, selectedPlan, costPerDay, remainingDays, newPlan, oldPlan)
        return amountCharged
        // console.log('Charged----Amount ', amountCharged)
    }
    
    render() {
        let { error, check0, check1, check2, check3, check4, check5, paymentError } = this.state
        let { Gold, Platinum, Wanderlust, nextBillingDate, selectedPlan, isOpen, toggle, upgrade, memberPoints, customer } = this.props
        let pacificTime = momentTimeZone().tz("America/Los_Angeles").format()
        let dateToday = moment(Date.now()).format('MM/DD/YYYY')
        let today = moment(dateToday, 'MM/DD/YYYY')
        let billingDate = moment(nextBillingDate, 'MM/DD/YYYY')
        let subscriptionEndDate = moment(this.props.customer.subscription_end_date).format('x')
        // remainingDays = billingDate.diff(today, 'days') + this.offsetCalculation()
        let timeDiff = Math.abs(parseInt(moment(pacificTime).format('x')) - parseInt(subscriptionEndDate));
        remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        let defaultCard
        if (customer.default_payment_method != undefined) {
            defaultCard = customer.default_payment_method
        }
        let oldPlan, newPlan, costPerDay, amountCharged;
        if (Gold == true && this.state.plans) {
            // oldPlan = this.state.plans.socialite.price
            if(this.props.customer.plan_id == this.state.plans.socialite.planStripeIdNew)
                oldPlan = this.state.plans.socialite.price
            else
                oldPlan = this.state.plans.socialite.planStripePrice
        }
        if (Platinum == true && this.state.plans) {
            // oldPlan = this.state.plans.trendsetter.price
            if(this.props.customer.plan_id == this.state.plans.trendsetter.planStripeIdNew)
                oldPlan = this.state.plans.trendsetter.price
            else
                oldPlan = this.state.plans.trendsetter.planStripePrice
        }
        if (Wanderlust == true && this.state.plans) {
            oldPlan = this.state.plans.wanderlust.planStripePrice
        }
        newPlan = getPlanAmount(selectedPlan, this.props.systemSettings)
        costPerDay = (newPlan - oldPlan) / 30
        amountCharged = costPerDay * (remainingDays)
        if(amountCharged % 1 > 0) {
            let decimal_val = amountCharged % 1;
            if(decimal_val > 0.5) 
               amountCharged = Math.ceil(amountCharged)
            else 
              amountCharged = Math.floor(amountCharged)
        }
        else {
            amountCharged = Math.floor(amountCharged)
        }
        // amountCharged = costPerDay * (remainingDays + this.offsetCalculation())
        // console.log('Charged---- Amount ',amountCharged)
        let deductionAmount = amountCharged - memberPoints
        let taxDeductionAmount = (amountCharged + this.state.taxAmount) - memberPoints;
        let promotionChargeAmount = 0;
        // let newPromotionChargeAmountwithTax_Point = 0;
        // let newPromotionChargeAmountwithPoint = 0;
        let promotion = false;
        if(this.props.fromPromotion){
            promotion = true
            if(this.props.Gold){
                promotionChargeAmount = parseInt(amountCharged) - 20
                // newPromotionChargeAmountwithPoint =   parseInt(amountCharged) - 20 - memberPoints;
                // newPromotionChargeAmountwithTax_Point = (amountCharged + this.state.taxAmount) - 20 - memberPoints;
            }else{
                promotionChargeAmount = 0
            }
        }
        let actualCharge = promotionChargeAmount
        let creditUsed = 0
        if(promotionChargeAmount > 0 && memberPoints > 0){
            if(promotionChargeAmount >memberPoints)
                creditUsed = memberPoints
            else
                creditUsed = promotionChargeAmount
            promotionChargeAmount = promotionChargeAmount-memberPoints
            if(promotionChargeAmount  < 0.5)
            promotionChargeAmount = 0
        }
        let nextToNextMonth= '';
        let dayBeforeBillingDate = ''
        nextToNextMonth = moment(nextBillingDate)
        // dayBeforeBillingDate = moment(nextToNextMonth).add(1, 'month').subtract(1, 'days')
        dayBeforeBillingDate = moment(nextToNextMonth).subtract(1, "days")
        nextToNextMonth = moment(nextToNextMonth).add(1, 'month')
        dayBeforeBillingDate = moment(dayBeforeBillingDate).format('M/D/YYYY')
        nextToNextMonth = moment(nextToNextMonth).format('M/D/YYYY')

        if (this.props.customer.default_payment_method == undefined) return null;
        if(!this.state.plans) return null;
        return (
            <Modal isOpen={true} toggle={() => { this.closeModal() }} centered className="changePlanModal basicModalLayout">
                {(this.state.dataLoading && !this.props.fromPromotion) ?
                    <MainLoader from={'planPopup'} /> : <>
                        <ModalHeader className='align-items-center' toggle={() => { this.closeModal() }}>{upgrade == true ? 'Upgrade' : 'Downgrade'}</ModalHeader>
                        <ModalBody className='upgrade_tendsetter'>
                            {upgrade == false ?
                                <div className="mt-3 mb-2">
                                    <FormGroup check>
                                        {((Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id) || (Wanderlust == true && this.state.plans.wanderlust.planStripeIdNew != this.props.customer.plan_id)) ?
                                            <>
                                                <Label check> <div className='input_div'><b> You are currently grandfathered into Legacy {getPlanNameAgianstSubscriptionName(this.props.customer.subscription_name)}.</b> </div> </Label>
                                                <Label check> <div className='input_div mt-2'> Please note, if you downgrade <b> you will no longer have access to any Legacy pricing or plans. </b> </div> </Label>
                                            </>
                                            : ""
                                        }
                                        <Label check>
                                            <Input className='mt-2' type="checkbox" name="check1" onChange={this.handleChange} checked={check1} />{''} <div className="mt-1"> I understand that I may continue borrowing {Gold == true ? "2" : ''} {Platinum == true ? "3" : ''} {Wanderlust == true ? "4" : ''} clothing items and {Gold == true ? "1" : ''} {Platinum == true ? "2" : ''} {Wanderlust == true ? "3" : ''} {Gold == true ? "accessory" : 'accessories'} at a
                                                time until my next billing cycle in  {remainingDays}  days ({dayBeforeBillingDate}). On my next billing cycle <span className='text-danger'>({nextBillingDate})</span>, I will be
                                                billed at the rate of <span className='text-danger'> ${selectedPlan == this.state.plans.socialite.planName ? this.state.plans.socialite.price : '' || selectedPlan == this.state.plans.trendsetter.planName ? this.state.plans.trendsetter.price : ''}/month </span> {this.state.applyTax ? '+ tax' : ''} for New {getPlanNameAgianstSubscriptionName(selectedPlan)}.</div>
                                        </Label>
                                        {((Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id) || (Wanderlust == true && this.state.plans.wanderlust.planStripeIdNew != this.props.customer.plan_id)) ?
                                            <Label check> <Input className='my-4' type="checkbox" name="check0" onChange={this.handleChange} checked={check0} />{''} <div className="px-2 mt-3 py-1 pr-4"> <b>  I understand that once I downgrade, I will be unable switch back to any other Legacy plan or pricing.</b> </div></Label>
                                            : ""
                                        }
                                    </FormGroup>
                                </div>
                                :
                                <div className="mt-3 mb-2">
                                    <FormGroup check>
                                        <Label check>
                                            {((Gold == true && this.state.plans.socialite.planStripeIdNew == this.props.customer.plan_id) || (Platinum == true && this.state.plans.trendsetter.planStripeIdNew == this.props.customer.plan_id)) ?
                                                <>
                                                    <Input type="checkbox" name="check2" onChange={this.handleChange} checked={check2} />{' '}I understand that my default credit card ({defaultCard.brand}
                                                    {' '} ending in {defaultCard.last4} - {defaultCard.expiry.slice(0, 2)}/20{defaultCard.expiry.slice(2, 4)}) will
                                                    immediately be charged the total prorated amount of ${taxDeductionAmount < 0.5 ? '0.00' : this.state.applyTax ?
                                                        (deductionAmount < 0 ? taxDeductionAmount.toFixed(2) : deductionAmount.toFixed(2) + " + tax") : deductionAmount.toFixed(2)} to be upgraded to
                                                    {' ' + getPlanNameAgianstSubscriptionName(selectedPlan)} (${selectedPlan == this.state.plans.wanderlust.planName ? this.state.plans.wanderlust.price :
                                                        '' || selectedPlan == this.state.plans.trendsetter.planName ? this.state.plans.trendsetter.price : ''}/mo) for the duration of my current billing cycle
                                                    ({remainingDays} {remainingDays > 1 ? 'days' : 'day'}). {memberPoints > 0 || memberPoints == deductionAmount ? memberPoints > amountCharged ? '$' + amountCharged + ' of my $' + memberPoints +
                                                        ' of account credit will also be used.' : 'My $' + memberPoints.toFixed(2) + ' of account credit will also be used.' : ''}
                                                </>
                                                :
                                                <>
                                                    <Input type="checkbox" name="check2" onChange={this.handleChange} checked={check2} />

                                                    {promotion ? <div className='input_div'>{` I understand that I will be charged $${this.props.Gold && promotionChargeAmount > 0 ? promotionChargeAmount.toFixed(2) : 0}
                                             ${this.state.taxAmount > 0 && this.props.Gold ? '+ tax' : ''} today to be upgraded to  ${selectedPlan == this.state.plans.trendsetter.planName ?
                                                            ('New Trendsetter ' + '($' + this.state.plans.trendsetter.price + '/mo)') : ''} ${selectedPlan == this.state.plans.wanderlust.planName ?
                                                                ('New Wanderlust ' + '(' + this.state.plans.wanderlust.price + '/mo)') : ''} for the duration of my current billing cycle (${remainingDays} days). `}
                                                        <span><b>{`${this.props.Gold && promotionChargeAmount < 0 ? `Remaining $${Math.abs(promotionChargeAmount)} from the upgrade promotion will be added as account credit.` : ''}`}</b>
                                                            {`${this.props.Gold && creditUsed > 0 ? memberPoints > actualCharge ? `$${creditUsed.toFixed(2)} of my $${memberPoints.toFixed(2)} of account credit will also be used.` : ' My $' + creditUsed.toFixed(2) + ' of account credit will also be used.' : ''}`}
                                                        </span> </div> :

                                                        <div className='input_div'> {` I understand that I will be charged $${taxDeductionAmount < 0.5 ? '0.00' : this.state.applyTax ?
                                                            (deductionAmount < 0 ? taxDeductionAmount.toFixed(2) : deductionAmount.toFixed(2) + " + tax") : deductionAmount.toFixed(2)}
                                              today to be upgraded to  ${selectedPlan == this.state.plans.trendsetter.planName ?
                                                                ('New Trendsetter ' + '($' + this.state.plans.trendsetter.price + '/mo)') : ''} ${selectedPlan == this.state.plans.wanderlust.planName ?
                                                                    ('New Wanderlust ' + '(' + this.state.plans.wanderlust.price + '/mo)') : ''} for the duration of my current billing cycle (${remainingDays} ${remainingDays > 1 ? 'days' : 'day'}).`}
                                                            {memberPoints > 0 || memberPoints == deductionAmount ? memberPoints > amountCharged ? '$' + amountCharged + ' of my $' + memberPoints +
                                                                ' of account credit will also be used.' : ' My $' + memberPoints.toFixed(2) + ' of account credit will also be used.' : ''}
                                                        </div>}
                                                </>
                                            }
                                        </Label>
                                    </FormGroup>

                                    <FormGroup check className="mt-2">
                                        <Label check>
                                            {((Gold == true && this.state.plans.socialite.planStripeIdNew == this.props.customer.plan_id) || (Platinum == true && this.state.plans.trendsetter.planStripeIdNew == this.props.customer.plan_id)) ?
                                                <>
                                                    <Input type="checkbox" name="check3" onChange={this.handleChange} checked={check3} />{' '}<div className='input_div'>I understand that on my billing cycle date <span className="text-danger"> {nextBillingDate} </span>,
                                                        I will be charged {selectedPlan == this.state.plans.wanderlust.planName ? <span className="text-danger"> ${parseInt(this.state.plans.wanderlust.price).toFixed(2)} </span> : ''
                                                            || selectedPlan == this.state.plans.trendsetter.planName ? <span className="text-danger"> ${parseInt(this.state.plans.trendsetter.price).toFixed(2)} </span> : ''}
                                                        {this.state.applyTax ? '+ tax' : ''} for
                                                        my {getPlanNameAgianstSubscriptionName(selectedPlan)} subscription renewal. </div>
                                                </> :
                                                <>
                                                    <Input type="checkbox" name="check3" onChange={this.handleChange} checked={check3} /><div className='input_div'>I understand that on my billing cycle date <span className="text-danger">{nextBillingDate}</span>, I will be charged {selectedPlan == this.state.plans.trendsetter.planName ? <><span className="text-danger">${`${promotion ? this.props.Gold ? `${this.state.plans.trendsetter.price}.00` : `${parseInt(this.state.plans.trendsetter.price) - 10}.00` : `${this.state.plans.trendsetter.price}`}`}</span>  + tax for my New Trendsetter </> : ''} {selectedPlan == this.state.plans.wanderlust.planName ? <><span className="text-danger font-weight-bold">${`${promotion ? this.props.Gold ? `${this.state.plans.wanderlust.price}.00` : `${parseInt(this.state.plans.wanderlust.price) - 10}.00` : `${this.state.plans.wanderlust.price}`}`}</span> + tax for my New Wanderlust </> : ''}  subscription renewal. {promotion && !this.props.Gold ? 'This includes the $10 OFF promotion for your first full month on the upgraded plan.' : ''} {promotion && !this.props.Gold && <span>Then on <span className='text-danger'>{nextToNextMonth}</span>, the following billing date, I will be charged <span className='text-danger'><b>${selectedPlan == this.state.plans.wanderlust.planName ? this.state.plans.wanderlust.price : this.state.plans.trendsetter.price}.00</b></span> + tax for my New {getPlanNameAgianstSubscriptionName(selectedPlan)} Subscription at full price.</span>}</div>
                                                </>
                                            }
                                        </Label>
                                    </FormGroup>

                                    <FormGroup check className="mt-2 mb-2">
                                        {this.state.earlyCheckIn == false ?
                                            <Label check>
                                                {((Gold == true && this.state.plans.socialite.planStripeIdNew == this.props.customer.plan_id) || (Platinum == true && this.state.plans.trendsetter.planStripeIdNew == this.props.customer.plan_id)) ?
                                                    <Label className='font-weight-bold'>
                                                        {this.state.noOrders == false
                                                            ?
                                                            <React.Fragment>
                                                                <Input type="checkbox" name="check4" onChange={this.handleChange} checked={check4} />{' '}I understand that I can immediately choose {Gold == true && selectedPlan == this.state.plans.trendsetter.planName ? '1' : '' || Gold == true && selectedPlan == this.state.plans.wanderlust.planName ? '2' : ''} {Platinum == true && selectedPlan == this.state.plans.wanderlust.planName ? '1' : ''} extra clothing item and {Gold == true && selectedPlan == this.state.plans.trendsetter.planName ? '1' : '' || Gold == true && selectedPlan == this.state.plans.wanderlust.planName ? '2' : ''} {Platinum == true && selectedPlan == this.state.plans.wanderlust.planName ? '1' : ''} extra accessory.
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <Input type="checkbox" name="check4" onChange={this.handleChange} checked={check4} />{' '}I understand that I can immediately choose my {Gold == true && selectedPlan == this.state.plans.trendsetter.planName ? capitializeString(this.state.plans.trendsetter.planWebName) : '' || Gold == true && selectedPlan == this.state.plans.wanderlust.planName ? capitializeString(this.state.plans.wanderlust.planWebName) : '' || capitializeString(this.state.plans.wanderlust.planWebName)} items.
                                                            </React.Fragment>
                                                        }
                                                    </Label>
                                                    :
                                                    <>
                                                        <React.Fragment>
                                                            <Input type="checkbox" name="check4" onChange={this.handleChange} checked={check4} /> {' '}<div className='input_div'>I understand that I get immediate access to my  {selectedPlan == this.state.plans.trendsetter.planName ? 'new trendsetter' : ''} {selectedPlan == this.state.plans.wanderlust.planName ? 'new wanderlust' : ''} perks: complimentary early check-in, swapping 2 accessories for 1 clothing item, and {selectedPlan == this.state.plans.trendsetter.planName ? '$10' : '$20'} monthly {promotion ? 'discount on my first purchase of each month' : 'purchase discount'}.</div>
                                                        </React.Fragment>
                                                    </>
                                                }
                                            </Label>
                                            :
                                            <Label check className='font-weight-bold'>
                                                <Input type="checkbox" name="check4" onChange={this.handleChange} checked={check4} />{' '}I understand that I can choose all of my items once I am checked in.
                                            </Label>
                                        }
                                    </FormGroup>

                                    {((Gold == true && this.state.plans.socialite.planStripeIdNew != this.props.customer.plan_id) || (Platinum == true && this.state.plans.trendsetter.planStripeIdNew != this.props.customer.plan_id) || (Wanderlust == true && this.state.plans.wanderlust.planStripeIdNew != this.props.customer.plan_id)) ?
                                        <FormGroup check className="mt-2">
                                            <Label check className='font-weight-bold' >
                                                <Input type="checkbox" name="check5" onChange={this.handleChange} checked={check5} /> {``}<div className='input_div'>I understand that once I upgrade to {selectedPlan == this.state.plans.trendsetter.planName ? 'New Trendsetter,' : ''} {selectedPlan == this.state.plans.wanderlust.planName ? 'New Wanderlust,' : ''} I will be unable switch back to Legacy {Gold == true ? 'Socialite' : ""} {Platinum == true ? 'Trendsetter' : ""} or any other Legacy plan.</div>
                                            </Label>
                                        </FormGroup> : ""}

                                    {paymentError != '' ?
                                        <div style={{ color: 'red' }}>
                                            {paymentError}
                                            <br />
                                            <p><span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'red' }} onClick={() => { this.gotoMyaccount() }}>Click here</span> to update your card details in My Account and then try again.</p>
                                        </div> : ''}
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter className="pb-5 pt-3">
                            <div className='btn_div'>
                                {upgrade == false ? <button className="downgradeBtn pinkBtn" onClick={() => this.closeModal()} > Keep My Current Plan </button> : ""}
                                <button className="downgradeBtn blackBtn" disabled={this.state.loading ? true : false} onClick={upgrade == true ? () => { this.upgrade(amountCharged, taxDeductionAmount) } : this.downgrade}>{this.state.loading == false ? <React.Fragment>{upgrade == true ? this.state.buttonText : 'Downgrade'}</React.Fragment> : <Spinner />}</button>{' '}
                            </div>
                            <p style={{ color: 'red', fontFamily: 'popins-regular' }}>{error}</p>
                        </ModalFooter>
                    </>}
            </Modal>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      customerAddresses:state.customerAddresses,
      systemSettings: state.systemSettings.systemSettings
    }
  }
const mapDispatchToProps = (dispatch) => {
    return {
        _upgradePlan: (data, emailData, chargeAmount, taxAmount,payFromCredit,addCreditAmount, fromPromotion) => dispatch(upgradePlan(data, emailData, chargeAmount, taxAmount,payFromCredit,addCreditAmount, fromPromotion)),
        _fetchCustomerSuccess: (customer) => dispatch(fetchCustomerSuccess(customer)),
        _updateTags: (add, remove) => dispatch(updateTags(add, remove)),
        _getCustomerTags: (customer_id) => dispatch(getCustomerTags(customer_id)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanPopup)

import { Icons } from '@/libraries/icons';
import { BrandDataType } from '@/services/product';
import Link from 'next/link';
import React, { useRef, useState } from 'react';
import { DrawerSubMenus } from '@/containers/header/top-bar/drawer-menu/drawer-menu-index/drawer-menu-index';

interface IProps {
  activeSubMenu: DrawerSubMenus | null;
  id: DrawerSubMenus;
  data: BrandDataType[];
  handleClearFilters: () => void;
}

const DrawerBodyDropdownListSubBrandsList: React.FC<IProps> = ({ activeSubMenu, id, data, handleClearFilters }) => {
  const [searchBrandText, setSearchBrandText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (activeSubMenu !== id || data.length <= 0) return <></>;
  return (
    <li className={'drawerBodyDropdownListSubBrands'}>
      <div className={'drawerBodyDropdownListSubBrandsInputContainer'} onClick={() => inputRef.current?.focus()}>
        <Icons.Search className={'drawerBodyDropdownListSubBrandsInputIcon'} />
        <input ref={inputRef} className={'drawerBodyDropdownListSubBrandsInput'} onChange={(e) => setSearchBrandText(e.target.value)} />
      </div>
      <div className={'drawerBodyDropdownListSubBrandsList'}>
        {data
          .filter((item) => item.name.toLowerCase().includes(searchBrandText.toLowerCase()))
          .map((item, index) => (
            <Link key={`${item.name}_${index}`} className={'drawerBodyDropdownListSubBrandsListItem'} href={`/${item.linked_category_handle}`} onClick={handleClearFilters}>
              {item.name}
            </Link>
          ))}
      </div>
    </li>
  );
};

export default DrawerBodyDropdownListSubBrandsList;

class WebWorker {
  constructor(worker: any) {
    const code = worker.toString();
    const blob = new Blob(['(' + code + ')()']);
    return new Worker(URL.createObjectURL(blob));
  }
}

// This file will be run by our web worker.
function workerThread() {
  self.addEventListener('message', (e) => {
    if (!e) {
      return;
    }
    // console.log('----------work thread------->>>>',e.data.api)
    fetch(e.data.api, {
      method: 'GET',
      // headers: { 'Authorization': 'Bearer ' + e.data.user_token }
    })
      .then((response) => {
        return response.json();
      })
      .then(function (data) {
        if (data.errors == undefined && data.success) {
          postMessage(data.system_settings.system_settings);
        }
      });
  });
}

export { WebWorker, workerThread };

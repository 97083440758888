import { Heading } from '@/components/heading';
import { Spinner } from '@/libraries/material-ui';
import { ContentItemDataType, getPageContentByHandle } from '@/services/content';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styles from './contact-section.module.scss';
import jsCookie from 'js-cookie';
interface IProps {
  contactData?: ContentItemDataType[];
}

type processedContactDataType = { timings?: ContentItemDataType; phone?: ContentItemDataType; instaID?: ContentItemDataType; email?: ContentItemDataType; textDetails?: ContentItemDataType };

const ContactSection: React.FC<IProps> = ({ contactData }) => {
  const [contact, setContact] = useState<{ isLoading: boolean; data: ContentItemDataType[]; processedData: processedContactDataType }>({ isLoading: true, data: [], processedData: {} });

  const handleSetContactData = (isLoading: boolean, data: ContentItemDataType[]) => {
    setContact({
      isLoading: isLoading,
      data: data,
      processedData: {
        timings: data.find((item) => item.title === 'timings'),
        phone: data.find((item) => item.title === 'phone'),
        instaID: data.find((item) => item.title === 'instagram_id'),
        email: data.find((item) => item.title === 'email_contact'),
        textDetails: data.find((item) => item.title === 'text_Details'),
      },
    });
  };

  const handleFetchContactData = async () => {
    const response = await getPageContentByHandle('contact-us', {});
    handleSetContactData(false, response && response.success ? response.content_list : []);
  };

  useEffect(() => {
    if (contactData === undefined || contactData === null) handleFetchContactData();
    else handleSetContactData(false, contactData);
  }, []);

  const openMsg = (info:any) => {
    let fromApp = jsCookie.get("fromMobileApp")
    if(fromApp != undefined) {
        if(typeof window != undefined && window != undefined) {
            window.flutter_inappwebview.callHandler('textHandler',{'success':true, 'body':"", 'subject':'', 'to':info})
        }
        return
    }
  }

  const openEmail = (info:any) => {
    let fromApp = jsCookie.get("fromMobileApp")
    if(fromApp != undefined) {
        if(typeof window != undefined && window != undefined) {
            window.flutter_inappwebview.callHandler('emailHandler',{'success':true, 'body':"", 'subject':'', 'to':info})
        }
        return
    }
  }

  return (
    <div className={styles.container}>
      {/* <Heading.Love title="CONTACT US" classes={{ container: styles.title }} /> */}
      <h1 className={styles.contact_header}>CONTACT US</h1>

      {contact.isLoading && <Spinner />}
      {!contact.isLoading && contact.data.length > 0 && (
        <div className={styles.contentContainer}>
          <h3 className={styles.contentTitle}>Ask us anything!</h3>

          {contact.processedData.textDetails && <p className={styles.contentText}>{contact.processedData.textDetails.detail}</p>}
          {contact.processedData.timings && <p className={styles.contentSubText}>{contact.processedData.timings.detail}</p>}

          <p className={styles.contentEmailText}>
            {contact.processedData.phone && (
              <span>
                Text:&nbsp;
                <Link onClick={()=>{ openMsg(`+1${contact.processedData.phone.detail}`.replace(/[- )(]/g, '')) }} href={`sms:+1${contact.processedData.phone.detail}`.replace(/[- )(]/g, '')} className={styles.link}>
                  {contact.processedData.phone.detail}
                </Link>
              </span>
            )}
            {contact.processedData.instaID && (
              <span>
                DM us:&nbsp;
                <Link href={'https://www.instagram.com/fashionpass/'} className={styles.link}>
                  {contact.processedData.instaID.detail}
                </Link>
              </span>
            )}
            {contact.processedData.email && (
              <span>
                Email:&nbsp;
                <Link onClick={()=>{ openEmail(contact.processedData.email.detail) }} href={`mailto:${contact.processedData.email.detail}`} className={styles.link}>
                  {contact.processedData.email.detail}
                </Link>
              </span>
            )}
          </p>

          <hr className={styles.divider} />

          <p className={styles.contentBottomText}>
            FashionPass is headquartered in Los Angeles, CA
          </p>
        </div>
      )}
    </div>
  );
};

export default ContactSection;
import { API, Plans, PlansDiscountedAmount, SystemSettings } from '@/constants/global-config';
import { Pages } from '@/constants/pages';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { Tooltip } from '@/libraries/material-ui';
import { Breakpoints } from '@/libraries/responsive';
import { GetDefaultPaymentMethod_ResponseType, PaymentMethodDataType } from '@/services/customer';
import { BTOA } from '@/utils/misc-methods';
import { MakeTextSingularOrPlural, TextExistsInString } from '@/utils/string-methods';
import AddedItemModal from 'components/shared/addedItemModal';
import EarlyCheckinModal from 'components/shared/earlyCheckinModal';
import { getCustomerDefaultPaymentMethod, getPlanNameAgianstSubscriptionName } from 'components/shared/globalFunctions';
import Link from 'next/link';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import styles from './try-now-btn.module.scss';
import 'styles/_fontsFamily.module.scss';
import jsCookie from 'js-cookie';

interface IProps { }

type buttonActions = 'addedModal' | 'earlyCheckin' | 'transfer_used' | '';

const TryNowBtn: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();

  const customerData = useAppSelector((state) => state.customer);
  const systemSettingsData = useAppSelector((state) => state.systemSettings);

  const [waitlistEnableDisableStatus, setWaitlistEnableDisableStatus] = useState(0);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethodDataType | null>(null);
  const [waitlistURL, setWaitlistURL] = useState(API.BASE_URL_WAITLIST_URL);
  const [buttonText, setButtonText] = useState('');
  const [buttonAction, setButtonAction] = useState<buttonActions>('');
  const [tooltipShow, setTooltipShow] = useState(false);
  const [topBarHoverText, setTopBarHoverText] = useState('');
  const [addedItemHover, setAddedItemHover] = useState('');

  const handleSystemSettingsUpdate = async () => {
    if (systemSettingsData.systemSettings.length === 0) return;

    let waitlistEnableDisable = 0;
    systemSettingsData.systemSettings.forEach((item) => {
      if (item !== null && item !== undefined && item.setting_id.toString() === SystemSettings.WAITLIST_ENABLE_DISABLE) waitlistEnableDisable = Number(item.setting_value);
    });

    setWaitlistEnableDisableStatus(waitlistEnableDisable);
  };

  const calculateTotalAdditionalItems = (checkingForThisTag: string) => {
    const additionalItemExists = customerData.customer !== 'not_login' ? customerData.customer.tag_list.find((item) => TextExistsInString(item, checkingForThisTag) === true) : '';
    return additionalItemExists ? Number((additionalItemExists.match(/\d+/) || [])[0]) : 0;
  };

  useEffect(() => {
    handleSystemSettingsUpdate();
  }, [systemSettingsData.systemSettings]);

  useEffect(() => {
    if (customerData.customer === 'not_login') return;
    dispatch(getCustomerDefaultPaymentMethod(false)).then((data: GetDefaultPaymentMethod_ResponseType) => {
      if (data && data.success === true) setDefaultPaymentMethod(data.pay_method);
    });
  }, [customerData.customer]);

  useEffect(() => {
    if (customerData.customer === 'not_login') return;

    let tempTooltipText = ''; // hoverText
    let tempCanShowTooltip = true;
    let isLastOrderAddedItemCount = 0;
    const customerTagstring = customerData.customer?.tag_list.join().toLowerCase() || ''; // userTagstring

    // ===================================== phase 1
    // let lastRentalOrderId = (customerData.customer != 'not_login' && customerData.customer.lastRentalOrder && customerData.customer.lastRentalOrder.orderid) ? customerData.customer.lastRentalOrder.orderid : -1;
    // let lastRentalOrderTransferCount = 0
    // let rentalOrderArr = customerData.customer != 'not_login' && customerData.customer.lastRentalOrderItems && customerData.customer.lastRentalOrderItems.length > 0 ? customerData.customer.lastRentalOrderItems : [];

    // rentalOrderArr.length > 0 && rentalOrderArr.map((item: any) => {
    //   if (item?.added == '1' && item?.tags.indexOf('transferitem') == -1) {
    //     isLastOrderAddedItemCount += 1;
    //   }
    // })

    // rentalOrderArr.length > 0 && rentalOrderArr.map((item: any, ind: any) => {
    //   if (lastRentalOrderId > -1 && item.tags != "" && item.tags.indexOf(`transferfrom:`) && ((item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) > -1 && !TextExistsInString(customerTagstring, 'currentlyborrowing')) || (item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) == -1 && TextExistsInString(customerTagstring, 'currentlyborrowing')))) lastRentalOrderTransferCount += 1
    // })

    // if (customerData.customer != 'not_login' && customerData.customer.has_subscription && (lastRentalOrderTransferCount + isLastOrderAddedItemCount) > 3 && TextExistsInString(customerTagstring, 'currentlyborrowing') && lastRentalOrderTransferCount > 0) {
    //   tempTooltipText = `You are allotted ${customerData.customer?.subscription_name == 'Gold' ? '1' : '2'} added item or transfer per order. Since you have used your 1 transfer please wait until your next order to add or transfer another item.`;
    //   setAddedItemHover('tooltipItemHover');
    // }
    // else if (TextExistsInString(customerTagstring, 'plan_name') && !TextExistsInString(customerTagstring, 'items') && !TextExistsInString(customerTagstring, 'earlycheckin') && TextExistsInString(customerTagstring, 'currentlyborrowing')) {
   
    // ===================================== phase 2
    // if (TextExistsInString(customerTagstring, 'plan_name') && !TextExistsInString(customerTagstring, 'items') && !TextExistsInString(customerTagstring, 'earlycheckin') && TextExistsInString(customerTagstring, 'currentlyborrowing')) {
    //   tempTooltipText = 'Need an additional item but not ready to send back your current order? Select your new item(s) for $19.95 each, and we will get it shipped out to you tomorrow with free standard shipping. Rush shipping options are available at checkout.';
    //   setAddedItemHover('tooltipItemHover');
    // }
    // else if (TextExistsInString(customerTagstring, 'upgradeitems') && TextExistsInString(customerTagstring, 'currentlyborrowing')) {
    
    
    if (TextExistsInString(customerTagstring, 'upgradeitems') && TextExistsInString(customerTagstring, 'currentlyborrowing')) {  
      const totalAdditionalItems = calculateTotalAdditionalItems('upgradeItems');
      tempTooltipText = totalAdditionalItems === 2 ? `You are all set to choose 1 extra clothing item and 1 extra accessory! it will be shipped out today with free standard USPS shipping. Rush shipping options are available at checkout.` : `You are all set to choose 2 extra clothing item and 2 extra accessory! it will be shipped out today with free standard USPS shipping. Rush shipping options are available at checkout.`;
    } else if (TextExistsInString(customerTagstring, 'items') && !TextExistsInString(customerTagstring, 'added items')) {
      const totalAdditionalItems = calculateTotalAdditionalItems('items');
      tempTooltipText = totalAdditionalItems === 2 ? `You are all set to choose ${totalAdditionalItems} extra items!` : `You are all set to choose ${totalAdditionalItems} extra item!`;
    } else if (TextExistsInString(customerTagstring, 'currentlyborrowing') && TextExistsInString(customerTagstring, 'earlycheckin')) {
      tempTooltipText = `Don't want to wait to choose your next items? Click to pay $9 for early check-in and pick your next items right away!`;
      setAddedItemHover('tooltipItemHover');
    }
    else tempCanShowTooltip = false;

    if (TextExistsInString(customerTagstring, 'authdeclined')) tempCanShowTooltip = false;

    setTopBarHoverText(tempTooltipText);
    setTooltipShow(tempCanShowTooltip);
  }, [customerData.customer, customerData.customer?.lastRentalOrderItems]);

  // DONT KNOW YET
  useEffect(() => {
    let tempWaitlistURL = API.BASE_URL_WAITLIST_URL;
    let tempButtonText = '';
    let tempButtonAction: buttonActions = '';
    let isLastOrderAddedItemCount = 0;

    // let lastRentalOrderId = (customerData.customer != 'not_login' && customerData.customer.lastRentalOrder && customerData.customer.lastRentalOrder.orderid) ? customerData.customer.lastRentalOrder.orderid : -1;
    // const lastOrderTags = (customerData.customer != 'not_login' && customerData.customer.lastRentalOrder && customerData.customer.lastRentalOrder.order_tags) ? customerData.customer.lastRentalOrder.order_tags : ""
    // let lastRentalOrderTransferCount = 0
    // let rentalOrderArr = customerData.customer != 'not_login' && customerData.customer.lastRentalOrderItems && customerData.customer.lastRentalOrderItems.length > 0 ? customerData.customer.lastRentalOrderItems : [];
    // const customerTagstring = customerData.customer === 'not_login' ? '' : customerData.customer.tag_list.join().toLowerCase();
    // rentalOrderArr.length > 0 && rentalOrderArr.map((item: any) => {
    //   if (item?.added == '1' && item?.tags.indexOf('transferitem') == -1) {
    //     isLastOrderAddedItemCount += 1;
    //   }
    // })
    // rentalOrderArr.length > 0 && rentalOrderArr.map((item: any, ind: any) => {
    //   if (lastRentalOrderId > -1 && item.tags != "" && item.tags.indexOf(`transferfrom:`) && ((item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) > -1 && !TextExistsInString(customerTagstring, 'currentlyborrowing')) || (item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) == -1 && TextExistsInString(customerTagstring, 'currentlyborrowing')))) lastRentalOrderTransferCount += 1
    // })

    if (customerData.customer === 'not_login' && waitlistEnableDisableStatus === 0) tempButtonText = `TRY FOR $59`;
    else if (customerData.customer === 'not_login' && waitlistEnableDisableStatus !== 0) tempButtonText = `TRY FOR $59`;
    else {
      const customerTagString = customerData.customer === 'not_login' ? '' : customerData.customer.tag_list.join().toLowerCase();

      if (TextExistsInString(customerTagString, 'authdeclined') && !TextExistsInString(customerTagString, 'paused')) tempButtonText = window.screen.width < Breakpoints.md ? 'BILLING' : 'update payment';
      // else if ((customerData.customer != 'not_login' && customerData.customer.has_subscription && (lastRentalOrderTransferCount + isLastOrderAddedItemCount) > 3 && TextExistsInString(customerTagstring, 'currentlyborrowing') && lastRentalOrderTransferCount > 0 )) {
      //   tempButtonText = 'TRANSFER USED';
      //   tempButtonAction = "transfer_used";
      // }
      else if (TextExistsInString(customerTagString, 'added items') && !TextExistsInString(customerTagString, 'earlycheckin')) tempButtonText = (customerData.customer !== 'not_login' && getPlanNameAgianstSubscriptionName(customerData.customer.subscription_name)) || '';
      // else if (TextExistsInString(customerTagString, 'plan_name') && !TextExistsInString(customerTagString, 'items') && !TextExistsInString(customerTagString, 'earlycheckin') && TextExistsInString(customerTagString, 'currentlyborrowing')) {
      //   tempButtonText = screen.width > 767 && screen.width < 1020 ? 'ADD ITEM' : 'ADD ITEM $19.95';
      //   tempButtonAction = 'addedModal';
      // } 
      else if (TextExistsInString(customerTagString, 'currentlyborrowing') && TextExistsInString(customerTagString, 'earlycheckin') && !TextExistsInString(customerTagString, 'plan_name:fashionpass')) {
        tempButtonText = 'EARLY CHECK-IN';
        tempButtonAction = 'earlyCheckin';
      } else if (TextExistsInString(customerTagString, 'upgradeitems')) {
        const totalAdditionalItems = calculateTotalAdditionalItems('upgradeItems');
        tempButtonText = MakeTextSingularOrPlural(`${totalAdditionalItems} item`, totalAdditionalItems);
      } else if (TextExistsInString(customerTagString, 'items')) {
        const totalAdditionalItems = calculateTotalAdditionalItems('items');
        tempButtonText = MakeTextSingularOrPlural(`${totalAdditionalItems} item`, totalAdditionalItems);
      } else if (!TextExistsInString(customerTagString, 'plan_name')) {
        tempButtonText = TextExistsInString(customerTagString, 'paused') && customerData.customer !== 'not_login' && customerData.customer.last_order_id > 0 ? 'REACTIVATE' : TextExistsInString(customerTagString, 'paused') && customerData.customer !== 'not_login' && customerData.customer.last_order_id <= 0 ? `TRY FOR $59` : `TRY FOR $59`;
        tempWaitlistURL = `${API.BASE_URL_WAITLIST_URL}?email=${BTOA(customerData.customer === 'not_login' ? '' : customerData.customer.email)}&loggedin=true`;
      } else tempButtonText = getPlanNameAgianstSubscriptionName(customerData.customer === 'not_login' ? '' : customerData.customer.subscription_name) || '';
    }

    // && ((customerData.customer?.subscription_name == 'Gold' && !TextExistsInString(customerTagString, 'transferitem')) || (customerData.customer?.subscription_name != 'Gold' && !TextExistsInString(customerTagString, 'transferitem2')))

    setWaitlistURL(tempWaitlistURL);
    setButtonText(tempButtonText);
    setButtonAction(tempButtonAction);
  }, [customerData.customer, customerData.customer?.tag_list, customerData.customer?.lastRentalOrderItems]);

  let setPrevUrlForSignup = () => {
    let url = location.pathname
    jsCookie.set('prevWebsiteUrl', url)
  }
  return (
    <Tooltip tooltip={tooltipShow ? topBarHoverText : ''} placement="bottom-end" classes={{ childContainer: 'try_now_btn_container', tooltip: 'try_now_btn_tooltip', tooltipText: 'try_now_btn_tooltipText' }}>
      {buttonAction === 'addedModal' ? (
        <AddedItemModal textButton={buttonText} setTooltip={setTooltipShow} defaultPaymentMethod={defaultPaymentMethod}  />

      ) : buttonAction === 'transfer_used' ? (
        <span className='transfer_used_btn_header' > {buttonText} </span>
      ) : buttonAction === 'earlyCheckin' ? (
        <EarlyCheckinModal textButton={buttonText} setTooltip={setTooltipShow} />
      ) : buttonText === 'TRY FOR $59' || buttonText === 'JOIN NOW' || buttonText === 'REACTIVATE' || buttonText === `TRY FOR $${Plans.PLAN_01.price - PlansDiscountedAmount}` ? (
        <Link href={waitlistURL || ''} onClick={() => { setPrevUrlForSignup() }} className="button-small added-item-btn addItem-btn-mobile button-black-small-try-now text-uppercase custom_font">
          {buttonText}
        </Link>
      ) : TextExistsInString(buttonText, 'item') ? (
        <Link href="#" id={['1 item', '2 items'].includes(buttonText) ? 'oneItem' : ''} className="button-small added-item-btn addItem-btn-mobile button-black-small text-uppercase">
          {buttonText}
        </Link>
      ) : ['update payment', 'BILLING'].includes(buttonText) ? (
        <Link href="#" className={['update payment', 'BILLING'].includes(buttonText) ? 'button-red-small button-black-small button-small added-item-btn addItem-btn-mobile text-uppercase' : ''} style={{ backgroundColor: ['update payment', 'BILLING'].includes(buttonText) ? 'red' : '' }} onClick={() => (['update payment', 'BILLING'].includes(buttonText) ? Router.push({ pathname: Pages.MY_ACCOUNT, query: { tab: 4 } }) : Router.push(Pages.HOW_IT_WORKS_SECTION.BENEFITS))}>
          {buttonText}
        </Link>
      ) : ''}
    </Tooltip>
  );
};

export default TryNowBtn;

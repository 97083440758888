import React, { useEffect, useState } from 'react'
import styles from '@/containers/cookie-popup/cookie-popup-index.module.scss';
import Link from 'next/link';
import jsCookie from 'js-cookie';
import { useAppDispatch,useAppSelector } from '@/hooks/redux-hooks';
import { updateCustomerTags } from 'components/shared/globalFunctions';
import { fetchCustomerSuccess } from '@/redux/actions/customerActions';

interface IProps { }

const cookiepopupindex: React.FC<IProps> = () => {
    const dispatch = useAppDispatch();
    const [showCookiePopup, setShowCookiePopup] = useState(false)
    const customerData = useAppSelector((state) => state.customer);
    const [loading, setLoading] = useState(false);
    let rejectCookiePopup = jsCookie.get('rejectCookiePopup');
    let notLoginCookieAccepted = jsCookie.get('notLoginCookieAccepted');


    useEffect(() => {
        if(customerData.customer != 'not_login'){
            if(notLoginCookieAccepted != undefined && customerData.customer.tag_list?.indexOf('cookie_popup_accepted') == -1 && loading == false){
                handleCookieAccepted();
            }else if(rejectCookiePopup == undefined && customerData.customer.tag_list?.indexOf('cookie_popup_accepted') == -1 ){
                setShowCookiePopup(true)
            }else{
                setShowCookiePopup(false)
            }
        }else {
            if(customerData.customer == 'not_login' && notLoginCookieAccepted == undefined && rejectCookiePopup == undefined){
                setShowCookiePopup(true)
            }else{
                setShowCookiePopup(false)
            }

        }
    }, [customerData])


    const handleCookieAccepted = () => {
        if(customerData.customer != 'not_login'){
            setLoading(true)
            updateCustomerTags('cookie_popup_accepted', '').then(response => response.json()).then(data => {
                if (data.success == true) {
                    setLoading(false)
                    setShowCookiePopup(false)
                    let customer = customerData.customer
                    customer.tag_list = data.tags;
                    dispatch(fetchCustomerSuccess(customer))
                    jsCookie.set('notLoginCookieAccepted', true)
                } else{
                    setLoading(false)
                }
            })
        }else{
            jsCookie.set('notLoginCookieAccepted', true)
            setShowCookiePopup(false)
        }
    }
    const handleRejectCookiePopup = () => {
        jsCookie.set('rejectCookiePopup', true, { expires: 1 });
        setShowCookiePopup(false)
    };

    return (

        showCookiePopup == true ?
        <div className={styles.cookie_container}>
             <i onClick={() => handleRejectCookiePopup()} className="fa fa-times" aria-hidden="true"></i>
            <div className={styles.cookie_sub_container}>
                <h1 className={styles.cookie_heading}>COOKIES & PRIVACY</h1>
                <p className={styles.cookie_text}>We value your privacy. We use cookies to provide and enhance our website and services. By using our site, you consent to our use of cookies.  <br /><Link href="/privacy-policy"><span className={styles.cookie_sub_text}>Privacy Policy.</span></Link></p>
            </div>
            <button className={styles.accept_cookie_button} disabled={loading} onClick={() => handleCookieAccepted()}>Accept Cookies</button>
        </div> : ''
    )

}



export default cookiepopupindex;
import { Icons } from '@/libraries/icons';
import { Popup } from '@/libraries/material-ui';
import React from 'react';
import PressSection from '../press-section/press-section';
import styles from './press-popup.module.scss';

interface IProps {
  isVisible: boolean;
  handleClosePopup: () => void;
}

const PressPopup: React.FC<IProps> = ({ isVisible, handleClosePopup }) => {
  return (
    <Popup size="md" isVisible={isVisible} handleClosePopup={handleClosePopup} className={styles.container}>
      <Icons.Cross className={styles.closeBtn} onClick={handleClosePopup} />
      <PressSection />
    </Popup>
  );
};

export default PressPopup;

import { GetDynamicKeyCookie, SetDynamicKeyCookie } from '@/libraries/browser-storage/functions/cookie';

export const GetDynamicCookie_NotificationKey = (key: string) => GetDynamicKeyCookie(key) as NotificationKeyCookieDataType;
export const SetDynamicCookie_NotificationKey = (key: string, val: NotificationKeyCookieDataType) => SetDynamicKeyCookie(key, val);
export type NotificationKeyCookieDataType = {
  message: string;
  isShown: boolean;
  isSubscribed: boolean;
  updated_at: number | undefined;
};

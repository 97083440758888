import { GrInstagram, GrSearch } from 'react-icons/gr';
import { BsFacebook, BsFillCloudArrowUpFill, BsPinterest, BsTwitter } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { AiFillHeart, AiOutlineHeart, AiFillCaretDown } from 'react-icons/ai';
import { IoMdUnlock } from 'react-icons/io';
import { BiChevronDown, BiChevronLeft, BiChevronRight, BiPencil, BiSearch } from 'react-icons/bi';
import { FaArrowRight, FaCaretLeft, FaCheck, FaCheckCircle, FaComment, FaInfoCircle, FaLock, FaRegGrinAlt, FaRegThumbsUp, FaThumbsUp, FaTrash, FaTiktok, FaCalendarAlt } from 'react-icons/fa';
import { TiStarFullOutline, TiStarHalfOutline, TiStarOutline } from 'react-icons/ti';

const Icons = {
  // Social Icons
  Facebook: BsFacebook,
  Instagram: GrInstagram,
  Pinterest: BsPinterest,
  Twitter: BsTwitter,
  Tiktok: FaTiktok,

  // Close Icon
  Close: IoCloseSharp,

  // Favorite Icon
  Favorite: AiOutlineHeart,
  FavoriteFilled: AiFillHeart,

  // Unlock
  Unlock: IoMdUnlock,
  Lock: FaLock,

  // DropDown & Nav Arrows
  DropDownArrow: BiChevronDown,
  ArrowPrev: BiChevronLeft,
  ArrowNext: BiChevronRight,
  FilledArrowLeft: FaCaretLeft,
  ArrowRight: FaArrowRight,
  AccordionArrow: AiFillCaretDown,

  // Rating Star
  FilledStar: TiStarFullOutline,
  HalfStar: TiStarHalfOutline,
  EmptyStar: TiStarOutline,

  // Zoom & Search Icon
  Search: GrSearch,
  Zoom: BiSearch,

  // Info Icon
  Info: FaInfoCircle,

  // Checkmark Icon
  Checkmark: FaCheck,
  CheckCircle: FaCheckCircle,

  // Like Icon
  FilledLike: FaThumbsUp,
  EmptyLike: FaRegThumbsUp,

  // Emoji Icon
  Emoji: FaRegGrinAlt,

  // Image Upload Icon
  ImageUpload: BsFillCloudArrowUpFill,

  // Memeber Rewards Text Code Icon
  TextCode: FaComment,

  // delete Icon
  Delete: FaTrash,

  // edit icon
  Edit: BiPencil,

  // calender
  Calender:FaCalendarAlt
};


export default Icons;

import { CookieKeys } from '@/constants/cookie-keys';
import { API, CollectionHandle } from '@/constants/global-config';
import { Pages } from '@/constants/pages';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { RemoveCookie, SetCookie } from '@/libraries/browser-storage';
import { Icons } from '@/libraries/icons';
import { filterQuerySubmit } from '@/redux/thunks/collectionPageThunks';
import { CategoryDataType } from '@/services/collections';
import { BrandDataType } from '@/services/product';
import Slide from '@mui/material/Slide';
import { getCustomerAddresses, getCustomerReferCode } from 'components/shared/customerDataFunctions';
import { getAccessories, getBrandsData, getOccasions, unSubscibeDeviceForMobile, searchTag } from 'components/shared/globalFunctions';
import Login from 'components/shared/login';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import DrawerBodyDropdownListItem from '@/containers/header/top-bar/drawer-menu/drawer-body-dropdown-list-item/drawer-body-dropdown-list-item';
import DrawerBodyDropdownListSubBrandsList from '@/containers/header/top-bar/drawer-menu/drawer-body-dropdown-list-sub-brands-list/drawer-body-dropdown-list-sub-brands-list';
import DrawerBodyDropdownListSubCategoriesList from '@/containers/header/top-bar/drawer-menu/drawer-body-dropdown-list-sub-categories-list/drawer-body-dropdown-list-sub-categories-list';
import DrawerBodyListItem from '@/containers/header/top-bar/drawer-menu/drawer-body-list-item/drawer-body-list-item';
import ContactPopup from '@/containers/contact/contact-popup/contact-popup';
import { GetCookie } from '@/libraries/browser-storage';
import jsCookie from 'js-cookie';
import CustomerOrderTracking from '@/containers/customer-order-tracking/customer-order-tracking-index';
interface IProps {
  navbarRef?: React.MutableRefObject<HTMLDivElement | null>;
}
declare global {
  interface Window {
    flutter_inappwebview: any;
  }
}
const drawerSlideDuration = 450;
export enum DrawerSubMenus {
  CLOTHING,
  ACCESSORIES,
  OCCASIONS,
  BRANDS,
}

const DrawerMenuIndex: React.FC<IProps> = ({ navbarRef }) => {
  const dispatch = useAppDispatch();

  const customerData = useAppSelector((state) => state.customer);
  const customerAddressesData = useAppSelector((state) => state.customerAddresses);
  const catsMobilemenuData = useAppSelector((state) => state.catsMobilemenu);
  const collectionPageData = useAppSelector((state) => state.collectionPage);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);

  const [coupon, setCoupon] = useState(''); // coupon
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [drawerDynamicStyle, setDrawerDynamicStyle] = useState<{ height: string; marginTop: string }>({ height: '100vh', marginTop: '0' });
  const [activeSubMenu, setActiveSubMenu] = useState<DrawerSubMenus | null>(null);
  const [clothing, setClothing] = useState<CategoryDataType[]>([]);
  const [accessories, setAccessories] = useState<CategoryDataType[]>([]); // Accessories
  const [occasions, setOccasions] = useState<CategoryDataType[]>([]); // Occasions
  const [brands, setBrands] = useState<BrandDataType[]>([]); // brands
  const [isContactPopupOpen, setIsContactPopupOpen] = useState(false);
  const [triggerLoginPopup, setTriggerLoginPopup] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isCustomerProfileNotFill, setIsCustomerProfileNotFill] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showSflNotification, setShowSflNotification] = useState('');

  const handleGetCustomerReferCode = () => {
    if (customerData.customer !== 'not_login' && customerData.customer.customer_id !== undefined) {
      dispatch(getCustomerReferCode()).then(() => {
        if (customerData.customer !== 'not_login') setCoupon(customerData.customer.customer_refer_code);
      });
    }
  };

  const handleLogoutCustomer = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    document.cookie = 'customer_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    RemoveCookie(CookieKeys.CUSTOMER_TOKEN, { path: '/', domain: location.hostname });
    SetCookie(CookieKeys.CUSTOMER_DATA, 'signOut');
    RemoveCookie(CookieKeys.LOGIN_POPUP_DISABLE);
    let fromApp = jsCookie.get('fromMobileApp')
    if (fromApp != undefined) {
      if (typeof window != undefined && window != undefined) {
        window.flutter_inappwebview.callHandler('logoutHandler', { 'success': true })
      }
    }
    if (GetCookie(CookieKeys.FCM_TOKEN)) unSubscibeDeviceForMobile(GetCookie(CookieKeys.FCM_TOKEN), customerData.customer.customer_id);
    RemoveCookie(CookieKeys.FCM_TOKEN);
    RemoveCookie(CookieKeys.IS_WEBGL_HASH_SUBMITTED);
    RemoveCookie(CookieKeys.FP_SIGNUP_LOGGEDIN_CUSTOMER);
    document.location.href = '/';
    handleToggleDrawer();
  };

  const handleToggleSubMenu = (target: DrawerSubMenus) => {
    if (activeSubMenu === target) setActiveSubMenu(null);
    else setActiveSubMenu(target);
  };

  const handleClearFilters = () => {
    handleToggleDrawer();
    if (Object.keys(collectionPageData.filterData.data).length != 0) {
      dispatch(filterQuerySubmit('clearAll', 'all'));
    }
  };

  const handleToggleDrawer = () => {
    if (isDrawerOpened) {
      setIsDrawerOpened(false);
      if (document.getElementById('prod-listing-header-mobile')) {
        document.getElementById('prod-listing-header-mobile').style.transitionDuration = '0.5s';
        document.getElementById('prod-listing-header-mobile').style.zIndex = 999;
      }
    }
    else {
      if (document.getElementById('prod-listing-header-mobile')) {
        document.getElementById('prod-listing-header-mobile').style.transitionDuration = 'unset';
        document.getElementById('prod-listing-header-mobile').style.zIndex = 999;
      }
      setIsDrawerOpened(true);
      if (navbarRef && navbarRef !== null) {
        const bounds = navbarRef?.current?.getBoundingClientRect();
        let topPinkBar = document.querySelector('#transitonScroll1')
        if(window && window.scrollY > topPinkBar?.offsetHeight){
          setDrawerDynamicStyle({ height: `calc(100vh - ${bounds?.top || 0}px)`, marginTop: `${bounds?.top || 0}px` });
        }else{
          setDrawerDynamicStyle({ height: `calc(100vh - ${(bounds?.top-((topPinkBar?.offsetHeight-window?.scrollY))) || 0}px)`, marginTop: `${(bounds?.top-(topPinkBar?.offsetHeight-window?.scrollY)) || 0}px` });
        }
      }
    }
  };

  const handleOpenPopup = (type: 'contact' | 'login') => {
    handleToggleDrawer();
    setTimeout(() => {
      if (type === 'contact') setIsContactPopupOpen(true);
      else if (type === 'login') setTriggerLoginPopup(true);
    }, drawerSlideDuration);
  };

  useEffect(() => {
    handleGetCustomerReferCode();

    getAccessories().then((response) => {
      const data = response.data.sub_categories || [];
      // data.push({ handle: '/shoes', name: 'Shoes' });
      if (customerData.customer === 'not_login') data.unshift({ handle: `/${CollectionHandle.ACCESSORIES}`, name: 'View All Accessories' });
      setAccessories(data);
    });

    getOccasions().then((response) => setOccasions(response.data || []));

    getBrandsData().then((response) => {
      const val: any[] = response ? response.brands : [];
      setBrands(
        val.sort((a, b) => {
          if (a.name !== null && b.name !== null) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          }
          return 0;
        })
      );
    });
  }, []);

  useEffect(() => {
    handleGetCustomerReferCode();
    dispatch(getCustomerAddresses());
  }, [customerData.customer]);

  useEffect(() => {
    if (customerData.customer != 'not_login') {
      let count = 0
      let tag_list = customerData.customer.tag_list
      const fetchedPrefs = customerPrefrence.customerPreferences;
      if (searchTag(tag_list, 'height') !== true && searchTag(tag_list, 'jeans size') !== true) {
        count += 1
        setIsCustomerProfileNotFill(true)
      } else {
        setIsCustomerProfileNotFill(false)
      }
      if (fetchedPrefs.preferences == null || (fetchedPrefs.preferences && (!fetchedPrefs.preferences.showNotificationPage || !fetchedPrefs.preferences.homePageNotification))) {
        if (fetchedPrefs.preferences == null || (!fetchedPrefs.preferences.showNotificationPage && !fetchedPrefs.preferences.homePageNotification)){
            count += 2
            setShowSflNotification('multiple')
        } else if (fetchedPrefs.preferences && (!fetchedPrefs.preferences.homePageNotification) || fetchedPrefs.preferences && (!fetchedPrefs.preferences.showNotificationPage)) {
            count += 1
            setShowSflNotification('single')
        }
        // setShowSflNotification(true)
      }else{
        setShowSflNotification('')
        // setShowSflNotification(false)
      }
      setNotificationCount(count)
    }
  }, [customerData,customerPrefrence.customerPreferences])

  useEffect(() => {
    setClothing(catsMobilemenuData.currentSubCats[0]?.sub_categories || []);
  }, [catsMobilemenuData.currentSubCats]);

  useEffect(() => {
    if (triggerLoginPopup) {
      if (isLoginPopupOpen) setIsLoginPopupOpen(false);
      else {
        setIsLoginPopupOpen(true);
        setTriggerLoginPopup(false);
      }
    }
  }, [triggerLoginPopup, isLoginPopupOpen]);

  let setPrevUrlForSignup = () => {
    let url = location.pathname
    jsCookie.set('prevWebsiteUrl', url)
  }

  return (
    <div className="drawer_menu_index_container">
      {isContactPopupOpen && <ContactPopup isVisible={isContactPopupOpen} handleClosePopup={() => setIsContactPopupOpen(false)} />}
      <Login openReviewPopup={false} isSignup isOpen={isLoginPopupOpen} />
      {
        isDrawerOpened ?
          <Icons.Cross onClick={handleToggleDrawer} size={20} className={'drawerHeaderCloseIcon'} />
          :
          <button className={`burgerMenuBtn ${isDrawerOpened && ''}`} onClick={handleToggleDrawer}>
            <span className={'line'} />
            <span className={'line'} />
            <span className={'line'} />
            {isDrawerOpened === false && (isCustomerProfileNotFill === true || showSflNotification != '') ? <div className='update_prof_Icon_mobile'><label>{notificationCount}</label></div> : ''}
          </button>

      }
      <Slide direction="right" in={isDrawerOpened} timeout={drawerSlideDuration} mountOnEnter unmountOnExit>
        <div className={'drawerContainer'} style={drawerDynamicStyle}>
          
          {/* <div className={'drawerHeader'} style={{ height: navbarRef?.current?.getBoundingClientRect()?.height || 0 }}>
            {
              showSflNotification != '' ?
              <h3 className={'drawerHeaderTitle drawerHeaderUpdtText'}>Hi {customerData.customer?.first_name}! Check out our <Link href={'/notification'}>feature announcements</Link>!</h3>
              :
              <>
              {isCustomerProfileNotFill ? <h3 className={'drawerHeaderTitle drawerHeaderUpdtText'}>Hi {customerData.customer?.first_name}! <Link href={'/my-account?tab=1'}>Update your profile</Link> to leave reviews!</h3> :
                <h3 className={'drawerHeaderTitle'}>{customerData.customer !== 'not_login' && customerData.customer.has_subscription ? `HI ${customerData.customer.first_name}!` : 'Shop Fashionpass'}</h3>}
              </>
            }
          </div> */}

          <div className={'drawerBody'}>
            <div className={'drawerHeader_Box'}>
              <CustomerOrderTracking handleToggleDrawer={handleToggleDrawer} />
            </div>
            <ul className={'drawerBodyList'}>
              {customerData.customer !== 'not_login' && customerData.customer.has_subscription && (
                <>
                  <DrawerBodyListItem title={'My Orders'} data={{ type: 'link', href: Pages.MY_ORDERS, handleToggleDrawer }} />
                  <DrawerBodyListItem
                    title={
                      <span>
                        My Code (<b className={'couponCode'}>{coupon}</b>)
                      </span>
                    }
                    data={{ type: 'link', href: Pages.MEMBER_REWARDS, handleToggleDrawer }}
                  />
                  {customerAddressesData.addresses.length > 0 && <DrawerBodyListItem title={'My FedEx Locations'} data={{ type: 'link', href: Pages.FEDEX, handleToggleDrawer }} />}
                  <DrawerBodyListItem title={'How it Works'} data={{ type: 'link', href: Pages.HOW_IT_WORKS, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'FAQs'} data={{ type: 'link', href: Pages.FAQ, handleToggleDrawer }} />
                  {/* <DrawerBodyListItem title={'Upgrade My Plan'} data={{ type: 'link', href: Pages.PLANS, handleToggleDrawer }} /> */}
                  <DrawerBodyListItem title={isCustomerProfileNotFill ? 'My Account (1)' : 'My Account'} data={{ type: 'link', href: Pages.MY_ACCOUNT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={showSflNotification == 'multiple' ? <span className={'notification_text'}>Notifications (2)</span> : showSflNotification == 'single' ? <span className={'notification_text'}>Notifications (1)</span> : 'Notifications'} data={{ type: 'link', href: Pages.NOTIFICATION, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'Contact Us'} data={{ type: 'link', href: Pages.CONTACT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'Sign Out'} data={{ type: 'button', onClick: handleLogoutCustomer }} />
                </>
              )}

              {customerData.customer !== 'not_login' && customerData.customer.has_subscription === false && (
                <>
                  <DrawerBodyDropdownListItem title={'Clothing'} id={DrawerSubMenus.CLOTHING} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={clothing} id={DrawerSubMenus.CLOTHING} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={[]} />

                  <DrawerBodyDropdownListItem title={'Accessories'} id={DrawerSubMenus.ACCESSORIES} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={accessories} id={DrawerSubMenus.ACCESSORIES} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={[]} />

                  <DrawerBodyDropdownListItem title={'Occasions'} id={DrawerSubMenus.OCCASIONS} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={occasions?.sub_categories} id={DrawerSubMenus.OCCASIONS} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={occasions?.headings} />

                  <DrawerBodyDropdownListItem title={'Brands'} id={DrawerSubMenus.BRANDS} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubBrandsList data={brands} id={DrawerSubMenus.BRANDS} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} />

                  <DrawerBodyListItem title={'My Orders'} data={{ type: 'link', href: Pages.MY_ORDERS, handleToggleDrawer }} />
                  {customerAddressesData.addresses.length > 0 && <DrawerBodyListItem title={'My FedEx Locations'} data={{ type: 'link', href: Pages.FEDEX, handleToggleDrawer }} />}
                  <DrawerBodyListItem title={isCustomerProfileNotFill ? 'My Account (1)' : 'My Account'} data={{ type: 'link', href: Pages.MY_ACCOUNT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={showSflNotification == 'multiple' ? 'Notifications (2)' : showSflNotification == 'single' ? 'Notifications (1)' : 'Notifications'} data={{ type: 'link', href: Pages.NOTIFICATION, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'Gift'} data={{ type: 'link', href: Pages.GIFT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'How it Works'} data={{ type: 'link', href: Pages.HOW_IT_WORKS, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'Contact Us'} data={{ type: 'link', href: Pages.CONTACT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={customerData.customer?.first_name ? `Sign Out (${customerData.customer.first_name})` : 'Sign Out'} data={{ type: 'button', onClick: handleLogoutCustomer }} />
                </>
              )}

              {customerData.customer === 'not_login' && (
                <>
                  <DrawerBodyDropdownListItem title={'Clothing'} id={DrawerSubMenus.CLOTHING} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={clothing} id={DrawerSubMenus.CLOTHING} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={[]} />

                  <DrawerBodyDropdownListItem title={'Accessories'} id={DrawerSubMenus.ACCESSORIES} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={accessories} id={DrawerSubMenus.ACCESSORIES} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={[]} />

                  <DrawerBodyDropdownListItem title={'Occasions'} id={DrawerSubMenus.OCCASIONS} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubCategoriesList data={occasions?.sub_categories} id={DrawerSubMenus.OCCASIONS} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} categoryHeadings={occasions?.headings} />

                  <DrawerBodyDropdownListItem title={'Brands'} id={DrawerSubMenus.BRANDS} activeSubMenu={activeSubMenu} handleToggleSubMenu={handleToggleSubMenu} />
                  <DrawerBodyDropdownListSubBrandsList data={brands} id={DrawerSubMenus.BRANDS} activeSubMenu={activeSubMenu} handleClearFilters={handleClearFilters} />

                  <DrawerBodyListItem title={'Gift'} data={{ type: 'link', href: Pages.GIFT, handleToggleDrawer }} />
                  <DrawerBodyListItem title={'Contact Us'} data={{ type: 'button', onClick: () => handleOpenPopup('contact') }} />

                  <li className={'btnsContainer'}>
                    <button onClick={() => handleOpenPopup('login')} className={'loginBtn'}>
                      Log in
                    </button>
                    {/* <Link href={API.BASE_URL_WAITLIST_URL || ''} className={'createProfileBtn'} onClick={() => { setPrevUrlForSignup() }}>
                      Create a Profile
                    </Link> */}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default DrawerMenuIndex;

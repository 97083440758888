import React from 'react';
// import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addedItemProcess, getCustomerTags, calculateAmountForPlanUpdate, getPlanNameAgianstSubscriptionName, gtUpgradablePlan, getPlanData, getSubscriptionId, getTaxForOrderWithEmail } from 'components/shared/globalFunctions';
import ChangePlanPopup from 'components/shared/changePlanPopup';
import { connect } from 'react-redux';
import Router from 'next/router';
import moment from 'moment'
import Gconfig from "globalconfig"
import {getTaxOnCheckout} from "components/shared/checkoutFunctions";

let isTax = 0
const AddedItemModal = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1);
  const [addedItemSetting, setAddedItemSetting] = useState(null);
  const [chargeForEach, setChargeForEach] = useState('');
  // const [paymentMethod, setPaymentMethod] = useState();
  const [paymentError, setpaymentError] = useState('');
  const [understandDefaultCard, setUnderstandDefaultCard] = useState(false);
  const [agreeFashionPass, setAgreeFashionPass] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [planModal, setPlanModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [gold, setGold] = useState(false);
  const [platinum, setPlatinum] = useState(false);
  const [wanderlust, setWanderlust] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState('');
  const [subscriptionName, setSubscriptionName] = useState('');
  const [isTaxShow, setIsTaxShow] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [isItemTransfered, setIsItemTransfered] = useState(null);
  const [isEligible, setIsEligible] = useState(true);
  
  const toggle = () => {
    setModal(!modal)
    setSelectedItem(1)
    setAddedItemSetting(null)
    setpaymentError('')
    props.setTooltip(false)
    if(modal == false){
      isTax = 0
      setIsTaxShow(false)
      setIsBilling(false)
      setDefaultAddress(null)
    }
  };
  let buyAddedItem = async () => {
    await setButtonDisable(true)
    setpaymentError('')
    if (agreeFashionPass && understandDefaultCard) {
      props._getCustomerTags(props.customer.customer.customer_id).then(() => {
        let customerTag = props.customer.customer.tag_list.join().toLowerCase();
        let obj = { email: props.customer.customer.email, quantity: selectedItem,amount:addedItemSetting.amount,stripe_meta:addedItemSetting.stripe_meta, customerId: props.customer.customer.customer_id};
        let tagAdd, tagRemove = '';
        if (customerTag.indexOf('currentlyborrowing') > -1) {
          tagAdd = selectedItem + 'items'
        } else {
          tagAdd = 'AdditionalItem' + selectedItem
        }
        props._addedItemProcess(obj, tagAdd, tagRemove,toggle,isBilling,defaultAddress, props.from).then(response => {
          // console.log(response)
          if(response.status == 0){
            setpaymentError(response.errors)
          }
          setButtonDisable(false)
        })
        
      })
    } else {
      setButtonDisable(false)
      setpaymentError('Please tick the box')
      return
    }


  }
  const itemQuantity = (event) => {
    setSelectedItem(event.target.value)
    let addedItemSettings = null
    let addedCount = event.target.value
    if(addedCount == 1)
    addedCount = Gconfig.system_settings.addedItem
    else
    addedCount = Gconfig.system_settings.addedItem2
    if(props.systemSettings.length > 0){
        addedItemSettings = props.systemSettings.filter(setting => setting.setting_id == addedCount)[0];
        if(addedItemSettings){setAddedItemSetting(JSON.parse(addedItemSettings.setting_value))}
    }
  }
  const cardAgree = (event) => {
    setUnderstandDefaultCard(!understandDefaultCard)
  }
  const agreeFashionpass = (event) => {
    setAgreeFashionPass((e) => !agreeFashionPass)
  }
  const changePlanToggle = async (e, isCloseOnly) => {
    if(props.from != "cart") {setModal(false)}
    
    if (isCloseOnly == true) {
        setPlanModal(!planModal)
        return
    }
    let subscriptionId = await getSubscriptionId(props.customer.customer.stripe_id)
    setSubscriptionId(subscriptionId)
    setPlanModal(true)
    // if(props.from == "cart") {
    //   setTimeout(()=>{
    //     setModal(false)
    //     props.setTooltip(false)
    //   },3000)
    // }
  }
  
  const refresh = () => {
    Router.push({ pathname: '/my-account', query: { tab: 6, upgrade: true } })
  }
  useEffect(() => {

    if(props.from == "cart") {
      setModal(true)
    }
  
    let addedItemSettings = null
    if(props.systemSettings.length > 0 && addedItemSetting == null){
        addedItemSettings = props.systemSettings.filter(setting => setting.setting_id == Gconfig.system_settings.addedItem)[0];
        if(addedItemSettings){
          setAddedItemSetting(JSON.parse(addedItemSettings.setting_value));
          setChargeForEach(JSON.parse(addedItemSettings.setting_value).amount)    
        }
      }

      let subscription_end_date = props.customer.customer.subscription_end_date
      setNextBillingDate(moment.utc(subscription_end_date).format('M/D/YYYY'))
      let subscriptionName = props.customer.customer.subscription_name
      setSubscriptionName(subscriptionName)
      setGold(false)
      setPlatinum(false)
      setWanderlust(false)

      let customerTag = props.customer.customer.tag_list.join().toLowerCase()
      // let lastRentalOrderId = (props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrder && props.customer.customer.lastRentalOrder.orderid) ? props.customer.customer.lastRentalOrder.orderid : -1 ;
      // let lastRentalOrderTransferCount = 0
      // let isLastOrderAddedItemCount = 0;
      // let rentalOrderArr = props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrderItems && props.customer.customer.lastRentalOrderItems.length > 0 ? props.customer.customer.lastRentalOrderItems : [];
      // rentalOrderArr.length > 0 && rentalOrderArr.map((item, ind) => {
      //    if(lastRentalOrderId > -1 && item.tags != "" && item.tags.indexOf(`transferfrom:`) && ((item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) > -1 && customerTag.indexOf('currentlyborrowing') == -1 ) || (item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) == -1 && customerTag.indexOf('currentlyborrowing') > -1))) lastRentalOrderTransferCount += 1
      // })
      // rentalOrderArr.length > 0 && rentalOrderArr.map((item)=>{
      //   if(item?.added == '1' && item?.tags.indexOf('transferitem') == -1){
      //     isLastOrderAddedItemCount += 1;
      //   }
      // })
      // if (props.customer.customer != "not_login" && props.customer.customer.has_subscription && (lastRentalOrderTransferCount+isLastOrderAddedItemCount) == 3 && customerTag.indexOf('currentlyborrowing') > -1) setIsItemTransfered(true);
      // else setIsItemTransfered(false);


      let plan_data = props.systemSettings.find(function (sett) { return (typeof sett != undefined && sett != undefined && sett.setting_id == Gconfig.fp_plans)});
      if(plan_data && plan_data.setting_value) {
          let plan = JSON.parse(plan_data.setting_value)
          if (plan.socialite && subscriptionName == plan.socialite.planName) {
              setGold(true)
          }
          if (plan.trendsetter && subscriptionName == plan.trendsetter.planName) {
              setPlatinum(true)
          }
          if (plan.wanderlust && subscriptionName == plan.wanderlust.planName) {
              setWanderlust(true)
          }
          // if (subscriptionName == Gconfig.plan[0].planName) {
          //     setGold(true)
          // }
          // if (subscriptionName == Gconfig.plan[1].planName) {
          //     setPlatinum(true)
          // }
          // if (subscriptionName == Gconfig.plan[2].planName) {
          //     setWanderlust(true)
          // }

        
          // if ((plan.socialite && subscriptionName == plan.socialite.planName && customerTag.indexOf('transferitem1') > -1 ) || (plan.socialite && subscriptionName != plan.socialite.planName && customerTag.indexOf('transferitem2') > -1)) setIsEligible(false);
          // if (props.customer.customer != "not_login" && props.customer.customer.has_subscription && (lastRentalOrderTransferCount+isLastOrderAddedItemCount) == 4 && customerTag.indexOf('currentlyborrowing') > -1 ) setIsEligible(false);
          // else setIsEligible(true);
      }  
  });

  useEffect(()=>{
    // let lastRentalOrderId = (props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrder && props.customer.customer.lastRentalOrder.orderid) ? props.customer.customer.lastRentalOrder.orderid : -1 ;
    //   const lastOrderTags = (props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrder && props.customer.customer.lastRentalOrder.order_tags) ? props.customer.customer.lastRentalOrder.order_tags : ""
    //   let customerTag = props.customer.customer.tag_list.join().toLowerCase()
    //   let lastRentalOrderTransferCount = 0
    //   let isLastOrderAddedItemCount = 0;
    //   let rentalOrderArr = props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrderItems && props.customer.customer.lastRentalOrderItems.length > 0 ? props.customer.customer.lastRentalOrderItems : [];
    //   rentalOrderArr.length > 0 && rentalOrderArr.map((item, ind) => {
    //     if(lastRentalOrderId > -1 && item.tags != "" && item.tags.indexOf(`transferfrom:`) && ((item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) > -1 && customerTag.indexOf('currentlyborrowing') == -1 ) || (item.tags.indexOf(`transferfrom:${lastRentalOrderId}`) == -1 && customerTag.indexOf('currentlyborrowing') > -1))) lastRentalOrderTransferCount += 1
    //   })
    //   rentalOrderArr.length > 0 && rentalOrderArr.map((item)=>{
    //     if(item?.added == '1' && item?.tags.indexOf('transferitem') == -1){
    //       isLastOrderAddedItemCount += 1;
    //     }
    //   })
      // if ((isLastOrderAddedItemCount+lastRentalOrderTransferCount) == 3) setIsItemTransfered(true);
      // else setIsItemTransfered(false);


      let plan_data = props.systemSettings.find(function (sett) { return (typeof sett != undefined && sett != undefined && sett.setting_id == Gconfig.fp_plans)});
      if(plan_data && plan_data.setting_value) {
          let plan = JSON.parse(plan_data.setting_value)
          // if ((plan.socialite && subscriptionName == plan.socialite.planName && customerTag.indexOf('transferitem1') > -1 ) || (plan.socialite && subscriptionName != plan.socialite.planName && customerTag.indexOf('transferitem2') > -1)) setIsEligible(false);
          // if (props.customer.customer.has_subscription && (lastRentalOrderTransferCount+isLastOrderAddedItemCount) == 4 && customerTag.indexOf('currentlyborrowing') > -1)  setIsEligible(false);
          // else setIsEligible(true);
      }
  },[props.customer.customer != 'not_login' && props.customer.customer.lastRentalOrderItems])

  const handleAsyncComponentDidMount = async () => {
    if (modal == true && isTax == 0) {
      if(addedItemSetting != null){
      isTax = 1
      if (props.customerAddresses.addresses.length > 0) {
        let defaultAddress = props.customerAddresses.addresses.find(function (address) {
          if (address.default_address == true && address.is_shipping == true)
            return address
            // console.log('Default Address----- ', defaultAddress)
        })
        if (defaultAddress == undefined) {
          setIsBilling(true)
          defaultAddress = props.customerAddresses.addresses.find(function (address) {
            if (address.default_address == true && address.is_shipping == false)
              return address
            // console.log('Default Address----- ', defaultAddress)
          })
          await setDefaultAddress(defaultAddress)
          let timeStamp = moment.unix(Date.now()).format('x')
          // console.log('timeStamp', timeStamp)
          let line_items = [{
            "id": timeStamp,
            "name":"Added Items",
            "quantity": 1,
            "product_tax_code": 20010,
            "unit_price": addedItemSetting.amount
          }]
          let taxObj = await getTaxOnCheckout(defaultAddress,addedItemSetting.amount,line_items,0)
          // console.log('taxoooooasd----------- ',taxObj)
          if(taxObj.tax == true){
            await setIsTaxShow(true)
          }
        } else {
          let getTax = await getTaxForOrderWithEmail(addedItemSetting.amount, props.customer.customer.email)
          if (getTax != null || getTax != undefined) {
            if (getTax.amount_to_collect > 0) {
              await setIsTaxShow(true)
            }
          }
        }
      } else {
        let getTax = await getTaxForOrderWithEmail(addedItemSetting.amount, props.customer.customer.email)
        if (getTax != null || getTax != undefined) {
          if (getTax.amount_to_collect > 0) {
            await setIsTaxShow(true)
          }
        }
      }
    }
    }
  }

  useEffect(() => {
    handleAsyncComponentDidMount();
  });
  let amountCharged = calculateAmountForPlanUpdate(props.customer.customer, props.systemSettings)
  let currentPlan = getPlanNameAgianstSubscriptionName(props.customer.customer.subscription_name)
  let upgradePlan = gtUpgradablePlan(currentPlan ? currentPlan.toUpperCase() : "")
  let currentPlanData = getPlanData( currentPlan ? currentPlan.toUpperCase() : "", props.systemSettings)
  let upgradePlanData = getPlanData(upgradePlan, props.systemSettings)
  return (
    <React.Fragment>
      {props.defaultPaymentMethod != null && addedItemSetting != null ?
        <div>
          {props.from != "cart" ? <div id="addemItemBtn" onMouseEnter={()=>{props.setTooltip(true)}} onMouseLeave={()=>{props.setTooltip(false)}} className="button-small addItem-btn-mobile button-black-small-try-now" onClick={toggle}>{props.textButton}</div> : "" }
          <Modal isOpen={modal} toggle={ props.from == 'cart' ? '': toggle} centered className="basicModalLayout modaladdeditem ">
            <ModalHeader toggle={toggle} charCode="" >Add items for ${chargeForEach} each</ModalHeader>
            <ModalBody>
              {paymentError ? <div className="errorMessage">{paymentError}</div> : ''}
              {paymentError.indexOf('declined')>-1 ? <div className="errorMessage">Please update your <span style={{textDecoration:'underline'}} onClick={() => { Router.push({ pathname: '/my-account', query: { tab: 4 } })}}>billing info here</span> and try again!</div> : ''}
              <p>Need an additional item but not ready to send back your current order? Select your new item(s) for ${chargeForEach} each, and we will get it shipped out to you <span className="addedItemDay">today</span> with free standard USPS shipping. Rush shipping options are available at checkout.</p>
              <p className="mt-3 mb-3 mt-md-2 mb-md-2" >I want to select <select className="itemSelect mr-1 ml-1" onChange={itemQuantity}><option value="1">1</option>  <option value="2">2</option> </select> <span className="addedItems">{selectedItem > 1?'items':'item'}</span> for ${chargeForEach} each.</p>
              {props.defaultPaymentMethod ? <p className="d-flex"><input className="mt-1 mr-0 mr-md-1 w-auto h-auto inputchk" type="checkbox" onChange={cardAgree} id="understandAddinionalItem" /><label className="checkinputlabel" style={{flex: '1'}}> I understand that my default card on file (<span id="card_brand_addItem">{props.defaultPaymentMethod.brand}</span> ending in <span id="card_last4_addItem">{props.defaultPaymentMethod.last4}</span> - <span id="card_exp_date_addItem">{props.defaultPaymentMethod.expiry.match(/.{1,2}/g)[0]}/20{props.defaultPaymentMethod.expiry.match(/.{1,2}/g)[1]}</span>) will be charged <span className="addedItemCharge" data-value={addedItemSetting.amount}>${(addedItemSetting.amount).toFixed(2)}</span>{isTaxShow ? ' + tax' : ''} for <span className="addedItemsCount">{selectedItem}</span> additional <span className="addedItems">{selectedItem > 1?'items':'item'}</span>.</label></p> : ''}
              <p className="d-flex" ><input className="w-auto h-auto mt-1 mr-0 mr-md-1 inputchk" type="checkbox" onChange={agreeFashionpass} id="agreeAddinionalItem" /> <label className="checkinputlabel mb-1">I agree to FashionPass's <a target="_blank" className="change_hover" href="/terms-of-service" style={{ textDecoration: 'underline' }}>Terms of Service.</a></label></p>
            </ModalBody>
            <ModalFooter className="pb-2">
             { buttonDisable == false && <button onClick={buyAddedItem}  className="chooseitem">Choose my items</button> } 
             { buttonDisable == true  &&  <div className="loadaddeditem" > <img src={Gconfig.fashionpassImageStorage+"assets/img/new_loading_img_login.gif?profile=a"} width="30px" height="30px" />  </div>}
            </ModalFooter>
            { subscriptionName != Gconfig.plan[2].planName &&
              <>
              <p className="text-center"><b className='upgadeText_bold'>OR</b></p>
              <p className="text-center mb-0 upgadeText">Upgrade your account now for <b>JUST ${amountCharged < 0 ? '0' : amountCharged}</b> and get</p>
              <p className="text-center mb-0 upgadeText"><b>1 additional clothing item + 1 additional accessory</b></p>
              <p className="text-center upgadeText">now and in every order for the rest of the month.</p>
              <button className="addedUpgrade mb-4" onClick={(event)=> changePlanToggle(event, false)}>UPGRADE FOR ${amountCharged < 0 ? '0' : amountCharged}</button>
              </>  
            }
              </Modal>
        </div>
        : ''
      }
      { planModal && <ChangePlanPopup isOpen={planModal} toggle={changePlanToggle.bind(this, false)} upgrade={true} Gold={gold} Platinum={platinum} Wanderlust={wanderlust} nextBillingDate={nextBillingDate} selectedPlan={upgradePlanData.planName} memberPoints={props.customer.customer.customer_points} customer={props.customer.customer} subscriptionId={subscriptionId} refresh={refresh} />}
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    customerAddresses:state.customerAddresses,
    systemSettings: state.systemSettings.systemSettings
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    _addedItemProcess: (obj, tagAdd, tagRemove,toggle,isBilling,address,from) => dispatch(addedItemProcess(obj, tagAdd, tagRemove,toggle,isBilling,address,from)),
    _getCustomerTags: (customer_id) => dispatch(getCustomerTags(customer_id))
  }
};
// export default AddedItemModal;
export default connect(mapStateToProps, mapDispatchToProps)(AddedItemModal);
import { LocalStorageKeys } from '@/constants/local-storage-keys';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { RemoveFromLocalStorage } from '@/libraries/browser-storage';
import { useResponsive } from '@/libraries/responsive';
import { globalRedirectionNavigation } from '@/redux/thunks/collectionPageThunks';
import { Category_SubCategoryDataType } from '@/services/collections';
import { preventAllEvents } from '@/utils/event-methods';
import { ScrollToTop } from '@/utils/window-methods';
import { Fade } from '@mui/material';
import React from 'react';
import NavBarDropdownDesktopMenu from '@/containers/header/nav-bar/nav-bar-dropdown-menu/nav-bar-dropdown-desktop-menu/nav-bar-dropdown-desktop-menu';
import NavBarDropdownMobileMenu from '@/containers/header/nav-bar/nav-bar-dropdown-menu/nav-bar-dropdown-mobile-menu/nav-bar-dropdown-mobile-menu';
import styles from './nav-bar-dropdown-menu-index.module.scss';
import { closeSubCategoryMenu } from 'components/shared/globalFunctions';

interface IProps {
  subCategory: Category_SubCategoryDataType;
  setVisibleDropdownIndex: any;
}

const NavBarDropdownMenuIndex: React.FC<IProps> = ({ subCategory, setVisibleDropdownIndex }) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  const customerData = useAppSelector((state) => state.customer);
  const collectionPageData = useAppSelector((state) => state.collectionPage);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);
  const notificationData = useAppSelector((state) => state.notification);

  const handleRedirection = (e: React.MouseEvent<HTMLElement>, linkedCategoryHandle: string | null | undefined) => {
    if (linkedCategoryHandle === null || linkedCategoryHandle === undefined) return;
    RemoveFromLocalStorage(LocalStorageKeys.IS_PRODUCT_PAGE);
    preventAllEvents(e);
    // ScrollToTop();
    dispatch(globalRedirectionNavigation(linkedCategoryHandle, true, collectionPageData.filterData.data, isTablet ? true : false));
    setTimeout(() => {
      setVisibleDropdownIndex('none');
      localStorage.removeItem('navMenuHoverStart')
    }, 150)
    if (isTablet == true) {
      closeSubCategoryMenu()
    }
    // DESKTOP - this.closeMenu();
    // MOBILE - this.props.closeMenu();
  };

  // console.log('subCategory', subCategory);

  return (
    <Fade in={true} timeout={400}>
      <div className={`${subCategory.category_link == "how-it-works" ? 'nbdm_container_hiw nbdm_container' : 'nbdm_container'} ${isTablet && 'nbdm_mobile_container'}`}>
        {isTablet && <NavBarDropdownMobileMenu subCategory={subCategory} collectionPageData={collectionPageData} handleRedirection={handleRedirection} />}
        {!isTablet && (
          <div className="container d-flex">
            <NavBarDropdownDesktopMenu subCategory={subCategory} handleRedirection={handleRedirection} />
          </div>
        )}
      </div>
    </Fade>
  );
};

export default NavBarDropdownMenuIndex;

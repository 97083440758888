import { CustomerSummaryDataType } from '@/services/customer';
import { DoesWindowExists } from '@/utils/window-methods';
import { formatDate, formatTime } from '@/libraries/date-time';

const subscriptionNames: Record<string, string> = {
  Platinum: 'Trendsetter',
  Gold: 'Socialite',
  Wanderlust: 'Wanderlust',
};

const doesLiveChatWidgetExists = Boolean(DoesWindowExists() && typeof window.LiveChatWidget !== undefined && window.LiveChatWidget !== undefined);

export const handleSetupLiveChatWidget = (customerInfo: CustomerSummaryDataType) => {
  if (!doesLiveChatWidgetExists) return;

  window.LiveChatWidget.call('set_customer_name', `${customerInfo.first_name} ${customerInfo.last_name}`);
  window.LiveChatWidget.call('set_customer_email', customerInfo.email);
  window.LiveChatWidget.call('set_session_variables', {
    lastorderID: customerInfo.last_order_id !== 0 ? customerInfo.last_order_id + 1000 : 0,
    Subscription_End_date: customerInfo.subscription_end_date === null ? null : `${formatDate(customerInfo.subscription_end_date, 'dddd m/dd/yy')} at ${formatTime(customerInfo.subscription_end_date, 'hh:MMtt')}`,
    Subscription: customerInfo.subscription_end_date === null ? undefined : subscriptionNames[customerInfo.subscription_name || ''],
    Tags: customerInfo.tag_list.join(),
  });
};

import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/hooks/redux-hooks';
import styles from './customer-order-tracking-index.module.scss';
import Gconfig from 'globalconfig';
import MediaQuery from 'react-responsive';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { checkForShipment, getSubscribedLastOrderInfo } from 'components/shared/globalFunctions';
import Link from 'next/link';
import { lastRentalReturnOrder } from '@/redux/actions/lastRentalReturnOrderActions'
import jsCookie from 'js-cookie';
import momentTimeZone from 'moment-timezone'
interface IProps { 
    fromMyOrder: boolean,
    orderCount: number,
    toggleDrawer:(value: boolean, value1: boolean) => any,
    handleToggleDrawer: () => void,
 }

let isFetchingLastOrderInfo = false
const CustomerOrderTracking: React.FC<IProps> = ({fromMyOrder, orderCount, toggleDrawer, handleToggleDrawer}) => {
  
    const customerData = useAppSelector((state) => state.customer);
    const lastReturnOrder = useAppSelector((state) => state.lastRentalReturnOrder);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [rentalOrderCountThisMonth, setRentalOrderCountThisMonth] = useState(0)
    const [isShowActualBillingDate, setIsShowActualBillingDate] = useState(false)
    const [signUrl, setSignUrl] = useState('/signup')
    const [lastRentalOrderUrl, setLastRentalOrderUrl] = useState('');
    const [isOrderOutgoingProgress, setIsOrderOutgoingProgress] = useState(false);
    
    const dispatch = useAppDispatch();
    const loggedInUserName = useMemo(() => {
    let val = '';
    if (customerData.customer !== 'not_login') {
        val += 'Hi, ';
        val += customerData.customer.first_name || 'User';
        val += !customerData.customer?.has_subscription ? '!' : '';
    }
    return val;
    }, [customerData.customer]);

    let orderReturnLabel = (order: any) => {
        let _lastOrder = order;
        let _setThis = {
            lastOrderReturnURL: '',
        }
        let shipmentTracking= null
        let addedShipmentTracking= null
        let check = false
        if(_lastOrder?.shipmentLabelsTracking?.length > 0)
            shipmentTracking = _lastOrder?.shipmentLabelsTracking[0]
        if(_lastOrder?.shipmentLabelsTrackingExtra?.length > 0){
            _lastOrder?.shipmentLabelsTrackingExtra.reverse()?.map((item, index)=>{
                if(item?.sent_tracking_num != "" && check == false){
                    addedShipmentTracking = item
                    check = true
                }
            })
        }

        if(addedShipmentTracking != null && addedShipmentTracking?.printreturn != '' && addedShipmentTracking?.return_progress != 'delivered'){
            _setThis.lastOrderReturnURL = addedShipmentTracking?.printreturn;
            if(addedShipmentTracking?.outgoing_progress == 'delivered') setIsOrderOutgoingProgress(true)
        }else if(shipmentTracking != null && shipmentTracking?.printreturn != '' && shipmentTracking?.return_progress != 'delivered'){
            _setThis.lastOrderReturnURL = shipmentTracking?.printreturn
            if(shipmentTracking?.outgoing_progress == 'delivered') setIsOrderOutgoingProgress(true)
        }
        setLastRentalOrderUrl(_setThis.lastOrderReturnURL)
    }

    useEffect(() => {
        const getLastOrderTracking = async () => { 
            if(lastReturnOrder?.result?.orderid != customerData?.customer?.lastRentalOrder?.orderid && isFetchingLastOrderInfo == false) {
                isFetchingLastOrderInfo = true
                let ordersData = await getSubscribedLastOrderInfo(customerData?.customer?.customer_id)
                if(ordersData?.success) {
                    await dispatch(lastRentalReturnOrder(ordersData))
                    orderReturnLabel(ordersData?.result)
                    isFetchingLastOrderInfo = false
                }
            }
            else {
                orderReturnLabel(lastReturnOrder?.result)
            }
        }
        getLastOrderTracking(); 
        if(customerData.customer != 'not_login' && customerData.customer?.has_subscription == true && customerData.customer.tag_list?.join()?.toLowerCase()?.indexOf("currentlyborrowing") > -1 && jsCookie.get("isCheckReturnStatus") == undefined) {
            if(lastReturnOrder?.result) {
                let _lastRentalOrderTag = lastReturnOrder?.result?.order_tags ?  lastReturnOrder?.result?.order_tags : "";
                if(_lastRentalOrderTag) {
                    let orderTags = _lastRentalOrderTag? _lastRentalOrderTag.split(",") : []
                    let return_status_date_arr = orderTags?.length > 0 ? orderTags?.filter((x) => x?.includes("return_status_date")) : []
                    let extractingReturnStatusTimeFromArray = return_status_date_arr?.length > 0 ? return_status_date_arr[return_status_date_arr.length - 1] : "";
                    let extractingReturnStatusTime = extractingReturnStatusTimeFromArray ? extractingReturnStatusTimeFromArray?.split("return_status_date:")[1] : ''
                    
                    if(extractingReturnStatusTime) {
                        const pstTimeZone = "America/Los_Angeles";
                        const convertOrderTagsDateIntoLocalTime = moment.tz(extractingReturnStatusTime, "MM/DD/YYYY hh:mm A", pstTimeZone).local();
                        const convertOrderTagsDateIntoTimestamp = convertOrderTagsDateIntoLocalTime.unix();
                        let currentTime = moment().unix();
                        let timeDifference = currentTime - convertOrderTagsDateIntoTimestamp;
                        if (timeDifference < 300) {
                            let remainingTimeInMilliseconds = 300 - timeDifference;
                            let remainingTimeInMinutes = Math.ceil(remainingTimeInMilliseconds / 60);
                            jsCookie.set("isCheckReturnStatus", "true" ,{ expires: remainingTimeInMinutes / 1440 })
                        }
                    }
                }
            }
        }
        

    }, [customerData?.customer?.lastRentalOrder?.orderid, lastReturnOrder])
    

    useEffect(() => {

        if(customerData.customer != "not_login" && customerData.customer?.has_subscription) {
            let customerAttribute = customerData.customer?.attributes
            let orderCount = 0
            if(customerAttribute && customerAttribute?.attributes && customerAttribute?.attributes?.length > 0 ) {
                let attributeArr = customerAttribute?.attributes
                attributeArr.map((attr:any, ind:any) => {
                    if(attr && attr.name?.indexOf('partial-first-shipment') > -1 && orderCount < 1) {
                        orderCount = 1
                    }
                    if(attr && attr.name?.indexOf('partial-second-shipment') > -1 && orderCount < 2) {
                        orderCount = 2
                    }
                    if(attr && attr.name?.indexOf('partial-third-shipment') > -1 && orderCount < 3) {
                        orderCount = 3
                    }
                })
            }
            setRentalOrderCountThisMonth(orderCount)
            if(customerData?.customer?.tag_list?.join()?.toLowerCase()?.indexOf('trial_at_4am') > -1){
                setIsShowActualBillingDate(true)
            }          
        }

        if(customerData.customer != 'not_login'){
            setSignUrl('/signup?email=' + btoa(customerData.customer.email) + '&loggedin=true')
        }

    },[])

    let orderRemainingProgressBar = () => {

        let subEndDateUnix = customerData.customer?.billing_end_date+25200
        let currentDateUnix = moment().unix('X')
        const diffTime = Math.abs(currentDateUnix - subEndDateUnix);
        const diffDays = Math.floor(diffTime / (60 * 60 * 24));

        let daysRemaining = diffDays > 0 ? diffDays : 1;
        if(isShowActualBillingDate == true){
            daysRemaining = diffDays+1
        }
        const percentage = (( (30-daysRemaining) / 30) * 100);
        return(
            <div className={styles.progressBarContainer}>
                <div className={styles.outerCircle} style={{ background: `conic-gradient(from 0deg, rgba(232,156,174,1) 0%, ${ percentage > 50 ? `rgba(255,243,247,1) 50%,` : ""} rgba(232,156,174,1) ${percentage}%, rgba(217,217,217,1) 0%)`}}>
                    <div className={styles.innerCircle}>
                        <div className={styles.text}>{daysRemaining} {daysRemaining>1?'days':'day'} <br /> remaining</div>
                    </div>
                </div>
            </div>
        )
    }

  

    return (
        <>
        {
            orderCount != undefined ?
            <>
                <div  className={`${styles.tracking_section_box} ${styles.myOrder_tracking_box}`}>
                    {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? 
                        <div className={`${styles.remaing_order_text} mt-0`}>
                            <p> You have <span className={styles.order_count}> {rentalOrderCountThisMonth > 1 ? '0' : rentalOrderCountThisMonth == 0 ? '2':'1'} of 2  </span> orders remaining </p>
                            {(customerData.customer?.billing_end_date > 0 && customerData.customer?.has_subscription == true) ? <p> Next billing date 
                                {
                                    isShowActualBillingDate == true ?
                                    <span className={styles.next_date}> {moment.unix(customerData.customer?.billing_end_date+25200)?.add(1, 'day')?.format('M/D/YY [at]')} 4:00am</span> 
                                    : 
                                    <span className={styles.next_date}> {moment.unix(customerData.customer?.billing_end_date+25200)?.format('M/D/YY [at]')} 4:00am</span> 
                                }
                            </p> : ''}
                        </div>
                    : ''}

                    <div className={styles.myOrder_heading}>
                        <h3 className={styles.myOrder_head} >My Orders</h3>
                        <h6 className={styles.total_count} >{orderCount> 1 ? `${orderCount} ORDERS` : `${orderCount} ORDER` }</h6>
                    </div>
                    {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? 
                        <div className={`${styles.shopping_bag_sec} mt-0 ${checkForShipment(customerData.customer)?.priority == 5 ? styles.desktop_hover : ''}`} onClick={()=>{checkForShipment(customerData.customer)?.priority == 5 ? setOpenCollapse(!openCollapse) : {}}}>
                            {
                                (checkForShipment(customerData.customer)?.priority == 2 || checkForShipment(customerData.customer)?.priority == 4 || checkForShipment(customerData.customer)?.priority == 6 ) ?
                                <div className={styles.left}>
                                    <p className={styles.cr_text}>Currently renting {checkForShipment(customerData.customer)?.priority == 2 ? '1st' : checkForShipment(customerData.customer)?.priority == 4 ? '2nd' : '3rd'} order</p>
                                    {customerData.customer?.lastRentalOrder?.created_at != null ? <p className={styles.delivery_user}>Placed on {moment.unix(customerData.customer?.lastRentalOrder?.created_at).format('MMM Do YYYY')}</p> : ''}
                                </div> : ''
                            }

                            {  
                            checkForShipment(customerData.customer)?.priority == 0 ?
                                <div className={styles.left}>
                                    <p className={`${styles.cr_text} m-0`}>Return your items to <br/> unlock your next order!</p>
                                </div> : ''
                            }

                            {
                                checkForShipment(customerData.customer)?.priority == 7 ?
                                <div className={styles.left}>
                                    <p className={`${styles.cr_text} m-0`}>Yout next shipment will <br/> unlock in {checkForShipment(customerData.customer).remainingPlanDays} days</p>
                                </div> : ''
                            }

                            {
                                (checkForShipment(customerData.customer)?.priority == 1 || checkForShipment(customerData.customer)?.priority == 3) ?
                                <div className={styles.left}>
                                    <p className={`${styles.cr_text} text-left`}>{checkForShipment(customerData.customer)?.priority == 1 ? '1st' : '2nd'} order unlocked!</p>
                                    <p className={styles.delivery_user}>5 clothing items <br/>+ 1 complimentary accessory</p>
                                </div> : ''
                            }

                            {
                                checkForShipment(customerData.customer)?.priority == 5 ?
                                <>
                                <MediaQuery minWidth={768}>
                                    <div className={`${styles.left}`}>
                                        <p className={styles.cr_text}>Need more items now?</p>
                                        <p className={styles.delivery_user}>Unlock 3rd order for $49.95</p>
                                        <div className={`${styles.desktop_hovered}`}>
                                            <p>Just add 5 clothing items and 1 <br /> accessory to your cart and pay <br /> $49.95 at checkout to rent a 3rd order.</p>
                                        </div>
                                    </div>
                                </MediaQuery>
                                </> : ''
                            }

                            <div className={styles.right}>
                                <img src={Gconfig.fashionpassImageStorage +  `assets/img/myfs_firstOrder.svg`} alt='FashionPass bag graphic.' />
                            </div>
                        </div>
                    : ''
                    }                    
                </div>
            
            </> :
            <>
            {
                customerData.customer != 'not_login' && customerData.customer.has_subscription == true ?
                <div className={styles.tracking_section_box}>
                    {
                        fromMyOrder == undefined ? 
                        <div className={styles.name_sec}>
                            <div className={styles.name_left}>
                                <p className={`${styles.name} ${loggedInUserName?.length > 11 ? styles.sm_name : ''}`} style={ customerData.customer.last_order_id == 0 ? {paddingLeft: '9px'}: {}}>{loggedInUserName}!</p>
                                { customerData.customer.last_order_id == 0 ? <p className={styles.welcome}>Welcome to FashionPass!</p> : ''}
                            </div>
                            <div className={styles.remaining_days_count}>
                                {orderRemainingProgressBar()}
                            </div>
                        </div>
                        : ''
                    }

                    <div className={styles.remaing_order_text}>
                        <p> You have <span className={styles.order_count}> {rentalOrderCountThisMonth > 1 ? '0' : rentalOrderCountThisMonth == 0 ? '2':'1'} of 2  </span> orders remaining </p>
                        {(customerData.customer?.billing_end_date > 0 && customerData.customer?.has_subscription == true) ? <p> Next billing date 
                            {
                                isShowActualBillingDate == true ?
                                <span className={styles.next_date}> {moment.unix(customerData.customer?.billing_end_date+25200)?.add(1, 'day')?.format('M/D/YY [at]')} 4:00am</span> 
                                : 
                                <span className={styles.next_date}> {moment.unix(customerData.customer?.billing_end_date+25200)?.format('M/D/YY [at]')} 4:00am</span> 
                            }
                            </p> : ''}
                    </div>

                    <div className={`${styles.shopping_bag_sec} ${checkForShipment(customerData.customer)?.priority == 5 ? styles.desktop_hover : ''}`} onClick={()=>{checkForShipment(customerData.customer)?.priority == 5 ? setOpenCollapse(!openCollapse) : {}}}>
                        {
                            (checkForShipment(customerData.customer)?.priority == 2 || checkForShipment(customerData.customer)?.priority == 4 || checkForShipment(customerData.customer)?.priority == 6 ) ?
                            <div className={styles.left}>
                                <p className={styles.cr_text}>Currently renting {checkForShipment(customerData.customer)?.priority == 2 ? '1st' : checkForShipment(customerData.customer)?.priority == 4 ? '2nd' : '3rd'} order</p>
                                { customerData.customer?.lastRentalOrder?.created_at != null ? <p className={styles.delivery_user}>Placed on {moment.unix(customerData.customer?.lastRentalOrder?.created_at).format('MMM Do YYYY')}</p> : ''}
                            </div> : ''
                        }

                        {  
                        checkForShipment(customerData.customer)?.priority == 0 ?
                            <div className={styles.left}>
                                <p className={`${styles.cr_text} m-0`}>Return your items to <br/> unlock your next order!</p>
                            </div> : ''
                        }

                        {
                            checkForShipment(customerData.customer)?.priority == 7 ?
                            <div className={styles.left}>
                                <p className={`${styles.cr_text} m-0`}>Yout next shipment will <br/> unlock in {checkForShipment(customerData.customer).remainingPlanDays} days</p>
                            </div> : ''
                        }

                        {
                            (checkForShipment(customerData.customer)?.priority == 1 || checkForShipment(customerData.customer)?.priority == 3) ?
                            <div className={styles.left}>
                                <p className={`${styles.cr_text} text-left`}>{checkForShipment(customerData.customer)?.priority == 1 ? '1st' : '2nd'} order unlocked!</p>
                                <p className={styles.delivery_user}>5 clothing items <br/>+ 1 complimentary accessory</p>
                            </div> : ''
                        }

                        {
                            checkForShipment(customerData.customer)?.priority == 5 ?
                            <>
                            <MediaQuery minWidth={768}>
                                <div className={`${styles.left}`}>
                                    <p className={styles.cr_text}>Need more items now?</p>
                                    <p className={styles.delivery_user}>Unlock 3rd order for $49.95</p>
                                    <div className={`${styles.desktop_hovered}`}>
                                        <p>Just add 5 clothing items and 1 <br /> accessory to your cart and pay <br /> $49.95 at checkout to rent a 3rd order.</p>
                                    </div>
                                </div>
                            </MediaQuery>

                            <MediaQuery maxWidth={767}>
                                <div className={styles.left}>
                                    <p className={styles.cr_text}>Need more items now?</p>
                                    <p className={styles.delivery_user}>Unlock 3rd order for $49.95</p>
                                </div>                     
                            </MediaQuery>
                            </> : ''
                        }

                        <div className={styles.right}>
                            <img src={Gconfig.fashionpassImageStorage +  `assets/img/myfs_firstOrder.svg`} alt='FashionPass bag graphic.' />
                        </div>
                    </div>
                    <MediaQuery maxWidth={767}>
                        <Collapse isOpen={openCollapse}>
                            <div className={`${styles.mbl_collapse}`}>
                                <p>Just add 5 clothing items and 1 <br /> accessory to your cart and pay <br /> $49.95 at checkout to rent a 3rd order.</p>
                            </div>
                        </Collapse>
                    </MediaQuery>
                </div>
                :
                <>
                    {
                        fromMyOrder == undefined ?
                        <div className={styles.non_sub_container}>
                            <div className={styles.user}>{loggedInUserName}</div>
                            <div className='text-center'>
        
                        { customerData.customer == 'not_login' || (customerData.customer != 'not_login' && customerData.customer.last_order_id == 0 )? 
                            <div className={styles.heading}>Join the Rental Club</div> 
                            :
                            <>
                                <div className={styles.heading}>We miss you!</div>
                                <Link href={'/signup?email=' + btoa(customerData.customer.email) + '&loggedin=true'}>
                                    <button className={styles.reactivate_button}>REACTIVATE</button>
                                </Link>
                            </>
                            }
                                <img className={styles.img}  src={Gconfig.fashionpassImageStorage+'assets/img/10itemsamonth.svg'} />
                            </div>
                            <div className={styles.boxes}>
                                <div className={styles.line_one}>
                                    <div className={styles.box + " " + styles.box_1}>
                                        <p className={styles.box_heading}>2</p>
                                        <p className={styles.box_text}>orders/month</p>
                                    </div>
                                    <div className={styles.box + " " + styles.box_2}>
                                        <p className={styles.box_heading}>5</p>
                                        <p className={styles.box_text}>items/order</p>
                                    </div>
                                </div>
                                <div className={styles.box + " " + styles.box_3}>
                                    <p className={styles.box_heading}><span>$</span>125</p>
                                    <p className={styles.box_text}>/month</p>
                                </div>
                            </div>
                            {customerData.customer.last_order_id > 0 ? 
                            '' : 
                                <div className={styles.buttons}>
                                    <Link href={signUrl}>
                                        <button className={styles.renting_button}>Start Renting</button>
                                    </Link>
                                    <button className={styles.cancel_button}>cancel anytime</button>
                                </div>
                            }
                        </div> : ''
                        
                    }
                </>
            }
            </>
        }

        {fromMyOrder != true && customerData.customer != 'not_login' && customerData.customer?.has_subscription == true && customerData.customer.tag_list?.join()?.toLowerCase()?.indexOf("currentlyborrowing") > -1 && lastRentalOrderUrl != '' && isOrderOutgoingProgress == true  ? 
            <div className={styles.checkReturn_reprintLabel}>
                <a href={lastRentalOrderUrl} target='_blank'><button><span><img src={Gconfig.fashionpassImageStorage + `assets/img/reprintReturnLabel.svg`} /></span> Reprint return label</button></a>
                {typeof window != undefined && window != undefined && window.innerWidth < 768 ? <Link className={jsCookie.get("isCheckReturnStatus") == undefined ? "" : styles.disabled} href={{ pathname: '/my-orders', query: { checkReturnStatus: true,myOrder: location?.pathname == '/my-orders' ? true : false} }}><button onClick={()=>handleToggleDrawer()}><span><img src={Gconfig.fashionpassImageStorage + `assets/img/checkReturnLabel.svg`} /></span> Check return status</button></Link> : "" }
                {typeof window != undefined && window != undefined && window.innerWidth > 767 ? <Link className={jsCookie.get("isCheckReturnStatus") == undefined ? "" : styles.disabled} href={{ pathname: '/my-orders', query: { checkReturnStatus: true,myOrder: location?.pathname == '/my-orders' ? true : false} }}><button onClick={toggleDrawer(false, false)}><span><img src={Gconfig.fashionpassImageStorage + `assets/img/checkReturnLabel.svg`} /></span> Check return status</button></Link> : "" }
            </div>
        : '' }

        </>
    );
};

export default CustomerOrderTracking;




import { CollectionPageSliceDataType } from '@/redux/reducers/collectionPageReducer';
import { Category_SubCategoryDataType } from '@/services/collections';
import { isValueEmpty, TextExistsInString } from '@/utils/string-methods';
import Link from 'next/link';
import React, { useState } from 'react';
import styles from './nav-bar-dropdown-mobile-menu.module.scss';
interface IProps {
  subCategory: Category_SubCategoryDataType;
  collectionPageData: CollectionPageSliceDataType;
  handleRedirection: (e: React.MouseEvent<HTMLElement>, linkedCategoryHandle: string | null | undefined) => void;
}

const alpha_array = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U",
  "V", "W", "X", "Y", "Z"
]

const NavBarDropdownMobileMenu: React.FC<IProps> = ({ subCategory, collectionPageData, handleRedirection }) => {

  let [headingAccordins, setHeadingAccordins] = useState([12])
  let [headingReverse, setHeadingReverse] = useState(false)
  let [subCategoryHeading, setSubCategoryHeading] = useState<{ heading_id: number, heading_text: string }[]>([]);

  const handleGenerateHeadingContents = (id: number | null, type: string) => {
    let filteredSubCats = []
    if (type == 'brands') {
      filteredSubCats = subCategory.sub_categories.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      filteredSubCats = subCategory.sub_categories.filter((item) => Boolean(item.heading_id === id || id === null));
    }

    return (filteredSubCats.map((item) => {
      const categoryName = item.name.replace('-internal', '').replace('-main', '');
      let fontFamily = 'Poppins Regular';

      if (collectionPageData.leftBarData.data.currentCat !== undefined) {
        let levelhandle = '';
        if (collectionPageData.leftBarData.data.currentCat?.cat?.tree) levelhandle = collectionPageData.leftBarData.data.currentCat.cat.tree.sub_item?.handle || '';
        if (collectionPageData.leftBarData.data.currentCat?.level == 1) levelhandle = collectionPageData.leftBarData.data.currentCat.cat?.handle || '';
        if (collectionPageData.leftBarData.data.currentCat?.level == 0) levelhandle = collectionPageData.leftBarData.data.currentCat.cat?.tree.handle || '';
        if (item.linked_category !== null && levelhandle === item.linked_category.handle && location.pathname !== '/') fontFamily = 'Poppins Medium';
      }

      if (TextExistsInString(item.name.toLowerCase(), 'view all clothing') || TextExistsInString(item.name.toLowerCase(), 'view all accessories')) fontFamily = 'Poppins Medium';

      return (
        <li key={item.id} className={`mobile_headingItem`}>
          {!isValueEmpty(item.linked_category) && (
            item?.category_link.indexOf('occasions') > -1 ?
            <Link href={'/'+item.linked_category?.handle} className={`mobile_headingItemLink`}>
              {categoryName}
            </Link>
            :
            <Link href={''+subCategory?.category_link?.toLowerCase() == "how-it-works" ? `/${item.linked_category.handle}` : "/[...category_handle]"} as={item.linked_category?.handle || '/'} className={`mobile_headingItemLink ${item.name?.indexOf('FP Merch') > -1 ? ' headingItemLink_pink' : ''}`} style={{ fontFamily }} onClick={(e) => {''+subCategory?.category_link?.toLowerCase() == "how-it-works" ?  {} : handleRedirection(e, item.linked_category?.handle)} }>
              {categoryName}
            </Link>
          )}
          {isValueEmpty(item.linked_category) && (
            <Link href={item.handle || '/'} className={`mobile_headingItemLink`}>
              {categoryName}
            </Link>
          )}
        </li>
      );
    }))
  };
  let toggleAccordins = (id) => {
    let ids = [...headingAccordins]
    let index = ids.indexOf(id)
    if (index > -1)
      ids.splice(index, 1)
    else
      ids.push(id)
    setHeadingAccordins(ids)
  }
  if (subCategory.headings.length > 0 && headingReverse == false) {
    let headingArr = []
    for (var i = subCategory.headings.length - 1; i >= 0; i--) {
      setHeadingReverse(true)
      headingArr.push(subCategory.headings[i])
      setSubCategoryHeading(headingArr)
      if (i == 0)
        setHeadingAccordins([subCategory.headings[i].heading_id])
    }
  }
  return (
    <>
      {/* seprating brands dropdown */}
      {subCategory.name == "Brands" ?
        <div className={`mobile_brand_dropdown`}>
          <div className={`mobile_treeMenuContainer`}>
            <div className={`brands_container d-flex justify-content-between align-center`}>
              <span className='mobile_heading_text'>Brands A-Z</span>
              <Link className="view_all" href='/brands'>view all</Link>
            </div>
            <ul className='alpha_letters'>
              {alpha_array.map((letter) => (
                <li key={letter}><Link href={`/brands?${letter}`} >{letter}</Link></li>
              ))}
              <li><Link href="/brands?#" >#</Link></li>
            </ul>
            <ul className={`mobile_headingsList`}>
              <div className='dropdownHeading' onClick={(e) => toggleAccordins(subCategoryHeading[1]?.heading_id)}>{"Featured Brands"} <i className={headingAccordins?.indexOf(subCategoryHeading[1]?.heading_id) > -1 ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i></div>
              <span className={headingAccordins?.indexOf(subCategoryHeading[1]?.heading_id) > -1 ? 'accordingDataOpen' : 'accordingDataClose'}>{handleGenerateHeadingContents(0, 'brands')}</span>
            </ul>
          </div>
        </div>
        : <></>}
      <div className={`mobile_treeMenuContainer`}>
        <ul className={`mobile_headingsList`}>
          {subCategory.headings.length <= 0 && subCategory.name != "Brands" && handleGenerateHeadingContents(null, '')}
          {subCategory.headings.length > 0 &&
            subCategoryHeading.length > 0 && subCategoryHeading?.map((item, index) => {
              return subCategory.name != "Brands" && (
                <>
                  <div className='dropdownHeading' onClick={(e) => toggleAccordins(item.heading_id)}>{item.heading_text} <i className={headingAccordins?.indexOf(item.heading_id) > -1 ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i></div>
                  <span className={headingAccordins?.indexOf(item.heading_id) > -1 ? 'accordingDataOpen' : 'accordingDataClose'}>{handleGenerateHeadingContents(item.heading_id, '')}</span>
                </>
              )
            })}
        </ul>
      </div>
    </>
  );
};

export default NavBarDropdownMobileMenu;

import React, { useEffect, useState, useRef } from 'react';
import styles from './complete-profile-popup-index.module.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Input, Label, Spinner } from 'reactstrap';
import Gconfig from 'globalconfig';
import { Validate } from '@/functions/validations';
import { updateCustomerTags, getCustomerTags } from 'components/shared/globalFunctions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { updateCustomerSizeInfo } from 'components/shared/customerDataFunctions';
import { fetchCustomerSuccess } from '@/redux/actions/customerActions';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  toggleReview: () => void;
  fromReview: boolean;
}

const CompleteProfilePopupMember: React.FC<IProps> = ({isOpen, toggle, toggleReview, fromReview}) => {

  const [heightFt, setHeightFt] = useState('');
  const [heightIn, setHeightIn] = useState('');
  const [weight, setWeight] = useState('');
  const [dressSize, setDressSize] = useState('');
  const [dressNumber, setDressNumber] = useState('');
  const [topSize, setTopSize] = useState('');
  const [jeansSize, setJeansSize] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const customerData = useAppSelector((state) => state.customer);
  const dispatch = useAppDispatch();

  let handleWeight = (e:any) => {
    if (e.target.value.length > 3) {
        return
    }
    if (Validate.isNumericValue(e.target.value) == true) {
        setWeight(e.target.value)
    } else {
        return
    }
  }

  let handleSubmitProfileData = async () => {
    setErrorMsg('')
    if(heightFt == '' || heightIn == '' || weight == '' || weight == 0 || dressSize == '' || dressNumber == '' || topSize == '' || jeansSize == ''){
      setErrorMsg('Please fill out all fields.')
      return
    }
    if(weight < 50 || weight > 999){
      setErrorMsg('Please enter a valid weight.')
      return
    }
    setIsLoading(true)
    let sizesData = {
        customer_id: customerData?.customer?.customer_id,
        height_ft: heightFt,
        height_in: heightIn,
        weight: weight,
        dress_size: dressSize + '-' + dressNumber,
        top_size: topSize,
        jeans_size: jeansSize
    }
    let tagsToAdd = [
      'Dress Size:' + dressNumber + ':' + dressSize,
      'Height:' + heightFt + '-' + heightIn,
      'Jeans Size:' + jeansSize,
      'Shirt & Top:' + topSize,
      'Weight:' + weight
    ]
    let customerProfileInfoResponse = await updateCustomerSizeInfo(sizesData)
    if (customerProfileInfoResponse.success == true) {
      updateCustomerTags(tagsToAdd.join(),'').then(response => response.json()).then(data => {
        if (data.success == true) {
            setIsLoading(false)
            let customer = customerData.customer
            customer.tag_list = data.tags;
            dispatch(fetchCustomerSuccess(customer))
            if(fromReview == true){
              toggleReview()
            }else{
              toggle();
            }
          }else{
            setIsLoading(false)
          }
      })
    }else{
      setIsLoading(false)
      setErrorMsg(customerProfileInfoResponse?.message)
    }
  }

  return (
    <>
      <Modal
            open={isOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={toggle}
        >            
            <Box className={styles.modal}>
              <div className={styles.complete_profile_container}>
                <img className={styles.crossIcon} onClick={()=>toggle()} src={Gconfig.fashionpassImageStorage+"assets/img/cross-icon-search-black.svg"}/>
                <p className={styles.heading}>Curate Your Experience</p>
                <div className={styles.curatePopupContent}>
                  <div className={styles.inputBox1}>
                    <div className={styles.heightSection}>
                      <Label for="customer_height_ft">Height</Label>
                      <div className={styles.heightInputs}>
                          <Input type="select" name="heightFt" id="customer_height_ft" placeholder='' value={heightFt} onChange={(e)=>{setHeightFt(e.target.value)}} className={``}>
                              <option value="" disabled></option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                          </Input>
                          {/* <Label for="customer_height_inch" className="height-l-one" style={{ paddingRight: '30px' }}>ft.</Label> */}
                          <Input id="customer_height_inch" name="heightIn" type="select" value={heightIn} onChange={(e)=>{setHeightIn(e.target.value)}} className={``}>
                              <option value="" disabled></option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                          </Input>
                      </div>
                    </div>
                    <div className={styles.weightSection}>
                        <Label for="customer_weight">Weight</Label>
                        <Input type="text" pattern="\d*" name="weight" value={weight} onChange={(e)=>{handleWeight(e)}} className={``}/>
                    </div>
                  </div>
                  <div>
                    <p className={styles.question}>What size do you typically wear?</p>
                    <div className={styles.inputBox2}>
                      <div className={styles.dressSizeSection}>
                        <Label for="customer_dress_number">Dress Size Letter/Number</Label>
                        <div className={styles.dressInput}>
                            <Input type="select" name="dressSize" id="customer_dress_size" value={dressSize} onChange={(e)=>{setDressSize(e.target.value)}} className={`${styles.dress_size_one} disable-size `}>
                                <option value="" disabled></option>
                                <option value="XXS">XXS</option>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="XXL">XXL</option>
                                <option value="3XL">3XL</option>
                                <option value="4XL">4XL</option>
                                <option value="5XL">5XL</option>
                            </Input>
                            <Input type="select" name="dressNumber" id="customer_dress_number" value={dressNumber} onChange={(e)=>{setDressNumber(e.target.value)}} className={`${styles.dress_size_two} `}>
                                <option value="" disabled></option>
                                <option value="00">00</option>
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="6">6</option>
                                <option value="8">8</option>
                                <option value="10">10</option>
                                <option value="12">12</option>
                                <option value="14">14</option>
                                <option value="16">16</option>
                            </Input>
                        </div>
                      </div>
                      <div className={styles.topSizeSection}>
                          <Label for="customer_top_size">Top Size</Label>
                          <Input type="select" name="topSize" id="customer_top_size" value={topSize} onChange={(e)=>{setTopSize(e.target.value)}} className={`${styles.top_size_one} disable-size `}>
                              <option value="" disabled></option>
                              <option value="XXS">XXS</option>
                              <option value="XS">XS</option>
                              <option value="S">S</option>
                              <option value="M">M</option>
                              <option value="L">L</option>
                              <option value="XL">XL</option>
                              <option value="XXL">XXL</option>
                              <option value="3XL">3XL</option>
                              <option value="4XL">4XL</option>
                              <option value="5XL">5XL</option>
                          </Input>
                      </div>
                      <div className={styles.jeanSizeSection}>
                          <Label for="customer_jeans_size">Jean Size </Label>
                          <Input type="select" name="jeansSize" id="customer_jeans_size" value={jeansSize} onChange={(e)=>{setJeansSize(e.target.value)}} className={`${styles.jeans_size_one} disable-size `}>
                              <option value="" disabled></option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="34">34</option>
                              <option value="36">36</option>
                          </Input>
                      </div>
                    </div>
                  </div>
                </div>
                { errorMsg != '' ? <p className={styles.error_msg}>{errorMsg}</p> : ''}
                <button className={styles.btnUpdateProfile} disabled={isLoading} onClick={()=>{handleSubmitProfileData()}}> {isLoading ? <Spinner /> : 'SET MY SIZES' }</button>
              </div>
            </Box>
        </Modal>
    </>
  );
};

export default CompleteProfilePopupMember;

import moment from 'moment';

// https://devhints.io/moment

/** This function returns the Unix Timestamp in milliseconds. */
export const getCurrentTimestamp = () => moment().valueOf();

/** This function returns boolean value*/
export const isLeapYear = () => {
  const currentYear = moment().format('YYYY');
  const leapyear = moment([currentYear]).isLeapYear();
  return leapyear;
};

import { CategoryDataType } from '@/services/collections';
import Link from 'next/link';
import React from 'react';
import { DrawerSubMenus } from '@/containers/header/top-bar/drawer-menu/drawer-menu-index/drawer-menu-index';

interface IProps {
  activeSubMenu: DrawerSubMenus | null;
  id: DrawerSubMenus;
  data: CategoryDataType[];
  handleClearFilters: () => void;
  categoryHeadings: { heading_id: number; heading_text: string }[] | null;
}

const DrawerBodyDropdownListSubCategoriesList: React.FC<IProps> = ({ activeSubMenu, id, data, handleClearFilters, categoryHeadings}) => {
  if (activeSubMenu !== id || data.length <= 0) return <></>;

  const handleGenerateHeadingContents = (id: number | null) => {
    const filteredSubCats = data.filter((item) => Boolean(item.heading_id === id || id === null));
    return(
      filteredSubCats.map((item, index) => (
        <li key={`${item.name}_${index}`} onClick={handleClearFilters}>
          <Link href="/[...category_handle]" as={`/${item.linked_category?.handle || item.handle || ''}`}>
            <div className={'drawerBodyDropdownListSubCategoriesListItem'}>
              <a className={'drawerBodyDropdownListSubCategoriesListItemText'}> {item.name} </a>
            </div>
          </Link>
        </li>
      ))
    )
  };
  return (
    <li>
      <ul className={'drawerBodyDropdownListSubCategoriesList'}>
        {categoryHeadings != undefined && categoryHeadings?.length <= 0 && handleGenerateHeadingContents(null)}


        {categoryHeadings != undefined && categoryHeadings?.length > 0 &&
        categoryHeadings.map((item, index) => {
          return(
            <>
              {item.heading_text?.indexOf('BROWSE BY OCCASION') == -1 && 
                <li key={'side_heading'+item.heading_id+'_'+index}>
                  <div className={'drawerBodyDropdownListSubCategoriesListItem'}>
                    {
                      item.heading_text?.toUpperCase()?.indexOf('BROWSE BY OCCASION') > -1 ?
                      <Link href={'/occasions'} className={'drawerBodyDropdownListSubCategoriesListItemText sideBarHeading'}> {item.heading_text} </Link>
                      :
                      <a className={'drawerBodyDropdownListSubCategoriesListItemText sideBarHeading'}> {item.heading_text} </a>
                    }
                  </div>
                </li>
              }
              {handleGenerateHeadingContents(item.heading_id)}
              {item.heading_text?.indexOf('BROWSE BY OCCASION') > -1 &&
                <li key={'side_heading'+item.heading_id+'_'+index}>
                  <div className={'drawerBodyDropdownListSubCategoriesListItem emptySideBarHeading'}>
                  </div>
                </li>
              }
            </>
          )
        })}
      </ul>
    </li>
  );
};

export default DrawerBodyDropdownListSubCategoriesList;

import { CookieKeys } from '@/constants/cookie-keys';
import { API } from '@/constants/global-config';
import { Pages } from '@/constants/pages';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { GetCookie, RemoveCookie } from '@/libraries/browser-storage';
import { TabletView, useResponsive } from '@/libraries/responsive';
import { TextExistsInString } from '@/utils/string-methods';
import { WindowSmoothScroll } from '@/utils/window-methods';
import { getCustomerCart, markCartLoaded, markCartLoading } from 'components/shared/cartFunctions';
import { getCustomerAddresses, getCustomerPoints } from 'components/shared/customerDataFunctions';
import { getSubscriptionNameAgianstPlanName, searchTag } from 'components/shared/globalFunctions';
import { getFavoriteBoardsList } from 'components/shared/productFavFunctions';
import moment from 'moment';
import Link from 'next/link';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import CartBtnIndex from '@/containers/header/top-bar/cart-btn/cart-btn-index/cart-btn-index';
import FavoritesBtn from '@/containers/header/top-bar/favorites-btn/favorites-btn';
import MonthlyDiscountMessage from '@/containers/header/top-bar/monthly-discount-message/monthly-discount-message';
import SearchBarIndex from '@/containers/header/top-bar/search-bar/search-bar-index/search-bar-index';
import TryNowBtn from '@/containers/header/top-bar/try-now-btn/try-now-btn';
import styles from './top-bar-index.module.scss';
import SelectNextItemsMessage from '@/containers/header/top-bar/select-next-items-message/select-next-items-message';
import LoginBtn from '@/containers/header/top-bar/login-btn/login-btn';
import SubscribedHomePagePopup from 'components/shared/subscribedHomePagePopup';
import NewUserPopup from 'components/shared/newUserPopup';
import CompleteProfilePopup from 'components/shared/completeProfilePopup';
import FirstPurchaseDiscountBar from 'components/shared/firstPurchaseDiscountBar';
import NewPricingIntroPopup from 'components/shared/newPricingIntroPopup';
import ChangePlanPopup from 'components/shared/changePlanPopup';
import LeftPricingPopup from 'components/shared/newPricingIntroTwo';
// import PromotionsPopupLayout from 'components/shared/promotionsPopupLayout';
import DrawerMenuIndex from '@/containers/header/top-bar//drawer-menu/drawer-menu-index/drawer-menu-index';
import dynamic from 'next/dynamic';
import UpdateProfileNotification from '@/containers/header/top-bar/update-profile-notification/update-profile-notification-index';
import AlgoliaSearch from '@/containers/search-algolia/new-search';
import CompleteProfilePopupMember from '@/containers/complete-profile-popup/complete-profile-popup-index'

const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });
interface IProps {
  navbarRef?: React.MutableRefObject<HTMLDivElement | null>;
  isVerificationSuccess?: Boolean;
}

const TopBarIndex: React.FC<IProps> = ({ navbarRef, isVerificationSuccess }) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  const systemSettingsData = useAppSelector((state) => state.systemSettings);
  const customerData = useAppSelector((state) => state.customer);
  const serversideReloadData = useAppSelector((state) => state.serversideReload);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);

  const [twoPmModal, setTwoPmModal] = useState(false); // twoPmModal
  const [newUserModal, setNewUserModal] = useState(false); // newUserModal
  const [completeProfileModal, setCompleteProfileModal] = useState(false); // completeProfileModal
  const [newSitePopup, setNewSitePopup] = useState(false); // newSitePopup
  const [pricingIntroPopup, setPricingIntroPopup] = useState(false); // pricingIntroPopup
  const [upgradePlanPopup, setUpgradePlanPopup] = useState(false); // upgradePlanPopup
  const [popupIndex, setPopupIndex] = useState(false); // popupIndex
  const [pricing48hourPopup, setPricing48hourPopup] = useState(false); // pricing48hourPopup

  const [pricing48hourCookie, setPricing48hourCookie] = useState<boolean>(false); // pricing48hourCookie
  const [urlCookie, setUrlCookie] = useState<string | null>(null); // urlCookie
  const [selectedPlan, setSelectedPlan] = useState(''); // selectedPlan
  const [nextBillingDate, setNextBillingDate] = useState(''); // nextBillingDate
  const [gold, setGold] = useState(false); // Gold
  const [platinum, setPlatinum] = useState(false); // Platinum
  const [wanderlust, setWanderlust] = useState(false); // Wanderlust
  const [subscriptionId, setSubscriptionId] = useState(''); // subscriptionId

  const isLoginPopupVisibile = document.getElementById('main-modal');

  const showVerificationPopup = GetCookie(CookieKeys.SHOW_VERIFICATION_POPUP);

  const handleRefresh = () => Router.push({ pathname: Pages.MY_ACCOUNT, query: { tab: 6, upgrade: true } }); // refresh

  const handlePlanModalToggle = (e: any, isCloseOnly: boolean) => {
    if (!isCloseOnly) return;
    setUpgradePlanPopup((prev) => !prev);
    setPricingIntroPopup(Boolean(customerData.customer !== 'not_login' && searchTag(customerData.customer.tag_list, 'promoofferreject') !== true));
    setPricing48hourPopup(Boolean(customerData.customer !== 'not_login' && searchTag(customerData.customer.tag_list, 'promoofferreject') === true));
    setPopupIndex(true);
  };

  const handlePlanModalUpdateToggle = () => {
    setUpgradePlanPopup((prev) => !prev);
    setPricingIntroPopup(false);
  };

  const handleSetSelectedPlan = (plan: string, subscriptionId: string, upgrade: boolean) => {
    setSelectedPlan(getSubscriptionNameAgianstPlanName(plan) || '');
    setSubscriptionId(subscriptionId);
    setUpgradePlanPopup(upgrade);
  };

  const handleRedirectToHomeTop = () => {
    if (window.location.pathname === Pages.HOME) WindowSmoothScroll(0);
    else Router.push(Pages.HOME);
  };

  const handleCustomerUpdate = async () => {
    const reactivatePricingOffer = GetCookie(CookieKeys.REACTIVATE_PRICING_OFFER);
    const completeProfilePopupShow = GetCookie(CookieKeys.COMPLETE_PROFILE_POPUP_SHOW);
    const loginSuccessCookie = GetCookie(CookieKeys.LOGIN_SUCCESS);

    if (customerData.customer === 'not_login') return;

    dispatch(markCartLoading());
    await dispatch(getCustomerCart()); // Setup Cart Data
    dispatch(markCartLoaded());
    dispatch(getFavoriteBoardsList()); // Setup Favorites
    await dispatch(getCustomerPoints()); // Setup Customer Points

    const tag_list = customerData.customer.tag_list;

    // for ChangePlanPopup
    if (customerData.customer.has_subscription) {
      setNextBillingDate(moment.utc(customerData.customer.subscription_end_date).format('M/D/YYYY'));
      const selectedSubscription = { Gold: false, Platinum: false, Wanderlust: false };
      if (customerData.customer.subscription_name === 'Gold') selectedSubscription.Gold = true;
      else if (customerData.customer.subscription_name === 'Platinum') selectedSubscription.Platinum = true;
      else selectedSubscription.Wanderlust = true;
      setGold(selectedSubscription.Gold);
      setPlatinum(selectedSubscription.Platinum);
      setWanderlust(selectedSubscription.Wanderlust);
    }

    // for CompleteProfileModal
    if (completeProfileModal === false && loginSuccessCookie != undefined && searchTag(tag_list, 'height') !== true && searchTag(tag_list, 'jeans size') !== true && searchTag(tag_list, 'email_verified') === true && searchTag(tag_list, 'phone_verified') === true && customerData.customer.subscription_name !== null && window.location.pathname !== Pages.ORDERCONFIRMATION && window.location.pathname !== Pages.MY_ACCOUNT) {
      setCompleteProfileModal(true);
      // RemoveCookie(CookieKeys.LOGIN_SUCCESS);
      // setTwoPmModal(false);
      // setNewUserModal(false);
    } else setCompleteProfileModal(false);

    // for NewUserModal
    if (searchTag(tag_list, 'fpsignup1') === true && window.location.pathname !== Pages.TERMS_OF_SERVICE && customerData.customer.subscription_name !== null) {
      Router.push('/confirmation')
      // setNewUserModal(true);
      setCompleteProfileModal(false);
      setTwoPmModal(false);
    } else setNewUserModal(false);

    // for PricingIntroPopup
    if (customerData.customer.has_subscription && customerData.customer.plan_id && TextExistsInString(customerData.customer.plan_id, 'hauteandborrowed') && searchTag(tag_list, 'promoofferreject') !== true && searchTag(tag_list, 'promoofferreject48') !== true && searchTag(tag_list, 'fpsignup1') !== true && searchTag(tag_list, 'bra') === true && searchTag(tag_list, 'height') === true && searchTag(tag_list, 'jeans size') === true) setPricingIntroPopup(true);

    // for Pricing48hourPopup
    if (customerData.customer.has_subscription && customerData.customer.plan_id && TextExistsInString(customerData.customer.plan_id, 'hauteandborrowed') && !pricingIntroPopup && searchTag(tag_list, 'promoofferreject') === true && searchTag(tag_list, 'promoofferreject48') !== true && !newUserModal && !completeProfileModal && serversideReloadData.serversideReload.reload && !twoPmModal) setPricing48hourPopup(true);
    if (reactivatePricingOffer !== undefined && customerData.customer.plan_id && TextExistsInString(customerData.customer.plan_id, 'hauteandborrowed') && !pricingIntroPopup) setPricing48hourPopup(true);
  };

  useEffect(() => {
    setUrlCookie(GetCookie(CookieKeys.CUSTOMER_URL_EVENT) || null);
    setPricing48hourCookie(GetCookie(CookieKeys.PRICING_48HOUR_MINIMIZE) || false);

    if (customerData.customer === 'not_login') {
      dispatch(markCartLoading());
      dispatch(getCustomerCart());
      dispatch(markCartLoaded());
    }
  }, []);

  useEffect(() => {
    handleCustomerUpdate();
    const customerTags = customerData.customer === 'not_login' ? [] : customerData.customer.tag_list;
    setNewSitePopup(Boolean(TextExistsInString(customerTags.join().toString(), 'newsitepopup')));
    dispatch(getCustomerAddresses());
  }, [customerData.customer]);

  // useEffect(()=>{
  //   if (customerData.customer === 'not_login') return;

  //   const tag_list = customerData.customer.tag_list;
  //   if (completeProfileModal === false && searchTag(tag_list, 'bra') !== true && searchTag(tag_list, 'height') !== true && searchTag(tag_list, 'jeans size') !== true && customerData.customer.subscription_name !== null && window.location.pathname !== Pages.TERMS_OF_SERVICE && searchTag(tag_list, 'phone_verified') === true && searchTag(tag_list, 'email_verified') === true && showVerificationPopup == undefined) {
  //     setCompleteProfileModal(true);
  //     setTwoPmModal(false);
  //     setNewUserModal(false);
  //   } else setCompleteProfileModal(false);

  // },[isVerificationSuccess])

  let toggleCompleteProfilePopup = () =>{
    setCompleteProfileModal(!completeProfileModal)
    RemoveCookie(CookieKeys.LOGIN_SUCCESS);
  }

  return (
    <>
      {/* <TwoPmPopup isVisible={twoPmPopup} handleClosePopup={() => setTwoPmModal(false)} /> */}
      {newSitePopup && <SubscribedHomePagePopup />}
      {customerData.customer !== null && customerData.customer !== 'not_login' && (
        <>
          {completeProfileModal && <CompleteProfilePopupMember isOpen={completeProfileModal} toggle={()=>{toggleCompleteProfilePopup()}} fromReview={false}/>}
          {/* <NewUserPopup isOpen={newUserModal} toggle={() => setNewUserModal(prev => !prev)} customer={customerData.customer} openCompleteProfilePopup={() => setCompleteProfileModal(prev => !prev)} /> */}
          {/* {completeProfileModal && <CompleteProfilePopup email={customerData.customer.email} modal={completeProfileModal} toggle={() => setCompleteProfileModal(prev => !prev)} tags={customerData.customer.tag_list} signup={false} />} */}
          {/* {customerData.customer.has_subscription && searchTag(customerData.customer.tag_list, 'fpsignup1') !== true && pricing48HourCookie === undefined && (searchTag(customerData.customer.tag_list, 'promoofferreject') !== true || (searchTag(customerData.customer.tag_list, 'promoofferreject') === true && searchTag(customerData.customer.tag_list, 'promoofferreject48') === true)) && searchTag(customerData.customer.tag_list, 'bra') === true && searchTag(customerData.customer.tag_list, 'height') === true && searchTag(customerData.customer.tag_list, 'jeans size') === true && !pricingIntroPopup && !upgradePlanPopup && <FirstPurchaseDiscountBar  />} */}
          {/* {pricingIntroPopup && <NewPricingIntroPopup popupIndex={popupIndex} isOpen={pricingIntroPopup} setSelectedPlan={handleSetSelectedPlan} toggle={() => setPricingIntroPopup((prev) => !prev)} />} */}
          {upgradePlanPopup && <ChangePlanPopup fromPromotion={true} upgrade={true} isOpen={upgradePlanPopup} updatePlanToggle={handlePlanModalUpdateToggle} toggle={handlePlanModalToggle.bind(this, false)} Gold={gold} Platinum={platinum} Wanderlust={wanderlust} nextBillingDate={nextBillingDate} selectedPlan={selectedPlan} memberPoints={customerData.customer.customer_points} customer={customerData.customer} subscriptionId={subscriptionId} refresh={handleRefresh} />}
          {pricing48hourCookie && <LeftPricingPopup isOpen={pricing48hourPopup} setSelectedPlan={handleSetSelectedPlan} toggle={() => setPricing48hourPopup((prev) => !prev)} />}
          {/* {customerData.customer.has_subscription && !pricingIntroPopup && !pricing48hourPopup && !pricingIntroPopup && !upgradePlanPopup && isLoginPopupVisibile === null && <PromotionsPopupLayout fromCheckout={false} />}
          {customerData.customer.has_subscription === false && isLoginPopupVisibile === null && <PromotionsPopupLayout fromCheckout={false} />} */}
        </>
      )}
      {/* 
      <MediaQuery maxWidth={768}>
        <div className={styles.container}>
          <div className={styles.left}>
            <DrawerMenuIndex navbarRef={navbarRef} />
            <Link href={Pages.HOME} onClick={handleRedirectToHomeTop}>
              <img className={styles.fashionPassLogoImg} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/fashionpass-logo.svg`} alt="FashionPass logo" />
            </Link>
          </div>
          <div className={`${styles.right}`}>
            {!customerData.loading && (
              <>
                <TryNowBtn />
                <SearchBarIndex />
                <FavoritesBtn />
                <CartBtnIndex />
              </>
            )}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={769}>
        <div className={styles.container}>
          <div className={styles.left}>
            {renderingConfigData.show.searchBar && <SearchBarIndex />}
            <SelectNextItemsMessage />
          </div>
          <div className={styles.center}>
            <Link href={Pages.HOME} onClick={handleRedirectToHomeTop}>
              <img className={styles.fashionPassLogoImg} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/fashionpass-logo.svg`} alt="FashionPass logo" />
            </Link>
          </div>
          <div className={`${styles.right} ${customerData.customer == 'not_login' ? styles.cstm_pd : ''}`}>
            {!customerData.loading && (
              <>
                <MonthlyDiscountMessage />
                <FavoritesBtn />
                <CartBtnIndex />
                <TryNowBtn />
                <LoginBtn />
              </>
            )}
          </div>
        </div>
      </MediaQuery> */}

      <MediaQuery maxWidth={768}>
        <div className={`topbar_container`}>
          <div className={`tleft`}>
            <DrawerMenuIndex navbarRef={navbarRef} />
            <Link href={Pages.HOME} onClick={handleRedirectToHomeTop}>
              <img className={`fashionPassLogoImg`} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/fashionpass-logo.svg`} alt="FashionPass logo" />
            </Link>
          </div>
          <div className={`tright`}>
            {!customerData.loading && (
              <>
                <TryNowBtn />
                {/* <SearchBarIndex /> */}
                <AlgoliaSearch />
                {/* <SelectNextItemsMessage /> */}
                <FavoritesBtn />
                <CartBtnIndex />
              </>
            )}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={769}>
        <div className={`topbar_container`}>
          <div className={`tleft`}>
            <AlgoliaSearch />
            {/* {renderingConfigData.show.searchBar && <SearchBarIndex />} */}
            <SelectNextItemsMessage />
          </div>
          <div className={`tcenter`}>
            <Link href={Pages.HOME} onClick={handleRedirectToHomeTop}>
              <img className={`fashionPassLogoImg`} src={`${API.FASHION_PASS_IMAGE_STORAGE_URL}assets/img/fashionpass-logo.svg`} alt="FashionPass logo" />
            </Link>
          </div>
          <div className={`tright ${customerData.customer == 'not_login' ? `cstm_pd` : ''}`}>
            {!customerData.loading && (
              <>
                {/* <MonthlyDiscountMessage /> */}
                <FavoritesBtn />
                <CartBtnIndex />
                <TryNowBtn />
                <LoginBtn />
                {customerData.customer != 'not_login' && <UpdateProfileNotification />}
              </>
            )}
          </div>
        </div>
      </MediaQuery>

    </>
  );
};

export default TopBarIndex;
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { bindPopper, bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import Login from 'components/shared/login';
import React, { useEffect, useMemo, useState } from 'react';
import { Icons } from '@/libraries/icons';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Link from 'next/link';
import { Pages } from '@/constants/pages';
import Router from 'next/router';
import { CookieKeys } from '@/constants/cookie-keys';
import { RemoveCookie, SetCookie } from '@/libraries/browser-storage';
import { getCustomerReferCode } from 'components/shared/customerDataFunctions';
import { unSubscibeDeviceForMobile, searchTag } from 'components/shared/globalFunctions';
import { GetCookie } from '@/libraries/browser-storage';
import 'styles/_fontsFamily.module.scss';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CustomerOrderTracking from '@/containers/customer-order-tracking/customer-order-tracking-index'
import jsCookie from 'js-cookie';

interface IProps { }

declare global {
  interface Window {
    flutter_inappwebview:any;
      
  }
}

const loggedInUserMenuPopperID = 'header-top-logged-in-user-menu-popper';
type BtnsDataType = { isVisible: boolean; title: JSX.Element; href?: string; onClick?: (e: React.MouseEvent<HTMLElement>) => void };

const LoginBtn: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const popupState = usePopupState({ variant: 'popper', popupId: loggedInUserMenuPopperID });

  const customerData = useAppSelector((state) => state.customer);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);
  const customerAddressesData = useAppSelector((state) => state.customerAddresses);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);

  const [coupon, setCoupon] = useState<string>('');
  const [memberPoints, setMemberPoints] = useState<number>(0);
  const [isCompleteProfileFill, setIsCompleteProfileFill] = useState<boolean>(false)
  const [showSflNotification, setShowSflNotification] = useState('');

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean, delay: boolean) => () => {
    if(delay == true){
      setTimeout(()=>{
        setOpen(newOpen);
      },300)
    }else{
      setOpen(newOpen);
    }
  };

  const handleAddToMyFashionCash = () => {
    if (window.location.pathname !== Pages.MY_ACCOUNT) Router.push({ pathname: Pages.MY_ACCOUNT, query: { tab: 5 } });
    else window.location.href = `${Pages.MY_ACCOUNT}?tab=5`;
  };

  const handleLogoutCustomer = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    document.cookie = `${CookieKeys.CUSTOMER_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    RemoveCookie(CookieKeys.CUSTOMER_TOKEN, { path: '/', domain: location.hostname });
    SetCookie(CookieKeys.CUSTOMER_DATA, 'signOut');
    RemoveCookie(CookieKeys.LOGIN_POPUP_DISABLE);
    
    let fromApp = jsCookie.get('fromMobileApp')
    if(fromApp != undefined){
        if(typeof window != undefined && window != undefined){
          window.flutter_inappwebview.callHandler('logoutHandler',{'success':true})
        }
    }
    if (GetCookie(CookieKeys.FCM_TOKEN)) unSubscibeDeviceForMobile(GetCookie(CookieKeys.FCM_TOKEN), customerData.customer.customer_id);
    RemoveCookie(CookieKeys.FCM_TOKEN);
    RemoveCookie(CookieKeys.IS_WEBGL_HASH_SUBMITTED);
    RemoveCookie(CookieKeys.FP_SIGNUP_LOGGEDIN_CUSTOMER);
    RemoveCookie(CookieKeys.LOGIN_SUCCESS);
    document.location.href = '/';
  };

  const loggedInUserName = useMemo(() => {
    let val = '';
    if (customerData.customer !== 'not_login') {
      val += customerData.customer.has_subscription === false ? 'Hi ' : '';
      val += customerData.customer.first_name || 'User';
      val += !customerData.customer?.has_subscription ? '!' : '';
    }
    return val;
  }, [customerData.customer]);

  const btnsData = useMemo<BtnsDataType[]>(
    () => [
      // { isVisible: Boolean(renderingConfigData.topBarDropdown.profile), href: Pages.UPDATE_PROFILE, title: customerData.customer !== 'not_login' && customerData.customer.has_subscription ? <span>Update Profile</span> : <span>Edit Profile</span> },
      { isVisible: Boolean(renderingConfigData.topBarDropdown.orders), href: Pages.MY_ORDERS, title: <span>My Orders</span> },
      { isVisible: Boolean(renderingConfigData.topBarDropdown.fedex && customerAddressesData.addresses.length > 0), href: Pages.FEDEX, title: <span>My Fedex Locations</span> },
      { isVisible: Boolean(renderingConfigData.topBarDropdown.fav), href: Pages.MY_FAVORITES, title: <span>My Favorites</span> },
      { isVisible: Boolean(renderingConfigData.topBarDropdown.account), href: Pages.MY_ACCOUNT, title: <span className={isCompleteProfileFill ? 'pink_color' : ''}>{isCompleteProfileFill ? 'My Account (1)' : 'My Account'}</span> },
      { isVisible: Boolean(renderingConfigData.topBarDropdown.hiw), href: Pages.HOW_IT_WORKS, title: <span>How It Works</span> },
      { isVisible: true, href: Pages.NOTIFICATION, title: <span className={showSflNotification != '' ? 'pink_color' : ''}>{showSflNotification == 'single' ? 'Notifications (1)' : showSflNotification == 'multiple' ? 'Notifications (2)' : 'Notifications'}</span> },
      {
        isVisible: Boolean(renderingConfigData.topBarDropdown.credits),
        title:
          memberPoints > 0 ? (
            <span>
              My Credits (<b>{memberPoints.toFixed(2)}</b>)
            </span>
          ) : (
            <span>My Credits</span>
          ),
        onClick: handleAddToMyFashionCash,
      },
      // { isVisible: Boolean(renderingConfigData.topBarDropdown.rewards), href: Page.MEMBER_REWARDS, title: <span>Member Rewards</span>'' },
      {
        isVisible: Boolean(renderingConfigData.topBarDropdown.mycode),
        href: Pages.MEMBER_REWARDS,
        title: (
          <span>
            My Code (<b className={'couponCode'}>{coupon}</b>)
          </span>
        ),
      },
    ],
    [renderingConfigData.topBarDropdown, customerData.customer, customerAddressesData.addresses, coupon, showSflNotification]
  );

  useEffect(() => {
    // calculating member points
    if (customerData.customer !== 'not_login' && customerData.customer.customer_points !== undefined) {
      let calculatedMemberPoints = customerData.customer.customer_points;
      if (customerData.customer.winback_credit !== undefined && customerData.customer.winback_credit !== 'free_month') calculatedMemberPoints += Number(customerData.customer.winback_credit);
      setMemberPoints(calculatedMemberPoints);
    }
    if (customerData.customer != 'not_login') {
      const tag_list = customerData.customer.tag_list;
      const fetchedPrefs = customerPrefrence.customerPreferences;
      if (searchTag(tag_list, 'height') !== true && searchTag(tag_list, 'jeans size') !== true) {
        setIsCompleteProfileFill(true)
      } else {
        setIsCompleteProfileFill(false)
      }
      if (fetchedPrefs.preferences == null || (fetchedPrefs.preferences && (!fetchedPrefs.preferences.showNotificationPage || !fetchedPrefs.preferences.homePageNotification))) {
        if (fetchedPrefs.preferences== null || (!fetchedPrefs.preferences.showNotificationPage && !fetchedPrefs.preferences.homePageNotification)){
          setShowSflNotification('multiple')
        } else if (fetchedPrefs.preferences && (!fetchedPrefs.preferences.showNotificationPage) || fetchedPrefs.preferences && (!fetchedPrefs.preferences.homePageNotification)) {
          setShowSflNotification('single')
        }
        // setShowSflNotification(true)
      }else{
        setShowSflNotification('')
        // setShowSflNotification(false)
      }
    }
  }, [customerData.customer,customerPrefrence.customerPreferences]);

  useEffect(() => {
    // setting coupon code
    dispatch(getCustomerReferCode()).then(() => {
      const couponVal = customerData.customer !== 'not_login' ? customerData.customer.customer_refer_code : '';
      setCoupon(couponVal);
    });
  }, [customerData.customer.customer_refer_code]);

  let handleFavourite = () => {
    localStorage.removeItem('myFavouriteSeleted');
    localStorage.removeItem('myFavouriteScrollPosition');
    Router.push('/my-favorites')
  }

  const DrawerList = (
    <Box sx={{ width: 395}} role="presentation" onClick={toggleDrawer(false, true)}>
       <div className={`header_logincomp_container`}>
          {btnsData.map((item, index) => {
            if (item.isVisible === false) return <React.Fragment key={`${item.title}_${index}`}></React.Fragment>;
            return (
              item.href === '/my-favorites' ?
                <a key={`${item.title}_${index}`} onClick={() => { handleFavourite() }} className={`${`header_logincomp_btn`} ${index > 0 ? `header_logincomp_btnTopBorder` : ''}`}>
                  {item.title}
                </a> :
                <Link key={`${item.title}_${index}`} href={item.href || '#'} onClick={item.onClick} className={`header_logincomp_btn ${index > 0 ? 'header_logincomp_btnTopBorder' : ''} `}>
                  {item.title}
                </Link>
            );
          })}

          {renderingConfigData.topBarDropdown.logout && (
            <a className='header_logincomp_btn header_logincomp_signOut_btn' onClick={handleLogoutCustomer}>
              Sign Out
            </a>
          )}
        </div>
    </Box>
  );



  if (customerData.customer === 'not_login') return <Login openReviewPopup={false} buttonClassName={`header_logincomp_loginBtn`} />;
  return (
    <>
      <h3 className={`header_logincomp_loggedInTitle`} onClick={toggleDrawer(true, false)} >
        <span className={`header_logincomp_text`}>{loggedInUserName}</span>
        <span className={`header_logincomp_icon`}>
          <Icons.CaretDown />
        </span>
      </h3>

      <Drawer open={open} anchor='right' onClose={toggleDrawer(false, false)}>
        <div className='headerSiderBar_rs'>
            <Icons.Cross onClick={toggleDrawer(false, false)} size={20} className={'drawerHeaderCloseIcon'} />
            <br/>
            <CustomerOrderTracking toggleDrawer={toggleDrawer} />
        </div>
        {DrawerList}
      </Drawer>


      {/* <Popper className={`header_logincomp_popperContainer`} {...bindPopper(popupState)} keepMounted placement={'bottom-end'} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <div className={`header_logincomp_container`}>
              {btnsData.map((item, index) => {
                if (item.isVisible === false) return <React.Fragment key={`${item.title}_${index}`}></React.Fragment>;
                return (
                  item.href === '/my-favorites' ?
                    <a key={`${item.title}_${index}`} onClick={() => { handleFavourite() }} className={`${`header_logincomp_btn`} ${index > 0 ? `header_logincomp_btnTopBorder` : ''}`}>
                      {item.title}
                    </a> :
                    <Link key={`${item.title}_${index}`} href={item.href || '#'} onClick={item.onClick} className={`header_logincomp_btn ${index > 0 ? 'header_logincomp_btnTopBorder' : ''} `}>
                      {item.title}
                    </Link>
                );
              })}

              {renderingConfigData.topBarDropdown.logout && (
                <button className={'header_logincomp_logoutBtn'} onClick={handleLogoutCustomer}>
                  Logout
                </button>
              )}
            </div>
          </Fade>
        )}
      </Popper> */}
    </>
  );
};

export default LoginBtn;

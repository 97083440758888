import { Popup } from '@/libraries/material-ui';
import React from 'react';
import ContactSection from '@/containers/contact/contact-section/contact-section';
import styles from './contact-popup.module.scss';
import { Icons } from '@/libraries/icons';

interface IProps {
  isVisible: boolean;
  handleClosePopup: () => void;
}

const ContactPopup: React.FC<IProps> = ({ isVisible, handleClosePopup }) => {
  return (
    <Popup size="lg" isVisible={isVisible} handleClosePopup={handleClosePopup} className={styles.container}>
      <Icons.Cross className={styles.closeBtn} onClick={handleClosePopup} />
      <ContactSection />
    </Popup>
  );
};

export default ContactPopup;

import { SetDynamicCookie_NotificationKey, NotificationKeyCookieDataType } from '@/functions/dynamic-cookie-methods';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { Icons } from '@/libraries/icons';
import { useResponsive } from '@/libraries/responsive';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import { getNotificationMessage, hideNotificationBar } from '@/redux/thunks/notificationThunks';
import NotificationPopup from '@/containers/header/notification-bar/notification-popup/notification-popup';
import React, { useMemo, useState } from 'react';
import styles from './notification-bar-index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

interface IProps {
  showNotification: boolean
}

const NotificationBarIndex: React.FC<IProps> = ({ showNotification }) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  const customerData = useAppSelector((state) => state.customer);
  const notificationData = useAppSelector((state) => state.notification);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const notificationBarData = useMemo(() => {
    const notificationTitle = notificationData.notification?.notification_title ? JSON.parse(notificationData.notification.notification_title) : undefined;
    return {
      bg_color: notificationTitle?.bg_color || '',
      font_color: notificationTitle?.text_color || '',
      border_color: notificationTitle?.border_color === undefined || notificationTitle?.border_color?.length === 0 || notificationTitle?.border_color === '' ? notificationTitle?.bg_color : notificationTitle?.border_color,
      message: notificationTitle?.text || '',
      popup_desc: notificationData?.notification?.notification_description?.trim() || '',
      updated_at: notificationData?.notification?.updated_at,
      key: notificationData?.notification?.notification_key || '',
    };
  }, [notificationData]);


  const handleCloseNotificationBar = () => {
    const data: NotificationKeyCookieDataType = {
      message: notificationBarData.message,
      isShown: false,
      isSubscribed: customerData.customer === 'not_login' ? false : customerData.customer.has_subscription,
      updated_at: notificationBarData.updated_at,
    };

    dispatch(hideNotificationBar({ ...notificationData, showNotificationBar: false }));

    if (customerData.customer === 'not_login') {
      SetDynamicCookie_NotificationKey(notificationBarData.key, data);
      dispatch(getNotificationMessage(isTablet));
    } else if (customerData.customer.has_subscription === false) {
      SetDynamicCookie_NotificationKey(notificationBarData.key, data);
      dispatch(updateCustomerPreferences(data, notificationBarData.key));
      dispatch(getNotificationMessage(isTablet));
    } else {
      dispatch(updateCustomerPreferences(data, notificationBarData.key));
      dispatch(getNotificationMessage(isTablet));
    }
  };

  return (
    < >
      <NotificationPopup isVisible={isModalOpen} handleClosePopup={() => setIsModalOpen(false)} borderColor={notificationBarData.border_color} bodyHtml={notificationBarData.popup_desc} />
      <>
        { notificationData?.notification && Object.values(notificationData?.notification).length > 0 ?
          <div className={`notificationbar_container`} style={{ backgroundColor: notificationBarData.bg_color == '' ? ' rgb(255, 245, 249)' : notificationBarData.bg_color, color: notificationBarData.font_color == "" ? '#000' : notificationBarData.font_color }}>
            {<div className={`uber_text_container ${(customerData?.customer == 'not_login' || !customerData?.customer?.has_subscription) ? 'justify-content-center' : ''}`}>
              {notificationBarData.popup_desc === '' && <section className={`message`} dangerouslySetInnerHTML={{ __html: notificationBarData.message }} />}
              {notificationBarData.popup_desc !== '' && <section className={`message click_box`} onClick={() => setIsModalOpen(true)} dangerouslySetInnerHTML={{ __html: notificationBarData.message }} />}
            </div>}
          </div> 
          :
          <>
          {customerData?.customer !== 'not_login' && customerData?.customer.last_order_id == 0 && 
          <div className={`notificationbar_container`} style={{ backgroundColor: notificationBarData.bg_color == '' ? ' rgb(255, 245, 249)' : notificationBarData.bg_color, color: notificationBarData.font_color == "" ? '#000' : notificationBarData.font_color }}>
          <div className='uber_text_container' >
            {<Link className='click_box' href="/fedex"><section className={`message`}><span className='bold_txt'>Welcome to FashionPass 💖</span><span className='italic_txt'> Add items to your cart to get your 1st order started.</span></section></Link>}
          </div>
          </div>}
          </>
        }
      </>

      <>
        {notificationData?.notification && Object.values(notificationData?.notification).length > 0 ?
          <div className={`notificationbar_container_mobile`} style={{ backgroundColor: notificationBarData.bg_color == '' ? 'rgb(255, 245, 249)' : notificationBarData.bg_color, color: notificationBarData.font_color == "" ? '#000' : notificationBarData.font_color }}>
            {<div className={`uber_text_container_mobile ${(customerData?.customer == 'not_login' || !customerData?.customer?.has_subscription) ? 'justify-content-center' : ''}`}>
              {notificationBarData.popup_desc === '' && <section className={`message bold_txt`} dangerouslySetInnerHTML={{ __html: notificationBarData.message }} />}
              {notificationBarData.popup_desc !== '' && <section className={`message bold_txt click_box`} onClick={() => setIsModalOpen(true)} dangerouslySetInnerHTML={{ __html: notificationBarData.message }} />}
            </div>}
          </div> :
          <>
          {customerData?.customer !== 'not_login' && customerData?.customer.last_order_id == 0 && 
          <div className={`notificationbar_container_mobile`} style={{ backgroundColor: notificationBarData.bg_color == '' ? 'rgb(255, 245, 249)' : notificationBarData.bg_color, color: notificationBarData.font_color == "" ? '#000' : notificationBarData.font_color }}>
          <div className='uber_text_container_mobile'>
            <Link className='click_box' href='/fedex'>
              <p className='bold_txt'>Welcome to FashionPass 💖</p>
              <p className='italic_txt'>Add items to your cart to get your 1st order started.</p>
            </Link>
          </div>
          </div>}
          </>
        }

      </>
    </>
  );
};

export default NotificationBarIndex;

import Link from 'next/link';
import React from 'react';

interface IProps {
  title: JSX.Element | string;
  data:
    | {
        type: 'link';
        href: string;
        handleToggleDrawer: () => void;
      }
    | {
        type: 'button';
        onClick: (e: React.MouseEvent<HTMLElement>) => void;
      };
}

const DrawerBodyListItem: React.FC<IProps> = ({ title, data }) => {
  return (
    <>
      {data.type === 'link' && (
        <li onClick={data.handleToggleDrawer}>
          <Link href={data.href}>
            <div className={'drawerBodyListItem'}>
              <a className={'drawerBodyListItemText' + ((title?.toString()?.indexOf('(1)') > -1 || (title?.toString()?.indexOf('(2)') > -1 )) ? ' pinkHighLight' : '')}>{title}</a>
            </div>
          </Link>
        </li>
      )}
      {data.type === 'button' && (
        <li onClick={data.onClick} className={'drawerBodyListItem'}>
          <p className={'drawerBodyListItemText'}> {title} </p>
        </li>
      )}
    </>
  );
};

export default DrawerBodyListItem;

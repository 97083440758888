import { Pages } from '@/constants/pages';
import { useAppSelector } from '@/hooks/redux-hooks';
import Badge from '@mui/material/Badge';
import { bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import Router from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import CartPopperIndex from '@/containers/header/top-bar/cart-btn/cart-popper/cart-popper-index/cart-popper-index';
import { useResponsive } from '@/libraries/responsive';
import Link from 'next/link';
import Gconfig from 'globalconfig';

interface IProps { }

const cartPopperID = 'header-top-bar-cart-popper';

const CartBtnIndex: React.FC<IProps> = () => {
  const popupState = usePopupState({ variant: 'popper', popupId: cartPopperID });

  const cartData = useAppSelector((state) => state.cart);
  const customerData = useAppSelector((state) => state.customer);

  const [totalItemsInCart, setTotalItemsInCart] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showCartOnMobile, setShowCartOnMobile] = useState(false)
  const { isTablet } = useResponsive();

  const isCartEmpty = useMemo(() => Boolean((cartData.cart && cartData.cart === null) || (cartData.cart && cartData.cart !== null && cartData.cart?.length === 0) || (cartData.cart && cartData.cart.items && cartData.cart.items.length === 0) || cartData.cart === null), [cartData.cart]);
  const handleOnCartBtnClick = () => {
    if (!isCartEmpty) Router.push(`${Pages.CART}?fromCheckout=true`);
    if (isCartEmpty && isTablet) {
      setShowCartOnMobile(true);
      setTimeout(() => setShowCartOnMobile(false), 1000);
    }
  };

  useEffect(() => {
    if (cartData.cart !== null && cartData.cart !== undefined && cartData.cart.items) setTotalItemsInCart(cartData.cart.items.length);
    else setTotalItemsInCart(0);
  }, [cartData.cart]);

  return (
    <>
      <CartPopperIndex showCartOnMobile={showCartOnMobile} popupState={popupState} totalItemsInCart={totalItemsInCart} anchorEl={anchorEl} />
      <button className={`${isCartEmpty == true && customerData.customer == 'not_login' ? 'cursor-default' : '' } header_cart_btn`} style={{ marginLeft: customerData.customer == 'not_login' ? '6px' : "" }} ref={(ref) => setAnchorEl(ref)} onClick={handleOnCartBtnClick} {...bindHover(popupState)}>
        <Link href={!isCartEmpty ? Pages.CART + "?fromCheckout=true" : (customerData.customer != "not_login" ? '/cart' : "")} className={`${isCartEmpty == true && customerData.customer == 'not_login' ? 'pointer-events' : '' }`}>
          <img src={Gconfig.fashionpassImageStorage + "assets/ShoppingBag.svg"} alt="Cart icon" aria-label='Add to your cart to start an order.' className={`${isCartEmpty == true && customerData.customer == 'not_login' ? 'cursor-default' : '' } icon`} />
          {totalItemsInCart > 0 && <span className={`badge ${isTablet ? 'showSFLIcon' : ''}`}>{totalItemsInCart}</span>}
          {
            isTablet ?
              <span className='header_save_later_btn_filled mobileIcon' style={{ display: 'none' }} >
                <svg role='img' aria-label='Find all of the products you have saved for later.' width="10" height="12" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>Save for later icon</title>
                  <path d="M0.75 22.63V2.65674C0.75 1.742 1.49151 1.00049 2.40625 1.00049H15.3438C16.2585 1.00049 17 1.742 17 2.65674V22.63L9.12694 18.0373L8.875 17.8904L8.62306 18.0373L0.75 22.63Z" fill="#E89CAE" stroke="black" />
                </svg>
              </span> : ''
          }
        </Link>
      </button>

      {/* <button className={'header_cart_btn_later'}>
        <Link href={ !isCartEmpty ? Pages.CART+"?fromCheckout=true" : '/cart'}>
          <img src="https://images.fashionpass.com/assets/img/later_cart_icon.png" alt="cart icon" className={'icon'} />
        </Link>
      </button> */}
      {customerData.customer != "not_login" ? <div className='d-none d-md-inline-block' >
        <span className='header_save_later_btn'>
          <Link onClick={() => localStorage.setItem('savedLaterSectionSelectedDesktop', 'true')} href={!isCartEmpty ? Pages.CART + "?fromCheckout=true" : '/cart'}>
            <svg role='img' aria-label='Find all of the products you have saved for later.' width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Save for later icon</title>
              <path d="M0.75 22.63V2.65674C0.75 1.742 1.49151 1.00049 2.40625 1.00049H15.3438C16.2585 1.00049 17 1.742 17 2.65674V22.63L9.12694 18.0373L8.875 17.8904L8.62306 18.0373L0.75 22.63Z" stroke="black" />
            </svg>
          </Link>
        </span>

        <span className='header_save_later_btn_filled' style={{ display: 'none' }} >
          <Link onClick={() => localStorage.setItem('savedLaterSectionSelectedDesktop', 'true')} href={!isCartEmpty ? Pages.CART + "?fromCheckout=true" : '/cart'}>
            <svg role='img' aria-label='Find all of the products you have saved for later.' width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <title>Save for later icon</title>
              <path d="M0.75 22.63V2.65674C0.75 1.742 1.49151 1.00049 2.40625 1.00049H15.3438C16.2585 1.00049 17 1.742 17 2.65674V22.63L9.12694 18.0373L8.875 17.8904L8.62306 18.0373L0.75 22.63Z" fill="#E89CAE" stroke="black" />
            </svg>
          </Link>
        </span>
      </div> : ""}

    </>
  );
};

export default CartBtnIndex;

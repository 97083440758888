import { StoreThunk } from '@/redux/store';
import { filterDataType } from '@/redux/reducers/collectionPageReducer';
import { isEmpty } from '@/utils/object-methods';
import { TextExistsInString } from '@/utils/string-methods';
import { LogInfo } from '@/utils/dev-logs';
import { fetchFilterDataSuccess, fetchPaginationDataSuccess } from '@/redux/actions/collectionPageActions';
import Router from 'next/router';

// arguements = key, data, name (not required because of 'data' type), urlName, noFoundClear, lastResultNotFound
export const filterQuerySubmit = (key: 'clearAll' | string, data?: 'all' | null | Partial<filterDataType>, name?: '' | null | keyof filterDataType, urlName?: string, noFoundClear?: boolean, lastResultNotFound?: boolean): StoreThunk => {
  return async (dispatch, getState) => {
    const collectionPageData = getState().collectionPage;
    const customerData = getState().customer;
    const customerPreferencesData = getState().customerPreferences;

    let filterData = collectionPageData.filterData.data;
    const paginationData = collectionPageData.paginationData.data;

    if (key === 'clearAll') {
      const prefSizes = !isEmpty(customerPreferencesData.customerPreferences.preferences) && !isEmpty(customerPreferencesData.customerPreferences.preferences?.sizes) ? customerPreferencesData.customerPreferences.preferences?.sizes.split(',') : '';
      const sizes: filterDataType['sizes'] = filterData.sizes?.map((item) => ({ ...item, size: item.size, selected: Boolean(TextExistsInString(prefSizes, item.size) && !lastResultNotFound) })) || [];
      const colors: filterDataType['colors'] = filterData.colors?.map((item) => ({ ...item, color: item.color, selected: false, paterns: Boolean(item.paterns) })) || [];
      const brands: filterDataType['brands'] = filterData.brands?.map((item) => ({ ...item, brand: item.brand, brand_id: item.brand_id, selected: false }));
      const occasion = filterData.occasion?.map((item) => (item.selected = false));
      const sort: filterDataType['sort'] = filterData?.sort?.filter(item => {item.selected = false; return item;})
      const season: filterDataType['season'] = filterData?.season?.filter(item => {item.selected = false; return item;})
      // if(location?.pathname.indexOf('/my-favorites') > -1 && filterData?.otherFilter?.length > 0){
        const otherFilter = filterData.otherFilter?.map((item) => (item.selected = false));
      // }

      if (filterData.filter && filterData.filter.length > 0) {
        filterData.filter.map((filter) => {
          filter.subfilter_categories.map((subFilter) => {
            subFilter.subfilter_lists.map((subList) => {
              subList.selected = false;
            });
          });
        });
      }

      const filter = filterData.filter;
      filterData.sizes = sizes;
      filterData.colors = colors;
      filterData.brands = brands;
      filterData.filter = filter;
      filterData.price = [];
      filterData.filters = undefined;
      filterData.sort = sort;
      filterData.season = season;
    } else {
      // filterData = data === 'all' ? filterData : { ...filterData, ...data };
      if (data === 'all') filterData = filterData;
      else filterData[name] = data;
    }

    paginationData.current_page = 1;
    if (urlName === undefined || urlName === '') paginationData.filterHit = true;
    if (key === 'clearAll') paginationData.clearCall = true;
    paginationData.noFoundClear = noFoundClear;
    paginationData.lastResultNotFound = lastResultNotFound;
    // if (location.pathname.indexOf('my-favorites') > -1) {
    //   paginationData.query = "all?"
    // }
    LogInfo('filterQuerySubmit method', `filterData = ${collectionPageData.filterData}`);
    dispatch(fetchFilterDataSuccess(filterData));
    dispatch(fetchPaginationDataSuccess(paginationData));
  };
};

export const resetDataQuerySubmit = (): StoreThunk => {
  return async (dispatch, getState) => {
    const collectionPageData = getState().collectionPage;
    const paginationData = collectionPageData.paginationData.data;
    paginationData.query = "all?";
    dispatch(fetchPaginationDataSuccess(paginationData));
  };
};

export const globalRedirectionNavigation = (path: string, filterClear: boolean, filterData?: filterDataType, urlHit: boolean = false, noFoundClear: boolean = false, lastResultNotFound: boolean = false, type:string): StoreThunk => {
  return async (dispatch, getState) => {
    if (filterClear && filterData) {
      if (Object.keys(filterData).length > 0) {
        if(location.pathname.indexOf(path)>-1) dispatch(filterQuerySubmit('clearAll', 'all', '', '', noFoundClear, lastResultNotFound));
        else dispatch(filterQuerySubmit('clearAll', 'all', '', path, noFoundClear, lastResultNotFound));
      }
    }
    
    if (type == "new_search"){
      Router.push("/" + path);
      return;
    } else {
      if (path) Router.push('/[...category_handle]', '/' + path);
    }
      // if(location.pathname?.includes(path)) Router.push('/' + path)
  };
};

import FooterIndex from '@/containers/footer/footer-index/footer-index';
import HeaderIndex from '@/containers/header/header-index/header-index';
import React from 'react';
import styles from './base-layout.module.scss';

interface IProps {
  children: React.ReactNode;
  withoutFooter?: boolean;
  withoutHeader?: boolean;
  style?: React.CSSProperties;
  className?: string;
  headerProps?: {
    isSignup?: boolean;
    isProductListingPage?: boolean;
    // faqPageSectionsData?: faqPageSectionsDataType;
    canShowBlackBox?: boolean;
    collectionPageData?: { isForSale: boolean; handleSizeToggle: (data: string) => Promise<void> };
  };
}

const BaseLayout: React.FC<IProps> = ({ withoutFooter = false, withoutHeader = false, headerProps, style, className, children }) => {
  return (
    <>
      {!withoutHeader && <HeaderIndex {...headerProps} />}
      <main style={style} className={`${styles.mainContainer} ${className}`}>
        {children}
      </main>
      {!withoutFooter && <FooterIndex />}
    </>
  );
};

export default BaseLayout;

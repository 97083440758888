import { identify, getCurrentSessionUrl } from 'react-fullstory';

type fullStoryUserVars = {
  name: string;
  displayName: string;
  email: string;
  lastorderID: string;
  Subscription_End_date: string;
  Subscription: string;
  Tags: string;
};

export const handleIdentifyFullStory = (userID: string, userVars: fullStoryUserVars) => identify(userID, userVars);

import { Category_SubCategoryDataType } from '@/services/collections';
import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import { API } from '@/constants/global-config';
import Router from 'next/router';

interface IProps {
  subCategory: Category_SubCategoryDataType;
  handleRedirection: (e: React.MouseEvent<HTMLElement>, linkedCategoryHandle: string | null | undefined) => void;
}

const alpha_array = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
  "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U",
  "V", "W", "X", "Y", "Z"
]

const NavBarDropdownDesktopMenu: React.FC<IProps> = ({ subCategory, handleRedirection }) => {
  const handleGenerateHeadingContents = (id: number | null, type: any) => {
    let filteredSubCats = []
    if (type == "brands") {
      filteredSubCats = subCategory.sub_categories.sort((a, b) => a.name.localeCompare(b.name));
    }
    else {
      filteredSubCats = subCategory.sub_categories.filter((item) => Boolean(item.heading_id === id || id === null));
    }

    return (
      <ul className={`desktop_headingsList`}>
        {filteredSubCats.map((item, index) =>
          item.linked_category !== undefined && item.linked_category !== null ? (
            <li key={index} className={`desktop_headingItem`}>
              <Link href={''+subCategory?.category_link?.toLowerCase() == "how-it-works" ? `/${item.linked_category.handle}` : "/[...category_handle]"} as={`/${item.linked_category.handle}`} className={`desktop_headingItemLink ${item.name?.indexOf('FP Merch') > -1 ? ' headingItemLink_pink' : ''}`} onClick={(e) => {''+subCategory?.category_link?.toLowerCase() == "how-it-works" ? { } : handleRedirection(e, item.linked_category?.handle) } }>
                {item.name.replace('-internal', '').replace('-main', '')}
              </Link>
            </li>
          ) : (
            <></>
          )
        )}
      </ul>
    );
  };

  return (
    <>
      {subCategory.images.map((item, index) => (
        <div key={index} className={`imagesContainer`}>
          {item.linked_category ? (
            <>
              <Link href="/[...category_handle]" as={`/${item.linked_category.handle}`} className={`categoryImgContainer`} onClick={(e) => handleRedirection(e, item.linked_category?.handle)}>
                <img className={`categoryImg`} layout="fill" alt={item.image_alt_text} src={item.image_url.replace('https://fashionpass.s3.us-west-1.amazonaws.com/', API.FASHION_PASS_IMAGE_STORAGE_URL).replace('.jpg', '.jpg?profile=b')} />
              </Link>
              <Link href="/[...category_handle]" as={`/${item.linked_category.handle}`} className={`imageText`} onClick={(e) => handleRedirection(e, item.linked_category?.handle)}>
                {item.image_alt_text}
              </Link>
            </>
          ) : (
            <div className={`categoryImgContainer`}>
              <img className={`categoryImg`} layout="fill" alt={item.image_alt_text} src={item.image_url.replace('https://fashionpass.s3.us-west-1.amazonaws.com/', API.FASHION_PASS_IMAGE_STORAGE_URL).replace('.jpg', '.jpg?profile=b')} />
            </div>
          )}
        </div>
      ))}

      {subCategory.name == "Brands" ? <div className={` d-flex justify-content-between brands_dropdown`}>
        <div className={`desktop_treeMenuContainer`} >
          <span className={`desktop_heading`}>Featured Brands</span>
          {handleGenerateHeadingContents(0, 'brands')}
        </div>
        <div className={`desktop_BrandMenuContainer`}>
          <div className='d-flex justify-content-between ms-4 brands_container'>
            <span className={`desktop_heading`}>Brands</span>
            <Link className="view_all" href='/brands'>view all</Link>
          </div>
          <div className="alpha_letters">
            <ul className='desktop_headingsList'>
              {alpha_array.map((letter) => (
                <li className='alpha' key={letter}><span onClick={() => { Router.push(`/brands?${letter}`) }} >{letter}</span></li>
              ))}
                <li className='alpha'><span onClick={() => Router.push('/brands?#')} >#</span></li>
            </ul>
          </div>
        </div>
      </div>
        : <></>}

      {subCategory.headings.length <= 0 && subCategory.name != "Brands" && <div className={`desktop_treeMenuContainer`}> {handleGenerateHeadingContents(null, '')} </div>}
      
      {subCategory.headings.length > 0 &&
        subCategory.headings.map((item, index) => subCategory.name != "Brands" && (
          <div key={index} className={`desktop_treeMenuContainer`}>
            <span className={`desktop_heading`}>{item.heading_text}</span>
            {handleGenerateHeadingContents(item.heading_id, '')}
          </div>
        ))}
    </>
  );
};

export default NavBarDropdownDesktopMenu;

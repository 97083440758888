import { Heading } from '@/components/heading';
import { Spinner } from '@/libraries/material-ui';
import { ContentItemDataType, getPageContentByHandle } from '@/services/content';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styles from './press-section.module.scss';

interface IProps {
  pressData?: ContentItemDataType | null;
}

const PressSection: React.FC<IProps> = ({ pressData }) => {
  const [press, setPress] = useState<{ isLoading: boolean; data: ContentItemDataType | undefined }>({ isLoading: true, data: undefined });

  const handleFetchPressData = async () => {
    const response = await getPageContentByHandle('press', {});
    setPress({ isLoading: false, data: response && response.success ? response.content_list[0] : undefined });
  };

  useEffect(() => {
    if (pressData === undefined || pressData === null) handleFetchPressData();
    else setPress({ isLoading: false, data: pressData });
  }, []);

  return (
    <div className={styles.container}>
      {/* <Heading.Love title="PRESS" classes={{ container: styles.title }} /> */}
      <h1 className={styles.press_header}>PRESS</h1>

      {press.isLoading && <Spinner />}
      {!press.isLoading && press.data && (
        <div className={styles.contentContainer}>
          <p className={styles.contentTitle}>
            FashionPass is a monthly subscription service <br className={styles.br} /> providing access to unlimited clothing and accessory <br className={styles.br} /> rentals from today&apos;s top designer brands.
          </p>
          <hr className={styles.divider} />
          <p className={styles.contentText}>
            Working on a story about FashionPass? Let us help you! <br /> Email our PR team at
            <Link href={`mailto:${press.data.detail}`} className={styles.link}>
              &nbsp;{press.data.detail}
              <span style={{ display: 'inline', color: 'var(--text-color-dark)' }}>.</span>
            </Link>
          </p>
          <p className={styles.contentText}>FashionPass is headquartered in Los Angeles, CA</p>
        </div>
      )}
    </div>
  );
};

export default PressSection;
